import api from "../../../api";
import useMap from "../../ui/hooks/useMap";

export default function useDistributionChannelOptions() {
	const [channels, loading, error] = api.distributionChannels.useAllOnce();

	const options = useMap(channels, (channel) => ({
		key: channel.id,
		value: channel.id,
		text: channel.name,
	}));

	return [options, loading, error];
}
