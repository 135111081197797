import React from "react";

import Input from "astrid-components/lib/components/Inputs/Input";
import Flex from "astrid-components/lib/components/Layout/Flex";
import Header from "astrid-components/lib/components/Text/Header";
import msToTime from "astrid-helpers/src/msToTime";

import useArtifactAudioPlayerTimeline from "../hooks/useArtifactAudioPlayerTimeline";

export default function ArtifactAudioPlayerTime({ action }) {
	const { position, duration, moveToPosition } = useArtifactAudioPlayerTimeline();

	return (
		<Flex paddingRight={10}>
			<Input.Time
				size="tiny"
				textSize="medium"
				value={position}
				disabled={action === "play"}
				style={{ width: 100, textAlign: "center" }}
				onChange={moveToPosition}
			/>

			<Header color="default" style={{ marginLeft: 10, fontSize: 14, fontWeight: 300 }}>
				{" / "}
				{msToTime(duration, false)}
			</Header>
		</Flex>
	);
}
