import { Icon } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import money from "astrid-helpers/src/money";

import themeColors from "../../ui/constants/themeColors";

export default function useProducerDashboardStatistics({ organization }) {
	const { i18n, t } = useTranslation();

	const { expenses, revenues, productions, users } = organization?.statistics || {};

	if (!organization?.statistics) {
		return [];
	}

	return [
		{
			total: money(revenues.total, organization.currencyDefault, 0, i18n.language),
			details: [
				{
					text: t("processed", "Processed"),
					icon: <Icon name="sack dollar" />,
					amount: money(revenues.processed, null, 0, i18n.language),
				},
				{
					text: t("unprocessed", "Unprocessed"),
					icon: <Icon name="sack xmark" />,
					amount: money(revenues.unprocessed, null, 0, i18n.language),
				},
			],
			label: t("revenues", "Revenues"),
			color: "green",
			detailColor: themeColors.GREEN,
			link: "costs/revenues",
			organization: organization,
		},
		{
			total: money(expenses.total, organization.currencyDefault, 0, i18n.language),
			details: [
				{
					text: t("processed", "Processed"),
					icon: <Icon name="sack dollar" />,
					amount: money(expenses.processed, null, 0, i18n.language),
				},
				{
					text: t("unprocessed", "Unprocessed"),
					icon: <Icon name="sack xmark" />,
					amount: money(expenses.unprocessed, null, 0, i18n.language),
				},
			],
			label: t("expenses", "Expenses"),
			color: "red",
			detailColor: themeColors.RED,
			link: "costs/expenses",
			organization: organization,
		},
		{
			total: productions.total,
			details: [
				{
					text: t("internal", "Internal"),
					icon: <Icon name="sign-in" />,
					amount: productions.internal,
				},

				{
					text: t("external", "External"),
					icon: <Icon name="sign-out" />,
					amount: productions.external,
				},
			],
			label: t("productions", "Productions"),
			color: "blue",
			detailColor: themeColors.BLUE,
			link: "productions",
			organization: organization,
		},
		{
			total: users.total,
			details: [
				{
					text: t("admin", "Admin"),
					icon: (
						<Icon.Group style={{ marginRight: 5, fontSize: 14.5 }}>
							<Icon name="user" />
							<Icon corner name="cog" />
						</Icon.Group>
					),
					amount: users.producerAdmin,
				},
				{ text: t("other", "Other"), icon: <Icon name="user" />, amount: users.producerStaff },
			],
			label: t("users", "Users"),
			link: "users",
			organization: organization,
		},
	];
}
