import DataTable from "../../../ui/components/DataTable/DataTable";

import useScriptsTableColumns from "../../hooks/useScriptsTableColumns";

export default function ProductionScriptsDataTable({ production, ...props }) {
	const data = Object.values(production.scripts || {});

	const columns = useScriptsTableColumns({ production });

	return (
		<DataTable
			data={data}
			hoverRow={false}
			columns={columns}
			visibleColumns={["name", "pages", "menu"]}
			{...props}
		/>
	);
}
