import SuperAdminDistributionChannelForm from "./components/SuperAdminDistributionChannelForm";
import SuperAdminDistributionChannelMetaDataTemplates from "./components/SuperAdminDistributionChannelMetaDataTemplates";

export default function SuperAdminDistributionChannelSettingsView({ channel }) {
	return (
		<>
			<SuperAdminDistributionChannelForm channel={channel} />
			<SuperAdminDistributionChannelMetaDataTemplates channel={channel} />
		</>
	);
}
