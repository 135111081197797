import { useTranslation } from "react-i18next";

import api from "../../../api";

export default function useDistributionPresetDropdownOptions({ publisher }) {
	const { t } = useTranslation();

	return (preset) => [
		{
			text: t("remove", "Remove"),
			action: () => {
				return api.organizations.removeDistributionPreset({
					publisher,
					preset,
				});
			},
		},
	];
}
