import { Icon, Menu, Search } from "semantic-ui-react";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useDebounce from "astrid-hooks/src/useDebounce";

import { useAlgoliaSearchClient } from "../../../algolia/state/algoliaSearchClient";
import { useOrganizationId } from "../../../organizations/state/organizationId";
import { useOrganizationType } from "../../../organizations/state/organizationType";

import resultRenderer from "./components/resultRenderer";

export default function QuickSearch() {
	const orgId = useOrganizationId();
	const orgType = useOrganizationType();
	const searchClient = useAlgoliaSearchClient();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [index, setIndex] = useState();

	const [search, setSearch] = useState("");
	const debouncedSearch = useDebounce(search, 500);
	const [result, setResult] = useState([]);

	const onSearch = (e, { value }) => {
		setSearch(value);
	};

	useEffect(() => {
		if (searchClient) {
			setIndex(searchClient.initIndex("productions"));
		}
		return () => {
			setIndex(undefined);
		};
	}, [searchClient]);

	useEffect(() => {
		if (index && debouncedSearch.length > 2) {
			// perform search
			setLoading(true);

			index
				.search(debouncedSearch, {
					restrictSearchableAttributes: [
						"title",
						"series",
						"isbn",
						"ebookISBN",
						"partISBN",
						"readerNames",
						"author",
					],
					filters: `${orgType}:${orgId}`,
				})
				.then((data) => {
					setResult(data.hits);
					setLoading(false);
				})
				.catch((err) => {
					console.log("Quick search error", err);
				});
		} else {
			setResult([]);
			setLoading(false);
		}
	}, [index, debouncedSearch, orgType, orgId]);

	const onSelect = (e, { result }) => {
		setSearch("");

		setTimeout(() => {
			document.getElementById("search").blur();
		}, 10);

		navigate("/production/" + result.id);
	};

	return (
		<Menu.Item as="label" style={{ cursor: "pointer", margin: 0, width: 140 }}>
			<Icon name="search" />
			<Search
				id="search"
				placeholder={t("titleOrIsbn")}
				value={search}
				input={{
					icon: null,
					transparent: true,
					loading,
				}}
				onSearchChange={onSearch}
				results={result.map((prod) => {
					const author = prod.author?.join(", ");

					return {
						key: prod.objectID,
						id: prod.objectID,
						title: prod.title,
						description: author,
						image: prod.imgThumb,
						language: prod.language,
					};
				})}
				resultRenderer={(value) => resultRenderer({ value })}
				onResultSelect={onSelect}
				noResultsMessage={t("noMatches")}
				noResultsDescription={<small>{t("quickSearchText")}</small>}
			/>
		</Menu.Item>
	);
}
