import { useCallback } from "react";

import useFirebaseHttpsCallableValue from "./useFirebaseHttpsCallableValue";

export default function useCollectionCount(query) {
	const [value, loading, error, callRef] = useFirebaseHttpsCallableValue("aggregation-onCallCount", {
		query,
	});

	const call = useCallback(() => {
		callRef.current();
	}, []);

	return [value?.data, loading, error, call];
}
