import { Route } from "react-router-dom";

import ForwardContext from "../../../../layouts/ForwardContext";

import AdminTitles from "../../pages/AdminTitles";

import adminTitleRoute from "./adminTitleRoute";

const adminTitlesRoute = (
	<Route path="titles" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("titles", "Titles") }}>
		<Route index element={<AdminTitles />} />

		{adminTitleRoute}
	</Route>
);

export default adminTitlesRoute;
