import firebase from "firebase/app";

import { useEffect, useMemo, useRef } from "react";

import useLoadingValue from "./useLoadingValue";

function getRef({ name, props }) {
	return JSON.stringify({ name, props });
}

export default function useFirebaseHttpsCallableValue(name = "", props = {}) {
	const ref = useRef("");
	const callRef = useRef();

	const { value, loading, error, reset, setValue, setError } = useLoadingValue(!!name);

	const nextRef = getRef({ name, props });

	useEffect(() => {
		if (ref.current === nextRef) {
			return;
		}

		ref.current = nextRef;

		const { name, props } = JSON.parse(nextRef);

		reset(!!name);

		if (name) {
			callRef.current = () => firebase.functions().httpsCallable(name)(props).then(setValue).catch(setError);
			callRef.current();
		}
	}, [nextRef, reset, setError, setValue]);

	return useMemo(() => [value, loading, error, callRef], [value, loading, error]);
}
