import { useOutletContext } from "react-router-dom";

import AdminExportPipelineView from "../../views/AdminExportView/AdminExportPipelineView";

export default function AdminExportPipeline() {
	const { export: theExport } = useOutletContext();

	if (!theExport.pipeline) {
		return null;
	}

	return <AdminExportPipelineView export={theExport} />;
}
