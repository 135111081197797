import { useTable as useReactTable } from "react-table";

import filterTypes from "../helpers/filterTypes";
import getUrlState from "../utils/getUrlState";

const noData = [];

export default function useTable({
	data,
	sortBy = [],
	columns = [],
	filters = [],
	plugins = [],
	pageSize = 30,
	getSubRows,
	initialState,
	hiddenColumns = [],
	autoResetSelectedRows = false,
	defaultSelectedRowIds = {},
	getRowId = (row, index) => index,
}) {
	return useReactTable(
		{
			getRowId,
			filterTypes,
			data: data || noData,
			columns,
			getSubRows,
			autoResetSelectedRows,
			disableMultiSort: true,
			autoResetPage: false,
			autoResetSortBy: false,
			autoResetFilters: false,
			autoResetExpanded: false,
			initialState: initialState ||
				getUrlState() || {
					sortBy: [sortBy],
					filters,
					pageSize,
					pageIndex: 0,
					hiddenColumns,
					selectedRowIds: defaultSelectedRowIds,
				},
		},
		...plugins,
	);
}
