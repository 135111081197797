export default async function runBatch(firebase, callback, transaction) {
	if (transaction) {
		return callback(transaction);
	}

	transaction = firebase.firestore().batch();

	const result = await callback(transaction);

	await transaction.commit();

	return result;
}
