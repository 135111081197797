import React from "react";
import { useTranslation } from "react-i18next";

import api from "../../../../../api";
import CountriesIncludedForm from "../../../../distribution/components/CountriesIncluded/CountriesIncludedForm";
import FormModal from "../../../../forms/components/FormModal/FormModal";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";
import ErrorMessage from "../../../../ui/components/Messages/ErrorMessage";

export default function CountryModal({ article, selectedChannel, onClose }) {
	const { t } = useTranslation();
	const { form, onSubmit, error } = useSimpleForm({
		defaultValues: {
			ref: article.ref,
			countries: selectedChannel.countries,
		},
		onSubmit: ({ ref, countries }) => {
			return api.articles.update(
				{
					ref,
					[`channels.${selectedChannel.id}.countries`]: countries,
				},
				{ version: false },
			);
		},
		onSuccess: onClose,
	});

	return (
		<FormModal
			form={form}
			header={t("editCountris", "Edit countries")}
			onSubmit={onSubmit}
			onClose={onClose}
			error={error}
		>
			<CountriesIncludedForm name="countries" />
			{error && <ErrorMessage error={error}>{t("errorMessageHeader", "Something went wrong")}</ErrorMessage>}
		</FormModal>
	);
}
