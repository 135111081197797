import FirestoreCollection from "../../firestore/FirestoreCollection";

import { ArticleBundle } from "./types/ArticleBundle";
import { ArticleBundleRef } from "./types/ArticleBundleRef";

export default class ArticleBundlesCollection extends FirestoreCollection {
	static collectionName = "articleBundles";
	static Schema = ArticleBundle;

	preProcess(data) {
		// If we have articles, we want to extract the ISBNs and IDs
		if (data.articles) {
			data.isbns = data.articles.map((article) => article.isbn).filter(Boolean);
			data.articleIds = data.articles.map((article) => article.id);
		}

		return data;
	}

	async sideEffects({ update, transaction }) {
		const articles = update.get("articles");

		for (const article of articles.removed()) {
			transaction.update(article.ref, { bundle: null });
		}

		for (const article of articles.added()) {
			transaction.update(article.ref, { bundle: ArticleBundleRef.parse(update.after) });
		}
	}

	addArticle({ article, bundle }) {
		return this.update({
			ref: bundle.ref,
			articles: [...bundle.articles, article],
		});
	}

	removeArticle({ article, bundle }) {
		return this.update({
			ref: bundle.ref,
			articles: bundle.articles.filter(({ id }) => id !== article.id),
		});
	}
}
