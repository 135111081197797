import { Divider } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import Flex from "../../../../../components/Flex/Flex";

export default function ArticleViewDistributionChannels({ channels }) {
	const { t } = useTranslation();

	const articleDistributionChannels = Object.values(channels).filter((channel) => channel.name);

	if (articleDistributionChannels.length === 0) {
		return null;
	}

	return (
		<>
			<Divider />

			<strong>{t("distributionChannels", "Distribution channels")}</strong>

			<div>
				{articleDistributionChannels.map(({ name, distribution }, index) => {
					return (
						<Flex key={index}>
							<div style={{ marginTop: 4 }}>{`${name} (${distribution.releaseDate})`}</div>
						</Flex>
					);
				})}
			</div>
		</>
	);
}
