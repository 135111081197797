import firebase from "firebase/app";

import createFirestoreAPI from "astrid-firestore/src/firestore/createFirestoreAPI";
import firestoreHooksPlugin from "astrid-firestore/src/firestore/plugins/firestoreHooksPlugin";
import { isDevelopment } from "astrid-helpers/src/env";

import { getProfile } from "./features/authentication/state/profile";

const api = createFirestoreAPI(firebase, {
	plugins: [firestoreHooksPlugin],
	getUser: getProfile,
	debug: isDevelopment,
});

export default api;
