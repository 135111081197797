import React from "react";
import { useTranslation } from "react-i18next";

import articleTypes from "astrid-firestore/src/api/articles/constants/articleTypes";
import { Article } from "astrid-firestore/src/api/articles/types/Article";

import api from "../../../../../api";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

import AdminForm from "../../../components/AdminForm/AdminForm";

export default function AdminArticleDurationForm({ article, locked, ...props }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		schema: Article,
		values: {
			ref: article.ref || null,
			pages: article.pages || null,
			duration: article.duration || null,
		},
		onSubmit: (data) => {
			return api.articles.update(data);
		},
		locked,
		...props,
	});

	return (
		<AdminForm header={t("duration", "Duration")} {...form}>
			<Form.Group widths="equal">
				<Form.NumberInput name="pages.start" label={t("startPage", "Start page")} />

				<Form.NumberInput name="pages.end" label={t("endPage", "End page")} />

				<Form.DurationInput
					name="duration"
					label={t("duration", "Duration") + " (hhh:mm:ss)"}
					disabled={article.type === articleTypes.EBOOK}
				/>
			</Form.Group>
		</AdminForm>
	);
}
