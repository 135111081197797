import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../authorization/constants/featureFlags";
import useFeatureFlag from "../../authorization/hooks/useFeatureFlag";

import SuperAdminDashboardView from "../views/SuperAdminDashboardView/SuperAdminDashboardView";

export default function SuperAdminDashboard() {
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature) {
		return <Unauthorized />;
	}

	return <SuperAdminDashboardView />;
}
