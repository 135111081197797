import React, { useContext } from "react";
import { useOutletContext } from "react-router-dom";

import ProductionOverviewView from "../views/ProductionOverviewView/ProductionOverviewView";
import ProductionOverviewViewOld from "../views/ProductionOverviewView/ProductionOverviewViewOld";
import ProductionDiscussion from "../views/ProductionView/components/ProductionDiscussion";
import ProductionViewContext from "../views/ProductionView/context/ProductionViewContext";

export default function ProductionOverview({ discussion }) {
	const outletContext = useOutletContext();
	const context = useContext(ProductionViewContext);

	const { isDesktop, permissions, fullProduction } = context;

	if (!isDesktop && discussion && fullProduction) {
		return (
			<ProductionDiscussion
				production={fullProduction}
				permissions={permissions}
				internalPublisher={discussion === "internal"}
			/>
		);
	}

	if (!fullProduction.articleIds) {
		return <ProductionOverviewViewOld {...outletContext} {...context} />;
	}

	return <ProductionOverviewView {...outletContext} />;
}
