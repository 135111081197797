import { useNavigate } from "react-router-dom";

import api from "../../../../api";
import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useTitlesTableColumns from "../../hooks/useTitlesTableColumns";

export default function TitlesCollectionDataTable({ publisher, imprint, serie, ...props }) {
	const navigate = useNavigate();

	const columns = useTitlesTableColumns();

	const query = api.titles.getQuery({
		publisher,
		imprint,
		serie,
	});

	return (
		<CollectionDataTable
			query={query}
			columns={columns}
			onClick={({ id, publisher }) => navigate(`/publisher/${publisher.id}/admin/titles/${id}`)}
			sortBy={{
				id: "created",
				desc: true,
			}}
			{...props}
		/>
	);
}
