import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { enumValues, timestamp } from "../../../types/_types";

import { ArticleRef } from "../../articles/types/ArticleRef";
import pipelineStatuses from "../../distribution/constants/pipelineStatuses";
import { Pipeline } from "../../pipelines/types/Pipeline";

const exportStatus = enumValues(pipelineStatuses).default(pipelineStatuses.PENDING);

export const Export = FirestoreDocument.extend({
	article: ArticleRef,
	pipeline: Pipeline,
	status: exportStatus,
	expires: timestamp(),
}).merge(FirestoreLifecycle);
