import { Card } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import LoadingContext from "../../../../ui/components/LoadingContext/LoadingContext";
import Vendor from "../../../../vendors/components/Vendor";

import AdminCard from "../../../components/AdminCard/AdminCard";

export default function AdminUserVendors({ user }) {
	const { t } = useTranslation();

	const vendors = Object.entries(user.vendors || {});

	return (
		<AdminCard header={t("vendors", "Vendors")}>
			<LoadingContext data={vendors}>
				<Card.Group>
					{vendors.map(([id, vendor]) => {
						return (
							<Card key={id}>
								<Card.Content>
									<Vendor vendor={vendor} />
								</Card.Content>
							</Card>
						);
					})}
				</Card.Group>
			</LoadingContext>
		</AdminCard>
	);
}
