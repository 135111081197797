import equal from "fast-deep-equal";

import { Button } from "semantic-ui-react";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useAsyncFn from "react-use/lib/useAsyncFn";

import updateDocument from "astrid-firestore/src/helpers/updateDocument";

import AdminCard from "../../../../admin/components/AdminCard/AdminCard";
import useOrganizationsOnce from "../../../../organizations/hooks/useOrganizationsOnce";
import Table from "../../../../ui/components/Table/Table";

import usePublishersColumns from "../hooks/usePublishersColumns";

function Footer({ producer, state }) {
	const { t } = useTranslation();

	const publishers = useMemo(() => Object.keys(state.selectedRowIds), [state.selectedRowIds]);

	const disabled = useMemo(() => equal(publishers, producer.publishers), [producer.publishers, publishers]);

	const [{ loading }, onClickSave] = useAsyncFn(() => updateDocument(producer.ref, { publishers }), [publishers]);

	return (
		<Button.Group>
			<Button primary loading={loading} disabled={disabled} onClick={onClickSave}>
				{t("save")}
			</Button>
		</Button.Group>
	);
}

export default function ProducerPublishers({ producer }) {
	const { t } = useTranslation();

	const publishers = useOrganizationsOnce({ type: "publisher" });
	const publishersColumns = usePublishersColumns();

	const selectedRowIds = useMemo(
		() => producer.publishers?.reduce((map, id) => ({ ...map, [id]: true }), {}) || {},
		[producer.publishers],
	);

	if (publishers.length === 0) {
		return false;
	}

	return (
		<AdminCard header={t("publisherAccess", "Publisher access")}>
			<Table
				sortable
				selectable
				filterable
				pagination
				data={publishers}
				columns={publishersColumns}
				initialState={{ pageSize: 5, selectedRowIds }}
				footer={<Footer producer={producer} />}
				sortBy={{
					id: "name",
					desc: false,
				}}
			/>
		</AdminCard>
	);
}
