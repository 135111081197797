import { Button, Icon } from "semantic-ui-react";

import { useState } from "react";
import useAsyncFn from "react-use/lib/useAsyncFn";

import { firebase } from "astrid-firebase";

import usePipelineStatusTranslations from "../../hooks/usePipelineStatusTranslations";
import distributionStatusColors from "../../utils/distributionStatusColors";

const getCloudRunStatus = firebase.functions().httpsCallable("distribution-getCloudRunStatus");

export default function CloudRunStatusLabel({ distribution }) {
	const [status, setStatus] = useState(null);
	const statusTranslations = usePipelineStatusTranslations();

	const [{ loading }, checkCloudRunStatus] = useAsyncFn(async () => {
		const response = await getCloudRunStatus({ jobId: distribution.jobId });
		setStatus(response.data);
	}, []);

	return distribution?.jobId ? (
		<Button
			size="mini"
			content={status ? statusTranslations[status] : "Check cloud run status"}
			icon={status ? <Icon name="redo" style={{ backgroundColor: "transparent" }} /> : null}
			loading={loading}
			labelPosition={status ? "right" : null}
			color={status ? distributionStatusColors[status] : "grey"}
			basic
			onClick={checkCloudRunStatus}
		/>
	) : null;
}
