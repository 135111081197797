import Label from "../../../ui/components/Label/Label";

import distributionStatusColors from "../../utils/distributionStatusColors";

import PipelineStatus from "./PipelineStatus";

export default function PipelineStatusLabel({ distribution, ...props }) {
	return (
		<Label color={distributionStatusColors[distribution.status]} {...props}>
			<PipelineStatus distribution={distribution} />
		</Label>
	);
}
