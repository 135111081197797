import React, { useCallback, useState } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import Divider from "astrid-components/lib/components/Layout/Divider";
import Panel from "astrid-components/lib/components/Layout/Panel";
import Theme from "astrid-components/lib/components/Theme";

import api from "../../../../api";
import Flex from "../../../../components/Flex/Flex";

import ArtifactAudioPlayerChapters from "./components/ArtifactAudioPlayerChapters";
import ArtifactAudioPlayerTimeline from "./components/ArtifactAudioPlayerTimeline";
import ArtifactAudioPlayerToolbar from "./components/ArtifactAudioPlayerToolbar";
import useArtifactAudioPlayer from "./hooks/useArtifactAudioPlayer";
import useArtifactAudioPlayerAudioFiles from "./hooks/useArtifactAudioPlayerAudioFiles";

export default function ArtifactAudioPlayer({ artifact }) {
	const [activeTool, setActiveTool] = useState(null);

	const audioFiles = useArtifactAudioPlayerAudioFiles({ artifact });

	const { play, pause, action } = useArtifactAudioPlayer({ audioFiles });

	const addMarker = useCallback(
		({ type, position }) => {
			api.artifacts.addMarker({ artifact, type, position, text: "Chapter" });
		},
		[artifact],
	);

	return (
		<Theme mode="semantic">
			<Flex>
				<Panel style={{ height: 300, flex: 1, margin: -1 }} overflow="hidden" background="secondary">
					<ArtifactAudioPlayerToolbar
						play={play}
						pause={pause}
						action={action}
						activeTool={activeTool}
						setActiveTool={setActiveTool}
					/>

					<Divider />

					<ArtifactAudioPlayerTimeline
						play={play}
						action={action}
						markers={artifact.markers}
						addMarker={addMarker}
						audioFiles={audioFiles}
						activeTool={activeTool}
						setActiveTool={setActiveTool}
					/>

					<Divider />

					<Timeline.Navigation />
				</Panel>

				<Panel style={{ height: 300, flex: 0.2, margin: -1 }} background="secondary">
					<ArtifactAudioPlayerChapters markers={artifact.markers} />
				</Panel>
			</Flex>
		</Theme>
	);
}
