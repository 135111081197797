import { useTranslation } from "react-i18next";

import api from "../../../api";

export default function useScriptDropdownMenuOptions({ production }) {
	const { t } = useTranslation();

	return (script) => [
		{
			as: "a",
			text: t("download", "Download"),
			href: script.file?.url,
			target: "_blank",
			download: true,
			disabled: !script.file?.url,
		},
		{
			text: t("remove", "Remove"),
			hidden: !production,
			action: () => {
				return api.productions.removeScript({
					production,
					script: script,
				});
			},
		},
		{
			text: t("delete", "Delete"),
			action: () => {
				return api.scripts.delete(script);
			},
		},
	];
}
