import { useTranslation } from "react-i18next";

import api from "../../../api";

export default function useDistributionArgsDropdownOptions({ publisher, setOpenEdit }) {
	const { t } = useTranslation();

	return (data) => {
		return [
			{
				text: t("edit", "Edit"),
				action: () => {
					setOpenEdit(data);
				},
			},
			{
				text: t("remove", "Remove"),
				action: () => {
					return api.organizations.removeDistributionArgs({
						publisher,
						channel: { id: data.channelId },
						action: data.action,
					});
				},
			},
		];
	};
}
