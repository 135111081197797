import { Divider, Label } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import Flex from "../../../../../components/Flex/Flex";

export default function ArticleViewThemaCodes({ themaCodes }) {
	const { t } = useTranslation();

	if (themaCodes.length === 0) {
		return null;
	}

	return (
		<>
			<Divider />

			<strong>{t("themaCodes", "Thema codes")}</strong>

			<Flex style={{ marginTop: 10 }} justifyContent="flex-start" gap={5}>
				{themaCodes.map((code) => {
					return (
						<Label style={{ marginLeft: 0 }} key={code}>
							{code}
						</Label>
					);
				})}
			</Flex>
		</>
	);
}
