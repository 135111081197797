import React from "react";
import { useTranslation } from "react-i18next";

import useCostTypeOptions from "../../../../costs/hooks/useCostTypeOptions";
import Form from "../../../../forms/components/Form/Form";
import CurrencySelect from "../../../../prices/components/CurrencySelect/CurrencySelect";
import usePriceUnitOptions from "../../../../prices/hooks/usePriceUnitOptions";
import ModalPrompt from "../../../../ui/components/ModalPrompt/ModalPrompt";

import useAutoApprovalRuleForm from "../hooks/useAutoApprovalRuleForm";
import submitAutoApprovalRule from "../utils/submitAutoApprovalRule";

const parts = ["user", "subcontractor"];

export default function AddAutoApprovalRule({ organization, onClose }) {
	const { t } = useTranslation();

	const costTypeOptions = useCostTypeOptions(parts);
	const costUnitOptions = usePriceUnitOptions("user");

	const form = useAutoApprovalRuleForm();
	const { formState, handleSubmit } = form;

	const onSubmit = ({ language, type, unit, currency, threshold }) => {
		return submitAutoApprovalRule({ organization, language, type, unit, currency, threshold });
	};

	return (
		<ModalPrompt
			header={t("autoApprove", "Auto approve")}
			onClose={onClose}
			disabled={!formState.isValid}
			onSave={handleSubmit(onSubmit)}
		>
			<Form form={form}>
				<Form.Group widths="3">
					<Form.Select name="type" label={t("costType", "Cost type")} options={costTypeOptions} />

					<Form.LanguageSelect
						name="language"
						label={t("language")}
						limitedOptions={organization?.languageOptions}
					/>

					<Form.Field name="currency">
						<CurrencySelect
							label={t("currency", "Currency")}
							limitedOptions={organization?.currencyOptions}
						/>
					</Form.Field>
				</Form.Group>

				<Form.Group widths="3">
					<Form.Select name="unit" label={t("unit", "Unit")} options={costUnitOptions} />

					<Form.NumberInput name="threshold" label={t("threshold", "Threshold")} />
				</Form.Group>
			</Form>
		</ModalPrompt>
	);
}
