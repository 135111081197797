import intersection from "lodash/intersection";
import union from "lodash/union";
import moment from "moment";

import { Button, Dimmer, Form, Header, Icon, Loader, Popup, Segment, Table } from "semantic-ui-react";

import React, { Component } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { base } from "astrid-firebase";
import addEvent from "astrid-firebase/src/utils";
import loginToken from "astrid-helpers/src/loginToken";
import NotificationButton from "astrid-web/src/components/calendar/NotificationButton";
import StudioAppIcon from "astrid-web/src/components/calendar/StudioAppIcon";
import { estimateStudioHours } from "astrid-web/src/components/production/fnc";
import { withStore } from "astrid-web/src/helpers/context";
import { durationToSec, secToDuration, toDate } from "astrid-web/src/helpers/fnc";

import SessionModal from "../../../features/calendar/components/SessionModal/SessionModal";
import getStudioHours from "../../../features/productions/utils/getStudioHours";
import compareOverlappingSessions from "../../../helpers/compareOverlappingSessions";

import Flex from "../../Flex/Flex";
import SessionsProgressBar from "../../progressbar/SessionsProgressBar";

class ProductionSessions extends Component {
	state = {
		loading: true,

		// logging
		loggingId: false,
		checklist: false,
		cubaseOutError: false,
		pageOutError: false,
		finishError: false,

		sessions: [],
		showCancelledSessions: false,
		sessionConnectionCounter: 1,
		studioStatus: {},

		// viewing/editing
		selectedSession: null,
		pageInterval: [],

		// affected peeps
		affectedStaff: [],
		notifications: {},

		// modal
		modal: null,
		preventModalClose: false,
	};

	componentDidMount() {
		// scroll to component if log link
		const qs = new URLSearchParams(window.location.search);
		const logSessionQuery = qs.get("logSession");
		if (logSessionQuery) {
			const arrival = qs.get("arrival");
			const finish = qs.get("finish");
			const recIn = qs.get("recIn");
			const recOut = qs.get("recOut");

			const newState = {
				scrollToLog: true,
				loggingLink: logSessionQuery,
				loggingData: { arrival, finish, recIn, recOut },
			};

			const pages = qs.getAll("pages");
			if (pages)
				newState.pageInterval = pages
					.map((p) => JSON.parse(p.replaceAll("undefined", "null")))
					.map(([a, b]) => ({ in: a, out: b }));

			this.setState(newState);

			// change status to booked?
			if (this.props.production.status === "accepted") {
				this.props.updateStatus("production");
			}
		}

		base.bindCollection("sessions", {
			context: this,
			state: "sessions",
			withIds: true,
			query: (ref) => ref.where("production", "==", this.props.productionId).orderBy("start"),
			then() {
				this.setState({ loading: false });

				// tell parent that production has sessions
				this.props.setHasSessions(!!this.state.sessions.filter((session) => session.status === "done").length);

				// open log on load
				if (logSessionQuery) {
					this.setState({ loggingId: logSessionQuery, scrollToLog: false });
					window.scrollTo({
						top: document.querySelector("#sessions").getBoundingClientRect().top,
						behavior: "smooth",
					});
					// remove query params
					this.props.history.replace(`/production/${this.props.productionId}/audiobook`);
				}

				// get studio status
				base.get("organizations/" + this.props.producer + "/studios", { withIds: true }).then((data) => {
					const studioStatus = data.reduce((prev, curr) => {
						prev[curr.id] = curr;
						return prev;
					}, {});

					this.setState({ studioStatus });
				});
			},
			onFailure(err) {
				console.log("Session get err", err);
			},
		});

		if (document.location.search.substr(0, 12) === "?editSession") {
			this.setState({
				selectedSession: document.location.search.substr(13),
				modal: "edit",
			});
		}

		// start ticking if user is a potential recorder
		const { isProducer } = this.props;
		if (
			isProducer &&
			(isProducer.includes("producerRecorder") ||
				isProducer.includes("producerAdmin") ||
				isProducer.includes("producerStaff"))
		) {
			this.interval = setInterval(this.tick, 60000);
		}
	}
	componentWillUnmount() {
		clearInterval(this.interval);
	}

	lastRender = 0;
	tick = () => {
		const now = +new Date();
		if (now - this.lastRender > 60000) {
			// console.log("60s session tick");
			this.setState({ triggeredUpdate: now });
		}
	};

	sessionAction = (e, data) => {
		e.preventDefault();
		e.stopPropagation();

		const { t } = this.props; //useTranslation();

		if (data.action === "log") {
			const opening = this.state.loggingId !== data.session;
			// start logging session
			this.setState(
				{
					loggingId: opening ? data.session : null,
					editLoggedSession: null,
					pageInterval: [],
					checklist: false,
				},
				() => {
					if (opening) {
						const lastPage = this.getLastPage();
						this.addPageInterval(lastPage);
					}
				},
			);
		} else if (data.action === "editLog") {
			// edit logged session

			// change old page data to page interval
			const sess = this.state.sessions.find((haystack) => data.session === haystack.id);
			if (!sess.pageInterval) sess.pageInterval = [{ in: sess.pageIn, out: sess.pageOut }];

			this.setState({
				checklist: true,
				loggingId: this.state.editLoggedSession !== data.session ? data.session : null,
				editLoggedSession: this.state.editLoggedSession !== data.session ? data.session : null,
				pageInterval: sess.pageInterval,
			});
		} else if (data.action === "deleteLog") {
			if (window.confirm(t("sureWantToDeleteLogin"))) {
				base.updateDoc("sessions/" + data.session, {
					status: "booked",
				})
					.then((ref) => {
						// all done
						this.calculateProductionStats();
						this.setState({
							loggingId: null,
							editLoggedSession: null,
						});
					})
					.catch((err) => {
						console.log("Session log error", err);
					});
			}
		} else {
			this.setState({
				// prevent closing if already open. just change session.
				preventModalClose: !!this.state.modal,
				selectedSession: data.session,
				modal: data.action,
			});
		}
	};

	logSession = async () => {
		const { splitProduction } = this.state;
		const { t } = this.props; //useTranslation();
		this.setState({
			savingLog: true,
		});

		// get info from session
		const session = this.state.sessions.find((session) => session.id === this.state.loggingId);
		const startDate = moment(session?.start.toDate()).format("YYYY-MM-DD");
		const isPolish = session.polish;

		// get log data
		let form = document.querySelector("#loggingForm");
		let arrival = form.querySelector("[name=arrival]").value;
		let finish = form.querySelector("[name=finish]").value;

		// new data
		let sessionData = {
			status: "done",
			arrival,
			finish,
		};

		if (!isPolish) {
			var cubaseIn = form.querySelector("[name=cubaseIn]").value;
			var cubaseOut = form.querySelector("[name=cubaseOut]").value;
			var cubaseInSec = durationToSec(cubaseIn);
			var cubaseOutSec = durationToSec(cubaseOut);
			var pageInterval = this.state.pageInterval;
			var readersLast = form.querySelector("[name=readersLast]").checked;
		} else {
			var polishNof = +form.querySelector("[name=polishNof]").value;
		}

		if (!this.props.production.pages) {
			window.alert(t("prodHasNoPages"));
			this.setState({ savingLog: false });
			return;
		} else if (arrival >= finish) {
			this.setState({ finishError: true, savingLog: false });
			return;
		} else if (!isPolish && cubaseInSec >= cubaseOutSec) {
			this.setState({ cubaseOutError: true, savingLog: false });
			return;
		} else if (isPolish && (!polishNof || polishNof < 1)) {
			this.setState({ polishNofError: true, savingLog: false });
			return;
		}

		if (!isPolish) {
			if (splitProduction || readersLast) {
				sessionData.end = moment(startDate + "T" + finish).toDate();
				sessionData.readersLast = readersLast;
			}

			sessionData = {
				...sessionData,
				cubaseIn,
				cubaseOut,
				pageInterval,
			};
		} else {
			sessionData.polishNof = polishNof;
		}

		if (session.status === "done") {
			console.log("Reset session status", this.state.loggingId);
			await base.updateDoc("sessions/" + this.state.loggingId, { status: "booked" });
		}

		// store
		base.updateDoc("sessions/" + this.state.loggingId, sessionData)
			.then((ref) => {
				this.setState({
					savingLog: false,
					loggingId: false,
					editLoggedSession: null,
					cubaseOutError: false,
					pageOutError: false,
					finishError: false,
				});

				if (splitProduction) {
					this.setState({
						savingLog: false,
					});

					// all set, create new session
					const newSessionData = {
						status: "booked",
						recorder: session.recorder || [],
						studio: session.studio,
						start: finish?.toDate ? finish.toDate() : new Date(`${startDate}T${finish}:00`),
						end: session.end,
						production: splitProduction,
						producer: session.producer,
						reader: session.reader || [],
					};

					// store in database
					base.addToCollection("sessions", newSessionData).then((ref) => {
						// go there (weird bug when going by react router... TODO: research)
						window.location = "/production/" + splitProduction + "/?logSession=" + ref.id;
					});
				}
			})
			.catch((err) => {
				console.log("Session log error", err);
			});
	};

	calculateSessionStats = (args) => {
		// use already logged session data if not supplied as argument
		const {
			session,
			cubaseDuration = session.stats ? session.stats.cubaseDuration : 0,
			sessionDuration = session.stats ? session.stats.sessionDuration : 0,
			plannedDuration = session.stats ? session.stats.plannedDuration : 0,
			recordedPages = session.stats ? session.stats.recordedPages : 0,
		} = args;

		const tempo = Math.round(recordedPages / (sessionDuration / 360000)) / 100;

		// % of planned time lost
		const loss = Math.round((1 - sessionDuration / plannedDuration) * 100);

		// % of planned time recorded
		const ratio = Math.round((cubaseDuration / sessionDuration) * 100);

		return {
			cubaseDuration,
			sessionDuration,
			plannedDuration,
			recordedPages,
			tempo,
			loss,
			ratio,
		};
	};

	calculateProductionStats = () => {
		let stats = null;
		const doneSessions = this.state.sessions.filter((session) => session.status === "done" && !session.polish);

		if (Object.keys(doneSessions).length) {
			stats = {};
			doneSessions.forEach((session, i) => {
				const sessionStats = [
					["pages", session.stats.recordedPages],
					["tempo", session.stats.tempo],
					["loss", session.stats.loss],
					["ratio", session.stats.ratio],
					["cubase", session.stats.cubaseDuration],
				];

				sessionStats.forEach(([name, val]) => {
					// nof values
					const values = 1 + (stats[name] ? stats[name].values : 0);
					// total amount (only useful for pages)
					const total = val + (stats[name] ? stats[name].total : 0);
					// average value
					const average = total / values;

					stats[name] = {
						values,
						total,
						average,
					};
				});
			});
		}

		// send to parent
		this.props.updateProductionsStats(stats);
	};

	connectionEvent = (event, studio) => {
		addEvent({
			productionId: this.props.productionId,
			user: this.props.store.state.user.uid,
			email: this.props.store.state.profile.email,
			data: { [event]: studio },
		});
	};

	addAffectedStaff = (update, ...staff) => {
		const affectedStaff = union(this.state.affectedStaff, ...staff);

		if (update) {
			this.setState({ affectedStaff });
		} else {
			return affectedStaff;
		}
	};

	addNotification = (session, type, staff) => {
		const notifications = { ...this.state.notifications };
		notifications[session] = {
			type: notifications[session] ? notifications[session].type : type,
			staff,
		};

		this.setState({ notifications });
	};

	modalClose = () => {
		if (this.state.preventModalClose) {
			this.setState({ preventModalClose: false });
		} else {
			this.setState({ modal: null, selectedSession: null });
		}
	};

	addPageInterval = (page) => {
		const newInterval = {};
		if (page) newInterval.in = page;
		this.setState({ pageInterval: [...this.state.pageInterval, newInterval] });
	};

	getLastPage = () => {
		const { pageInterval, sessions } = this.state;
		let lastSession = [...sessions].reverse().find((sess) => sess.status === "done" && !sess.polish);

		let lastPage;
		if (pageInterval.length < 2 && lastSession) {
			if (lastSession.pageInterval) {
				const last = Object.values(lastSession.pageInterval).pop();
				if (last) {
					lastPage = last.out;
				}
			} else if (lastSession.pageOut) {
				lastPage = lastSession.pageOut;
			}
		}
		return lastPage;
	};

	logForm = (session, loggingSessionData, loggedSessionData, lastSession) => {
		const { pageInterval, loggingData, loggingLink } = this.state;
		const { isProducer } = this.props;
		const { t } = this.props; //useTranslation();
		// set page interval initial state based on previous session
		const lastPage = this.getLastPage();

		const intervalComplete =
			loggingSessionData.polish ||
			(pageInterval.length &&
				!pageInterval.find((interval) => !(interval.in && interval.out && interval.in <= interval.out)));

		const minToStart = moment(toDate(session.start)).diff(moment(), "minutes");

		if (!loggingSessionData.start?.toDate) {
			return false;
		}

		const endDate = loggingSessionData.end.toDate().toISOString().replace(/T.+/, "");
		const startDate = loggingSessionData.start.toDate().toISOString().replace(/T.+/, "");

		return (
			<Table.Row positive className="session-logging-row">
				<Table.Cell colSpan={5} style={{ borderTop: 0, paddingTop: 10 }}>
					{!loggedSessionData &&
						(minToStart > 16 ? ( // 1 grace minute
							<em>{t("logoutFromSessionMessage")}</em>
						) : (
							<Form.Checkbox
								label={t("checkedCheckList")}
								name="checklist"
								checked={this.state.checklist}
								onChange={(e, data) => {
									this.setState({ checklist: data.checked });
								}}
							/>
						))}
					<Form
						id="loggingForm"
						onSubmit={this.logSession}
						loading={this.state.savingLog}
						style={{
							marginTop: "1em",
							opacity: !this.state.checklist ? 0.6 : 1,
							pointerEvents: !this.state.checklist ? "none" : "initial",
						}}
					>
						<Form.Group widths="equal">
							<Form.Input
								fluid
								type="time"
								required
								label={t("arrival")}
								disabled={!!(loggingLink === session.id && loggingData?.arrival)}
								defaultValue={
									loggingData?.arrival ||
									moment(
										loggedSessionData
											? loggedSessionData.arrival?.toDate
												? loggedSessionData.arrival.toDate()
												: new Date(`${startDate}T${loggedSessionData.arrival}:00`)
											: loggingSessionData.start.toDate(),
									).format("LT")
								}
								name="arrival"
							/>
							<Form.Input
								fluid
								type="time"
								required
								label={t("finish")}
								disabled={!!(loggingLink === session.id && loggingData?.finish)}
								defaultValue={
									loggingData?.finish ||
									moment(
										loggedSessionData
											? loggedSessionData.finish?.toDate
												? loggedSessionData.finish.toDate()
												: new Date(`${endDate}T${loggedSessionData.finish}:00`)
											: loggingSessionData.end.toDate(),
									).format("LT")
								}
								error={this.state.finishError}
								name="finish"
							/>
							{!loggingSessionData.polish ? (
								<>
									<Form.Input
										fluid
										type="text"
										required
										disabled={
											loggingSessionData.polish ||
											!!(loggingLink === session.id && loggingData?.recIn)
										}
										label={t("cubaseIn")}
										defaultValue={
											loggingData?.recIn ||
											(loggedSessionData
												? loggedSessionData.cubaseIn
												: lastSession
												? lastSession.cubaseOut
												: "0:00:00")
										}
										pattern="([0-9]{0,3})(:[0-5][0-9]){2}"
										name="cubaseIn"
									/>

									<Form.Input
										fluid
										type="text"
										required
										disabled={
											loggingSessionData.polish ||
											!!(loggingLink === session.id && loggingData?.recOut)
										}
										label={t("cubaseOut")}
										defaultValue={
											loggingData?.recOut ||
											(loggedSessionData ? loggedSessionData.cubaseOut : "0:00:00")
										}
										error={this.state.cubaseOutError}
										pattern="([0-9]{0,3})(:[0-5][0-9]){2}"
										name="cubaseOut"
									/>
								</>
							) : (
								<Form.Input
									fluid
									type="number"
									required
									label={t("poplishNrOfErrors")}
									name="polishNof"
									defaultValue={loggedSessionData ? loggedSessionData.polishNof : ""}
									error={this.state.polishNofError}
								/>
							)}
						</Form.Group>
						{!loggingSessionData.polish &&
							pageInterval.map((interval, i) => (
								<Form.Group key={i}>
									<div className="field">
										<label>{t("pageIntervall")}</label>
										<Button
											size="mini"
											circular
											icon="minus"
											onClick={() => {
												const pi = pageInterval;
												pageInterval.splice(i, 1);

												this.setState({ pageInterval: pi });
											}}
										/>
										<Button
											size="mini"
											circular
											icon="plus"
											color="blue"
											disabled={!intervalComplete}
											onClick={this.addPageInterval}
										/>
									</div>
									<Form.Input
										type="number"
										width={4}
										required
										label={t("pageIn")}
										value={interval.in || ""}
										onChange={(e, data) => {
											const pi = pageInterval;
											pi[i].in = parseInt(data.value, 10);
											this.setState({ pageInterval: pi });
										}}
										error={interval.out && interval.in && interval.in > interval.out}
										name="pageIn"
									/>
									<Form.Input
										type="number"
										width={4}
										required
										disabled={loggingSessionData.polish}
										label={t("pageOut")}
										value={interval.out || ""}
										onChange={(e, data) => {
											const pi = pageInterval;
											pi[i].out = parseInt(data.value, 10);
											this.setState({ pageInterval: pi });
										}}
										error={interval.in && interval.out && interval.out < interval.in}
										name="pageOut"
									/>
									{i === Object.keys(pageInterval).length - 1 && (
										<Form.Checkbox
											label={t("readersLast")}
											name="readersLast"
											style={{ paddingTop: "2.2rem" }}
											disabled={!!(loggedSessionData && loggedSessionData.readersLast)}
											defaultChecked={loggedSessionData ? loggedSessionData.readersLast : false}
										/>
									)}
								</Form.Group>
							))}
						{!loggingSessionData.polish && !pageInterval.length && (
							// no page interval
							<Form.Group>
								<div className="field">
									<label>{t("pageIntervall")}</label>
									<Button
										circular
										size="mini"
										color="blue"
										icon="plus"
										onClick={() => {
											this.addPageInterval(lastPage);
										}}
									/>
								</div>
							</Form.Group>
						)}

						{!!(intervalComplete && this.state.connectedSession) && (
							<em style={{ display: "block", textAlign: "right", marginBottom: "1em" }}>
								{t("putOn")} <Icon name="telephone text" /> {t("toSaveTheLogg")}
							</em>
						)}

						<Form.Group widths="equal">
							{session.status === "done" && (
								<div style={{ whiteSpace: "nowrap", marginLeft: 6, marginTop: 15 }}>
									<Button
										action={"deleteLog"}
										color={"red"}
										content={t("deleteLogg")}
										session={session.id}
										onClick={this.sessionAction}
										disabled={
											!(
												session.recorder === this.props.user.uid ||
												(isProducer &&
													(isProducer.includes("producerAdmin") ||
														isProducer.includes("producerStaff")))
											)
										}
									/>
								</div>
							)}

							<div className="field" />

							<Form.Button
								content={t("loggAndFinish")}
								icon="check"
								color="green"
								labelPosition="left"
								floated="right"
								disabled={
									!!this.state.connectedSession ||
									!this.state.checklist ||
									!!this.state.splitProduction ||
									!intervalComplete
								}
							/>

							<div style={{ clear: "both" }} />
						</Form.Group>
					</Form>
				</Table.Cell>
			</Table.Row>
		);
	};

	splitMenu = (session) => {
		const { t, store } = this.props;

		return (
			<div>
				{t("finishThisLogg")}
				<Form.Select
					size="small"
					placeholder={t("chooseProd")}
					style={{ margin: "5px 0" }}
					fluid
					loading={this.state.loadingProds}
					options={
						store.state.productions
							? Object.keys(store.state.productions)
									.filter((id) => {
										// same readers?
										const same = intersection(store.state.productions[id].reader, session.reader);
										return (
											!["done", "draft", "offer"].includes(store.state.productions[id].status) &&
											id !== session.production &&
											(!session.reader ||
												(store.state.productions[id].reader &&
													same &&
													same.length === session.reader.length))
										);
									})
									.sort((a, b) =>
										(toDate(store.state.productions[a].productionDate) ||
											toDate(store.state.productions[a].deliveryDate)) <
										(toDate(store.state.productions[b].productionDate) ||
											toDate(store.state.productions[b].deliveryDate))
											? 1
											: -1,
									)
									.map((id, i) => {
										const hours = estimateStudioHours(store.state.productions[id]);
										return {
											key: i + 1,
											text: store.state.productions[id].title + (hours ? ` (${hours}h)` : ""),
											value: store.state.productions[id].id,
										};
									})
							: [
									{
										key: "x",
										value: "",
										text: t("loadingLogg"),
									},
							  ]
					}
					onOpen={() => {
						this.setState({ splitOpen: session.id });
						if (this.state.loadingProds !== false) {
							this.setState({
								loadingProds: true,
							});
							store.getProductions().then(() => {
								this.setState({
									loadingProds: false,
								});
							});
						}
					}}
					value={this.state.splitProduction || ""}
					onChange={(e, data) => {
						this.setState({ splitProduction: data.value });
					}}
				/>
				{this.state.splitProduction && this.state.splitProduction !== true && (
					<Button
						color="green"
						fluid
						size="small"
						content={t("continue")}
						labelPosition="right"
						icon="arrow right"
						onClick={this.logSession}
					/>
				)}
			</div>
		);
	};

	summary = () => {
		const { t, production } = this.props;
		const { sessions } = this.state;

		let pagesRead = 0;
		let durationTotal = 0;

		sessions.forEach((session) => {
			if (session.stats && session.status !== "cancelled") {
				if (session.stats.cubaseDuration) durationTotal += session.stats.cubaseDuration;
				if (session.stats.recordedPages) pagesRead += session.stats.recordedPages;
			}
		});

		return (
			<div style={{ paddingTop: 10 }}>
				<span>
					{t("totalRecordedDuration")} {secToDuration(durationTotal)}
				</span>
				<p>
					{t("pagesReadTotal", {
						pagesRead: pagesRead,
						totalPages: production?.pages || "N/A",
					})}
				</p>
			</div>
		);
	};

	getStudioDomain = (session) => {
		const isAlpha = session.studioData?.version?.includes?.("alpha");
		const isBeta = session.studioData?.version?.includes?.("beta");
		const isDev = session.studioData?.version?.includes?.("dev");

		if (isAlpha) {
			return "stage.studio.astrid.fm";
		} else if (isBeta) {
			return "beta.studio.astrid.fm";
		} else if (isDev) {
			return "localhost:3001";
		} else {
			return "studio.astrid.fm";
		}
	};

	render() {
		this.lastRender = +new Date();

		const { sessions, selectedSession, editLoggedSession, loggingId, showLoggedInfo } = this.state;
		const { t, production, profile, productionId, productionRights, isProducer, handleChange } = this.props;

		// see if the user is a reader. but if the reader is self recording, don't count as reader.
		let { isReader } = this.props;
		if (isReader && profile.readerData && profile.readerData.selfRecorder) isReader = false;

		const { totalStudioHours, completedStudioHours, bookedStudioHours, remainingStudioHours } =
			getStudioHours(production);

		// get last session when logging
		let lastSession = false;
		const sessionReversed = [...sessions].reverse();
		if (loggingId) lastSession = sessionReversed.find((sess) => sess.status === "done");

		// get selected session data
		const selectedSessionData = selectedSession && sessions.find((haystack) => selectedSession === haystack.id);
		const loggingSessionData = loggingId && sessions.find((haystack) => loggingId === haystack.id);
		const loggedSessionData = editLoggedSession && sessions.find((haystack) => editLoggedSession === haystack.id);
		const bookedSessions = sessions.filter((session) => session.status === "booked");

		const showCancelledSessions = this.state.showCancelledSessions;

		const filteredSessions = this.state.showCancelledSessions
			? sessions
			: sessions.filter((session) => session.status !== "cancelled");

		const cancelledSessions = sessions.filter((session) => session.status === "cancelled").length;

		return (
			<div>
				{this.state.scrollToLog && !sessions.length && (
					// dim before scrolling down
					<Dimmer active inverted page>
						<Loader>{t("loadingRecToLogg")}</Loader>
					</Dimmer>
				)}
				<Segment.Group id="sessions">
					{(production.status !== "done" || !!sessions.length) && (
						<Segment padded style={{ overflow: "hidden" }} loading={this.state.loading}>
							<Flex alignItems="center">
								<div>
									<Header as="h4" icon="microphone" content={t("recSession")} />
								</div>
								{cancelledSessions > 0 && (
									<div>
										<Popup
											position="top center"
											content={
												showCancelledSessions
													? t("hideCancelledSessions", "Hide {{amount}} cancelled sessions", {
															amount: cancelledSessions,
													  })
													: t("showCancelledSessions", "Show {{amount}} cancelled sessions", {
															amount: cancelledSessions,
													  })
											}
											trigger={
												<Icon
													size="large"
													link
													name={showCancelledSessions ? "eye slash" : "eye"}
													onClick={() =>
														this.setState({
															showCancelledSessions: !showCancelledSessions,
														})
													}
												/>
											}
										/>
									</div>
								)}
							</Flex>
							{/* session list */}
							{!!sessions.length && (
								<div style={{ overflowX: "auto", marginBottom: 20, marginTop: 20 }}>
									<Table sortable compact basic="very">
										<Table.Header>
											<Table.Row>
												<Table.HeaderCell collapsing>{t("technician")}</Table.HeaderCell>
												<Table.HeaderCell>{t("narrator")}</Table.HeaderCell>
												<Table.HeaderCell>{t("studio")}</Table.HeaderCell>
												<Table.HeaderCell>{t("time")}</Table.HeaderCell>
												<Table.HeaderCell collapsing />
											</Table.Row>
										</Table.Header>
										<Table.Body>
											{/* list booked sessions */}
											{filteredSessions.map((session, i) => {
												// who is recording?
												let rec =
													this.props.userMap &&
													Object.values(this.props.userMap).find(
														(user) => user.id === session.recorder,
													);

												const reader = session.reader?.map((id) => {
													const user = this.props.userMap[id];
													return user?.firstName + " " + user?.lastName;
												});

												const canEdit =
													this.props.canEdit &&
													(session.recorder === this.props.user.uid ||
														(isProducer &&
															((!rec && isProducer.includes("producerRecorder")) ||
																isProducer.includes("producerAdmin") ||
																isProducer.includes("producerStaff"))));

												const canCall =
													session.recorder === this.props.user.uid ||
													(isProducer &&
														((!rec && isProducer.includes("producerRecorder")) ||
															isProducer.includes("producerAdmin") ||
															isProducer.includes("producerStaff")));

												const isPrio = session.recorderPrio;

												if (!rec)
													rec = {
														firstName: (
															<span style={{ whiteSpace: "nowrap" }}>
																{t("missingTechn")}{" "}
																<Icon
																	name={isPrio ? "file powerpoint" : "warning sign"}
																	color={isPrio ? "red" : "orange"}
																/>
															</span>
														),
													};

												if (session.status === "booked") {
													// show connect button 15 min (+ 1 grace min) before and 60 min after session
													const minToStart = moment(toDate(session.start)).diff(
														moment(),
														"minutes",
													);
													const minToEnd = moment(toDate(session.end)).diff(
														moment(),
														"minutes",
													);
													const isSessionTime = minToStart <= 16 && minToEnd > -60;

													const studioDomain = this.getStudioDomain(session);

													const startsAfterToday =
														toDate(session.start) > moment().add(1, "day").startOf("day");

													return (
														<React.Fragment key={session.id}>
															<Table.Row
																positive={session.id === loggingId}
																className={
																	session.id === loggingId
																		? "session-logging-row"
																		: ""
																}
																style={
																	startsAfterToday
																		? {
																				opacity: ".6",
																				background: "#f7f7f7",
																		  }
																		: null
																}
															>
																<SharedSessionColumns
																	session={session}
																	rec={rec}
																	studio={
																		this.props.studios?.[session.studio] ||
																		session.studioData
																	}
																	studioStatus={this.state.studioStatus}
																	reader={reader}
																/>
																<Table.Cell textAlign="right" singleLine>
																	{!isReader && (
																		<>
																			<Button.Group size="mini">
																				<Popup
																					size="mini"
																					inverted
																					content={t("bookedTechnicianMess")}
																					trigger={
																						<Button
																							color={"blue"}
																							icon="phone volume"
																							size={"mini"}
																							loading={
																								this.state
																									.connectingSession ===
																								session.id
																							}
																							onClick={
																								canCall && isSessionTime
																									? () => {
																											this.setState(
																												{
																													connectingSession:
																														session.id,
																												},
																											);

																											loginToken().then(
																												(
																													token,
																												) => {
																													const url = `https://${studioDomain}?token=${token}&production=${productionId}&studio=${session.studio}&session=${session.id}`;

																													window.open(
																														url,
																													);
																													this.setState(
																														{
																															connectingSession:
																																null,
																														},
																													);
																												},
																											);
																									  }
																									: null
																							}
																							style={
																								canCall && isSessionTime
																									? null
																									: {
																											cursor: "default",
																											opacity:
																												"0.5",
																									  }
																							}
																						/>
																					}
																				/>
																				{[
																					{
																						key: "log",
																						icon:
																							loggingId === session.id
																								? "minus"
																								: "check",
																						color: "green",
																						action: "log",
																						popup:
																							loggingId === session.id
																								? t("closeLogging")
																								: t("loggSession"),
																						disabled: !canEdit,
																					},
																					{
																						key: "edit",
																						icon: "pencil",
																						action: "edit",
																						color: "grey",
																						popup: t("editSession"),
																						disabled: !canEdit,
																					},
																				].map((btn) => (
																					<Popup
																						size="mini"
																						inverted
																						key={btn.action}
																						content={btn.popup}
																						trigger={
																							<Button
																								icon={btn.icon}
																								color={btn.color}
																								action={btn.action}
																								disabled={btn.disabled}
																								session={session.id}
																								onClick={
																									this.sessionAction
																								}
																								style={
																									btn.action ===
																										"log" &&
																									this.state
																										.connectedSession ===
																										session.id
																										? {
																												display:
																													"none",
																										  } // hide log button while connected to studio
																										: null
																								}
																							/>
																						}
																					/>
																				))}
																			</Button.Group>
																		</>
																	)}
																</Table.Cell>
															</Table.Row>
															{session.id === loggingId &&
																this.logForm(
																	session,
																	loggingSessionData,
																	loggedSessionData,
																	lastSession,
																)}
														</React.Fragment>
													);
												} else {
													return (
														<React.Fragment key={session.id}>
															<Table.Row
																positive={session.status !== "cancelled"}
																negative={session.status === "cancelled"}
																className={
																	session.id === loggingId
																		? "session-logging-row"
																		: ""
																}
																verticalAlign="top"
															>
																<SharedSessionColumns
																	session={session}
																	rec={rec}
																	studio={
																		this.props.studios?.[session.studio] ||
																		session.studioData
																	}
																	studioStatus={this.state.studioStatus}
																	production={production}
																	isProducer={isProducer}
																	handleChange={handleChange}
																	reader={reader}
																/>

																<Table.Cell textAlign="right" style={{ width: "25%" }}>
																	{compareOverlappingSessions(session, sessions) && (
																		<Popup
																			size="mini"
																			inverted
																			content={
																				"sidor/tider överlappar med annan session"
																			}
																			trigger={
																				<Icon
																					name="warning"
																					color="grey"
																					style={{ paddingLeft: 13 }}
																				/>
																			}
																		/>
																	)}
																	{session.status === "cancelled" ? (
																		<p style={{ textAlign: "left" }}>
																			{session.cancelReason}
																		</p>
																	) : (
																		session.status === "done" &&
																		production.status === "production" &&
																		!isReader && (
																			<Button.Group
																				size="mini"
																				style={{ marginLeft: 12 }}
																			>
																				<Button
																					icon={
																						showLoggedInfo === session.id
																							? "minus"
																							: "info"
																					}
																					color={"teal"}
																					onClick={() => {
																						this.setState({
																							showLoggedInfo:
																								showLoggedInfo ===
																								session.id
																									? null
																									: session.id,
																						});
																					}}
																				/>

																				{[
																					{
																						icon:
																							session.id === loggingId
																								? "minus"
																								: "pencil",
																						color: "grey",
																						action: "editLog",
																						popup:
																							session.id === loggingId
																								? t("closeLogging")
																								: t("editLogg"),
																					},
																					// {
																					// 	icon: "cancel",
																					// 	color: "red",
																					// 	action: "deleteLog",
																					// 	popup: "Radera logg",
																					// },
																				].map((btn) => (
																					<Popup
																						size="mini"
																						inverted
																						content={btn.popup}
																						key={btn.action}
																						hoverable={
																							btn.action === "splitLog"
																						}
																						trigger={
																							<Button
																								icon={btn.icon}
																								action={btn.action}
																								color={btn.color}
																								session={session.id}
																								onClick={
																									this.sessionAction
																								}
																								disabled={
																									!(
																										session.recorder ===
																											this.props
																												.user
																												.uid ||
																										(isProducer &&
																											(isProducer.includes(
																												"producerAdmin",
																											) ||
																												isProducer.includes(
																													"producerStaff",
																												)))
																									)
																								}
																							/>
																						}
																					/>
																				))}
																			</Button.Group>
																		)
																	)}
																</Table.Cell>
															</Table.Row>
															{session.id === showLoggedInfo && (
																<Table.Row>
																	<Table.Cell
																		colSpan={5}
																		positive
																		className="session-logging-row"
																	>
																		<div className="flex-stack">
																			<p>
																				<strong>{t("sessionArrival")}</strong>{" "}
																				{session.arrival?.toDate
																					? moment(
																							session.arrival.toDate(),
																					  ).format("LT")
																					: session.arrival}
																				<br />
																				<strong>
																					{t("sessionFinish")}
																				</strong>{" "}
																				{session.finish?.toDate
																					? moment(
																							session.finish.toDate(),
																					  ).format("LT")
																					: session.finish}
																			</p>

																			{session.cubaseIn && (
																				<p>
																					<b>{t("timeLine")}</b>
																					<br />
																					{t("start")} {session.cubaseIn}
																					<br />
																					{t("stop") + ":"}{" "}
																					{session.cubaseOut}
																					{session.stats && (
																						<>
																							<br />
																							{t("sessionTime")}{" "}
																							{secToDuration(
																								session.stats
																									.cubaseDuration,
																							)}
																						</>
																					)}
																				</p>
																			)}
																			{(session.pageInterval ||
																				session.pageIn) && (
																				<p>
																					<b>{t("pages")}</b>
																					<br />
																					{session.pageInterval ? (
																						session.pageInterval.map(
																							(interval, i) => (
																								<span key={i}>
																									{interval.in} -{" "}
																									{interval.out}
																									<br />
																								</span>
																							),
																						)
																					) : (
																						<div>
																							{t("start")}{" "}
																							{session.pageIn}
																							<br />
																							{t("stop") + ":"}{" "}
																							{session.pageOut}
																						</div>
																					)}
																					{t("readText")}{" "}
																					{session.stats?.recordedPages || 0}
																				</p>
																			)}

																			{!isReader && session.stats && (
																				<p>
																					<b>{t("statistics")}</b>
																					<br />
																					{t("sessionTempo")}{" "}
																					{session.stats.tempo}
																					<br />
																					{t("sessionLoss")}{" "}
																					{session.stats.loss}%
																					<br />
																					{t("sessionRatio")}{" "}
																					{session.stats.ratio}%
																				</p>
																			)}

																			{session.polishNof && (
																				<p>
																					<b>{t("noOfCorrections")}:</b>{" "}
																					{session.polishNof}
																				</p>
																			)}
																		</div>
																	</Table.Cell>
																</Table.Row>
															)}

															{session.id === loggingId &&
																this.logForm(
																	session,
																	loggingSessionData,
																	loggedSessionData,
																	lastSession,
																)}
														</React.Fragment>
													);
												}
											})}
										</Table.Body>
									</Table>
								</div>
							)}

							<div className="flex-stack" style={{ alignItems: "center" }}>
								<div>
									{/* show booking button user has rights and is not logging */}

									{(productionRights.includes("bookSession") ||
										(profile.readerData && profile.readerData.selfRecorder)) &&
										!loggingId &&
										!editLoggedSession && (
											<Button
												as={Link}
												to={`/producer/${production.producer}/calendar/${production.id}`}
												primary
												content={t("bookSession")}
												icon="calendar plus outline"
												labelPosition="left"
											/>
										)}

									{(productionRights.includes("bookSession") ||
										(profile.readerData && profile.readerData.selfRecorder)) &&
										!loggingId &&
										!editLoggedSession && (
											<Button
												as={Link}
												to={`/calendar/${productionId}`}
												content={t("bookSession") + " (Old)"}
												primary
											/>
										)}

									{/* mail notification button */}
									{!!bookedSessions.length && !isReader && !!this.state.affectedStaff.length && (
										<NotificationButton
											affectedStaff={this.state.affectedStaff}
											onClick={() => {
												this.setState({
													modal: "notify",
												});
											}}
											style={{ float: "right" }}
										/>
									)}
								</div>
								<div style={{ maxWidth: 300, width: "100%" }}>
									<Popup
										trigger={
											<SessionsProgressBar
												bookedHours={
													(completedStudioHours + bookedStudioHours) / totalStudioHours
												}
												completeHours={completedStudioHours / totalStudioHours}
											/>
										}
										inverted
										size="mini"
										content={
											totalStudioHours ? (
												<>
													<div>
														<b>{t("studioHours", "Studio hours")}</b>
														<br />
														<b>
															{t("estimatedTotalStudioHours", "Estimated total")}:
														</b> ca {totalStudioHours} {t("hours")}
													</div>
													<b>{t("completedStudioHours", "Completed")}: </b>
													{completedStudioHours} {t("hours")}
													<br />
													<b>{t("bookedStudioHours", "Booked")}:</b> {bookedStudioHours}{" "}
													{t("hours")}
													<br />
													<b>{t("remainingStudioHours", "Remaining")}:</b>{" "}
													{remainingStudioHours} {t("hours")}{" "}
													{remainingStudioHours < 0 && `(${t("overbooked", "Overbooked")})`}
												</>
											) : (
												<>
													<b>
														{t("bookedStudioTime")} {bookedStudioHours} {t("hours")}
													</b>
												</>
											)
										}
									/>
								</div>
							</div>
							{this.summary()}
						</Segment>
					)}
				</Segment.Group>
				{this.state.modal === "edit" && selectedSessionData && (
					<SessionModal session={selectedSessionData} sessions={sessions} onClose={this.modalClose} />
				)}
			</div>
		);
	}
}

export default withTranslation()(withStore(ProductionSessions));

const SharedSessionColumns = ({ session, rec, studio, studioStatus, production, isProducer, handleChange, reader }) => {
	const { t } = useTranslation();
	return (
		<>
			<Table.Cell selectable singleLine>
				{session.recorder && rec.phone ? (
					<Link
						to="#"
						onClick={(e) => {
							window.location.href = `tel:${rec.phone}`;
							e.preventDefault();
						}}
					>
						{rec.firstName} {rec.lastName}
					</Link>
				) : (
					<p>
						{rec.firstName} {rec.lastName}
					</p>
				)}
			</Table.Cell>
			<Table.Cell collapsing>{reader}</Table.Cell>
			<Table.Cell selectable collapsing>
				<a
					href={"https://www.google.com/maps/search/?api=1&query=" + studio?.address}
					target="_blank"
					rel="noopener noreferrer"
				>
					<StudioAppIcon studioStatus={studioStatus} studio={session.studio} />{" "}
					{session.studio && studio?.name}{" "}
					<Popup
						trigger={<Icon fitted name="location arrow" color="blue" />}
						content={studio?.address}
						size="mini"
						inverted
					/>
				</a>
			</Table.Cell>
			<Table.Cell collapsing>
				{session.start && session.start.toDate && moment(session.start.toDate()).format("YYYY-MM-DD LT")} -{" "}
				{session.end && session.end.toDate && moment(session.end.toDate()).format("LT")}{" "}
				{session.polish && (
					<Popup trigger={<Icon name="paint brush" />} content={t("fineSession")} size="mini" inverted />
				)}
				{session.polish &&
					session.polishNof &&
					production?.status === "production" &&
					isProducer &&
					isProducer.includes("producerAdmin") && (
						<Form.Checkbox
							label={t("readyForMaster")}
							checked={production.polishDone}
							style={{ marginTop: 4, fontSize: ".9em" }}
							onChange={(e) => {
								handleChange(null, {
									name: "polishDone",
									value: !production.polishDone,
								});
							}}
						/>
					)}
			</Table.Cell>
		</>
	);
};
