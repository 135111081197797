import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import PrimaryButton from "../../../ui/components/Buttons/PrimaryButton";
import useSearchInput from "../../../ui/hooks/useSearchInput";

import ProductionsInstantSearchDataTable from "../../../productions/components/ProductionsInstantSearchDataTable/ProductionsInstantSearchDataTable";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

export default function AdminProductionsView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("allProductions", "All productions")}>
				{searchInput}

				<PrimaryButton as={Link} to={`/${organization.type}/${organization.id}/create-production/`}>
					{t("createProduction", "Create production")}
				</PrimaryButton>
			</AdminHeader>

			<ProductionsInstantSearchDataTable
				producer={organization}
				searchQuery={searchQuery}
				visibleColumns={["title", "publisherName", "productionType", "status", "created", "productionDate"]}
			/>
		</>
	);
}
