import { useDebounce } from "use-debounce";

import { useMemo } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import audioWave from "astrid-helpers/src/audioWave";
import map from "astrid-helpers/src/map";

export default function useAudioWave(buffer, { end, start, fadein, fadeout, volume }) {
	const [debouncedSize] = useDebounce(Timeline.getSize(), 300);

	return useMemo(
		() =>
			buffer &&
			audioWave({
				end,
				start,
				fadein,
				fadeout,
				volume,
				sampleRate: buffer.sampleRate,
				width: Math.ceil(map(end - start, 0, debouncedSize, 0, Timeline.getRect().width)),
				data: Array.from({ length: buffer.numberOfChannels }, (v, i) => i).map((i) => buffer.getChannelData(i)),
			}),
		[buffer, debouncedSize, end, fadein, fadeout, start, volume],
	);
}
