import FirestoreCollection from "../../firestore/FirestoreCollection";

import { DistributionChannel } from "./types/DistributionChannel";

export default class DistributionChannelsCollection extends FirestoreCollection {
	static collectionName = "distributionChannels";
	static Schema = DistributionChannel;

	// uniqueKeys: ["name"],

	createPipeline({ channel, id, data }) {
		return this.update({
			ref: channel.ref,
			[`pipelines.${id}`]: { id, ...data },
		});
	}

	updatePipeline({ channel, pipeline, data }) {
		return this.update({
			ref: channel.ref,
			...Object.entries(data).reduce(
				(acc, [key, value]) => ({
					...acc,
					[`pipelines.${pipeline.id}.${key}`]: value,
				}),
				{},
			),
		});
	}

	deletePipeline({ channel, pipeline }) {
		return this.update(
			{
				ref: channel.ref,
				[`pipelines.${pipeline.id}`]: this.utils.delete(),
			},
			{
				validate: false,
			},
		);
	}
}
