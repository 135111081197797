import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import AddArticlesToTitleForm from "./AddArticlesToTitleForm";

export default function AddArticlesToTitleButton({ title }) {
	const { t } = useTranslation();

	return (
		<OpenButton text={t("addArticle", "Add article")}>
			{({ setOpen }) => <AddArticlesToTitleForm title={title} onClose={() => setOpen(false)} />}
		</OpenButton>
	);
}
