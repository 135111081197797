import { Button, Header, Radio, Segment } from "semantic-ui-react";

import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { firebase } from "astrid-firebase";

import Message from "../../../features/ui/components/Messages/Message";

const countProductions = firebase.functions().httpsCallable("algolia-countProductions");

export default function IndexQuestions() {
	const { t } = useTranslation();
	const [selectedQuestion, setSelectedQuestion] = useState();
	const [loading, setLoading] = useState(false);
	const [result, setResult] = useState();

	const questions = useMemo(() => {
		return [
			{
				id: "numberOfProductionsForStorytelPublishers",
				label: t(
					"numberOfProductionsForStorytelPublishers",
					"Calculate total number of productions for storytel publishers",
				),
				questionFn: () => {
					setLoading(true);
					setResult(undefined);
					const filters =
						"status:done AND publisher:lIHTmCbJGCNjHfvDXgzo OR publisher:UZ5tZfqwNIzXB5aeNZap OR publisher:iP0GjiQjrA3cTJE4g1Hi OR publisher:FJFODA31CaYsTaVwDoe6 OR publisher:bhx1EXClLYIXerZOBiAo OR publisher:xLyLfyl0FlpmA4aperBO OR publisher:ruABAUe7e3vwR3C36hmF OR publisher:T4mo7AkxOVNqlpj3GbdP OR publisher:ozg1MvOnQhVF23unAApF OR publisher:4HMSow3MiUKSAouR93rD";
					countProductions({ query: "", filters, facetFilters: "", groupBy: "publisherName" })
						.then(({ data }) => {
							console.log(JSON.stringify(data));

							const totalArray = Object.entries(data)
								.map(([key, value]) => {
									return `${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`;
								})
								.join(", ");

							setResult(totalArray);
							setLoading(false);
						})
						.catch((error) => {
							console.error(error);
							setLoading(false);
						});
				},
			},
		];
	}, [t]);

	return (
		<Segment.Group>
			<Segment>
				<Header as="h3">{t("indexQuestions", "Index questions (not statistics)")}</Header>
			</Segment>

			<Segment>
				<div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
					{questions.map((question) => {
						return (
							<Radio
								key={question}
								label={question.label}
								checked={selectedQuestion === question.id}
								onChange={() => setSelectedQuestion(question.id)}
							/>
						);
					})}

					<Button
						primary
						content={t("runChosenQuestion", "Run chosen question")}
						loading={loading}
						disabled={loading || !selectedQuestion}
						onClick={() => {
							const selected = questions.find((q) => q.id === selectedQuestion);
							if (!selected?.questionFn) {
								console.log("no function implemented!");
							} else {
								selected.questionFn();
							}
						}}
					/>

					{result && <Message style={{ marginTop: 0 }}>{result}</Message>}
				</div>
			</Segment>
		</Segment.Group>
	);
}
