import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import CreateScriptForm from "./CreateScriptForm";

export default function CreateScriptButton({ publisher, production, ...props }) {
	const { t } = useTranslation();

	return (
		<OpenButton text={t("uploadScript", "Upload script")} {...props}>
			{({ setOpen }) => (
				<CreateScriptForm publisher={publisher} production={production} onClose={() => setOpen(false)} />
			)}
		</OpenButton>
	);
}
