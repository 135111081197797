import { useTranslation } from "react-i18next";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import CreateImprintButton from "../../../imprints/components/CreateImprintForm/CreateImprintButton";
import ImprintsCollectionDataTable from "../../../imprints/components/ImprintsCollectionDataTable/ImprintsCollectionDataTable";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

export default function AdminImprintsView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("imprints", "Imprints")}>
				{searchInput}
				{organization && <CreateImprintButton organization={organization} />}
			</AdminHeader>

			<ImprintsCollectionDataTable publisher={organization} searchQuery={searchQuery} />
		</>
	);
}
