import anchorme from "anchorme";
import intersection from "lodash/intersection";
import moment from "moment/min/moment-with-locales";

import { Button, Comment, Form, Icon, Label, Popup } from "semantic-ui-react";

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { firebase } from "astrid-firebase";
import UploadButton from "astrid-web/src/components/UploadButton";
import { toDate } from "astrid-web/src/helpers/fnc";

class ProductionSingleComment extends Component {
	state = {
		reply: false,
		uploadText: null,
		uploadedFile: null,
		uploadedFileName: null,
		seriesComment: false,
	};

	uploadFile = (e) => {
		const storageRef = firebase
			.storage()
			.refFromURL(window.ES.stage ? "gs://stage-earselect-secret" : "gs://earselect-secret");

		// file from input
		const file = e.target.files[0];
		if (!file) return;

		// start upload
		const timestamp = +new Date(); // create folder to allow file names with identical names
		const uploadTask = storageRef
			.child("comments/" + this.props.productionId + "/" + timestamp + "/" + file.name)
			.put(file);

		// upload status events
		uploadTask.on(
			firebase.storage.TaskEvent.STATE_CHANGED,
			(snapshot) => {
				// progress
				const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
				this.setState({ uploadText: progress + "%" });
			},
			(error) => {
				// error
				console.log(error);
			},
			() => {
				// success, store in db
				uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
					this.setState({ uploadText: null, uploadedFile: downloadURL, uploadedFileName: file.name });
				});
			},
		);
	};

	getCommentBorderColor(comment = {}, userMap = {}, production = {}) {
		const permissions = userMap?.[comment?.user]?.permissions;

		const isProducerAdmin = permissions?.producer?.[production.producer]?.includes("producerAdmin");
		const isProducerStaff = permissions?.producer?.[production.producer]?.includes("producerStaff");
		const isPublisherAdminOrStaff = permissions?.publisher?.[production.publisher]?.some((permission) =>
			["publisherAdmin", "publisherStaff"].includes(permission),
		);

		if (isProducerAdmin) {
			return "#D4AC2B";
		} else if (isProducerStaff) {
			return "#A2D2FF";
		} else if (isPublisherAdminOrStaff) {
			return "#34BE82";
		} else {
			return undefined;
		}
	}

	render() {
		const { reply, uploadText, uploadedFile, uploadedFileName, seriesComment } = this.state;
		const {
			t,
			parent,
			uid,
			production,
			profile,
			publisher,
			isProducer,
			isPublisher,
			internalPublisher,
			productionRights,
			recipientData,
			filteredRecipients,
			seriesTitle,
			userMap,
			comment,
			parentSeriesComment,
			deleteComment,
			thumbComment,
			setAdminAttention,
			setSeriesComment,
			setCompletionReminder,
			setCommentRecipient,
			submitComment,
			replies,
		} = this.props;

		const selectableRecipients = [
			{ label: t("prodStaff"), id: "prodStaff" },
			{ label: t("publisher"), id: "publisher" },
			{ label: t("narrator"), id: "reader" },
		];

		if (production.subcontractor) selectableRecipients.push({ label: t("subcontractor"), id: "subcontractor" });

		const isProducerStaff = isProducer && isProducer.includes("producerStaff");
		const isProducerAdmin = isProducer && isProducer.includes("producerAdmin");

		const recpientLabels =
			!internalPublisher &&
			recipientData &&
			Object.keys(comment.recipient)
				.filter(
					// filter away admin, publisher and reader label for self
					(rec) =>
						// all posts are admin, so hide the label
						// except if you're a publisher and the post is confidential
						comment.recipient[rec] && !(isPublisher && rec === "publisher"),
				)
				.map((rec, i) => {
					const data = recipientData.find((data) => data.recipient === rec);
					let label = data && data.text;
					if (i) label = data && label.toLowerCase();
					return label;
				});

		const newComment = moment(toDate(comment.time)).diff(moment(), "hours") > -24;
		const commentBorderColor = this.getCommentBorderColor(comment, userMap, production);

		return (
			<Comment className={"clear" + (newComment && !seriesTitle ? " new" : "")} id={"comment-" + comment.id}>
				<Comment.Avatar
					as={Link}
					to={"/profile/" + comment.user}
					src={(userMap?.[comment.user] && userMap?.[comment.user].img) || "/img/avatar.png"}
				/>
				<Comment.Content>
					<div
						className="comment-inner"
						style={
							commentBorderColor && {
								border: "1.5px solid #d4d4d5",
								borderBottom: "none",
								borderRadius: "5px",
								boxShadow: `0 2.6px 0px ${commentBorderColor}`,
								padding: "3px",
								marginBottom: "10px",
							}
						}
					>
						<Comment.Author as={Link} to={"/profile/" + comment.user}>
							{userMap?.[comment.user] ? userMap?.[comment.user].firstName : t("unknownOrDeleted")}
							{profile.producer &&
								userMap?.[comment.user]?.permissions?.publisher?.[production?.publisher] && (
									<small> ({publisher})</small>
								)}
						</Comment.Author>
						<Comment.Metadata>
							<Popup
								trigger={
									<span style={{ display: "inline-block", marginRight: 5 }}>
										<Icon fitted name="time" />{" "}
										{comment.time && moment(comment.time.toDate()).fromNow()}
									</span>
								}
								content={comment.time && moment(comment.time.toDate()).format("llll")}
								size="mini"
								inverted
							/>
						</Comment.Metadata>
						{deleteComment && (comment.user === uid || isProducerAdmin) && (
							<Popup
								position="bottom left"
								size="mini"
								inverted
								content={t("clickToDelete")}
								trigger={
									<Icon
										name="trash alternate"
										style={{ cursor: "pointer" }}
										onClick={(e) => deleteComment(comment.id)}
									/>
								}
							/>
						)}
						{setAdminAttention && !internalPublisher && (comment.user === uid || isProducer) && (
							<Popup
								position="bottom left"
								size="mini"
								inverted
								content={comment.adminAttention ? t("clickToStopAskForAdm") : t("clickToAskForAdm")}
								trigger={
									<Icon
										color={comment.adminAttention ? "orange" : "grey"}
										name="fire"
										style={{ cursor: "pointer" }}
										onClick={(e) => {
											setAdminAttention(comment.id, !comment.adminAttention);
										}}
									/>
								}
							/>
						)}
						{setCompletionReminder && !internalPublisher && (isProducerAdmin || isProducerStaff) && (
							<Popup
								position="bottom left"
								size="mini"
								inverted
								content={
									comment.completionReminder ? t("clickToRemoveReminder") : t("clickToAddReminder")
								}
								trigger={
									<Icon
										color={comment.completionReminder ? "orange" : "grey"}
										name="lightbulb"
										style={{ cursor: "pointer" }}
										onClick={(e) => {
											setCompletionReminder(
												comment.id,
												comment.completionReminder ? false : true,
											);
										}}
									/>
								}
							/>
						)}
						{production.series &&
							!seriesTitle &&
							setSeriesComment &&
							(!comment.reply || parentSeriesComment) && (
								<Popup
									position="bottom left"
									size="mini"
									inverted
									content={comment.seriesComment ? t("clickToStopThisCom") : t("clickToThisCom")}
									trigger={
										<Icon
											color={comment.seriesComment ? "teal" : "grey"}
											name="stack overflow"
											style={{ cursor: "pointer", fontSize: "1.3em", marginTop: "-.3em" }}
											onClick={(e) => {
												setSeriesComment(comment.id, !comment.seriesComment);
											}}
										/>
									}
								/>
							)}
						{!comment.reply && (
							<div style={{ float: "right" }}>
								{((recpientLabels &&
									recpientLabels.length &&
									comment.recipient &&
									!internalPublisher) ||
									(isProducer && isProducer.includes("producerAdmin"))) && (
									<Popup
										content={
											<Form>
												{selectableRecipients.map((rec) => (
													<Form.Checkbox
														key={rec.id}
														checked={comment.recipient[rec.id]}
														label={rec.label}
														onClick={(e) => {
															setCommentRecipient(
																comment.id,
																rec.id,
																!comment.recipient[rec.id],
															);
														}}
													/>
												))}
												{comment.recipient.reader && (
													// list reader names
													<small style={{ display: "block", margin: "-1em 0 1em" }}>
														{(comment.readerUsers || production.reader || []).map(
															(readerId) => (
																<div key={readerId}>
																	{userMap?.[readerId] &&
																		userMap?.[readerId].firstName +
																			" " +
																			userMap?.[readerId].lastName}
																</div>
															),
														)}
													</small>
												)}
												<Form.Button
													color="green"
													content="Klar"
													onClick={(e) => {
														parent.setState({ editRecipient: null });
													}}
												/>
											</Form>
										}
										open={parent && parent.state && parent.state.editRecipient === comment.id}
										position="bottom right"
										size="tiny"
										trigger={
											<Label
												content={
													recpientLabels && recpientLabels.length ? (
														// span is needed for css selector
														<span>
															{recpientLabels.join(", ")}
															{/* warning icon if reader recipient is not same as current production readers */}
															{comment.recipient.reader &&
																comment.readerUsers &&
																production.reader &&
																intersection(comment.readerUsers, production.reader)
																	.length !== production.reader.length && (
																	<Icon
																		name="warning circle"
																		color="red"
																		style={{ marginLeft: 3 }}
																	/>
																)}
														</span>
													) : (
														""
													)
												}
												icon="eye"
												size="tiny"
												style={
													parent &&
													isProducer &&
													(isProducer.includes("producerAdmin") ||
														isProducer.includes("producerStaff"))
														? { cursor: "pointer" }
														: null
												}
												onClick={
													parent &&
													isProducer &&
													(isProducer.includes("producerAdmin") ||
														isProducer.includes("producerStaff"))
														? (e) => {
																parent.setState({ editRecipient: comment.id });
														  }
														: null
												}
											/>
										}
									/>
								)}
							</div>
						)}
						<Comment.Text>
							<div
								dangerouslySetInnerHTML={{
									__html: anchorme(comment.text, {
										truncate: [26, 15],
										attributes: [
											function (urlObj) {
												if (urlObj.protocol !== "mailto:")
													return { name: "target", value: "blank" };
											},
										],
									}),
								}}
							/>
							{comment.file && (
								<small style={{ display: "block", marginTop: ".5em" }}>
									<strong>{t("attachedFileComment")} </strong>
									<a
										href={comment.file}
										target="_blank"
										rel="noopener noreferrer"
										style={{ fontWeight: "bold" }}
									>
										<Icon fitted name="file" /> {comment.fileName}
									</a>
								</small>
							)}
						</Comment.Text>
						{thumbComment && (
							<Popup
								size="mini"
								inverted
								content={
									comment.thumbs && comment.thumbs.length
										? comment.thumbs.reduce((str, thumb, i) => {
												if (i && i < comment.thumbs.length - 1) str += ", ";
												else if (i && i === comment.thumbs.length - 1)
													str += " " + t("and") + " ";

												let name;
												if (userMap?.[thumb]) {
													name =
														thumb === uid
															? i
																? t("youSmall")
																: t("youCap")
															: userMap?.[thumb].firstName +
															  " " +
															  userMap?.[thumb].lastName;
												} else {
													name = thumb;
												}

												str += name;

												if (i === comment.thumbs.length - 1) str += t("givenThumbUp");

												return str;
										  }, "")
										: t("giveThumbUp")
								}
								position="bottom left"
								trigger={
									comment.thumbs && comment.thumbs.includes(uid) ? (
										<div style={{ display: "inline-block" }}>
											<Icon color="teal" name="thumbs up" />
											<small>
												<b>{comment.thumbs.length}</b>
											</small>{" "}
											<Icon
												name="delete"
												color="grey"
												size="small"
												style={{ cursor: "pointer" }}
												onClick={(e) => {
													thumbComment(comment.id, comment.thumbs);
												}}
											/>
										</div>
									) : (
										<div
											style={{ display: "inline-block", cursor: "pointer" }}
											onClick={(e) => {
												// turn off admin alert when producer thumbs publisher comment
												const adminAlertOff = isProducer && comment.recipient.publisher;
												thumbComment(comment.id, comment.thumbs, adminAlertOff);
											}}
										>
											<Icon name="thumbs up" color="grey" />
											<small>
												<b>
													{comment.thumbs && comment.thumbs.length
														? comment.thumbs.length
														: ""}
												</b>
											</small>
										</div>
									)
								}
							/>
						)}
					</div>

					{replies &&
						replies.map((reply) => (
							<TranslatedSingleComment
								key={reply.id}
								comment={reply}
								parentSeriesComment={comment.seriesComment}
								userMap={userMap}
								uid={uid}
								internalPublisher={internalPublisher}
								production={production}
								profile={profile}
								publisher={publisher}
								isProducer={isProducer}
								isPublisher={isPublisher}
								productionRights={productionRights}
								thumbComment={thumbComment}
								deleteComment={deleteComment}
								setAdminAttention={setAdminAttention}
								setSeriesComment={setSeriesComment}
								setCompletionReminder={setCompletionReminder}
							/>
						))}

					{!seriesTitle && submitComment && (
						<Button
							icon
							labelPosition="left"
							color={reply ? "grey" : "teal"}
							size="mini"
							style={{ cursor: "pointer", float: "right" }}
							onClick={(e) => {
								this.setState({ reply: !reply });
							}}
						>
							<Icon name={reply ? "x" : "reply"} /> {!reply ? t("answer") : t("close")}
						</Button>
					)}
					{seriesTitle && (
						<div style={{ fontWeight: "bold", fontSize: ".8em", textAlign: "right", marginTop: -5 }}>
							{t("commentFrom")} <Link to={"/production/" + comment.productionId}>{seriesTitle}</Link>
						</div>
					)}

					{reply && submitComment && (
						<Form
							className="clear comment-reply"
							style={{ overflow: "hidden", padding: "10px 0" }}
							onSubmit={(e) => {
								submitComment(
									e,
									comment.id,
									comment.recipient,
									uploadedFile,
									uploadedFileName,
									seriesComment,
								);
								this.setState({
									reply: false,
									uploadedFile: null,
									uploadedFileName: null,
								});
							}}
						>
							{!internalPublisher && (
								<div className="clear">
									{production.series && comment.seriesComment && (
										<Icon
											color={seriesComment ? "teal" : "grey"}
											name="stack overflow"
											style={{ cursor: "pointer", fontSize: "1.3em" }}
											onClick={(e, data) => this.setState({ seriesComment: !seriesComment })}
										/>
									)}

									<Label
										style={{ float: "right", marginBottom: 4 }}
										content={
											comment.recipient && Object.keys(comment.recipient).length ? (
												// span is needed for css selector
												<span>
													{Object.keys(comment.recipient).reduce((prev, curr) => {
														// skip publishers own label or falsy values
														if (
															!comment.recipient[curr] ||
															(isPublisher && curr === "publisher")
														)
															return prev;

														// build string for others
														const rec = recipientData.find(
															(rec) => rec.recipient === curr,
														).text;

														if (prev) {
															prev += ", " + rec.toLowerCase();
														} else {
															prev = rec;
														}

														return prev;
													}, "")}
												</span>
											) : (
												""
											)
										}
										icon="eye"
										size="tiny"
									/>
								</div>
							)}

							<Form.TextArea name="commentText" size="tiny" placeholder={t("writeAnswer")} required />

							<Button
								primary
								floated="right"
								size="tiny"
								disabled={!!uploadText}
								content={uploadText || t("send")}
							/>

							<div style={{ width: "calc(100% - 80px)", float: "left" }}>
								{!internalPublisher && filteredRecipients && (
									<Form.Group inline className="comment-recipient">
										<b>
											<Icon name="eye" />
											{t("visibleFor") + ":"}
										</b>
										{filteredRecipients.map((btn) => (
											<Form.Checkbox
												key={btn.recipient}
												size="tiny"
												checked={comment.recipient[btn.recipient]}
												disabled
												label={btn.text}
												recipient={btn.recipient}
											/>
										))}
									</Form.Group>
								)}

								{production.series && comment.seriesComment && (
									<Form.Checkbox
										label={t("showOtherBooksInSerie")}
										name="seriesComment"
										className="series-checkbox"
										checked={this.state.seriesComment}
										onChange={(e, data) => this.setState({ seriesComment: data.checked })}
									/>
								)}

								{!uploadedFileName ? (
									<UploadButton text={t("attacheFile")} size="small" onUpload={this.uploadFile} />
								) : (
									<small>
										<strong>{t("attachedFileComment")} </strong>
										<a
											href={uploadedFile}
											target="_blank"
											rel="noopener noreferrer"
											style={{ fontWeight: "bold" }}
										>
											<Icon fitted name="file" /> {uploadedFileName}
										</a>{" "}
										<Icon
											name="delete"
											color="grey"
											style={{ cursor: "pointer" }}
											onClick={(e) => {
												this.setState({ uploadedFile: null, uploadedFileName: null });
											}}
										/>
									</small>
								)}
							</div>
						</Form>
					)}
				</Comment.Content>
			</Comment>
		);
	}
}

const TranslatedSingleComment = withTranslation()(ProductionSingleComment);

export default TranslatedSingleComment;
