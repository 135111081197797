import * as Sentry from "@sentry/react";

import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

import { isDevelopment, isProduction, isStage } from "astrid-helpers/src/env";
import { name, version } from "astrid-web/package.json";

Sentry.init({
	dsn: "https://af221391a79e4ebf848eeeb24ed2d047@sentry.io/1724157",
	release: `${name}@${version}`,
	environment: (isProduction && ((isStage && "staging") || "production")) || "development",
	beforeSend(event) {
		// ignore events
		if (
			// ignore broken master tool player loop to avoid exhausting Sentry events...
			event.exception?.values?.[0]?.value === "Cannot read property '_id' of undefined" ||
			// ignore development errors
			isDevelopment
		) {
			return null;
		}

		return event;
	},
	integrations: [
		new Sentry.Feedback({
			autoInject: false,
		}),
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			),
		}),
		new Sentry.BrowserProfilingIntegration(),
	],
	tracesSampleRate: 1.0,
	tracePropagationTargets: [/astrid.fm/],
	profilesSampleRate: 1.0,
});

export default Sentry;
