import { Button, Header, List, Radio, Segment } from "semantic-ui-react";

import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { firebase } from "astrid-firebase";
import msToTime from "astrid-helpers/src/msToTime";
import round from "astrid-helpers/src/round";

import Flex from "../../../components/Flex/Flex";
import Select from "../../../features/ui/components/Select/Select";
import Table from "../../../features/ui/components/Table/Table";

import { quarters } from "../constants/quarters";
import { selectableStatisticQuestions } from "../constants/selectableStatisticQuestions";
import { years } from "../constants/years";

const browseProductionStatistics = firebase.functions().httpsCallable("algolia-browseProductionStatistics");

export default function DurationForQuarter() {
	const { t } = useTranslation();

	const [selectedYear, setSelectedYear] = useState(years[0].value);
	const [selectedQuarter, setSelectedQuarter] = useState(quarters[0]);
	const [result, setResult] = useState();
	const [loading, setLoading] = useState(false);
	const [selectedQuestion, setSelectedQuestion] = useState();

	const columns = useMemo(
		() => [
			{
				id: "name",
				Header: t("name"),
				collapsing: true,
			},
			{
				id: "deliveryDurationInHours",
				Header: `${t("totalDuration")} (h)`,
				collapsing: true,
				Cell: ({ value }) => {
					return round(value, 2);
				},
			},
			{
				id: "deliveryDurationString",
				Header: `${t("totalDuration")} (hh:mm:ss)`,
				collapsing: true,
			},
			{
				id: "numberOfProductions",
				Header: t("productions", "Productions"),
				collapsing: true,
			},
		],
		[t],
	);

	const questionTranslations = useMemo(() => {
		return {
			calculateDurationForStorytelProducers: t("calculateDurationForStorytelProducers"),
			calculateDurationForStorytelPublishers: t("calculateDurationForStorytelPublishers"),
		};
	}, [t]);

	const totalDuration = result && result.reduce((acc, value) => acc + value.deliveryDurationInHours, 0);

	const totalDurationAsString = totalDuration && msToTime(totalDuration * 3600000, false, true);

	return (
		<Segment.Group>
			<Segment>
				<Header as="h3">{t("predefinedQuestions", "Predefined questions")}</Header>
			</Segment>

			<Segment>
				<div style={{ display: "flex", flexDirection: "column", gap: 7 }}>
					<div>
						<Header
							as="h5"
							content={t("selectYear", "Select year")}
							style={{ marginBottom: 5, fontSize: 13 }}
						/>

						<Select
							fluid
							value={selectedYear}
							options={years}
							onChange={(_, { value }) => setSelectedYear(value)}
						/>
					</div>

					<div>
						<Header
							as="h5"
							content={t("selectQuarter", "Select quarter")}
							style={{ marginTop: 7, marginBottom: 7, fontSize: 13 }}
						/>

						<div style={{ display: "flex", justifyContent: "space-between", width: "40%" }}>
							{quarters.map((quarter) => {
								return (
									<Radio
										key={quarter.name}
										label={quarter.name}
										checked={selectedQuarter.name === quarter.name}
										onChange={() => setSelectedQuarter(quarter)}
									/>
								);
							})}
						</div>
					</div>

					<div>
						<Header
							as="h5"
							content={t("selectQuestion", "Select question")}
							style={{ marginTop: 7, marginBottom: 7, fontSize: 13 }}
						/>

						{Object.keys(selectableStatisticQuestions).map((question) => {
							return (
								<Flex flexDirection="column" alignItems="flex-start" key={question}>
									<Radio
										style={{ marginBottom: 8 }}
										label={questionTranslations[question]}
										checked={selectedQuestion === question}
										onChange={() => setSelectedQuestion(question)}
									/>
								</Flex>
							);
						})}
					</div>

					<Button
						primary
						content={t("runChosenQuestion", "Run chosen question")}
						loading={loading}
						disabled={loading || !selectedQuestion || !selectedQuarter}
						onClick={() => {
							setLoading(true);
							setResult(undefined);
							Promise.all(
								selectableStatisticQuestions[selectedQuestion].map((question) => {
									return new Promise((resolve, reject) => {
										browseProductionStatistics({
											filter: `${
												question.filter
											} AND statisticsDate_timestamp:${selectedQuarter.from(
												selectedYear,
											)} TO ${selectedQuarter.to(selectedYear)}`,
										})
											.then(({ data }) => {
												const totalDurationInHours = data?.reduce((acc, value) => {
													return acc + (value?.production?.deliveryDurationInHours || 0);
												}, 0);

												const totalDurationAsString = msToTime(
													totalDurationInHours * 3600000,
													false,
													true,
												);

												resolve({
													id: question.id,
													name: question.name,
													deliveryDurationInHours: totalDurationInHours,
													deliveryDurationString: totalDurationAsString,
													numberOfProductions: data.length,
												});
											})
											.catch((error) => {
												console.error(error);
												reject(error);
											});
									});
								}),
							)
								.then((result) => {
									setLoading(false);
									setResult(result);
								})
								.catch((error) => {
									console.error(error);
									setLoading(false);
								});
						}}
					/>

					{result && (
						<Table sortable data={result} columns={columns} style={{ marginTop: 10 }} footer={false} />
					)}

					{result && (
						<Flex style={{ width: "70%", marginLeft: 1, marginTop: 5 }}>
							<List.Item>
								<p>
									<b>{`${t("totalDuration")} (h): `}</b>
									{round(totalDuration, 2)}
								</p>
							</List.Item>
							<List.Item>
								<p>
									<b>{`${t("totalDuration")} (hh:mm:ss): `}</b>
									{totalDurationAsString}
								</p>
							</List.Item>

							<List.Item>
								<p>
									<b>{`${t("numberOfProductions", "Number of productions")}: `}</b>
									{result.reduce((acc, value) => acc + value.numberOfProductions, 0)}
								</p>
							</List.Item>
						</Flex>
					)}
				</div>
			</Segment>
		</Segment.Group>
	);
}
