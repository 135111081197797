import { useTranslation } from "react-i18next";

import TitlesCollectionDataTable from "../../../titles/components/TitlesCollectionDataTable/TitlesCollectionDataTable";

import AdminCard from "../../components/AdminCard/AdminCard";

export default function AdminImprintTitlesView({ imprint }) {
	const { t } = useTranslation();

	return (
		<AdminCard header={t("titles", "Titles")}>
			<TitlesCollectionDataTable imprint={imprint} />
		</AdminCard>
	);
}
