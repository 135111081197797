import pipelineStatuses from "astrid-firestore/src/api/distribution/constants/pipelineStatuses";

const distributionStatusColors = {
	[pipelineStatuses.PENDING]: "blue",
	[pipelineStatuses.SCHEDULED]: "blue",
	[pipelineStatuses.INITIALIZED]: "yellow",
	[pipelineStatuses.RUNNING]: "yellow",
	[pipelineStatuses.CANCELLED]: "black",
	[pipelineStatuses.COMPLETED]: "green",
	[pipelineStatuses.FAILED]: "red",
};

export default distributionStatusColors;
