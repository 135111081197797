import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import ExpiringAgreementsView from "../views/ExpiringAgreementsView/ExpiringAgreementsView";

export default function AdminExpiringAgreements() {
	const { organization } = useOutletContext();

	const hasRight = useHasRight();

	if (!hasRight("agreements.view")) {
		return <Unauthorized />;
	}

	return <ExpiringAgreementsView firstPartyId={organization.id} />;
}
