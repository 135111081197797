import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { array, string } from "../../../types/_types";

import { OrganizationRef } from "../../organizations/types/OrganizationRef";

export const Imprint = FirestoreDocument.extend({
	name: string(3),
	publisher: OrganizationRef,
	titleIds: array(string()),
	articleIds: array(string()),
}).merge(FirestoreLifecycle);
