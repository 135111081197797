import { useTranslation } from "react-i18next";

import articleStatuses from "astrid-firestore/src/api/articles/constants/articleStatuses";

export default function useArticleStatusOptions() {
	const { t } = useTranslation();

	return [
		{ key: "draft", text: t("draft", "Draft"), value: articleStatuses.DRAFT },
		{ key: "ready", text: t("readySound", "Ready"), value: articleStatuses.READY },
	];
}
