import { defaultObject, enumValues } from "../../../types/_types";

import articleDeliveryStatuses from "../constants/articleDeliveryStatuses";

export const articleDeliveryStatus = enumValues(articleDeliveryStatuses).default(
	articleDeliveryStatuses.DELIVERY_NOT_STARTED,
);
export const ArticleDelivery = defaultObject({
	artifact: defaultObject({
		status: articleDeliveryStatus,
	}),
	metadata: defaultObject({
		status: articleDeliveryStatus,
	}),
});
