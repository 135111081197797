import useLoadingFunction from "../../ui/hooks/useLoadingFunction";

export default function useFormSubmit(
	form,
	dataHandler,
	{ transform, onSuccess, onError, onSubmitError, clearOnSuccess } = {},
) {
	const { reset, handleSubmit, formState } = form;

	const [submitHandler, , error] = useLoadingFunction(
		async (data) => {
			const result = await dataHandler(transform ? transform(data) : data);
			reset(clearOnSuccess ? {} : data);
			return result;
		},
		{ onSuccess, onError },
	);

	const onSubmit = handleSubmit(submitHandler, onSubmitError);

	return [onSubmit, formState.isSubmitting, error];
}
