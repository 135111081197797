import { Icon } from "semantic-ui-react";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import AppendixDropdownMenu from "../components/AppendixDropdownMenu/AppendixDropdownMenu";

import useAgreementParts from "./useAgreementParts";
import useAgreementTypes from "./useAgreementTypes";

export default function useAppendixesColumns() {
	const { t } = useTranslation();

	const agreementPartsMap = useAgreementParts();
	const agreementTypesMap = useAgreementTypes();

	return useMemo(
		() => [
			{
				id: "created",
				Header: t("created", "Created"),
				accessor: ({ created }) => created?.toDate().getTime() || "",
				Cell: ({ value }) => new Date(value).toLocaleString(),
			},
			{
				id: "name",
				filter: "text",
				Header: t("name"),
				Cell: ({ row, value }) => (
					<a download target="_blank" rel="noreferrer" href={row.original.file.url}>
						{value} <Icon name="download" />
					</a>
				),
			},
			{
				id: "agreementTypes",
				Header: t("agreementTypes", "Agreement types"),
				accessor: ({ agreementTypes }) => agreementTypes.map((type) => agreementTypesMap[type]).join(", "),
			},
			{
				id: "agreementParts",
				Header: t("agreementParts", "Agreement parts"),
				accessor: ({ agreementParts }) => agreementParts.map((part) => agreementPartsMap[part]).join(", "),
			},
			{
				id: "actions",
				textAlign: "right",
				disableSortBy: true,
				Cell: ({ row }) => <AppendixDropdownMenu appendix={row.original} />,
			},
		],
		[agreementPartsMap, agreementTypesMap, t],
	);
}
