import firebase from "firebase/app";

import { useTranslation } from "react-i18next";

import ArticlesCollectionDataTable from "../../../articles/components/ArticlesCollectionDataTable/ArticlesCollectionDataTable";

import AdminCard from "../../components/AdminCard/AdminCard";

export default function AdminArtifactArticlesView({ artifact }) {
	const { t } = useTranslation();

	return (
		<AdminCard header={t("articles", "Articles")}>
			<ArticlesCollectionDataTable
				sortable={false}
				filterable={false}
				getQuery={(query) => query.where(firebase.firestore.FieldPath.documentId(), "==", artifact.article?.id)}
			/>
		</AdminCard>
	);
}
