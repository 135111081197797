import { useTranslation } from "react-i18next";

import DistributionsCollectionDataTable from "../../../distribution/components/DistributionsCollectionDataTable/DistributionsCollectionDataTable";

import AdminCard from "../../components/AdminCard/AdminCard";

export default function AdminArticleBundleDistributionView({ bundle }) {
	const { t } = useTranslation();

	return (
		<AdminCard header={t("distributions", "Distributions")}>
			<DistributionsCollectionDataTable bundle={bundle} />
		</AdminCard>
	);
}
