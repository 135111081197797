export default function mapDocument(document) {
	if (!document) {
		return null;
	}

	if (typeof document.data !== "function") {
		return document;
	}

	return {
		id: document.id,
		ref: document.ref,
		exists: document.exists,
		...document.data(),
	};
}
