import { useTranslation } from "react-i18next";

import useBucket from "astrid-hooks/src/useBucket";

import api from "../../../../../api";

export default function useExportDropdownMenuOptions() {
	const { t } = useTranslation();
	const { downloadFolder, deleteFolder } = useBucket("earselect-exports");

	return (data) => {
		return [
			{
				text: t("download", "Download"),
				action: async () => {
					await downloadFolder(data.id);
				},
			},
			{
				text: t("delete", "Delete"),
				action: async () => {
					await api.exports.softDelete(data);
					await deleteFolder(data.id);
				},
			},
		];
	};
}
