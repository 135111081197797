import { Button } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { deleteDocument } from "astrid-firestore/src/helpers";

import AdminCard from "../../../../admin/components/AdminCard/AdminCard";
import useConfirm from "../../../../ui/hooks/useConfirm";

export default function OrganizationActions({ organization }) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const onClickDelete = useConfirm(t("deleteConfirmMessage", "Are you sure you want to delete?"), async () => {
		await deleteDocument(organization.ref);
		navigate("../list");
	});

	return (
		<AdminCard>
			<Button onClick={onClickDelete}>{t("delete", "Delete")}</Button>
		</AdminCard>
	);
}
