import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../../authorization/hooks/useHasRight";

import AdminArtifactsView from "../../views/AdminArtifactsView/AdminArtifactsView";

export default function AdminArtifacts() {
	const { organization } = useOutletContext();

	const hasRight = useHasRight();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature || !hasRight("artifacts.view")) {
		return <Unauthorized />;
	}

	return <AdminArtifactsView organization={organization} />;
}
