import { Form } from "semantic-ui-react";

import React from "react";

import useFormField from "../../hooks/useFormField";

export default function FormCheckbox({ label, labelFalse, ...props }) {
	const { field } = useFormField({ valueField: "checked", rules: { required: false }, ...props });

	const { value, ...restField } = field;

	return (
		<Form.Checkbox {...restField} checked={!!value} label={value ? label : labelFalse || label} ref={undefined} />
	);
}
