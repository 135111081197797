import { Dropdown } from "semantic-ui-react";

import { useProfile } from "../../../../authentication/state/profile";
import DropdownMenu from "../../../../ui/components/DropdownMenu/DropdownMenu";
import DropdownMenuItems from "../../../../ui/components/DropdownMenu/DropdownMenuItems";

export default function SecondaryMenu({ hidden, options }) {
	const profile = useProfile();

	return (
		<DropdownMenu
			open={hidden ? false : undefined}
			fitted
			icon="bars"
			style={{ margin: 12 }}
			iconStyle={{ width: 20 }}
		>
			{() => (
				<>
					<Dropdown.Header>
						{profile?.img && (
							<img
								alt={profile.firstName}
								src={profile.img}
								style={{
									display: "inline-block",
									verticalAlign: "middle",
									borderRadius: "50%",
									width: "30px",
									marginRight: "5px",
								}}
							/>
						)}
						<span>{profile?.email}</span>
					</Dropdown.Header>

					<Dropdown.Divider style={{ margin: 2 }} />

					<DropdownMenuItems options={options} />
				</>
			)}
		</DropdownMenu>
	);
}
