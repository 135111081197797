import { useTranslation } from "react-i18next";

import { languageNames } from "astrid-helpers/src/languages";

import { dropdownMenuColumn } from "../../ui/utils/columns";

import displaySelectedCountries from "../../admin/views/AdminArticleView/utils/displaySelectedCountries";
import useArticleTypeTranslations from "../../articles/hooks/useArticleTypeTranslations";

import useDistributionPresetDropdownOptions from "./useDistributionPresetDropdownOptions";

export default function useDistributionPresetsTableColumns({ publisher }) {
	const { t } = useTranslation();

	const articleTypeTranslations = useArticleTypeTranslations();

	return [
		{
			id: "channel",
			Header: t("channel", "Channel"),
			collapsing: true,
			accessor: ({ channel }) => channel?.name,
		},
		{
			id: "articleType",
			Header: t("articleType", "Article type"),
			collapsing: true,
			accessor: ({ articleType }) => articleTypeTranslations[articleType],
		},
		{
			id: "languages",
			Header: t("languages", "Languages"),
			collapsing: true,
			accessor: ({ languages }) => (languages.includes("all") ? t("all", "All") : languageNames(languages)),
		},
		{
			id: "targetData.countries",
			Header: t("countries", "Countries"),
			collapsing: true,
			accessor: ({ targetData }) => {
				return displaySelectedCountries(targetData?.countries);
			},
		},
		dropdownMenuColumn(useDistributionPresetDropdownOptions({ publisher })),
	];
}
