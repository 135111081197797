import { html } from "@codemirror/lang-html";
import { xml } from "@codemirror/lang-xml";
import { useCodeMirror } from "@uiw/react-codemirror";
import { html as beautifyHtml } from "js-beautify";
import formatter from "xml-formatter";

import { Accordion, Button, Icon } from "semantic-ui-react";

import React, { useState } from "react";
import useAsyncFn from "react-use/lib/useAsyncFn";

import api from "../../../../../api";
import FlexRow from "../../../../../components/Flex/FlexRow";

import metadataTemplateTypes from "../constants/metadataTemplateTypes";
import useTemplate from "../hooks/useTemplate";
import useTemplateHeaderTranslations from "../hooks/useTemplateHeaderTranslations";

const EXTENSIONS = {
	[metadataTemplateTypes.ONIX]: xml(),
	[metadataTemplateTypes.CUSTOM_XML]: xml(),
	[metadataTemplateTypes.EXCEL]: html(),
};

function formatHtml(html) {
	return beautifyHtml(html);
}

function formatXML(xml) {
	return formatter(xml, { collapseContent: true });
}

const FORMAT_FNS = {
	[metadataTemplateTypes.ONIX]: formatXML,
	[metadataTemplateTypes.CUSTOM_XML]: formatXML,
	[metadataTemplateTypes.EXCEL]: formatHtml,
};

export default function MetadataEditor({ channel, templateType }) {
	const [isCollapsed, setIsCollapsed] = useState(false);
	const [template, setTemplate] = useTemplate(channel, templateType);
	const headers = useTemplateHeaderTranslations();

	const { setContainer } = useCodeMirror({
		container: document.getElementById("editor"),
		value: template,
		extensions: [EXTENSIONS[templateType]],
		onChange: (value) => {
			setTemplate(value);
		},
	});

	const [{ loading }, saveTemplate] = useAsyncFn(() => {
		return api.distributionChannels.update({
			ref: channel.ref,
			[templateType]: FORMAT_FNS[templateType](template),
		});
	}, [template, templateType]);

	return (
		<Accordion fluid styled>
			<Accordion.Title active={isCollapsed} onClick={() => setIsCollapsed(!isCollapsed)}>
				<Icon name="dropdown" />
				{headers[templateType]}
			</Accordion.Title>
			<Accordion.Content active={isCollapsed}>
				<div
					id="editor"
					ref={setContainer}
					style={{
						maxHeight: "800px",
						overflow: "auto",
						border: "1px solid #ccc",
						borderRadius: "8px",
						boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
					}}
				/>
				<FlexRow style={{ gap: 10, marginTop: 20, marginBottom: 10 }}>
					<Button
						secondary
						onClick={() => setTemplate(FORMAT_FNS[templateType](template))}
						content="Format template"
					/>
					<Button primary loading={loading} onClick={saveTemplate} content="Save template" />
				</FlexRow>
			</Accordion.Content>
		</Accordion>
	);
}
