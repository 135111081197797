import { Icon, List, Popup } from "semantic-ui-react";

import { toHoursAndMinutes } from "astrid-helpers/src/time";

export default function CostSessionPopup({ cost }) {
	return (
		<Popup
			size="small"
			trigger={<Icon style={{ marginTop: "-5px" }} name="calendar clock outline" />}
			position={"right center"}
			content={
				<List>
					{(cost.costs || [cost])
						?.filter((cost) => cost.session)
						.map((cost) => (
							<List.Item key={cost.session.id}>
								<strong style={{ marginRight: 8 }}>
									{cost.session.start?.toDate().toLocaleDateString()}
								</strong>
								{toHoursAndMinutes(cost.session.start?.toDate())} -{" "}
								{toHoursAndMinutes(cost.session.end?.toDate())}
							</List.Item>
						))}
				</List>
			}
		/>
	);
}
