import React from "react";

import Dashboard from "../../../dashboard/components/Dashboard/Dashboard";
import usePublisherDashboardStatistics from "../../../organizations/hooks/usePublisherDashboardStatistics";

export default function AdminPublisherDashboardView({ organization }) {
	const statistics = usePublisherDashboardStatistics({ organization });

	return <Dashboard statistics={statistics} />;
}
