import { useMemo } from "react";

import useCollectionData from "./useCollectionData";

export default function useCollectionDataOptions(query, { mapOption } = {}) {
	const [documents, loading, error] = useCollectionData(query);

	const options = useMemo(
		() =>
			documents
				?.map((document) => {
					return {
						key: document.id,
						value: document.id,
						text: document.name || document.title || document.id,
						data: document,
						...mapOption?.(document),
					};
				})
				.sort((a, b) => a.text.localeCompare(b.text, "sv")) || [],
		[documents],
	);

	return [options, loading, error];
}
