import { Route } from "react-router-dom";

import ForwardContext from "../../../../layouts/ForwardContext";

import AdminArticles from "../../pages/AdminArticles/AdminArticles";
import AdminArticlesDashboard from "../../pages/AdminArticles/AdminArticlesDashboard";
import AdminArticlesDelivered from "../../pages/AdminArticles/AdminArticlesDelivered";
import AdminArticlesDeliveryNeeded from "../../pages/AdminArticles/AdminArticlesDeliveryNeeded";
import AdminArticlesDrafts from "../../pages/AdminArticles/AdminArticlesDrafts";
import AdminArticlesNotDelivered from "../../pages/AdminArticles/AdminArticlesNotDelivered";
import AdminArticlesScheduled from "../../pages/AdminArticles/AdminArticlesScheduled";

import adminArticleRoute from "./adminArticleRoute";

const adminArticlesRoute = (
	<Route path="articles" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("articles", "Articles") }}>
		<Route index element={<AdminArticlesDashboard />} />

		<Route path="all" element={<AdminArticles />} />

		<Route path="drafts" element={<AdminArticlesDrafts />} />

		<Route path="deliveryNeeded" element={<AdminArticlesDeliveryNeeded />} />

		<Route path="delivered" element={<AdminArticlesDelivered />} />

		<Route path="scheduled" element={<AdminArticlesScheduled />} />

		<Route path="notDelivered" element={<AdminArticlesNotDelivered />} />

		{adminArticleRoute}
	</Route>
);

export default adminArticlesRoute;
