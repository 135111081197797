import { useTranslation } from "react-i18next";

import distributionOperations from "astrid-firestore/src/api/distribution/constants/distributionOperations";

import Form from "../../../../forms/components/Form/Form";

import DistributeArticlesFormDivider from "./DistributeArticlesFormDivider";

export default function DistributeArticlesFormOperation() {
	const { t } = useTranslation();

	const { watch } = Form.useFormContext();

	const { delivery } = watch();

	if (!delivery.metadata) {
		return null;
	}

	return (
		<>
			<DistributeArticlesFormDivider>{t("operation", "Operation")}</DistributeArticlesFormDivider>

			<Form.Group widths="equal">
				<Form.RadioButton fluid basic value={distributionOperations.UPDATE} name="operation">
					{t("update", "Update")}
				</Form.RadioButton>

				<Form.RadioButton fluid basic value={distributionOperations.RELEASE} name="operation">
					{t("releaseAt", "Publish")}
				</Form.RadioButton>

				<Form.RadioButton fluid basic value={distributionOperations.TAKEDOWN} name="operation">
					{t("takedownAt", "Unpublish")}
				</Form.RadioButton>
			</Form.Group>

			<Form.ValidationMessage name="operation" />
		</>
	);
}
