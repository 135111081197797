import { Form } from "semantic-ui-react";

import useFormField from "../../hooks/useFormField";

export default function FormRadioButton({ value, color = "blue", ...props }) {
	const { field } = useFormField(props);

	return (
		<Form.Button
			{...field}
			color={value === field.value ? color : undefined}
			ref={undefined}
			onClick={() => field.onChange(value)}
		/>
	);
}
