import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import CreateArticleBundleForm from "./CreateArticleBundleForm";

export default function CreateArticleBundleFromArticlesButton({ articles, ...props }) {
	const { t } = useTranslation();

	const text = t("createArticleBundle", "Create article bundle");

	return (
		<OpenButton text={text} disabled={articles?.length === 0} {...props}>
			{({ setOpen }) => <CreateArticleBundleForm articles={articles} onClose={() => setOpen(false)} />}
		</OpenButton>
	);
}
