import FirestoreDocument from "../../../types/FirestoreDocument";
import {
	array,
	currency,
	distributor,
	enumValues,
	language,
	nullable,
	object,
	record,
	string,
} from "../../../types/_types";

import { DistributionChannelSettings } from "../../distribution/types/DistributionChannelSettings";
import { DistributionPreset } from "../../distribution/types/DistributionPreset";
import { Vendors } from "../../vendors/types/Vendor";

import organizationTypes from "../constants/organizationTypes";

export const organizationType = enumValues(organizationTypes);

export const Organization = FirestoreDocument.extend({
	adminUserIds: array(string()),
	currencyDefault: nullable(currency()),
	currencyOptions: array(currency()),
	distributors: array(distributor()),
	distribution: object({
		presets: record(string(), DistributionPreset),
		channels: record(string(), DistributionChannelSettings),
	}),
	languageDefault: language(),
	languageOptions: array(language()),
	name: string(2),
	type: organizationType,
	vendors: Vendors,
});
