import Cache from "lru-cache";

import context from "./audioContext";

export const buffers = new Cache({ max: 50 });

export function audioBufferFromArrayBuffer(buffer) {
	return context.decodeAudioData(buffer);
}

export function audioBufferFromURL(url) {
	if (url) {
		if (!buffers.has(url)) {
			const promise = fetch(url)
				.then((res) => res.arrayBuffer())
				.then(audioBufferFromArrayBuffer)
				.catch(() => {
					if (buffers.get(url) === promise) {
						buffers.delete(url);
					}
				});

			buffers.set(url, promise);
		}

		return buffers.get(url);
	}
}

export function audioBufferFromBlob(blob) {
	if (blob) {
		return new Promise((resolve) => {
			const fr = new FileReader();
			fr.onload = async () => resolve(await audioBufferFromArrayBuffer(fr.result));
			fr.readAsArrayBuffer(blob);
		});
	}
}
