const defaultOnixTemplate =
	'<?xml version="1.0" encoding="UTF-8"?>\n' +
	'<ONIXMessage xmlns="http://ns.editeur.org/onix/3.0/reference" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" release="3.0">\n' +
	"    <Header>\n" +
	"        <Sender>\n" +
	"            <SenderName>Earselect AB</SenderName>\n" +
	"            <ContactName>Rickard Källqwist</ContactName>\n" +
	"            <EmailAddress>rickard@earselect.se</EmailAddress>\n" +
	"        </Sender>\n" +
	"        <SentDateTime>20240507</SentDateTime>\n" +
	"    </Header>\n" +
	"    <Product>\n" +
	"        <RecordReference>se.earselect.{{article.title.id}}</RecordReference>\n" +
	"        <NotificationType>04</NotificationType>\n" +
	"        <ProductIdentifier>\n" +
	"            <ProductIDType>15</ProductIDType>\n" +
	"            <IDValue>{{article.isbn}}</IDValue>\n" +
	"        </ProductIdentifier>\n" +
	"        <EditionType>TODO</EditionType>\n" +
	"        <DescriptiveDetail>\n" +
	"            <ProductionComposition>00</ProductionComposition>\n" +
	"            <ProductForm>AJ</ProductForm>\n" +
	"            <ProductFormDetail>A103</ProductFormDetail>\n" +
	"            {{#if (isEbook article)}}\n" +
	"            <ProductFormFeature>\n" +
	"                <ProductFormFeatureType>15</ProductFormFeatureType>\n" +
	"                <ProductFormFeatureValue>101B</ProductFormFeatureValue>\n" +
	"                <EpubTechnicalProtection>02</EpubTechnicalProtection>\n" +
	"            </ProductFormFeature>\n" +
	"            {{/if}}\n" +
	"            {{#if article.serie}}\n" +
	"            <Collection>\n" +
	"                <CollectionType>10</CollectionType>\n" +
	"                <TitleDetail>\n" +
	"                    <TitleType>01</TitleType>\n" +
	"                    <TitleElement>\n" +
	"                        <TitleElementLevel>02</TitleElementLevel>\n" +
	"                        <TitleText>{{article.serie.name}}</TitleText>\n" +
	"                    </TitleElement>\n" +
	"                    <TitleElement>\n" +
	"                        <TitleElementLevel>01</TitleElementLevel>\n" +
	"                        <PartNumber>{{article.serieNumber}}</PartNumber>\n" +
	"                    </TitleElement>\n" +
	"                </TitleDetail>\n" +
	"            </Collection>\n" +
	"            {{/if}}\n" +
	"            <TitleDetail>\n" +
	"                <TitleType>01</TitleType>\n" +
	"                <TitleElement>\n" +
	"                    <TitleElementLevel>01</TitleElementLevel>\n" +
	"                    <TitleText>{{article.title.name}}</TitleText>\n" +
	"                </TitleElement>\n" +
	"            </TitleDetail>\n" +
	"            {{#if article.originalName}}\n" +
	"            <TitleDetail>\n" +
	"                <TitleType>03</TitleType>\n" +
	"                <TitleElement>\n" +
	"                    <TitleElementLevel>01</TitleElementLevel>\n" +
	"                    <TitleText>{{article.originalName}}</TitleText>\n" +
	"                </TitleElement>\n" +
	"            </TitleDetail>\n" +
	"            {{/if}}\n" +
	"            {{#each article.authors}}\n" +
	"            <Contributor>\n" +
	"                <ContributorRole>A01</ContributorRole>\n" +
	"                <PersonNameInverted>{{lastName}}, {{firstName}}</PersonNameInverted>\n" +
	"                <NamesBeforeKey>{{firstName}}</NamesBeforeKey>\n" +
	"                <KeyNames>{{lastName}}</KeyNames>\n" +
	"            </Contributor>\n" +
	"            {{/each}}\n" +
	"            {{#if article.translators}}\n" +
	"            {{#each article.translators}}\n" +
	"            <Contributor>\n" +
	"                <ContributorRole>B06</ContributorRole>\n" +
	"                <PersonNameInverted>{{lastName}}, {{firstName}}</PersonNameInverted>\n" +
	"                <NamesBeforeKey>{{firstName}}</NamesBeforeKey>\n" +
	"                <KeyNames>{{lastName}}</KeyNames>\n" +
	"            </Contributor>\n" +
	"            {{/each}}\n" +
	"            {{/if}}\n" +
	"            {{#unless (isEbook article)}}\n" +
	"            {{#each article.narrators}}\n" +
	"            <Contributor>\n" +
	"                <ContributorRole>E07</ContributorRole>\n" +
	"                <PersonNameInverted>{{lastName}}, {{firstName}}</PersonNameInverted>\n" +
	"                <NamesBeforeKey>{{firstName}}</NamesBeforeKey>\n" +
	"                <KeyNames>{{lastName}}</KeyNames>\n" +
	"            </Contributor>\n" +
	"            {{/each}}\n" +
	"            {{/unless}}\n" +
	"            <EditionNumber>1</EditionNumber>\n" +
	"            <Language>\n" +
	"                <LanguageRole>01</LanguageRole>\n" +
	"                <LanguageCode>{{article.language}}</LanguageCode>\n" +
	"            </Language>\n" +
	"            <Extent>\n" +
	"                <ExtentType>09</ExtentType>\n" +
	"                <ExtentValue>TODO-MINUTES!</ExtentValue>\n" +
	"                <ExtentUnit>05</ExtentUnit>\n" +
	"            </Extent>\n" +
	"            <CATEGORIZATION>TODO</CATEGORIZATION>\n" +
	"            <TAGS>TODO</TAGS>\n" +
	"        </DescriptiveDetail>\n" +
	"        {{#if article.synopsis}}\n" +
	"        <CollateralDetail>\n" +
	"            <TextContent>\n" +
	"                <TextType>03</TextType>\n" +
	"                <ContentAudience>00</ContentAudience>\n" +
	"                <Text>{{article.synopsis}}</Text>\n" +
	"            </TextContent>\n" +
	"        </CollateralDetail>\n" +
	"        {{/if}}\n" +
	"        <PublishingDetail>\n" +
	"            <Imprint>\n" +
	"                <ImprintName>{{article.imprint.name}}</ImprintName>\n" +
	"            </Imprint>\n" +
	"            {{#if article.distributionDates.releaseDate}}\n" +
	"            <PublishingDate>\n" +
	"                <PublishingDateRole>01</PublishingDateRole>\n" +
	'                <Date dateformat="00">{{article.distributionDates.releaseDate}}</Date>\n' +
	"            </PublishingDate>\n" +
	"            {{/if}}\n" +
	"            {{#if article.distributionDates.takeDownDate}}\n" +
	"            <PublishingDate>\n" +
	"                <PublishingDateRole>13</PublishingDateRole>\n" +
	'                <Date dateformat="00">{{article.distributionDates.takeDownDate}}</Date>\n' +
	"            </PublishingDate>\n" +
	"            {{/if}}\n" +
	"            {{#if article.distributionDates.announcementDate}}\n" +
	"            <PublishingDate>\n" +
	"                <PublishingDateRole>09</PublishingDateRole>\n" +
	'                <Date dateformat="00">{{article.distributionDates.announcementDate}}</Date>\n' +
	"            </PublishingDate>\n" +
	"            {{/if}}\n" +
	"            <SalesRights> <!--todo only when specific countires-->\n" +
	"                <SalesRightsType>01</SalesRightsType>\n" +
	"                <Territory>TODO</Territory> <!-- todo either CountriesIncluded or RegionsIncluded-->\n" +
	"            </SalesRights>\n" +
	"        </PublishingDetail>\n" +
	"        <ProductSupply>\n" +
	"            <Market>\n" +
	"                <Territory>\n" +
	"                    <RegionsIncluded>WORLD</RegionsIncluded>\n" +
	"                </Territory>\n" +
	"                <SalesRestriction>\n" +
	"                    <SalesRestrictionType>00</SalesRestrictionType>\n" +
	"                </SalesRestriction>\n" +
	"            </Market>\n" +
	"            {{#if article.distributionDates.releaseDate}}\n" +
	"            <MarketPublishingDetail>\n" +
	"                <MarketPublishingStatus>TODO</MarketPublishingStatus> <!--todo active-->\n" +
	"                <MarketDate>\n" +
	"                    <MarketDateRole>01</MarketDateRole>\n" +
	'                    <Date dateformat="00">{{article.distributionDates.releaseDate}}</Date>\n' +
	"                </MarketDate>\n" +
	"            </MarketPublishingDetail>\n" +
	"            {{/if}}\n" +
	"            <SupplyDetail>\n" +
	"                <Supplier>\n" +
	"                    <SupplierRole>03</SupplierRole>\n" +
	"                    <SupplierName>EGEN</SupplierName>\n" +
	"                </Supplier>\n" +
	"                <ProductAvailability>TODO</ProductAvailability>\n" +
	"                <Price>\n" +
	"                    <PriceIdentifier>\n" +
	"                        <PriceIDType>01</PriceIDType>\n" +
	"                        <IDValue>PAY_PER_LOAN</IDValue>\n" +
	"                    </PriceIdentifier>\n" +
	"                    <PriceType>01</PriceType>\n" +
	"                    <PriceQualifier>10</PriceQualifier>\n" +
	'                    <PriceAmount>TODO</PriceAmount> <!-- todo pricePPL || priceCPC || "14.00"-->\n' +
	"                    <Tax>\n" +
	"                        <TaxType>01</TaxType>\n" +
	"                        <TaxRateCode>Z</TaxRateCode>\n" +
	"                        <TaxRatePercent>0.00</TaxRatePercent>\n" +
	'                        <TaxableAmount>TODO</TaxableAmount> <!--todo pricePPL || priceCPC || "14.00"-->\n' +
	"                    </Tax>\n" +
	'                    <CurrencyCode>TODO</CurrencyCode>  <!-- todo pricePPLCurrency || priceCPCCurrency || "SEK"-->\n' +
	"                    <Territory>\n" +
	"                        <RegionsIncluded>WORLD</RegionsIncluded>\n" +
	"                    </Territory>\n" +
	"                </Price>\n" +
	"                <Price>\n" +
	"                    <PriceIdentifier>\n" +
	"                        <PriceIDType>01</PriceIDType>\n" +
	"                        <IDValue>SINGLE</IDValue>\n" +
	"                    </PriceIdentifier>\n" +
	"                    <PriceType>01</PriceType>\n" +
	'                    <PriceAmount>todo</PriceAmount> <!-- todo priceSingle || priceOCOU || "149.00"-->\n' +
	"                    <Tax>\n" +
	"                        <TaxType>01</TaxType>\n" +
	"                        <TaxRateCode>Z</TaxRateCode>\n" +
	"                        <TaxRatePercent>0.00</TaxRatePercent>\n" +
	"                    </Tax>\n" +
	'                    <CurrencyCode>todo</CurrencyCode> <!--todo  priceSingleCurrency || priceOCOUCurrency || "SEK"-->\n' +
	"                    <Territory>\n" +
	"                        <RegionsIncluded>WORLD</RegionsIncluded>\n" +
	"                    </Territory>\n" +
	"                </Price>\n" +
	"            </SupplyDetail>\n" +
	"        </ProductSupply>\n" +
	"    </Product>\n" +
	"</ONIXMessage>";
export default defaultOnixTemplate;
