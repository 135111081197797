export default class DataValidator {
	constructor(Schema) {
		if (!Schema) {
			throw new Error("Schema is required");
		}

		this.Schema = Schema;
	}

	validate(data, { partial = false }) {
		if (!data) {
			throw new Error("Data is required");
		}

		const name = this.Schema.name || "Schema";
		const type = partial ? this.Schema.partial() : this.Schema;

		const result = type.safeParse(data);

		if (!result.success) {
			throw new Error(`${name} ${result.error?.message}`);
		}

		return result.data;
	}
}
