import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useAdminDistributionsDashboardMenuOptions({
	channels,
	organization,
	activeChannelId,
	setActiveChannelId,
}) {
	const { t } = useTranslation();

	return useMemo(() => {
		const organizationChannels = channels?.filter(({ publishers }) => publishers?.[organization.id]?.statistics);

		const allChannelsScheduledCount = organizationChannels?.reduce(
			(acc, { publishers }) => acc + publishers[organization.id].statistics.distributions.scheduled,
			0,
		);

		return [
			{
				text: t("allChannels", "All channels"),
				label: allChannelsScheduledCount,
				labelColor: "blue",
				active: !activeChannelId,
				onClick: () => setActiveChannelId(),
			},
			...(organizationChannels
				?.map(({ id, name, publishers }) => {
					return {
						key: id,
						text: name,
						label: publishers?.[organization.id]?.statistics?.distributions.scheduled || 0,
						labelColor: "blue",
						active: id === activeChannelId,
						onClick: () => setActiveChannelId(id),
					};
				})
				.sort((a, b) => b.label - a.label) || []),
		];
	}, [activeChannelId, channels, organization.id, setActiveChannelId, t]);
}
