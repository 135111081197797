import { Route } from "react-router-dom";

import AdminTitle from "../../pages/AdminTitle/AdminTitle";
import AdminTitleArticles from "../../pages/AdminTitle/AdminTitleArticles";
import AdminTitleSettings from "../../pages/AdminTitle/AdminTitleSettings";
import AdminTitleVersions from "../../pages/AdminTitle/AdminTitleVersions";

const adminTitleRoute = (
	<Route path=":title" element={<AdminTitle />} handle={{ crumb: ({ title }) => title?.name }}>
		<Route index element={<AdminTitleSettings />} handle={{ crumb: ({ t }) => t("metaData", "Meta data") }} />

		<Route
			path="articles"
			element={<AdminTitleArticles />}
			handle={{ crumb: ({ t }) => t("articles", "Articles") }}
		/>

		<Route
			path="versions"
			element={<AdminTitleVersions />}
			handle={{ crumb: ({ t }) => t("versions", "Versions") }}
		/>
	</Route>
);

export default adminTitleRoute;
