import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";

import useRefinement from "../hooks/useRefinement";

export default function LanguageSkillsSelect() {
	const { t } = useTranslation();

	const {
		items,
		value = [],
		refine,
	} = useRefinement({
		attribute: "languageSkillCodes",
	});

	const languages = useMemo(() => items.map(({ value }) => value), [items]);

	return (
		<Form.LanguageSelect
			name="languages"
			label={t("languageSkills", "Language skills")}
			value={value}
			limitedOptions={languages}
			multiple
			deburr
			fluid
			onChange={(e, { value }) => refine(value)}
		/>
	);
}
