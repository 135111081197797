import { useEffect, useRef, useState } from "react";
import { useRefinementList } from "react-instantsearch";

import Select from "../Select/Select";

import InstantSearchData from "./InstantSearchData";

function useOptions({ data, getOption }) {
	const getOptionRef = useRef(getOption);

	const [options, setOptions] = useState([]);

	useEffect(() => {
		if (data?.length > 0) {
			setOptions((options) => {
				const newOptions = data
					.map(getOptionRef.current)
					.filter(({ value }) => !options.find((option) => option.value === value));

				return [...options, ...newOptions].sort((a, b) => a.text.localeCompare(b.text));
			});
		}
	}, [data]);

	return [options, setOptions];
}

function RegularSearchSelect({ data, getOption, setSearchQuery, ...props }) {
	const [options] = useOptions({ data, getOption });
	const onSearchChange = (e, { searchQuery }) => setSearchQuery(searchQuery);
	return <Select search onSearchChange={onSearchChange} options={options} disabled={false} {...props} />;
}

function RefinementSearchSelect({ getOption, searchQuery, setSearchQuery, refinement, ...props }) {
	const { items, searchForItems } = useRefinementList(refinement);
	const [options, setOptions] = useOptions({ data: items, getOption });

	const refinementSearch = (e, { searchQuery }) => {
		searchForItems(searchQuery);
		setSearchQuery(searchQuery);
	};

	return (
		<Select
			search
			onSearchChange={refinementSearch}
			onAddItem={(e, { value }) => {
				setOptions((options) => [
					...options,
					{
						key: `addition-${value}`,
						value: value,
						text: value,
					},
				]);
			}}
			options={options}
			disabled={false}
			value={searchQuery}
			{...props}
		/>
	);
}

export default function InstantSearchSelect({ configure, value, getOption, refinement, ...props }) {
	const [searchQuery, setSearchQuery] = useState("");

	useEffect(() => {
		setSearchQuery("");
	}, [value]);

	return (
		<InstantSearchData
			value={value}
			searchQuery={searchQuery}
			configure={{
				hitsPerPage: 10,
				...configure,
			}}
			{...props}
		>
			{refinement ? (
				<RefinementSearchSelect
					refinement={refinement}
					getOption={getOption}
					searchQuery={searchQuery}
					setSearchQuery={setSearchQuery}
				/>
			) : (
				<RegularSearchSelect getOption={getOption} setSearchQuery={setSearchQuery} />
			)}
		</InstantSearchData>
	);
}
