export default function eventTarget() {
	let relays = [];
	let listeners = {};

	const getRelays = () => {
		return relays;
	};

	const getListeners = (event) => {
		return listeners[event];
	};

	const clear = () => {
		relays = [];
		listeners = {};
	};

	const off = (event, listener) => {
		if (listeners[event]) {
			listeners[event] = listener ? listeners[event].filter((a) => a !== listener) : [];
		}
	};

	const on = (event, listener, first = false) => {
		listeners[event] = first ? [listener, ...(listeners[event] || [])] : [...(listeners[event] || []), listener];

		return () => off(event, listener);
	};

	const once = (event, listener, first) => {
		const handler = (...payload) => {
			off(event, handler);
			listener(...payload);
		};

		on(event, handler, first);

		return () => off(event, handler);
	};

	const until = (event) => {
		return new Promise((resolve, reject) => {
			once(event, (data) => {
				if (data !== "abort") {
					resolve(data);
				} else {
					reject(data);
				}
			});
		});
	};

	const emit = (event, ...payload) => {
		for (const relay of relays) {
			relay.emit(event, ...payload);
		}

		if (listeners[event]) {
			for (const listener of listeners[event]) {
				listener(...payload);
			}
		}
	};

	const abort = (event) => {
		return emit(event, "abort");
	};

	const removeRelay = (target) => {
		relays = relays.filter((t) => t !== target);
	};

	const addRelay = (target) => {
		relays.push(target);

		return () => {
			return removeRelay(target);
		};
	};

	return {
		getRelays,
		getListeners,
		clear,
		off,
		on,
		once,
		until,
		emit,
		abort,
		removeRelay,
		addRelay,
	};
}
