import { Message } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import VerticalMenu from "../../../ui/components/VerticalMenu/VerticalMenu";

import featureFlags from "../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../authorization/hooks/useFeatureFlag";

export default function SuperAdminSidebar({ needsApproval, uncompletedBiblioTaskCount }) {
	const { t } = useTranslation();

	const location = useLocation();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	const { productionPrices, prices, offers, agreements, agreementTemplates } = needsApproval;

	const total =
		productionPrices.length + prices.length + agreements.length + agreementTemplates.length + offers.length;

	const approveOptions = [
		{
			path: "approve/costs",
			name: t("costs", "Costs"),
			label: productionPrices.length ? productionPrices.length : undefined,
		},
		{
			path: "approve/prices",
			name: t("prices", "Prices"),
			label: prices.length ? prices.length : undefined,
		},
		{
			path: "approve/offers",
			name: t("offers", "Offers"),
			label: offers.length ? offers.length : undefined,
		},
		{
			path: "approve/agreements",
			name: t("agreements", "Agreements"),
			label: agreements.length ? agreements.length : undefined,
		},
		{
			path: "approve/templates",
			name: t("agreementTemplates", "Agreement templates"),
			label: agreementTemplates.length ? agreementTemplates.length : undefined,
		},
	];

	const options = [
		{
			path: approveOptions.find(({ label }) => label)?.path || "approve/costs",
			name: t("needsApproval", "Needs approval"),
			label: !location.pathname.includes("approve") ? total : undefined,
			options: approveOptions,
		},
		{
			path: "producers",
			name: t("producers", "Producers"),
		},
		{
			path: "publishers",
			name: t("publishers", "Publishers"),
		},
		{
			path: "agreements/appendixes",
			name: t("agreements", "Agreements"),
			options: [
				{
					path: "agreements/appendixes",
					name: t("appendixes", "Appendixes"),
				},
			],
		},
		{
			path: "users/narrator",
			name: t("users", "Users"),
			options: [
				{
					path: "users/narrator",
					name: t("readers", "Narrators"),
				},
				{
					path: "users/producer",
					name: t("producers", "Producers"),
				},
				{
					path: "users/publisher",
					name: t("publishers", "Publishers"),
				},
				{
					path: "users/unassigned",
					name: t("unassigned", "Unassigned"),
				},
			],
		},
		{
			path: "articles",
			name: t("articles", "Articles"),
			hidden: !hasFlatteningFeature,
		},
		{
			path: "distributions",
			name: t("distribution", "Distribution"),
			hidden: !hasFlatteningFeature,
			options: [
				{
					path: "distributions",
					name: t("distributions", "Distributions"),
				},
				{
					path: "distributions/channels",
					name: t("channels", "Channels"),
				},
			],
		},
		{
			path: "biblio/settings",
			name: "Biblio",
			label: uncompletedBiblioTaskCount || undefined,
			labelColor: "red",
			options: [
				{
					path: "biblio/settings",
					name: t("settings", "Settings"),
				},
				{
					path: "biblio/tasks",
					name: t("tasks", "Tasks"),
					label: uncompletedBiblioTaskCount || undefined,
					labelColor: "red",
				},
			],
		},
		{
			path: "series",
			name: t("series", "Series"),
		},
		{
			path: "titles",
			name: t("titles", "Titles"),
		},
		{
			path: "imprints",
			name: t("imprints", "Imprints"),
		},
	];

	return (
		<>
			<Message color="black" style={{ backgroundColor: "white", paddingLeft: "1.3em" }}>
				<Message.Header as={Link} to="/admin" style={{ color: "black" }}>
					{t("superAdmin", "Super admin")}
				</Message.Header>
			</Message>
			<VerticalMenu options={options} />
		</>
	);
}
