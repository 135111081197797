import { Loader, Modal } from "semantic-ui-react";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import useBucket from "astrid-hooks/src/useBucket";

import api from "../../../../api";
import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";

import Form from "../../../forms/components/Form/Form";
import useForm from "../../../forms/hooks/useForm";

import ArtifactDataTableForm from "./components/ArtifactDataTableForm";

export default function ManageArtifactFiles({ artifact }) {
	const { t } = useTranslation();
	const bucket = useBucket("earselect-artifacts");

	const form = useForm({
		defaultValues: {
			files: artifact?.files,
		},
	});

	const [showUploadModal, setShowUploadModal] = useState(false);

	const { error, handleSubmit, reset } = form;

	const onSubmit = async ({ files }) => {
		const toBeAddedToBucket = files.filter((file) => !file.url);

		if (toBeAddedToBucket?.length) {
			setShowUploadModal(true);

			const newlyAddedFiles = await bucket.putFiles(artifact.id, toBeAddedToBucket);

			const updatedFiles = files.map((file) => {
				if (!file.url) {
					const newFile = newlyAddedFiles.find((newFile) => newFile.name === file.name);
					return {
						...newFile,
						duration: file.duration,
					};
				}
				return file;
			});

			await api.artifacts.update({ ref: artifact.ref, files: updatedFiles });

			reset({ files: updatedFiles });

			setShowUploadModal(false);
		} else {
			await api.artifacts.update({ ref: artifact.ref, files });

			reset({ files });
		}
	};

	return (
		<>
			<Form form={form}>
				<ArtifactDataTableForm artifact={artifact} onSubmit={handleSubmit(onSubmit)} />

				{error && <ErrorMessage error={error} />}
			</Form>

			{showUploadModal && (
				<Modal open size="tiny">
					<Modal.Header content={t("pleaseWaitWhileWeUpload", "Please wait while we upload the files...")} />

					<Modal.Content>
						<Loader active inline="centered" />
					</Modal.Content>
				</Modal>
			)}
		</>
	);
}
