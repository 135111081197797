import { Input } from "semantic-ui-react";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import useDebounce from "astrid-hooks/src/useDebounce";

export default function useSearchInput({ debounce = 100, ...props } = {}) {
	const { t } = useTranslation();
	const [searchQuery, setSearchQuery] = useState("");
	const debouncedSearchQuery = useDebounce(searchQuery, debounce);

	const input = (
		<Input
			icon="search"
			style={{ width: "100%" }}
			placeholder={`${t("search", "Search")}...`}
			onChange={(e, { value }) => setSearchQuery(value)}
			{...props}
		/>
	);

	return [input, debouncedSearchQuery, setSearchQuery];
}
