export default function sortArtifactFiles(files) {
	if (!files || files.length === 0) {
		throw new Error("No files were provided to sortArtifactFiles");
	}

	const extractNumber = (fileName) => {
		const startMatch = fileName.match(/^\d+/); // Number at the start
		const endMatch = fileName.match(/[_-](\d+)\./); // Number at the end before extension

		if (startMatch) {
			return parseInt(startMatch[0], 10);
		} else if (endMatch) {
			return parseInt(endMatch[1], 10);
		} else {
			return 0; // No number found, default to 0
		}
	};

	return files.sort((a, b) => {
		const numA = extractNumber(a.name);
		const numB = extractNumber(b.name);

		return numA - numB;
	});
}
