import { Label, Menu } from "semantic-ui-react";

import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function SecondaryMenu({ options }) {
	const { pathname } = useLocation();

	return (
		<Menu secondary>
			{options.map(({ name, path, label, labelColor = "blue", onClick, active }) => (
				<Menu.Item
					as={Link}
					key={path}
					to={onClick ? null : path}
					onClick={onClick}
					active={active || pathname.endsWith(path)}
				>
					{name}
					{label ? <Label color={labelColor}>{label}</Label> : null}
				</Menu.Item>
			))}
		</Menu>
	);
}
