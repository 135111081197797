import { Form } from "semantic-ui-react";

import React from "react";

import useFormField from "../../hooks/useFormField";

export default function FormRadio({ value, ...props }) {
	const { field } = useFormField(props);

	return <Form.Radio {...field} value={value} checked={value === field.value} ref={undefined} />;
}
