import { useTranslation } from "react-i18next";

import api from "../../../../../api";
import useFileUpload from "../../../../ui/hooks/useFileUpload";

export default function useAdminArticleDropdownMenuOptions({ article }) {
	const { t } = useTranslation();

	const [{ uploadFile, input, error, loading }] = useFileUpload({
		bucketName: "earselect-uploads",
		getDestination: () => `covers/${article.id}`,
		onSuccess: (cover) => {
			return api.articles.update({
				ref: article.ref,
				cover,
			});
		},
	});

	const options = [
		{
			text: t("viewPresentation", "View presentation"),
			linkTo: `../../../articles/${article.id}`,
		},
		{
			text: t("viewTitle", "View title"),
			disabled: !article.title,
			linkTo: `../../titles/${article.title?.id}`,
		},
		{
			text: t("uploadCover", "Upload cover"),
			onClick: uploadFile,
		},
		{
			text: t("delete", "Delete"),
			onClick: () => {
				return api.articles.delete(article);
			},
		},
	];

	return { options, input, loading, error };
}
