import { date, defaultObject, nullable } from "../../../types/_types";

export const ArticleDistribution = defaultObject({
	announcementDate: nullable(date()),
	releaseDate: nullable(date()),
	takedownDate: nullable(date()),
})
	.refine(
		({ announcementDate, releaseDate }) => {
			return !announcementDate || !releaseDate || announcementDate <= releaseDate;
		},
		{
			message: "Must be after announcement date",
			path: ["releaseDate"],
		},
	)
	.refine(
		({ releaseDate, takedownDate }) => {
			return !releaseDate || !takedownDate || releaseDate <= takedownDate;
		},
		{
			message: "Must be after release date",
			path: ["takedownDate"],
		},
	);
