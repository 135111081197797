import { Grid, Segment } from "semantic-ui-react";

import React from "react";
import { Outlet } from "react-router-dom";
import useWindowSize from "react-use/lib/useWindowSize";

export default function SidebarLayout({ sidebar, ...props }) {
	const { width } = useWindowSize();

	const large = width > 600;

	return (
		<Segment padded>
			<Grid>
				<Grid.Column width={large ? 3 : 16}>{sidebar}</Grid.Column>

				<Grid.Column width={large ? 13 : 16}>
					<Outlet {...props} />
				</Grid.Column>
			</Grid>
		</Segment>
	);
}
