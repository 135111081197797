import { arrayMinMax, defaultObject, language, literal, object, string } from "../../../types/_types";

import { articleType } from "../../articles/types/Article";

import { DistributionChannelRef } from "./DistributionChannelRef";

export const DistributionPreset = object({
	id: string(),
	articleType: articleType,
	languages: arrayMinMax(language().or(literal("all")), 1),
	channel: DistributionChannelRef,
	targetData: defaultObject({
		countries: arrayMinMax(string(), 1),
	}),
});
