import { Divider, Icon, Item, Label } from "semantic-ui-react";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import offerTypes from "astrid-firestore/src/api/offers/constants/offerTypes";
import { teamRoles } from "astrid-firestore/src/constants/teamRoles";
import { languageName } from "astrid-helpers/src/languages";

import Flex from "../../../../../components/Flex/Flex";
import { formatPrice } from "../../../../prices/utils/priceFormatters";
import getAuthorNames from "../../../../productions/utils/getAuthorNames";
import getReaderNames from "../../../../productions/utils/getReaderNames";

import useOfferPrice from "../../../hooks/useOfferPrice";

export function OfferDetailLabel({ label, detail, icon }) {
	if (!detail) return null;

	return (
		<Label basic size="tiny" style={{ margin: 2 }}>
			{label} <Label.Detail>{detail}</Label.Detail>
			{icon}
		</Label>
	);
}

export default function OfferInformation({ production, offer, hidePrice, children }) {
	const { t } = useTranslation();

	const price = useOfferPrice(offer);

	const [extendText, setExtendText] = useState(production?.synopsis?.length <= 800);

	return (
		<>
			<Item.Group divided>
				<Item>
					<Item.Image size="small" src={production.img ? production.img : "/img/image.png"} />

					<Item.Content>
						<Item.Header>{production.title}</Item.Header>
						<Item.Meta>{production.publisherName}</Item.Meta>
						{production.synopsis && (
							<Item.Description>
								<p>
									{extendText ? production.synopsis : production.synopsis.substring(0, 800) + "... "}
									{!extendText && (
										<span
											onClick={() => setExtendText(!extendText)}
											style={{ fontWeight: "bold", cursor: "pointer" }}
										>
											{t("showMore")}
										</span>
									)}
								</p>
							</Item.Description>
						)}
						<Item.Extra>
							<div style={{ marginLeft: "-2px" }}>
								<OfferDetailLabel label={t("title")} detail={production.title} />
								<OfferDetailLabel label={t("isbn")} detail={production.isbn} />
								<OfferDetailLabel
									label={t("deliveryDate")}
									detail={production.deliveryDate?.toDate().toLocaleDateString()}
								/>
								{offer?.role === teamRoles.MANAGER && (
									<OfferDetailLabel
										label={t("seriesPart", "Series (part)")}
										detail={
											production.series || production.part
												? `${production.series || "-"} ${production.part || ""}`
												: undefined
										}
									/>
								)}
								<OfferDetailLabel label={t("author")} detail={getAuthorNames(production)} />
								<OfferDetailLabel label={t("pages")} detail={production.pages} />
								<OfferDetailLabel label={t("narrator")} detail={getReaderNames(production)} />
								<OfferDetailLabel label={t("language")} detail={languageName(production.language)} />
								<OfferDetailLabel
									label={t("otherLanguages")}
									detail={production.languageExtra
										?.map((langExtra) => {
											return languageName(langExtra);
										})
										.join(", ")}
								/>
								<OfferDetailLabel
									label={t("scriptDate")}
									detail={
										production.scriptUploadDate?.toDate().toLocaleDateString() ||
										production.scriptDate?.toDate().toLocaleDateString()
									}
									icon={
										production.scriptUploadDate && (
											<Icon style={{ marginLeft: "5px" }} name="check" color="green" />
										)
									}
								/>
							</div>
						</Item.Extra>
						<Divider />
						<Flex style={{ marginTop: 5 }}>
							{production.productionDate && (
								<div>
									<strong>{t("productionDate")}: </strong>
									{production.productionDate.toDate().toLocaleDateString()}
								</div>
							)}
							{offer.price && !hidePrice && (
								<div>
									<strong>{t("price")}: </strong>
									{formatPrice(price)}{" "}
									{offer?.price?.name === "Custom" && ` (${t("customPrice", "Custom")})`}
								</div>
							)}
						</Flex>
						<Divider />
						{offer.type === offerTypes.WORK_ORDER && (
							<>
								<div>
									<strong>{t("sentBy", "Sent by")}: </strong>
									{offer?.createdBy?.name}
								</div>
								<Divider />
							</>
						)}
						{children}
					</Item.Content>
				</Item>
			</Item.Group>
		</>
	);
}
