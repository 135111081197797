import get from "lodash/get";
import trim from "lodash/trim";
import moment from "moment";

import {
	Button,
	Form,
	Icon,
	Input,
	Label,
	Message,
	Modal,
	Popup,
	Responsive,
	Segment,
	Tab,
	Table,
} from "semantic-ui-react";

import React, { Component, useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import languages from "astrid-config/src/languages";
import { base, db, firebase, hasRight } from "astrid-firebase";
import useDebounce from "astrid-hooks/src/useDebounce";
import DocTitle from "astrid-web/src/components/DocTitle";

import DateTime from "../../features/ui/components/DateInput/DateTime";
import { withStore } from "../../helpers/context";
import { localStorage, secToDuration, toDate } from "../../helpers/fnc";
import { prodStatus } from "../../helpers/lists";
import { deleteProduction } from "../../helpers/production";

import Pagination from "../Pagination";

import ExportButton from "./ExportButton";
import ExportChoice from "./ExportChoice";
import ProductionBilling from "./ProductionBilling";
import ProgressEdit from "./ProgressEdit";
import ProgressRecording from "./ProgressRecording";
// import Right from "./Right";
import StarToggle from "./StarToggle";
import { bookedStudioHours, estimateStudioHours } from "./fnc";
import ProductionTags from "./parts/ProductionTags";

const getProductions = firebase.functions().httpsCallable("getProductions");

const hasPublisherRole = (permissions, role) => {
	return Object.values(permissions?.publisher || {})
		.reduce((result, value) => {
			return result.concat(value);
		}, [])
		.includes(role);
};

class ListProductions extends Component {
	state = {
		loading: true,
		tabs: [],
		currentTab: 0,
		search: "",
		searchResult: [],
		publishers: [],
		selectedPublisher: null,
		selectedArticles: {},

		page: 0,
		column: this.props.profile.permissions.publisher ? "deliveryDate" : "productionDate",
		direction: "ascending",

		columns: ["series", "status", "deliveryDate", "updated"], // default before "real" default is set from comWiMo

		filter: false,
		filterEdited: false,
		users: [],
		readers: [],
		people: [],
		modal: null,
		productions: [],
		subcontractors: [],
	};

	result = [];

	componentDidMount() {
		const { store } = this.props;
		const { permissions } = this.props.profile;

		// load readers instantly for non-readers
		if (permissions.publisher || permissions.producer) {
			this.loadReaders();
		}

		// set tabs and listen to resize events
		this.setTabs();
		window.addEventListener("resize", this.setTabs);

		this.props.store.getOrganizations().then(() => {
			// set default organizations for new productions
			if (
				permissions &&
				permissions.producer &&
				!permissions.publisher &&
				Object.keys(permissions.producer).length === 1
			) {
				this.setState({
					selectedProducer: Object.keys(permissions.producer)[0],
					selectedProducerSingle: true,
				});
			}

			if (Object.keys(permissions?.producer || {}).length > 1) {
				this.setState({
					selectedProducer: store.state.producerId,
				});
			}

			// user belongs to single publisher, set as default for new productions
			if (
				permissions &&
				permissions.publisher &&
				!permissions.producer &&
				Object.keys(permissions.publisher).length === 1
			) {
				this.setState({
					selectedPublisher: Object.keys(permissions.publisher)[0],
					selectedPublisherSingle: true,
				});
			}
		});

		// set previously chosen sorting, filter and columns
		const ls = localStorage("listProductionSettings2");
		if (ls.column) this.setState({ column: ls.column });
		if (ls.direction) this.setState({ direction: ls.direction });

		// apply initial tab filter
		this.resetFilter();
		if (ls.filter) {
			this.setState({
				filter: {
					...ls.filter,
				},
				filterEdited: ls.filter.filterEdited,
			});
		}

		if (ls.columns) {
			this.setState({ columns: ls.columns });

			// load people if column is selected
			if (ls.columns.includes("author") || ls.columns.includes("translator")) this.loadPeople();
		} else {
			// set default columns based on permissions
			let columns = ["series", "status"];

			if (permissions.producer) {
				if (Object.values(permissions.producer)[0].includes("producerAdmin")) {
					// prod admin
					columns = [
						...columns,
						"progress",
						"editor",
						"proofer",
						"productionDate",
						"deliveryDate",
						"billingStatus",
					];
				} else {
					// other prod staff
					columns.push("progress");
					if (Object.values(permissions.producer)[0].includes("producerSubcontractor")) {
						columns.push("subcontractor");
					}
					if (Object.values(permissions.producer)[0].includes("producerProofer")) {
						columns.push("proofer");
					}
					if (Object.values(permissions.producer)[0].includes("producerEditor")) {
						columns.push("editor");
					}
					columns.push("productionDate");
				}
			}

			if (permissions.publisher) {
				columns = [...columns, "progressTotal", "reader", "deliveryDate", "billingStatus"];
			}

			if (permissions.reader) {
				columns = [...columns, "progressTotal", "productionDate"];
			}
			this.setState({ columns: columns });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const filterChanged = JSON.stringify(prevState.filter) !== JSON.stringify(this.state.filter);
		const columnsChanged = JSON.stringify(prevState.columns) !== JSON.stringify(this.state.columns);
		const orderChanged = prevState.column !== this.state.column || prevState.direction !== this.state.direction;
		const pageChanged = prevState.page !== this.state.page;
		const searchChanged = prevState.search !== this.state.search;

		if (filterChanged || columnsChanged || orderChanged || pageChanged || searchChanged) {
			this.getProductions();
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.setTabs);
	}

	setTabs = () => {
		const { t, profile } = this.props;
		const { producerId } = this.props.store.state;
		const { currentTab } = this.state;
		const isPublisher = profile.permissions.publisher && !profile.permissions.producer;
		const isAdmin = profile.permissions.producer?.[producerId]?.includes("producerAdmin");
		const isProducerStaff = profile.permissions.producer?.[producerId]?.includes("producerStaff");

		const isDesktop = window.innerWidth > Responsive.onlyTablet.minWidth;
		const tabs = isPublisher
			? [{ menuItem: { key: "all", content: t("titles"), icon: "book" } }]
			: [
					{
						menuItem: {
							key: "current",
							content: isDesktop || !currentTab ? t("current") : "",
							icon: "microphone",
						},
					},
					{
						menuItem: {
							key: "done",
							content: isDesktop || currentTab === 1 ? t("completed") : "",
							icon: "check square",
						},
					},
			  ];
		if (isAdmin || isProducerStaff)
			tabs.push({
				menuItem: {
					key: "external",
					content: isDesktop || currentTab === 2 ? t("external") : "",
					icon: "cloud upload",
				},
			});

		this.setState({ tabs });
	};

	getProductions = () => {
		const { filter, column, columns, direction, page, search } = this.state;
		const { languageAccess } = this.props.profile;

		console.log("Get productions from cloud function", { columns, filter, languageAccess });

		const producerId = get(this.props, "store.state.producerId");

		this.setState({ loading: true });
		getProductions({
			filter: JSON.stringify(filter),
			column,
			columns,
			direction,
			page,
			search,
			languageAccess,
			producerId,
			dontOverWriteProgress: true, // transition variable, remove after live deploy
		})
			.then(({ data }) => {
				const { productions, hits, totalDuration, avgProdRatio, avgHourRatio } = data;

				this.setState({
					loading: false,
					productions,
					hits,
					totalDuration,
					avgProdRatio,
					avgHourRatio,
				});
			})
			.catch((err) => {
				console.log("GP error", err);
			});
	};

	searchProduction = (search) => {
		if (search !== this.state.search) {
			this.setState({
				search,
				page: 0,
			});
		}
	};

	confirmDeleteProduction = (productionId, title, status) => {
		const { t, store } = this.props;
		const { permissions } = this.props.profile;
		const uid = firebase.auth().currentUser.uid;
		const { producerId } = store.state;

		const started = !["draft", "offer"].includes(status);
		const isProducerAdmin = permissions?.producer?.[producerId]?.includes("producerAdmin");

		// check
		if (productionId && isProducerAdmin && window.confirm(t("wantToDelete") + " " + title + "?")) {
			// double check
			if (started && !window.confirm(t("warningDeleteProd"))) return;
			deleteProduction(productionId, uid);
		}
	};

	handleSubmit = () => {
		const { t } = this.props;
		const prodTitleField = document.querySelector("#title-input");
		const prodTitle = trim(prodTitleField.value);
		const { languageAccess } = this.props.profile;

		let pub = this.state.selectedPublisher;

		if (!pub) {
			alert(t("choosePubl"));
			return;
		}

		const data = {
			title: prodTitle,
			status: !this.state.selectedProducer ? "draft" : "offer",
			publisher: pub,
			createdBy: this.props.profile.email,
			created: firebase.firestore.FieldValue.serverTimestamp(),
			producer: "HfvG4FQfXWpWv6dzqM5E", // hard coded earselect
		};

		const publisher = get(this.props, "store.state.organizations." + pub);

		if (publisher && publisher.languageDefault) data.language = publisher.languageDefault;
		// if user does not have access to above language, get one that's ok
		if (languageAccess && languageAccess.length && !languageAccess.includes(data.language)) {
			// the first one will do, they can change later if needed
			data.language = languageAccess[0];
		}

		// created by producer?
		if (this.state.selectedProducer) data.producer = this.state.selectedProducer;

		base.addToCollection("productions", data)
			.then((ref) => {
				prodTitleField.value = "";
				this.props.history.push("/production/" + ref.id);
			})
			.catch((error) => {
				console.log("Production add error", error);
			});
	};

	handleSort = (clickedColumn) => () => {
		const { column, direction } = this.state;

		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				direction: "descending",
			});
			localStorage("listProductionSettings2", "column", clickedColumn);
			localStorage("listProductionSettings2", "direction", "descending");
		} else {
			const dir = direction === "ascending" ? "descending" : "ascending";
			this.setState({
				direction: dir,
			});
			localStorage("listProductionSettings2", "direction", dir);
		}
	};

	modalClose = () => {
		if (this.state.preventModalClose) {
			this.setState({ preventModalClose: false });
		} else {
			this.setState({ modal: null });
		}
	};

	setFilter = (e, data) => {
		let filter = this.state.filter ? { ...this.state.filter } : {};

		// remove empty arrays
		if (Array.isArray(data.value) && !data.value.length) delete filter[data.name];
		// remove null values
		else if (data.value === null) delete filter[data.name];
		// handle checkbox input
		else if (data.checked === false) filter[data.name] = false;
		else if (data.checked === true) filter[data.name] = true;
		// or set value
		else filter[data.name] = data.value;

		// remove filter if empty
		if (!Object.values(filter).length) filter = false;

		this.setState({
			filter,
			filterEdited: true,
			page: 0,
		});
		localStorage("listProductionSettings2", "filter", { ...filter, filterEdited: true });
	};

	quickFilter = (settings) => {
		this.setState({
			filter: settings.filter !== undefined ? settings.filter : this.state.filter,
			column: settings.column !== undefined ? settings.column : this.state.column,
			direction: settings.direction !== undefined ? settings.direction : this.state.direction,
		});
		if (settings.column !== undefined) localStorage("listProductionSettings2", "column", settings.column);
		if (settings.direction !== undefined) localStorage("listProductionSettings2", "direction", settings.direction);
	};

	resetFilter = () => {
		const { profile } = this.props;
		const { currentTab } = this.state;
		const isPublisher = profile.permissions.publisher && !profile.permissions.producer;

		this.setState({
			filterEdited: false,
			filter: isPublisher
				? false
				: {
						status:
							currentTab === 1
								? ["done"]
								: !currentTab
								? ["draft", "offer", "planning", "accepted", "booked", "production"]
								: null,
						productionType: currentTab !== 2 ? ["internal", "subcontract"] : ["external", "backlist"],
				  },
		});

		localStorage("listProductionSettings2", "filter", false);
	};

	loadStaff = () => {
		if (!this.state.filterLoadingStaff && !this.state.users.length) {
			this.setState({ filterLoadingStaff: true });

			base.bindCollection("users", {
				context: this,
				state: "users",
				withIds: true,
				query: (ref) => {
					return ref.where(
						"permissions.producer." + this.props.store.state.producerId,
						"array-contains",
						"any",
					);
				},
				then() {
					this.setState({ filterLoadingStaff: false });
				},
			});
			this.loadReaders();
		}
	};

	loadReaders = () => {
		if (!this.state.filterLoadingReaders && !this.state.readers.length) {
			this.setState({ filterLoadingReaders: true });
			base.bindCollection("users", {
				context: this,
				state: "readers",
				withIds: true,
				query: (ref) => {
					return ref.where("permissions.reader", "array-contains", "any");
				},
				then() {
					this.setState({ filterLoadingReaders: false });
				},
			});
		}
	};

	loadSubcontractors = () => {
		if (!this.state.filterLoadingSubcontractors && !this.state.subcontractors.length) {
			this.setState({ filterLoadingSubcontractors: true });
			base.bindCollection("users", {
				context: this,
				state: "subcontractors",
				withIds: true,
				query: (ref) => {
					return ref.where(
						"permissions.producer." + this.props.store.state.producerId,
						"array-contains",
						"producerSubcontractor",
					);
				},
				then() {
					this.setState({ filterLoadingSubcontractors: false });
				},
			});
		}
	};

	loadPeople = () => {
		if (!this.state.filterLoadingPeople && !this.state.people.length) {
			this.setState({ filterLoadingPeople: true });

			base.bindCollection("people", {
				context: this,
				state: "people",
				withIds: true,
				then() {
					this.setState({ filterLoadingPeople: false });
				},
			});
		}
	};

	render() {
		const { t, store, user } = this.props;
		const {
			column,
			direction,
			page,
			readers,
			people,
			selectedArticles,
			hits,
			totalDuration,
			avgProdRatio,
			avgHourRatio,
			tabs,
		} = this.state;

		const { permissions } = this.props.profile;
		const { producerId } = store.state;
		let productions = this.state.productions || {};

		// paging
		const perPage = 30;

		// columns
		const columns = {
			series: {
				name: t("seriesPart", "Series (part)"),
			},
			publisher: {
				name: t("publisher"),
			},
			reader: {
				name: t("narrator"),
				hidden: !(this.props.profile.permissions.publisher || this.props.profile.permissions.producer),
			},
			author: {
				name: t("author"),
			},
			translator: {
				name: t("translator"),
			},
			status: {
				name: t("status"),
			},
			isbn: {
				name: t("isbn"),
			},
			language: {
				name: t("language"),
			},
			productionDate: {
				name: t("prodDate"),
				hidden: !this.props.profile.permissions.producer,
			},
			studioHours: {
				name: t("studHours"),
				hidden: !this.props.profile.permissions.producer,
			},
			duration: {
				name: t("duration"),
			},
			ratio: {
				name: t("ratio"),
				hidden: !hasRight(store, "producerBilling.viewPublisherInvoice"),
			},
			proofer: {
				name: t("proofer"),
				hidden: !this.props.profile.permissions.producer,
			},
			editor: {
				name: t("editor"),
				hidden: !this.props.profile.permissions.producer,
			},
			progress: {
				name: t("progress"),
				hidden: !this.props.profile.permissions.producer,
			},
			progressTotal: {
				name: t("progressTot"),
				hidden: this.props.profile.permissions.producer,
			},
			deliveryDate: {
				name: t("deliveryDate"),
				hidden: !hasRight(store, "productionDeliveryDate.view"),
			},
			billingStatus: {
				name: t("billingStatus"),
				hidden: !hasRight(store, "producerBilling.viewPublisherInvoice"),
			},
			scriptDate: { name: t("scriptDate") },
			updated: { name: t("updated") },
			created: { name: t("created") },
		};

		// get meta data columns
		let metaColumns;
		if (this.props.profile.permissions.publisher) {
			metaColumns = Object.keys(this.props.profile.permissions.publisher).reduce((prev, curr) => {
				const org = store.state && store.state.organizations && store.state.organizations[curr];

				return {
					...prev,
					...(org && org.productionMeta ? org.productionMeta : {}),
				};
			}, {});
		}

		const isProducerAdmin = permissions?.producer?.[producerId]?.includes("producerAdmin");
		const isProducerStaff = permissions?.producer?.[producerId]?.includes("producerStaff");
		const isPublisherAdmin = hasPublisherRole(permissions, "publisherAdmin");
		const isPublisherStaff = hasPublisherRole(permissions, "publisherStaff");

		return (
			<>
				<DocTitle title={t("docTitleProductions")} />

				<Tab
					panes={tabs}
					onTabChange={(e, { activeIndex }) => {
						this.setState({ currentTab: activeIndex }, this.setTabs);

						if (activeIndex === 0) {
							this.quickFilter({
								filter: {
									...this.state.filter,
									status: ["draft", "offer", "planning", "accepted", "booked", "production"],
									productionType: ["internal", "subcontract"],
								},
							});
						} else if (activeIndex === 1) {
							this.quickFilter({
								filter: {
									...this.state.filter,
									status: ["done"],
									productionType: ["internal", "subcontract"],
								},
							});
						} else if (activeIndex === 2) {
							this.quickFilter({
								filter: {
									...this.state.filter,
									status: null,
									productionType: ["external", "backlist"],
								},
							});
						}
					}}
				/>

				{hasRight(store, "createProduction") && (
					<div className="list-productions-create">
						<Button
							primary
							size="small"
							content={t("newProd")}
							onClick={() => {
								this.setState({ modal: "create" });
							}}
						/>
					</div>
				)}

				<Segment padded loading={this.state.loading} className="clear tab-segment">
					<div className="flex-stack">
						<div style={{ marginBottom: 5 }}>
							<SearchField onSearch={this.searchProduction} />

							<Label
								color={this.state.filterEdited ? "teal" : null}
								as="a"
								size="large"
								onClick={() => {
									// populate staff and/or people?
									if (this.state.filter.editor || this.state.filter.proofer) {
										this.loadStaff();
									}
									if (this.state.filter.reader) {
										this.loadReaders();
									}
									if (this.state.filter.author || this.state.filter.translator) {
										this.loadPeople();
									}
									if (this.state.filter.subcontractor) {
										this.loadSubcontractors();
									}
									this.setState({
										modal: "filter",
									});
								}}
								style={{ margin: "0 1rem" }}
							>
								<Icon name="sliders" />
								{t("filter")}
								{this.state.filter && (
									<Icon
										name="delete"
										onClick={(e) => {
											e.stopPropagation();
											this.resetFilter();
										}}
									/>
								)}
							</Label>
							<Label
								as="a"
								size="large"
								onClick={() => {
									this.setState({
										modal: "columns",
									});
								}}
							>
								<Icon name="columns" />
								{t("columns")}
							</Label>
						</div>

						<div className="list-productions-filter-presets">
							{isProducerAdmin && (
								<UnacceptedCount
									quickFilter={this.quickFilter}
									producerId={Object.keys(permissions.producer)[0]}
								/>
							)}

							{!!get(this, "props.profile.permissions.producer") && (
								<Popup
									inverted
									size="tiny"
									content={t("sortOnProdDate")}
									trigger={
										<Button
											basic
											size="tiny"
											content={t("prodDate")}
											icon="arrow circle up"
											onClick={(e) => {
												this.quickFilter({
													column: "productionDate",
													direction: "ascending",
												});
											}}
										/>
									}
								/>
							)}

							{!!get(this, "props.profile.permissions.publisher") && (
								<Popup
									inverted
									size="tiny"
									content={t("sortOnDelDate")}
									trigger={
										<Button
											basic
											size="tiny"
											content={t("delDate")}
											icon="arrow circle down"
											onClick={(e) => {
												this.quickFilter({
													column: "deliveryDate",
													direction: "ascending",
												});
											}}
										/>
									}
								/>
							)}
							{!get(this, "props.profile.permissions.reader") && (
								<Popup
									inverted
									size="tiny"
									content={t("sortOnLastChange")}
									trigger={
										<Button
											basic
											size="tiny"
											content={t("updated")}
											icon="arrow circle down"
											onClick={(e) => {
												this.quickFilter({
													column: "updated",
													direction: "descending",
												});
											}}
										/>
									}
								/>
							)}

							{((permissions.producer &&
								Object.values(permissions.producer)[0].includes("producerAdmin")) ||
								permissions.publisher) && (
								<Popup
									inverted
									size="tiny"
									content={t("filterAwayProdWithReader")}
									trigger={
										<Button
											basic
											size="tiny"
											content={t("missingReader")}
											icon="user circle"
											onClick={(e) => {
												this.quickFilter({
													filter: {
														...this.state.filter,
														reader: "EMPTY",
													},
												});
											}}
										/>
									}
								/>
							)}

							{permissions.producer &&
								Object.values(permissions.producer)[0].includes("producerProofer") && (
									<Popup
										inverted
										size="tiny"
										content={t("filterAwayProdWithOtherProofer")}
										trigger={
											<Button
												basic
												size="tiny"
												content={t("myProofSheets")}
												icon="user circle"
												onClick={(e) => {
													this.quickFilter({
														filter: {
															...this.state.filter,
															proofer: user.uid,
														},
													});
												}}
											/>
										}
									/>
								)}
							{permissions.producer &&
								Object.values(permissions.producer)[0].includes("producerProofer") && (
									<Popup
										inverted
										size="tiny"
										content={t("filterAwayProdWithProofer")}
										trigger={
											<Button
												basic
												size="tiny"
												content={t("missProofer")}
												icon="user circle"
												onClick={(e) => {
													this.quickFilter({
														filter: {
															...this.state.filter,
															proofer: "EMPTY",
														},
													});
												}}
											/>
										}
									/>
								)}

							{permissions.producer &&
								Object.values(permissions.producer)[0].includes("producerEditor") && (
									<Popup
										inverted
										size="tiny"
										content={t("filterAwayProdWithOtherEditor")}
										trigger={
											<Button
												basic
												size="tiny"
												content={t("myEdits")}
												icon="user circle"
												onClick={(e) => {
													this.quickFilter({
														filter: {
															...this.state.filter,
															editor: user.uid,
														},
													});
												}}
											/>
										}
									/>
								)}
							{permissions.producer &&
								Object.values(permissions.producer)[0].includes("producerEditor") && (
									<Popup
										inverted
										size="tiny"
										content={t("filterAwayProdWithEditor")}
										trigger={
											<Button
												basic
												size="tiny"
												content={t("missingEditor")}
												icon="user circle"
												onClick={(e) => {
													this.quickFilter({
														filter: {
															...this.state.filter,
															editor: "EMPTY",
														},
													});
												}}
											/>
										}
									/>
								)}
						</div>

						<div>
							<Pagination
								page={page}
								items={hits}
								per={perPage}
								setPage={(page) => this.setState({ page })}
								style={{ marginLeft: "1rem", float: "right" }}
							/>
						</div>
					</div>
					{this.state.message && <Message {...this.state.message} />}
					<Table basic="very" compact sortable className="list-productions">
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell
									sorted={column === "title" ? direction : null}
									onClick={this.handleSort("title")}
								>
									{t("title")}
								</Table.HeaderCell>

								{this.state.columns.map((key) => {
									let col = columns[key];

									// get custom publisher meta
									if (!col && metaColumns) {
										col = metaColumns[key];
									}

									// don't break if the meta field was deleted
									if (!col) return null;

									return (
										<Table.HeaderCell
											key={key}
											sorted={column === key ? direction : null}
											onClick={this.handleSort(key)}
										>
											{["proofer", "editor"].includes(key) ? "" : col.name}
										</Table.HeaderCell>
									);
								})}
								{/* delete column */}
								<Table.HeaderCell />
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{productions
								.filter((prod) => {
									//Return all results for admins else remove offer status productions
									if (isProducerAdmin || isProducerStaff || isPublisherAdmin) {
										return prod;
									} else {
										if (prod.status !== "offer") {
											return prod;
										}
									}
									return false;
								})
								.map((prod) => {
									const { t } = this.props;
									const status = prod.status;

									const planningSnoozed =
										isProducerAdmin &&
										prod.statusPlanningSnooze &&
										toDate(prod.statusPlanningSnooze) > new Date();

									return (
										<Table.Row key={prod.id} className={planningSnoozed ? "faded" : ""}>
											<Table.Cell selectable style={{ fontWeight: "bold" }}>
												<Link to={"/production/" + prod.id}>
													{hasRight(store, "production.exportFile") && (
														<ExportChoice
															selectedArticles={selectedArticles}
															prod={prod}
															setArticles={(articles) => {
																this.setState({ selectedArticles: articles });
															}}
														/>
													)}
													<StarToggle
														production={prod.id}
														profile={this.props.profile}
														uid={user.uid}
													/>
													{prod.title}
												</Link>
											</Table.Cell>

											{this.state.columns.map((key) => {
												let col = columns[key];
												let colData = prod[key];

												// format some field data for display
												if (key === "series") {
													colData =
														(prod.series || "") + (prod.part ? ` (${prod.part})` : "");
												} else if (key === "status") {
													//Failsafe for older subcontractor productions to have correct status
													if (
														prod.status === "booked" &&
														prod.productionType === "subcontract" &&
														prod.subcontractor &&
														prod.subcontractorStatus === "accepted"
													)
														prod.status = "production";
													colData = (
														<>
															<Icon
																name={prodStatus[status].icon}
																color={prodStatus[status].color}
															/>
															{/* {prodStatus[status].text} */}
															{t(prod.status)}

															{(prod.missingData || prod.lastCommentPublisher) && (
																<Popup
																	size="mini"
																	inverted
																	position="top right"
																	content={
																		(prod.missingData
																			? t("isMissing") +
																			  ": " +
																			  prod.missingData
																					.map((m) => t("missingData" + m))
																					.join(", ") +
																			  ". "
																			: "") +
																		(prod.lastCommentPublisher
																			? t("lastCommentIsFrPubl")
																			: "")
																	}
																	trigger={
																		<Icon
																			name={"attention"}
																			color={"red"}
																			style={{ float: "right" }}
																		/>
																	}
																/>
															)}
														</>
													);
												} else if (key === "publisher") {
													colData = get(
														store,
														"state.organizations." + prod.publisher + ".name",
													);
												} else if (key === "language") {
													colData = prod.language && t("language:" + prod.language);
													//(languages[prod.language].sv || languages[prod.language].name);
												} else if (
													["productionDate", "scriptDate", "deliveryDate"].includes(key)
												) {
													// date without time
													colData = colData
														? moment(toDate(colData)).format("YYYY-MM-DD")
														: "";

													// edit date inline?
													if (prod.status !== "done" && prod.isAdmin) {
														colData = (
															<>
																{colData}
																<DateTime
																	value={colData}
																	timeFormat={false}
																	closeOnSelect={true}
																	onChange={(dt) => {
																		if (typeof dt === "object") {
																			base.updateDoc("productions/" + prod.id, {
																				[key]: new Date(
																					dt.format("YYYY-MM-DD"),
																				),
																			});
																		}
																	}}
																	renderInput={(
																		props,
																		openCalendar,
																		closeCalendar,
																	) => (
																		<Icon
																			name="pencil"
																			size="small"
																			onClick={openCalendar}
																		/>
																	)}
																/>
															</>
														);
													}
												} else if (["updated", "created"].includes(key)) {
													// date with time
													colData =
														colData &&
														moment(toDate(colData)).format("YYYY-MM-DD HH:mm:ss");
												} else if (key === "reader") {
													// get reader name
													colData =
														colData && colData.length ? (
															colData
																.map((id) => {
																	const reader =
																		readers.length &&
																		readers.find((reader) => reader.id === id);

																	return reader
																		? reader.firstName + " " + reader.lastName
																		: null; // <-- GHOST
																})
																.filter(
																	(reader) =>
																		// avoid "ghost" readers
																		reader,
																)
																.join(", ")
														) : (
															<Icon name="attention" color="red" />
														);
												} else if (key === "author" || key === "translator") {
													// get author name
													colData =
														colData &&
														colData.length &&
														colData
															.map((id) => {
																const person =
																	people.length &&
																	people.find((person) => person.id === id);

																return person
																	? person.firstName + " " + person.lastName
																	: null;
															})
															.join(", ");
												} else if (key === "proofer") {
													// proofer status
													colData = colData ? (
														<Icon
															name="headphones"
															color={
																prod.isProofer && !colData.includes(user.uid)
																	? "yellow"
																	: "green"
															}
														/>
													) : (
														<Icon name="attention" color="red" />
													);

													if (prod.productionType || prod.productionType === "subcontract")
														colData = null;
												} else if (key === "editor") {
													// editor status
													colData = colData ? (
														<Icon
															name="cut"
															color={
																prod.isEditor && !colData.includes(user.uid)
																	? "yellow"
																	: "green"
															}
														/>
													) : (
														<Icon name="attention" color="red" />
													);

													if (prod.productionType || prod.productionType === "subcontract")
														colData = null;
												} else if (key === "billingStatus") {
													colData = <ProductionBilling production={prod} />;
												} else if (key === "progress") {
													colData = (
														<>
															{prod.stats && (
																<ProgressRecording size="tiny" production={prod} />
															)}

															<ProgressEdit size="tiny" production={prod} />
														</>
													);
												} else if (key === "progressTotal") {
													colData = (
														<>
															{prod.stats && (
																<ProgressRecording size="small" production={prod} />
															)}
														</>
													);
												} else if (key === "ratio") {
													colData =
														prod.ratio !== undefined ? Math.round(prod.ratio) + "%" : null;
												} else if (key === "duration") {
													const duration = prod.duration;

													colData = (
														<>
															{duration && secToDuration(duration, false)}{" "}
															{!prod.deliveryDuration && duration && (
																<Popup
																	inverted
																	size="tiny"
																	content={t("calcDurMess")}
																	trigger={
																		<Icon
																			name="calculator"
																			size="small"
																			color="grey"
																		/>
																	}
																/>
															)}
														</>
													);
												} else if (key === "studioHours") {
													const bookedHours = bookedStudioHours(prod);
													const estHours = estimateStudioHours(prod);

													colData = estHours
														? ` (${bookedHours ? bookedHours + "/" : ""}${estHours}h)`
														: "";
												}

												// format custom publisher meta
												if (!col && metaColumns) {
													col = metaColumns[key];
													const prodMeta = prod.meta || {};
													colData = prodMeta[key];

													if (col && col.type === "checkbox") {
														colData = (
															<Icon
																name={col.icon || "checkmark"}
																color={col.color || "green"}
																style={{ opacity: prodMeta[key] ? 1 : 0.3 }}
															/>
														);
													} else if (col && col.type === "file") {
														colData = prodMeta[key] ? (
															<a
																href={prodMeta[key]}
																target="_blank"
																rel="noopener noreferrer"
															>
																<Icon name="file" />
																{t("file")}
															</a>
														) : (
															""
														);
													}
												}

												// don't break if the meta field was deleted
												if (!col) return null;
												return <Table.Cell key={key}>{colData}</Table.Cell>;
											})}

											{hasRight(store, "production.deleteDraftOffer", { prod }) ? (
												<Table.Cell collapsing>
													{status === "draft" ||
													status === "offer" ||
													hasRight(store, "production.deleteAfterAccepted", { prod }) ? (
														<a
															href="/"
															onClick={(e) => {
																e.preventDefault();
																this.confirmDeleteProduction(
																	prod.id,
																	prod.title,
																	status,
																);
															}}
														>
															<Icon name="trash alternate" color="black" />
														</a>
													) : (
														<Icon name="trash alternate" color="black" disabled={true} />
													)}
												</Table.Cell>
											) : (
												<Table.Cell />
											)}
										</Table.Row>
									);
								})}
						</Table.Body>
					</Table>
					<div className="flex-stack">
						{hasRight(store, "production.exportFile") && (
							<ExportButton
								selected={selectedArticles}
								productions={store.state.productions}
								selectAll={(e) => {
									// merge and get unique
									const newSelected = {
										...selectedArticles,
										...productions.reduce((prev, curr) => {
											const articles = {};
											if (curr.isbn) articles.total = "metadata";

											if (curr.deliveryParts)
												curr.deliveryParts.forEach((part, i) => {
													if (part.title && part.isbn) articles["part_" + i] = "metadata";
												});

											if (curr.deliveryEbook && curr.deliveryEbook.isbn)
												articles.ebook = "metadata";

											if (Object.keys(articles).length) {
												prev[curr.id] = articles;
											}

											return prev;
										}, {}),
									};

									this.setState({ selectedArticles: newSelected });
								}}
								unselectAll={(e) => {
									this.setState({ selectedArticles: {} });
								}}
							/>
						)}

						<div style={{ textAlign: "center" }}>
							{!!totalDuration && (
								<span>
									<Icon name="clock" />
									<b>{t("totDuration")}</b> {secToDuration(totalDuration, false)}{" "}
								</span>
							)}
							{isProducerAdmin && (
								<span>
									({hits} {t("productions")})
									{avgHourRatio && (
										<div>
											<b>{t("prodRatio")}</b> {avgProdRatio}% &bull; <b>{t("hourRatio")}</b>{" "}
											{avgHourRatio}%
										</div>
									)}
								</span>
							)}
						</div>

						<Pagination
							page={page}
							items={hits}
							per={perPage}
							setPage={(page) => this.setState({ page })}
							style={{ marginLeft: "1rem", float: "right" }}
							emptyElement={true}
						/>
					</div>
				</Segment>

				{this.state.modal && (
					<Modal size="small" open={true} onClose={this.modalClose} closeOnDocumentClick>
						{this.state.modal === "filter" && (
							<>
								<Modal.Header>{t("filterProds")}</Modal.Header>
								<Modal.Content>
									<Form>
										<Form.Group widths="equal">
											<Form.Select
												fluid
												multiple
												label="Status"
												name="status"
												onChange={this.setFilter}
												value={
													this.state.filter && this.state.filter.status
														? this.state.filter.status
														: []
												}
												options={Object.keys(prodStatus).map((key, i) => {
													return {
														key: i,
														//text: prodStatus[key].text,
														text: t(key),
														value: key,
													};
												})}
											/>
											{hasRight(store, "producerBilling.createPublisherInvoice") && (
												<Form.Select
													fluid
													label={t("invoice")}
													name="billing"
													onChange={this.setFilter}
													value={this.state.filter.billing || null}
													options={[
														{
															key: "fake",
															text: t("dontFilter"),
															value: null,
														},
														{
															key: "unbilled",
															value: "unbilled",
															text: t("notInvoiced"),
														},
														{
															key: "unbilledProduction",
															value: "unbilledProduction",
															text: t("notInvoicedProd"),
														},
														{
															key: "unbilledCloud",
															value: "unbilledCloud",
															text: t("notInvoicedCloud"),
														},
														{
															key: "unpayed",
															value: "unpayed",
															text: t("invoicedNotPayed"),
														},
														{
															key: "payed",
															value: "payed",
															text: t("invoicedAndPayed"),
														},
													]}
												/>
											)}
											<Form.Select
												search
												deburr
												fluid
												label={t("author")}
												name="author"
												loading={this.state.filterLoadingPeople}
												onOpen={this.loadPeople}
												onChange={this.setFilter}
												value={this.state.filter.author || null}
												options={[
													{
														key: "fake",
														text: t("dontFilter"),
														value: null,
													},
													...people
														.filter(
															(author) =>
																!author.visible ||
																!author.visible.length ||
																author.visible.find(
																	(pubId) =>
																		this.props.profile.permissions &&
																		(this.props.profile.permissions.producer ||
																			(this.props.profile.permissions.publisher &&
																				this.props.profile.permissions
																					.publisher[pubId])),
																),
														)
														.map((author, i) => ({
															key: i,
															text: author.firstName + " " + author.lastName,
															value: author.id,
														})),
												]}
											/>
											<Form.Select
												search
												deburr
												fluid
												label={t("narrator")}
												name="reader"
												loading={this.state.filterLoadingReaders}
												onOpen={this.loadReaders}
												onChange={this.setFilter}
												value={this.state.filter.reader || null}
												options={[
													{
														key: "fake",
														text: t("dontFilter"),
														value: null,
													},
													{
														key: "missing",
														text: t("missingReader"),
														value: "EMPTY",
													},
													...this.state.readers
														.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
														.map((user, i) => ({
															key: i,
															text: user.firstName + " " + user.lastName,
															value: user.id,
														})),
												]}
											/>
											<Form.Select
												search
												deburr
												fluid
												label={t("subcontractor")}
												name="subcontractor"
												loading={this.state.filterLoadingReaders}
												onOpen={this.loadSubcontractors}
												onChange={this.setFilter}
												value={this.state.filter.subcontractor || null}
												options={[
													{
														key: "fake",
														text: t("dontFilter"),
														value: null,
													},
													{
														key: "missing",
														text: t("missingSubcontractor"),
														value: "EMPTY",
													},
													...this.state.subcontractors
														.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
														.map((user, i) => ({
															key: i,
															text: user.firstName + " " + user.lastName,
															value: user.id,
														})),
												]}
											/>
										</Form.Group>
										<Form.Group widths="equal">
											<Form.Select
												search
												deburr
												fluid
												label={t("language")}
												name="language"
												onChange={this.setFilter}
												value={this.state.filter.language || null}
												options={[
													{
														key: "fake",
														text: t("dontFilter"),
														value: null,
													},
													...Object.keys(languages)
														.filter((langCode) => !["nb", "nn"].includes(langCode))
														.map((langCode) => {
															const lang = languages[langCode];

															return {
																key: langCode,
																value: langCode,
																text:
																	(lang.sv || lang.name) +
																	" (" +
																	lang.nativeName +
																	")",
															};
														}),
												]}
											/>
											{store.state.organizations && store.state.producerId && (
												<Form.Select
													search
													deburr
													fluid
													label={t("publisher")}
													name="publisher"
													onChange={this.setFilter}
													value={this.state.filter.publisher || null}
													options={[
														{
															key: "fake",
															text: t("dontFilter"),
															value: null,
														},
														...store.state.organizations[store.state.producerId].publishers
															.filter((publisher) => store.state.organizations[publisher])
															.map((publisher) => {
																const { id, name } =
																	store.state.organizations[publisher];
																return {
																	key: id,
																	value: id,
																	text: name,
																};
															})
															.sort((a, b) =>
																a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1,
															),
													]}
												/>
											)}
											{get(this.props, "profile.permissions.producer") ? (
												<>
													<Form.Select
														search
														deburr
														fluid
														label={t("editor")}
														name="editor"
														loading={this.state.filterLoadingStaff}
														onOpen={this.loadStaff}
														onChange={this.setFilter}
														value={this.state.filter.editor || null}
														options={[
															{
																key: "fake",
																text: t("dontFilter"),
																value: null,
															},
															{
																key: "missing",
																text: t("missingEditor"),
																value: "EMPTY",
															},
															...this.state.users
																.filter((user) =>
																	Object.values(user.permissions.producer).find(
																		(roles) => roles.includes("producerEditor"),
																	),
																)
																.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
																.map((user, i) => ({
																	key: i,
																	text: user.firstName + " " + user.lastName,
																	value: user.id,
																})),
														]}
													/>

													<Form.Select
														search
														deburr
														fluid
														label={t("proofer")}
														name="proofer"
														loading={this.state.filterLoadingStaff}
														onOpen={this.loadStaff}
														onChange={this.setFilter}
														value={this.state.filter.proofer || null}
														options={[
															{
																key: "fake",
																text: t("dontFilter"),
																value: null,
															},
															{
																key: "missing",
																text: `(${t("missingProofer")})`,
																value: "EMPTY",
															},
															...this.state.users
																.filter((user) =>
																	Object.values(user.permissions.producer).find(
																		(roles) => roles.includes("producerProofer"),
																	),
																)
																.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
																.map((user, i) => ({
																	key: i,
																	text: user.firstName + " " + user.lastName,
																	value: user.id,
																})),
														]}
													/>
												</>
											) : (
												<ArticleField
													setFilter={this.setFilter}
													value={this.state.filter && this.state.filter.articles}
												/>
											)}
										</Form.Group>
										{get(this.props, "profile.permissions.producer") && (
											<Form.Group widths="equal">
												<FilterDate
													label={t("productionDateAfter")}
													name="productionDateAfter"
													filter={this.state.filter}
													setFilter={this.setFilter}
												/>
												<FilterDate
													label={t("productionDateBefore")}
													name="productionDateBefore"
													filter={this.state.filter}
													setFilter={this.setFilter}
												/>
												<Form.Select
													search
													deburr
													fluid
													label={t("productionManager")}
													name="manager"
													loading={this.state.filterLoadingStaff}
													onOpen={this.loadStaff}
													onChange={this.setFilter}
													value={this.state.filter.manager || null}
													options={[
														{
															key: "fake",
															text: t("dontFilter"),
															value: null,
														},
														{
															key: "missing",
															text: t("missingProdManager"),
															value: "EMPTY",
														},
														...this.state.users
															.filter((user) =>
																Object.values(user.permissions.producer).find(
																	(roles) =>
																		roles.includes("producerAdmin") ||
																		roles.includes("producerStaff"),
																),
															)
															.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
															.map((user, i) => ({
																key: i,
																text: user.firstName + " " + user.lastName,
																value: user.id,
															})),
													]}
												/>
												<ArticleField
													setFilter={this.setFilter}
													value={this.state.filter && this.state.filter.articles}
												/>
											</Form.Group>
										)}
										<Form.Group widths="equal">
											<FilterDate
												label={t("scriptDateAfter")}
												name="scriptDateAfter"
												filter={this.state.filter}
												setFilter={this.setFilter}
											/>
											<FilterDate
												label={t("scriptDateBefore")}
												name="scriptDateBefore"
												filter={this.state.filter}
												setFilter={this.setFilter}
											/>
											{hasRight(store, "producerBilling.createPublisherInvoice") ||
											isPublisherAdmin ||
											isPublisherStaff ? (
												<>
													<FilterDate
														label={t("delDateAfter")}
														name="deliveryDateAfter"
														filter={this.state.filter}
														setFilter={this.setFilter}
													/>
													<FilterDate
														label={t("delDateBefore")}
														name="deliveryDateBefore"
														filter={this.state.filter}
														setFilter={this.setFilter}
													/>
												</>
											) : (
												<>
													<div className="field" />
													<div className="field" />
												</>
											)}
										</Form.Group>
										<Form.Group widths="equal">
											<ProductionTags
												value={this.state?.filter?.productionTags || []}
												onChange={(data) => this.setFilter(null, data)}
											/>
											{hasRight(store, "producerBilling.createPublisherInvoice") ||
											isPublisherStaff ||
											isPublisherAdmin ? (
												<Form.Select
													fluid
													multiple
													label={t("typeOfProd")}
													name="productionType"
													onChange={this.setFilter}
													value={
														(this.state.filter && this.state.filter.productionType) || []
													}
													options={[
														{ key: "internal", text: t("internal") },
														{ key: "backlist", text: t("backlistEbook") },
														{ key: "external", text: t("extern") },
														{ key: "subcontract", text: t("subcontractor") },
													].map(({ key, text }) => {
														return {
															key,
															text,
															value: key,
														};
													})}
												/>
											) : (
												<div className="field" />
											)}
											<div className="field" />
											<div className="field" />
											<Form.Checkbox
												label={t("scheduled")}
												name="scheduled"
												onChange={this.setFilter}
												checked={this.state.filter?.scheduled}
											/>
										</Form.Group>
									</Form>
								</Modal.Content>

								<Modal.Actions>
									<Button onClick={this.resetFilter} content={t("reset")} />
									<Button
										positive
										icon="checkmark"
										labelPosition="right"
										content={t("ready")}
										onClick={this.modalClose}
									/>
								</Modal.Actions>
							</>
						)}

						{this.state.modal === "columns" && (
							<>
								<Modal.Header>{t("chooseColumns")}</Modal.Header>
								<Modal.Content>
									<strong>{t("availableFields")}</strong>
									<div className="list-columns">
										{Object.keys(columns).map((key) => {
											const col = columns[key];
											const active = this.state.columns.includes(key);
											if (col.hidden) return null;

											return (
												<Label
													key={key}
													as="a"
													value={key}
													onClick={(e, data) => {
														const columns = [...this.state.columns];
														if (active) {
															const ci = columns.indexOf(key);
															columns.splice(ci, 1);
														} else {
															columns.push(data.value);

															// load people if column is selected
															if (data.value === "author" || data.value === "translator")
																this.loadPeople();
														}
														this.setState({ columns });
														localStorage("listProductionSettings2", "columns", columns);
													}}
													style={{ opacity: active ? 0.5 : 1 }}
												>
													<Icon name={active ? "minus" : "plus"} />
													{col.name}
												</Label>
											);
										})}
									</div>
									{this.props.profile.permissions.publisher && (
										<>
											<strong>{t("extraPublFields")}</strong>
											<div className="list-columns custom-meta">
												{/* TODO: clean this mess up */}
												{metaColumns &&
													Object.keys(metaColumns).map((key) => {
														const meta = metaColumns[key];
														const active = this.state.columns.includes(key);
														return (
															<Label
																key={key}
																as="a"
																value={key}
																onClick={(e, data) => {
																	const columns = [...this.state.columns];
																	if (active) {
																		const ci = columns.indexOf(key);
																		columns.splice(ci, 1);
																	} else {
																		columns.push(data.value);
																	}
																	this.setState({ columns });
																	localStorage(
																		"listProductionSettings2",
																		"columns",
																		columns,
																	);
																}}
																style={{ opacity: active ? 0.5 : 1 }}
															>
																<Icon name={active ? "minus" : "plus"} />
																{meta.name}
															</Label>
														);
													})}
											</div>
										</>
									)}
									<strong>{t("choosenFields")}</strong>
									<div className="list-columns">
										{this.state.columns.map((key) => {
											let col = columns[key];
											let custom = false;

											// get custom publisher meta
											if (!col && metaColumns) {
												col = metaColumns[key];
												custom = true;
											}

											// don't break if the meta field was deleted
											if (!col) return null;

											return (
												<Label
													key={key}
													as="a"
													value={key}
													className={custom ? "custom-meta" : ""}
													onClick={(e, data) => {
														const columns = [...this.state.columns];

														const ci = columns.indexOf(key);
														if (ci) {
															const tempLeft = columns[ci - 1];
															columns[ci - 1] = columns[ci];
															columns[ci] = tempLeft;
															this.setState({ columns });
															localStorage("listProductionSettings2", "columns", columns);
														}
													}}
												>
													<Icon name="left arrow" />
													{col.name}
												</Label>
											);
										})}
									</div>
								</Modal.Content>
								<Modal.Actions>
									<Button
										positive
										icon="checkmark"
										labelPosition="right"
										content={t("ready")}
										onClick={this.modalClose}
									/>
								</Modal.Actions>
							</>
						)}

						{this.state.modal === "create" && (
							<>
								<Modal.Header>{t("createNewProd")}</Modal.Header>
								<Modal.Content>
									<Form size="small" className="clear">
										<Form.Group widths="equal">
											<Form.Input
												required
												label={t("bookTitle")}
												title="prodTitle"
												id="title-input"
												error={!this.state.newTitleSet}
												onChange={(e, data) => {
													this.setState({ newTitleSet: !!data.value });
												}}
											/>
											{!this.state.selectedPublisherSingle && (
												<Form.Select
													search
													deburr
													label={t("publisher")}
													name="publisher"
													options={
														store.state.organizations
															? Object.values(store.state.organizations)
																	.filter(
																		(pub) =>
																			pub.type === "publisher" &&
																			(store.state.organizations?.[
																				producerId
																			]?.publishers?.includes(pub.id) ||
																				this.props.profile.permissions
																					?.publisher?.[pub.id]),
																	)
																	.sort((a, b) =>
																		a.name.toLowerCase() > b.name.toLowerCase()
																			? 1
																			: -1,
																	)
																	.map((pub) => ({
																		key: pub.id,
																		value: pub.id,
																		text: pub.name,
																	}))
															: []
													}
													value={this.state.selectedPublisher}
													onChange={(e, data) => {
														this.setState({ selectedPublisher: data.value });
													}}
													required
												/>
											)}
											{this.props.profile.permissions.producer &&
												!this.state.selectedProducerSingle && (
													<Form.Select
														search
														deburr
														label={t("productionCompany")}
														name="producer"
														options={
															store.state.organizations
																? Object.values(store.state.organizations)
																		.filter(
																			(org) =>
																				org.type === "producer" &&
																				this.props.profile.permissions.producer[
																					org.id
																				],
																		)
																		.sort((a, b) =>
																			a.name.toLowerCase() > b.name.toLowerCase()
																				? 1
																				: -1,
																		)
																		.map((pub) => ({
																			key: pub.id,
																			value: pub.id,
																			text: pub.name,
																		}))
																: []
														}
														value={this.state.selectedProducer}
														onChange={(e, data) => {
															this.setState({ selectedProducer: data.value });
														}}
														required
													/>
												)}
										</Form.Group>
									</Form>
								</Modal.Content>
								<Modal.Actions className="clear">
									<Button content={t("close")} floated="left" onClick={this.modalClose} />
									<Button
										size="small"
										color="blue"
										floated="right"
										content={t("save")}
										onClick={this.handleSubmit}
									/>
								</Modal.Actions>
							</>
						)}
					</Modal>
				)}
			</>
		);
	}
}

const FilterDate = (props) => {
	const { filter, label, name, setFilter } = props;
	return (
		<div className="field">
			<label>{label}</label>
			<DateTime
				value={filter[name] ? moment(filter[name]).format("YYYY-MM-DD") : ""}
				timeFormat={false}
				onChange={(dt) => {
					if (typeof dt === "object") {
						// valid date, save it
						setFilter(null, {
							name,
							value: moment(dt.format("YYYY-MM-DD")).toDate(),
						});
					}
				}}
				onBlur={(dt) => {
					const writtenDate = moment(dt).format("YYYY-MM-DD");
					// see if typed date is valid and not the same as currently is in the db
					if (writtenDate !== "Invalid date") {
						// valid date, save it
						setFilter(null, {
							name,
							value: moment(writtenDate).toDate(),
						});
					} else {
						console.log("Invalid date", dt);
						setFilter(null, {
							name,
							value: "",
						});
					}
				}}
			/>
		</div>
	);
};

const SearchField = (props) => {
	const { t } = useTranslation();
	const { onSearch } = props;
	const [search, setSearch] = useState("");
	const debouncedSearch = useDebounce(search, 500);

	useEffect(() => {
		// query string search
		const urlParams = new URLSearchParams(window.location.search);
		const querySearch = urlParams.get("q");
		if (querySearch) {
			console.log("set");
			setSearch(querySearch);
		}
	}, []);

	useEffect(() => {
		// perform search
		onSearch(debouncedSearch);
	}, [onSearch, debouncedSearch]);

	//Problem with placeholder...
	return (
		<Input
			size="small"
			icon="search"
			placeholder={t("search")}
			value={search}
			onChange={(e, data) => {
				setSearch(data.value);
			}}
		/>
	);
};

const ArticleField = ({ setFilter, value }) => {
	//const { t } = this.props;
	const { t } = useTranslation();
	return (
		<Form.Select
			fluid
			multiple
			label={t("articles")}
			name="articles"
			onChange={setFilter}
			value={value || []}
			options={[
				{ key: "Total", text: t("normal") },
				{ key: "Parts", text: t("shared") },
				{ key: "CD", text: t("cd") },
				{ key: "MP3CD", text: t("mp3cd") },
				{ key: "Ebook", text: t("eBook") },
			].map(({ key, text }) => {
				return {
					key,
					text,
					value: key,
				};
			})}
		/>
	);
};

const UnacceptedCount = ({ quickFilter, producerId }) => {
	const [count, setCount] = useState();
	const { t } = useTranslation();

	useEffect(
		() =>
			// returns it's own detach function
			db
				.collection("organizations")
				.doc(producerId)
				.collection("aggregation")
				.doc("counters")
				.onSnapshot((doc) => {
					const counters = doc.data();
					if (counters) {
						const { unacceptedProductions } = counters;
						setCount(unacceptedProductions);
					}
				}),

		[producerId],
	);

	return (
		<Popup
			inverted
			size="tiny"
			content={t("statusQuestionOrPlan")}
			trigger={
				<Button
					basic
					size="tiny"
					onClick={(e) => {
						quickFilter({
							filter: {
								status: ["offer", "planning"],
							},
							column: "updated",
							direction: "descending",
						});
					}}
					style={{ position: "relative" }}
				>
					<Icon name="thumbs down" />
					{t("notAccepted")}
					{!!count && (
						<Label color="red" floating circular size="tiny">
							{count}
						</Label>
					)}
				</Button>
			}
		/>
	);
};

export default withTranslation(["common", "language"])(withStore(ListProductions));
