import { useTranslation } from "react-i18next";

import arrayChunk from "astrid-helpers/src/arrayChunk";

import Form from "../../../../forms/components/Form/Form";

import DistributeArticlesFormDivider from "./DistributeArticlesFormDivider";

export default function DistributeArticlesFormChannels({ filteredChannels, selectedChannels }) {
	const { t } = useTranslation();

	const { watch } = Form.useFormContext();

	const { operation } = watch();

	if (!operation) {
		return null;
	}

	const selectedChannelIds = selectedChannels.map(({ id }) => id);

	return (
		<>
			<DistributeArticlesFormDivider>{t("channels", "Channels")}</DistributeArticlesFormDivider>

			{arrayChunk(filteredChannels, 3).map((channels, index) => (
				<Form.Group key={index} widths={3}>
					{channels.map((channel) => (
						<Form.CheckboxButton
							key={channel.id}
							fluid
							basic
							name={`channels.${channel.id}`}
							defaultValue={selectedChannelIds.includes(channel.id) || false}
						>
							{channel.name}
						</Form.CheckboxButton>
					))}
				</Form.Group>
			))}

			<Form.ValidationMessage root name="channels" />
		</>
	);
}
