import React from "react";
import { useTranslation } from "react-i18next";

import ManageArtifactFiles from "../../../artifacts/components/ManageArtifactFiles/ManageArtifactFiles";

import AdminCard from "../../components/AdminCard/AdminCard";

export default function AdminArtifactFilesView({ artifact }) {
	const { t } = useTranslation();

	return (
		<AdminCard header={t("parts", "Files")}>
			<ManageArtifactFiles artifact={artifact} />
		</AdminCard>
	);
}
