import { useTranslation } from "react-i18next";

import api from "../../../api";

export default function useDistributionDropdownMenuOptions(distribution) {
	const { t } = useTranslation();

	return [
		{
			text: t("view", "View"),
			linkTo: `/publisher/${distribution.publisher.id}/admin/distributions/${distribution.id}`,
		},
		{
			text: t("viewArticle", "View article"),
			linkTo: `/publisher/${distribution.publisher.id}/admin/articles/${distribution.article.id}`,
		},
		{
			text: t("delete", "Delete"),
			onClick: () => {
				return api.distributions.hardDelete(distribution);
			},
		},
	];
}
