import { useTranslation } from "react-i18next";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import CreateScriptButton from "../../../scripts/components/CreateScript/CreateScriptButton";
import ScriptsCollectionDataTable from "../../../scripts/components/ScriptsCollectionDataTable/ScriptsCollectionDataTable";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

export default function AdminScriptsView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("scripts", "Scripts")}>
				{searchInput}

				<CreateScriptButton publisher={organization} />
			</AdminHeader>

			<ScriptsCollectionDataTable searchQuery={searchQuery} />
		</>
	);
}
