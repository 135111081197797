import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import mergeArticleDataWithPresetTargetData from "astrid-firestore/src/api/distribution/utils/mergeArticleDataWithPresetTargetData";

import Collapsible from "../../../../ui/components/Collapsible/Collapsible";
import DataTable from "../../../../ui/components/DataTable/DataTable";

import AdminCard from "../../../components/AdminCard/AdminCard";

import displaySelectedCountries from "../utils/displaySelectedCountries";

import CountryModal from "./CountryModal";

export default function AdminArticleDistributionCountries({ channels, publisher, article }) {
	const { t } = useTranslation();
	const [selectedChannel, setSelectedChannel] = useState(null);

	const mergedCountries = mergeArticleDataWithPresetTargetData({ channels, article, publisher });

	return (
		<Collapsible title={t("countries", "Countries")} style={{ margin: 6 }}>
			<AdminCard header={t("countries", "Countries")}>
				<DataTable
					data={channels.map((channel) => {
						return {
							id: channel.id,
							name: channel.name,
							countries: mergedCountries[channel.id]?.countries,
						};
					})}
					onClick={(channel) => setSelectedChannel(channel)}
					columns={[
						{
							id: "name",
							Header: t("channel", "Channel"),
							collapsing: true,
						},
						{
							id: "countries",
							Header: t("countries", "Countries"),
							collapsing: true,
							accessor: ({ countries }) => {
								return displaySelectedCountries(countries);
							},
						},
					]}
				/>
				{selectedChannel && (
					<CountryModal
						selectedChannel={selectedChannel}
						article={article}
						onClose={() => setSelectedChannel(null)}
					/>
				)}
			</AdminCard>
		</Collapsible>
	);
}
