import { useTranslation } from "react-i18next";

import CreateDistributionArgsButton from "../../../distribution/components/CreateDistributionCredentials/CreateDistributionArgsButton";
import CreateDistributionPresetButton from "../../../distribution/components/CreateDistributionPreset/CreateDistributionPresetButton";
import DistributionArgsDataTable from "../../../distribution/components/DistributionCredentialsDataTable/DistributionArgsDataTable";
import DistributionPresetsDataTable from "../../../distribution/components/DistributionPresetsDataTable/DistributionPresetsDataTable";

import AdminCard from "../../components/AdminCard/AdminCard";
import AdminHeader from "../../components/AdminHeader/AdminHeader";

export default function AdminDistributionSettingsView({ publisher }) {
	const { t } = useTranslation();

	return (
		<>
			<AdminHeader text={t("distribution", "Distribution")}></AdminHeader>
			<AdminCard
				header={t("presets", "Presets")}
				extra={<CreateDistributionPresetButton publisher={publisher} />}
			>
				<DistributionPresetsDataTable publisher={publisher} />
			</AdminCard>
			<AdminCard
				header={t("overriddenDistributionArgs", "Overridden distribution args")}
				extra={<CreateDistributionArgsButton publisher={publisher} />}
			>
				<DistributionArgsDataTable publisher={publisher} />
			</AdminCard>
		</>
	);
}
