import { useTranslation } from "react-i18next";

import ProductionPriceForm from "../../../../productions/components/ProductionPriceForm/ProductionPriceForm";
import OpenButton from "../../../../ui/components/OpenButton/OpenButton";

export default function AddRevenueAction({ production, ...props }) {
	const { t } = useTranslation();

	return (
		<OpenButton text={t("addEarnings")} {...props}>
			{({ setOpen }) => {
				return (
					<ProductionPriceForm
						type="revenue"
						production={production}
						firstPartyId={production.producer}
						onClose={() => setOpen(false)}
					/>
				);
			}}
		</OpenButton>
	);
}
