import { Header } from "semantic-ui-react";

import Flex from "../../../../../components/Flex/Flex";
import StudioDropdownMenu from "../../../../studios/components/StudioDropdownMenu/StudioDropdownMenu";

import AdminCard from "../../../components/AdminCard/AdminCard";

export default function StudioHeader({ studio }) {
	return (
		<AdminCard>
			<Flex style={{ gap: 15, justifyContent: "flex-start" }}>
				<Header style={{ margin: 0 }}>
					{studio.name}
					<Header.Subheader>{studio.id}</Header.Subheader>
				</Header>
				<StudioDropdownMenu studio={studio} />
			</Flex>
		</AdminCard>
	);
}
