import { Modal } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import articleStatuses from "astrid-firestore/src/api/articles/constants/articleStatuses";

import api from "../../../../api";
import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

export default function DistributeArticlesDraftForm({ article, onClose }) {
	const { t } = useTranslation();

	const onSubmit = async () => {
		return api.articles.update({ ref: article.ref, status: articleStatuses.READY });
	};

	return (
		<ModalPrompt
			header={t("distribute", "Distribute")}
			confirmText={t("markAsReady", "Mark as ready")}
			onClose={onClose}
			onSave={onSubmit}
		>
			<Modal.Description>
				{t(
					"articleIsInDraftConfirm",
					"This article is a draft. Do you want to mark it as ready and distribute?",
				)}
			</Modal.Description>
		</ModalPrompt>
	);
}
