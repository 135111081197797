import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import InstantSearchDataTable from "../../../ui/components/InstantSearch/InstantSearchDataTable";
import useSearchInput from "../../../ui/hooks/useSearchInput";

import AddUserButton from "../../../users/components/AddUser/AddUserButton";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

import useAdminUsersColumns from "./hooks/useAdminUsersColumns";

export default function AdminUsersView({ organization }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const columns = useAdminUsersColumns({ organization });

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("users", "Users")}>
				{searchInput}
				<AddUserButton organization={organization} />
			</AdminHeader>

			<InstantSearchDataTable
				indexName="users"
				searchQuery={searchQuery}
				columns={columns}
				filters={`organizationIds:${organization.id}`}
				onClick={({ id }) => navigate(id)}
			/>
		</>
	);
}
