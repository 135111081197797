import { Table } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import api from "../../../../../api";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

export default function ArticleDistributionChannelsEditForm({ article, channels, onClose }) {
	const { t } = useTranslation();
	const channelIds = article.channelIds || channels.map((channel) => channel.id);

	const { form, onSubmit, error } = useSimpleForm({
		values: {
			channels: channelIds.reduce((acc, id) => ({ ...acc, [id]: true }), {}) || {},
		},
		transform: ({ channels }) => {
			return {
				ref: article.ref,
				channelIds: Object.entries(channels)
					.filter(([, value]) => value)
					.map(([id]) => id),
			};
		},
		onSubmit: (data) => {
			return api.articles.update(data);
		},
		onSuccess: onClose,
	});

	return (
		<Form.Modal
			size="tiny"
			dense
			header={t("distributionChannels", "Distribution channels")}
			form={form}
			error={error}
			onSubmit={onSubmit}
			onClose={onClose}
		>
			<Table basic="very">
				{channels.map(({ id, name }) => (
					<Table.Row key={id} verticalAlign="middle">
						<Table.Cell collapsing style={{ paddingLeft: "1.5em" }}>
							<Form.Checkbox
								name={`channels.${id}`}
								style={{ display: "block" }}
								rules={{ required: false }}
							/>
						</Table.Cell>
						<Table.Cell>{name}</Table.Cell>
					</Table.Row>
				))}
			</Table>
		</Form.Modal>
	);
}
