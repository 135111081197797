import { Message } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import AccountAudioSamples from "../../../account/views/AccountSettingsView/components/AccountAudioSamples";
import AccountGeneral from "../../../account/views/AccountSettingsView/components/AccountGeneral";
import AccountLanguages from "../../../account/views/AccountSettingsView/components/AccountLanguages";
import AccountNarration from "../../../account/views/AccountSettingsView/components/AccountNarration";
import UserInfo from "../../../superadmin/views/SuperAdminUserView/components/UserInfo";

import AdminUserAgreements from "./components/AdminUserAgreements";
import AdminUserPrices from "./components/AdminUserPrices";
import AdminUserSettings from "./components/AdminUserSettings";
import AdminUserVendors from "./components/AdminUserVendors";

export default function AdminUserView({ organization, user }) {
	const { t } = useTranslation();

	const userIsPartOfOrganization =
		user?.permissions?.[organization.type]?.[organization.id] || user?.organizationIds?.includes(organization.id);

	const isProducer = organization.type === organizationTypes.PRODUCER;
	const isReader = !!user?.permissions?.reader;

	return (
		<>
			<UserInfo user={user} organization={organization} />

			{userIsPartOfOrganization && (
				<>
					<AdminUserSettings user={user} organization={organization} />
					{isReader && <AccountGeneral user={user} />}
					{isReader && <AccountLanguages user={user} />}
					{isReader && <AccountNarration user={user} />}
					{isReader && <AccountAudioSamples user={user} />}
					{isProducer && <AdminUserPrices user={user} organization={organization} />}
					{isProducer && <AdminUserAgreements user={user} organization={organization} />}
					{isProducer && <AdminUserVendors user={user} />}
				</>
			)}

			{!userIsPartOfOrganization && (
				<Message error>{t("userNotInOrganization", "The user is not part of this organization")}</Message>
			)}
		</>
	);
}
