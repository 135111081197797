import { useOutletContext, useParams } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import AdminPageView from "../components/AdminPage/AdminPageView";
import AdminPriceView from "../views/AdminPriceView/AdminPriceView";

export default function AdminPrice() {
	const { id } = useParams();
	const hasRight = useHasRight();
	const { organization } = useOutletContext();

	if (!hasRight("prices.view")) {
		return <Unauthorized />;
	}

	return (
		<AdminPageView id={id} organization={organization}>
			<AdminPriceView />
		</AdminPageView>
	);
}
