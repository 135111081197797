import { useTranslation } from "react-i18next";

import api from "../../../../../api";

export default function useAdminArticleBundleDropdownMenuOptions({ bundle }) {
	const { t } = useTranslation();

	return [
		{
			text: t("delete", "Delete"),
			action: () => {
				return api.articleBundles.delete(bundle);
			},
		},
	];
}
