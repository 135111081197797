import { useNavigate } from "react-router-dom";

import api from "../../../../api";
import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useArticlesTableColumns from "../../hooks/useArticlesTableColumns";

import ArticlesTableFooter from "../ArticlesTableFooter/ArticlesTableFooter";

export default function ArticlesCollectionDataTable({
	publisher,
	production,
	enableSync,
	showSync,
	bundle,
	imprint,
	serie,
	title,
	...props
}) {
	const navigate = useNavigate();

	const columns = useArticlesTableColumns({ bundle, production, title, enableSync, showSync });

	const query = api.articles.getQuery({
		publisher,
		production,
		bundle,
		imprint,
		serie,
		title,
	});

	return (
		<CollectionDataTable
			selectable={publisher && (({ bundle }) => !bundle)}
			query={query}
			columns={columns}
			onClick={({ id, publisher }) => navigate(`/publisher/${publisher.id}/admin/articles/${id}`)}
			sortBy={{
				id: "created",
				desc: true,
			}}
			footer={publisher ? <ArticlesTableFooter publisher={publisher} /> : false}
			{...props}
		/>
	);
}
