import DropdownItemSoftDelete from "../../../ui/components/DropdownMenu/DropdownItemSoftDelete";
import DropdownMenu from "../../../ui/components/DropdownMenu/DropdownMenu";

import EditAppendixDropdownItem from "../EditAppendix/EditAppendixDropdownItem";

export default function AppendixDropdownMenu({ appendix }) {
	return (
		<DropdownMenu>
			{(context) => (
				<>
					<EditAppendixDropdownItem appendix={appendix} {...context} />
					<DropdownItemSoftDelete data={appendix} {...context} />
				</>
			)}
		</DropdownMenu>
	);
}
