import { Dropdown, Icon } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import Popup from "../../../../ui/components/Popup/Popup";

export default function TodoUserDropdown({ userId, todos, onClick }) {
	const { t } = useTranslation();

	const options = todos
		.flatMap((todo) => {
			if (todo.users?.length) {
				return todo.users?.map(({ id, name }) => ({
					key: id,
					value: id,
					text: name,
				}));
			} else if (todo.userNames?.length) {
				return todo.userIds.map((userId, index) => ({
					key: userId,
					value: userId,
					text: todo.userNames[index],
				}));
			} else {
				return [];
			}
		})
		.concat({ key: "notAssigned", value: "notAssigned", text: "Not assigned" }) // Adding an option without a userId
		.filter((option, index, arr) => arr.findIndex((t) => t.value === option.value) === index);

	const user = options.find((option) => option.value === userId);

	return (
		<Dropdown
			icon={false}
			floating
			trigger={
				<Popup
					content={user ? user?.text : t("filterByUser", "Filter by user")}
					trigger={<Icon color={user && "blue"} link name="user" />}
				/>
			}
		>
			<Dropdown.Menu>
				<Dropdown.Item text={t("all", "All")} onClick={() => onClick(null)} />
				{options.map((option) => (
					<Dropdown.Item
						active={user?.text === option.text}
						key={option.key}
						text={option.text}
						onClick={() => onClick(option.value)}
						style={{
							color: user?.text === option.text && "#2185d0",
							fontWeight: user?.text === option.text && "normal",
						}}
					/>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
}
