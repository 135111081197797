import firebase from "firebase";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import productionStatuses from "astrid-firestore/src/api/productions/constants/productionStatuses";

import { useProfile } from "../../../features/authentication/state/profile";
import useHasRight from "../../../features/authorization/hooks/useHasRight";
import ConfirmationModal from "../../../features/ui/components/ConfirmationModal/ConfirmationModal";
import PageHeader from "../../../features/ui/components/PageHeader/PageHeader";
import { deleteProduction } from "../../../helpers/production";

import AlertToggle from "../AlertToggle";
import StarToggle from "../StarToggle";

import HistoryModal from "./ProductionHistory";

export default function ProductionHeader({ production }) {
	const { t } = useTranslation();

	const profile = useProfile();
	const hasRight = useHasRight();

	const [isDeleting, setIsDeleting] = useState(false);
	const [historyOpen, setHistoryOpen] = useState(false);

	return (
		<>
			<PageHeader
				header={production.title}
				subheader={`${production.publisherName} + ${production.producerName}`}
				dropdownMenuOptions={[
					{
						text: t("history", "History"),
						disabled: !hasRight("productions.admin"),
						onClick: () => {
							setHistoryOpen(true);
						},
					},
					{
						text: t("delete", "Delete"),
						disabled: !hasRight("productions.delete") || production.status !== productionStatuses.DRAFT,
						onClick: () => {
							setIsDeleting(true);
						},
					},
				]}
			>
				<StarToggle production={production.id} profile={profile} uid={profile?.id} />
				<AlertToggle production={production} profile={profile} uid={profile?.id} />
			</PageHeader>

			{isDeleting && (
				<ConfirmationModal
					header={t("deleteProductionHeader", "Delete production")}
					content={t("deleteProduction", "Are you sure you wish to delete this production?")}
					onClose={() => setIsDeleting(false)}
					onConfirm={() => {
						deleteProduction(production.id, firebase.auth().currentUser.uid);
						setIsDeleting(false);
					}}
				/>
			)}

			{historyOpen && (
				<HistoryModal
					productionId={production.id}
					onClose={() => {
						setHistoryOpen(false);
					}}
				/>
			)}
		</>
	);
}
