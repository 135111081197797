import { useTranslation } from "react-i18next";

import articleStatuses from "astrid-firestore/src/api/articles/constants/articleStatuses";
import { Article } from "astrid-firestore/src/api/articles/types/Article";

import api from "../../../../api";
import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

import useArticleTypeOptions from "../../hooks/useArticleTypeOptions";

export default function CreateArticleForm({ publisher, production, onClose, onSuccess }) {
	const { t } = useTranslation();

	const articleTypeOptions = useArticleTypeOptions();

	const { form, onSubmit, error } = useSimpleForm({
		schema: Article.passthrough(),
		values: {
			name: "",
			isbn: null,
			type: null,
			status: articleStatuses.DRAFT,
			publisher,
			production,
		},
		onSubmit: (data) => {
			return api.articles.create(data);
		},
		onSuccess: (data) => {
			onSuccess(data);
			onClose();
		},
	});

	return (
		<ModalPrompt
			size="tiny"
			header={t("createArticle", "Create article")}
			error={error}
			onSave={onSubmit}
			onClose={onClose}
			closeOnConfirm={false}
		>
			<Form form={form}>
				<Form.Input name="isbn" label={t("isbn", "ISBN")} />

				<Form.Input name="name" label={t("articleName", "Article name")} />

				<Form.Select name="type" label={t("type", "Type")} options={articleTypeOptions} />
			</Form>
		</ModalPrompt>
	);
}
