import getArticlePreset from "./getArticlePreset";

export default function mergeArticleDataWithPresetTargetData({ channels, article, publisher }) {
	return channels.reduce((acc, channel) => {
		const articleChannelData = article.channels[channel.id];
		const preset = getArticlePreset({ channel, article, publisher });

		const mergedArticleDataWithPresetTargetData = Object.keys(preset?.targetData || {}).reduce(
			(acc, presetTargetDataKey) => {
				const overriddenSetting = articleChannelData?.[presetTargetDataKey];

				if (overriddenSetting) {
					return {
						...acc,
						[presetTargetDataKey]: overriddenSetting,
					};
				}
				return { ...acc, [presetTargetDataKey]: preset?.targetData?.[presetTargetDataKey] };
			},
			{},
		);

		return {
			...acc,
			[channel.id]: mergedArticleDataWithPresetTargetData,
		};
	}, {});
}
