import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useAdminProductionsDashboardMenuOptions({
	publishers,
	organization,
	activePublisherId,
	setActivePublisherId,
}) {
	const { t } = useTranslation();

	return useMemo(() => {
		const allPublishersOfferedCount = publishers
			?.filter((publisher) => publisher.producers?.[organization.id]?.statistics)
			.reduce(
				(acc, { producers }) =>
					acc +
					producers[organization.id].statistics.productions.internal.requests +
					producers[organization.id].statistics.productions.external.requests,
				0,
			);

		return [
			{
				text: t("allPublishers", "All publishers"),
				label: allPublishersOfferedCount,
				labelColor: "orange",
				active: !activePublisherId,
				onClick: () => setActivePublisherId(),
			},
			...(publishers
				?.map(({ id, name, producers }) => {
					return {
						key: id,
						text: name,
						label:
							producers?.[organization.id]?.statistics?.productions.internal.requests +
								producers?.[organization.id]?.statistics?.productions.external.requests || 0,
						labelColor: "orange",
						active: id === activePublisherId,
						onClick: () => setActivePublisherId(id),
					};
				})
				.sort((a, b) => b.label - a.label) || []),
		];
	}, [activePublisherId, organization.id, publishers, setActivePublisherId, t]);
}
