import { useTranslation } from "react-i18next";

import ArticleVersionsCollectionDataTable from "../../../articles/components/ArticleVersionsCollectionDataTable/ArticleVersionsCollectionDataTable";

import AdminCard from "../../components/AdminCard/AdminCard";

export default function AdminArticleVersionsView({ article }) {
	const { t } = useTranslation();

	return (
		<AdminCard header={t("versions", "Versions")}>
			<ArticleVersionsCollectionDataTable article={article} />
		</AdminCard>
	);
}
