import moment from "moment";

import { Button, Form, Icon, Modal, Popup, Transition } from "semantic-ui-react";

import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { base } from "astrid-firebase";

import DateTime from "../../features/ui/components/DateInput/DateTime";
import { toDate } from "../../helpers/fnc";

class AlertToggle extends Component {
	state = {
		modal: false,
		time: new Date(),
		comment: "",
		transition: true,
	};

	componentDidMount() {
		// default to one week ahead
		this.setDays(7);

		// animate if alert has passed
		const { profile, production } = this.props;
		if (
			profile.alertProductions &&
			profile.alertProductions[production.id] &&
			toDate(profile.alertProductions[production.id].time) < new Date()
		) {
			setTimeout(() => {
				this.setState({ transition: false });
			}, 600);
		}
	}

	setDays = (days) => {
		this.setState({
			time: moment().add(days, "days").toDate(),
		});
	};

	setDate = (date) => {
		const time = moment(date + " " + moment(this.state.time).format("LT")).toDate();
		this.setState({ time });
	};

	setTime = (hm) => {
		const time = moment(moment(this.state.time).format("YYYY-MM-DD") + "T" + hm).toDate();
		this.setState({ time });
	};

	toggle = (e) => {
		e.preventDefault();

		let alertProductions = this.props.profile.alertProductions || {};

		if (alertProductions[this.props.production.id]) {
			// unalert
			delete alertProductions[this.props.production.id];
		} else {
			// star
			alertProductions[this.props.production.id] = {
				time: this.state.time,
				comment: this.state.comment,
				deliveryDate: this.props.production?.deliveryDate || null,
				title: this.props.production?.title || null,
			};
			this.modalClose();
		}

		base.updateDoc("users/" + this.props.uid, { alertProductions });
	};

	modalClose = (e) => {
		this.setState({ modal: false });
	};

	render() {
		const { modal, time, comment, transition } = this.state;
		const { t, profile, production } = this.props;
		const active = profile.alertProductions && profile.alertProductions[production.id];

		return (
			<>
				<Popup
					trigger={
						<span>
							<Transition animation={"tada"} duration={1400} visible={transition}>
								<Icon
									name={active ? "alarm" : "alarm outline"}
									color={active ? "purple" : "grey"}
									onClick={
										active
											? this.toggle
											: (e) => {
													this.setState({ modal: true });
											  }
									}
									style={{ cursor: "pointer" }}
								/>
							</Transition>
						</span>
					}
					content={
						active ? (
							<div>
								{moment(toDate(active.time)).format("YYYY-MM-DD HH:mm")}
								<br />
								<em>{active.comment}</em>
								<br />
								{t("clickToReset")}
							</div>
						) : (
							t("setReminder")
						)
					}
					size="mini"
					inverted
				/>
				{modal && (
					<Modal size="tiny" open={true} onClose={this.modalClose} closeOnDocumentClick>
						<Modal.Header>{t("setReminder")}</Modal.Header>
						<Modal.Content>
							<p>{t("chooseTimeWriteComment")}</p>
							<Form>
								<Form.Group widths="equal">
									<div className="field wide">
										<label>{t("date")}</label>
										<DateTime
											value={time}
											timeFormat={false}
											onChange={(dt) => {
												if (typeof dt === "object") {
													this.setDate(dt.format("YYYY-MM-DD"));
												}
											}}
											onBlur={(dt) => {
												const writtenDate = moment(dt).format("YYYY-MM-DD");
												// see if typed date is valid and not the same as currently is in the db
												if (
													writtenDate !== "Invalid date" &&
													writtenDate !== moment(toDate(time)).format("YYYY-MM-DD")
												) {
													// valid date, save it
													this.setDate(writtenDate);
												}
											}}
										/>
									</div>
									<div className="field wide">
										<label>{t("time")}</label>
										<DateTime
											locale={"sv"}
											value={time}
											dateFormat={false}
											onChange={(dt) => {
												if (typeof dt === "object") {
													this.setTime(dt.format("HH:mm"));
												}
											}}
										/>
									</div>
								</Form.Group>

								<Form.TextArea
									placeholder={t("comment")}
									value={comment}
									onChange={(e, data) => {
										this.setState({ comment: data.value });
									}}
								/>
							</Form>
						</Modal.Content>

						<Modal.Actions>
							<Button onClick={this.modalClose}>Avbryt</Button>
							<Button
								color="purple"
								icon="alarm"
								labelPosition="right"
								content={t("saveReminder")}
								onClick={this.toggle}
							/>
						</Modal.Actions>
					</Modal>
				)}
			</>
		);
	}
}

export default withTranslation()(AlertToggle);
