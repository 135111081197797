import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import DataTable from "../../../../ui/components/DataTable/DataTable";

import useDistributeArticlesTableColumns from "../hooks/useDistributeArticlesTableColumns";

import DistributeArticlesFormDivider from "./DistributeArticlesFormDivider";

export default function DistributeArticlesFormArticles({ articles }) {
	const { t } = useTranslation();

	const { watch } = Form.useFormContext();

	const { operation, distribution } = watch();

	const columns = useDistributeArticlesTableColumns({ distribution });

	if (!operation) {
		return null;
	}

	return (
		<>
			<DistributeArticlesFormDivider>
				{t("articles", "Articles")} ({articles.length})
			</DistributeArticlesFormDivider>

			<DataTable
				hoverRow={false}
				sortable={false}
				data={articles}
				columns={columns}
				sortBy={{
					id: "name",
					desc: false,
				}}
			/>
		</>
	);
}
