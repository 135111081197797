import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { MetaData } from "../../../types/MetaData";
import { array, nullable, ref, string } from "../../../types/_types";

import { OrganizationRef } from "../../organizations/types/OrganizationRef";

export const Title = FirestoreDocument.extend({
	publisher: OrganizationRef,
	releaseYear: nullable(string()),
	articleIds: array(string()),
})
	.merge(FirestoreLifecycle)
	.merge(MetaData);

export const TitleRef = ref(Title, {
	name: true,
});
