import { useTranslation } from "react-i18next";

import CreateDistributionPipelineButton from "../../../distribution/components/CreateDistributionPipelineForm/CreateDistributionPipelineButton";
import DistributionChannelPipelinesDataTable from "../../../distribution/components/DistributionChannelPipelinesDataTable/DistributionChannelPipelinesDataTable";

import AdminCard from "../../components/AdminCard/AdminCard";

export default function AdminDistributionPipelinesView({ channel }) {
	const { t } = useTranslation();

	return (
		<AdminCard header={t("pipelines", "Pipelines")} extra={<CreateDistributionPipelineButton channel={channel} />}>
			<DistributionChannelPipelinesDataTable channel={channel} />
		</AdminCard>
	);
}
