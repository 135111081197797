import { useTranslation } from "react-i18next";

import contributorRoles from "astrid-firestore/src/api/contributors/constants/contributorRoles";

export default function useContributorRolesOptions() {
	const { t } = useTranslation();

	return [
		{ key: "narrator", text: t("narrator", "Narrator"), value: contributorRoles.NARRATOR },
		{ key: "author", text: t("author", "Author"), value: contributorRoles.AUTHOR },
		{ key: "translator", text: t("translator", "Translator"), value: contributorRoles.TRANSLATOR },
	];
}
