import { Loader, Image as SemanticImage } from "semantic-ui-react";

import React, { useEffect, useState } from "react";

export default function ArticleCoverImage({ article, thumb, style, loading, ...props }) {
	const [internalSrc, setInternalSrc] = useState("/img/thumb.jpg");

	useEffect(() => {
		const src = thumb ? article.cover?.thumbUrl : article.cover?.url;

		if (src) {
			const img = new Image();
			img.onload = () => setInternalSrc(src);
			img.src = src;
		}
	}, [article, thumb]);

	const children = loading || (article.cover && !article.cover?.thumbUrl) ? <Loader active /> : undefined;

	return (
		<SemanticImage src={internalSrc} style={{ width: "100%", ...style }} {...props}>
			{children}
		</SemanticImage>
	);
}
