import { formatDate } from "../../../../../helpers/date";
import Form from "../../../../forms/components/Form/Form";

import DistributeArticlesFormDivider from "./DistributeArticlesFormDivider";

export default function DistributeArticlesFormDistributionDate({ label, name, defaultValue }) {
	return (
		<div style={{ flex: 1 }}>
			<DistributeArticlesFormDivider style={{ margin: "20px auto" }}>{label}</DistributeArticlesFormDivider>

			<Form.DateInput
				name={name}
				disableBeforeToday
				formatDate={formatDate}
				defaultValue={defaultValue}
				style={{ textAlign: "center" }}
				rules={{ valueAsDate: false }}
			/>
		</div>
	);
}
