import { useTranslation } from "react-i18next";

import AdminCard from "../../../../admin/components/AdminCard/AdminCard";
import LoadingContext from "../../../../ui/components/LoadingContext/LoadingContext";
import Message from "../../../../ui/components/Messages/Message";
import Table from "../../../../ui/components/Table/Table";

import useAutoApprovalRules from "../../../hooks/useAutoApprovalRules";

import useAutoApprovalRulesColumns from "../hooks/useAutoApprovalRulesColumns";

import AddAutoApprovalRule from "./AddAutoApprovalRule";

export default function AutoApprovalRules({ organization }) {
	const { t } = useTranslation();

	const [autoApprovalRules, loading, error] = useAutoApprovalRules(organization);

	const autoApprovalRulesColumns = useAutoApprovalRulesColumns(organization);

	return (
		<AdminCard
			header={t("autoApprovalRules", "Auto-approval rules")}
			extra={
				<>
					<AddAutoApprovalRule organization={organization} />
				</>
			}
		>
			<Message>{t("autoApprovalRulesMessage", "Any prices below the threshold will be auto-approved")}</Message>

			<LoadingContext data={autoApprovalRules} loading={loading} error={error}>
				<Table
					data={autoApprovalRules}
					columns={autoApprovalRulesColumns}
					footer={false}
					style={{ marginTop: 10 }}
				/>
			</LoadingContext>
		</AdminCard>
	);
}
