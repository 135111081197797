import { Button, Modal } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import AsyncButton from "../AsyncButton/AsyncButton";

export default function ConfirmationModal({ header, text, content, onConfirm, onClose }) {
	const { t } = useTranslation();

	return (
		<Modal open size="tiny" closeOnDimmerClick onClose={onClose}>
			{header && <Modal.Header>{header}</Modal.Header>}

			{(content || text) && (
				<Modal.Content>
					{content || text.split("\n").map((row, index) => <p key={index}>{row}</p>)}
				</Modal.Content>
			)}

			<Modal.Actions>
				<Button content={t("negative", "No")} onClick={onClose} />

				<AsyncButton
					primary
					content={t("yes", "Yes")}
					onClick={async (e) => {
						await onConfirm(e);
						onClose();
					}}
				/>
			</Modal.Actions>
		</Modal>
	);
}
