import { useEffect, useMemo } from "react";

import { useMetaGuide } from "../../../../../components/production/parts/MetaGuide";
import { getAlerts } from "../../../../../helpers/production";

export default function useProductionMetaGuide({ history, production }) {
	const checkMissing = useMetaGuide((state) => state.checkMissing);
	const initiated = useMetaGuide((state) => state.initiated);
	const setAlerts = useMetaGuide((state) => state.setAlerts);

	const alerts = useMemo(
		() =>
			!production || production.status === "done" || production.articleIds
				? {}
				: getAlerts({ id: production?.id, production }),
		[production],
	);

	useEffect(() => {
		setAlerts(alerts);
	}, [alerts, setAlerts]);

	useEffect(() => {
		// check missing on production load if status is draft
		if (!initiated && production?.status === "draft" && !production.articleIds) {
			checkMissing({ productionId: production?.id, production, history });
		}
	}, [initiated, checkMissing, production?.id, history, production]);

	useEffect(() => {
		// unset alerts on page unload
		return () => {
			setAlerts({});
		};
	}, [setAlerts]);

	return alerts;
}
