import { useTranslation } from "react-i18next";

import articleStatuses from "astrid-firestore/src/api/articles/constants/articleStatuses";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import DistributeArticlesDraftForm from "./DistributeArticlesDraftForm";
import DistributeArticlesForm from "./DistributeArticlesForm";

export default function DistributeArticlesButton({ publisher, articles, channels, onSuccess, ...props }) {
	const { t } = useTranslation();

	const article = articles[0];

	return (
		<OpenButton text={t("distribute", "Distribute")} disabled={articles.length === 0} {...props}>
			{({ setOpen }) => {
				if (article.status === articleStatuses.DRAFT) {
					return <DistributeArticlesDraftForm article={article} onClose={() => setOpen(false)} />;
				} else {
					return (
						<DistributeArticlesForm
							publisher={publisher}
							articles={articles}
							channels={channels}
							onClose={() => setOpen(false)}
							onSuccess={onSuccess}
						/>
					);
				}
			}}
		</OpenButton>
	);
}
