import equal from "fast-deep-equal";

import { Card, Checkbox } from "semantic-ui-react";

import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import updateDocument from "astrid-firestore/src/helpers/updateDocument";

import usePriceTargets from "../../../../prices/hooks/usePriceTargets";
import Table from "../../../../ui/components/Table/Table";

import AdminCard from "../../../components/AdminCard/AdminCard";

import usePriceTargetsColumns from "../hooks/usePriceTargetsColumns";

export default function PriceTargets({ price }) {
	const { type, producer, users, ref } = price;
	const { t } = useTranslation();

	const priceTargets = usePriceTargets({ type, producer: producer.id });
	const priceTargetsColumns = usePriceTargetsColumns();

	const selectedRowIds = useMemo(() => users?.reduce((map, id) => ({ ...map, [id]: true }), {}) || {}, [users]);

	const onChange = useCallback((e, { checked }) => updateDocument(ref, { users: checked ? [] : null }), [ref]);

	const onSelectRows = useCallback(
		(rows) => {
			const next = rows.map(({ id }) => id);

			if (!equal(next, users)) {
				updateDocument(ref, { users: next });
			}
		},
		[ref, users],
	);

	const label = users ? t("availableForSome", "Available for some") : t("availableForAll", "Available for all");

	return (
		<AdminCard header={t("availability", "Availability")}>
			<Checkbox slider label={label} checked={!!users} onChange={onChange} />

			{users && (
				<Card.Content>
					<Table
						sortable
						selectable
						filterable
						pagination
						data={priceTargets}
						columns={priceTargetsColumns}
						initialState={{ selectedRowIds }}
						onSelectRows={onSelectRows}
					/>
				</Card.Content>
			)}
		</AdminCard>
	);
}
