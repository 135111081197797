import mapDocument from "../utils/mapDocument";

export default function runTransaction(firebase, refsOrDocuments = [], callback, transaction) {
	const runCallback = async (transaction) => {
		const documents = await Promise.all(
			refsOrDocuments.filter(Boolean).map((refOrDocument) => {
				return transaction.get(refOrDocument?.ref || refOrDocument).then(mapDocument);
			}),
		);

		return callback(transaction, documents);
	};

	if (transaction) {
		return runCallback(transaction);
	}

	return firebase.firestore().runTransaction(runCallback);
}
