export default function getCollectionQuery(collection, { fields, softDeletes }) {
	const query = softDeletes ? collection.where("deleted", "==", false) : collection;

	return Object.entries(fields || {}).reduce((query, [key, value]) => {
		if (value?.id) {
			return query.where(`${key}.id`, "==", value.id);
		}

		if (Array.isArray(value)) {
			return query.where(key, value[0], value[1]);
		}

		if (value !== undefined) {
			return query.where(key, "==", value);
		}

		return query;
	}, query);
}
