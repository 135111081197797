import { FormField } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import FileUploadTable from "../../../ui/components/FileUploadTable/FileUploadTable";

import Form from "../../../forms/components/Form/Form";

import useArtifactForm from "../../hooks/useArtifactForm";

export default function CreateArtifactForm({ article, articles, publisher, production, defaultName, onClose }) {
	const { t } = useTranslation();

	const { form, setValue, onSubmit, error } = useArtifactForm({
		article,
		publisher,
		production,
		defaultName,
		onClose,
	});

	// hämta artiklar som har produktions id och passera in som articles istället för att skicka in artiklar

	return (
		<Form.Modal
			size="tiny"
			header={t("uploadArtifact", "Upload artifact")}
			form={form}
			error={error}
			onSubmit={onSubmit}
			onClose={onClose}
		>
			{articles && (
				<Form.DocumentSelect
					name="article"
					label={t("article", "Article")}
					options={articles.map((article) => ({
						key: article.id,
						value: article.id,
						text: article.name,
						data: article,
					}))}
				/>
			)}

			<Form.Input name="name" label={t("artifactName", "Artifact name")} />

			<FormField>
				<label>{t("uploadFiles", "Upload files")}</label>

				<FileUploadTable onChange={(files) => setValue("targetFiles", files)} />
			</FormField>

			<Form.Checkbox name="isDefault" label={t("setAsDefault", "Set as default")} />
		</Form.Modal>
	);
}
