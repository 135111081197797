import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../../authorization/hooks/useHasRight";

import AdminProductionsView from "../../views/AdminProductionsView/AdminProductionsView";

export default function AdminProductions() {
	const { organization } = useOutletContext();

	const hasRight = useHasRight();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature || !hasRight("productions.admin")) {
		return <Unauthorized />;
	}

	return <AdminProductionsView organization={organization} />;
}
