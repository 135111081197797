import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import api from "../../../../api";
import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import CreateExportButton from "../../../exports/components/CreateExportForm/CreateExportButton";

import AdminCard from "../../components/AdminCard/AdminCard";

import useExportsTableColumns from "./hooks/useExportsTableColumns";

export default function AdminArticleExportsView({ article }) {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const columns = useExportsTableColumns();

	return (
		<AdminCard header={t("exports", "Exports")} extra={<CreateExportButton article={article} />}>
			<CollectionDataTable
				columns={columns}
				onClick={({ id }) => navigate(`./${id}`)}
				query={api.exports.getQuery({ article, deleted: false }).orderBy("created", "asc").limit(10)}
			/>
		</AdminCard>
	);
}
