import { useTranslation } from "react-i18next";

import articleDeliveryStatuses from "astrid-firestore/src/api/articles/constants/articleDeliveryStatuses";

export default function useArticleDeliveryStatuses() {
	const { t } = useTranslation();

	return {
		[articleDeliveryStatuses.DELIVERY_UNSUPPORTED]: {
			status: t("unsupported", "Unsupported"),
			color: "grey",
			disabled: true,
		},
		[articleDeliveryStatuses.DELIVERY_NOT_STARTED]: {
			status: t("notDelivered", "Not delivered"),
			color: "grey",
		},
		[articleDeliveryStatuses.DELIVERY_PENDING]: {
			status: t("scheduled", "Scheduled"),
			color: "blue",
		},
		[articleDeliveryStatuses.DELIVERY_IN_PROGRESS]: {
			status: t("inProgress", "In progress"),
			color: "blue",
			active: true,
		},
		[articleDeliveryStatuses.DELIVERY_COMPLETED]: {
			status: t("delivered", "Delivered"),
			color: "green",
		},
		[articleDeliveryStatuses.DELIVERY_FAILED]: {
			status: t("failed", "Failed"),
			color: "red",
		},
		[articleDeliveryStatuses.DELIVERY_NEEDED]: {
			status: t("deliveryNeeded", "Delivery needed"),
			color: "orange",
		},
	};
}
