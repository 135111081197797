import React from "react";
import { useTranslation } from "react-i18next";

import { Article } from "astrid-firestore/src/api/articles/types/Article";

import api from "../../../api";

import Form from "../../forms/components/Form/Form";
import useSimpleForm from "../../forms/hooks/useSimpleForm";

import useContributorRolesOptions from "../hooks/useContributorRolesOptions";

export default function AddContributorModal({ article, onClose, ...props }) {
	const { t } = useTranslation();

	const contributorRolesOptions = useContributorRolesOptions();

	const { form, onSubmit, error } = useSimpleForm({
		schema: Article,
		values: {
			ref: article.ref,
			role: "",
			firstName: "",
			lastName: "",
		},
		onSubmit: ({ role, firstName, lastName }) => {
			const name = firstName + " " + lastName;
			const contributor = {
				role,
				name,
				firstName,
				lastName,
			};

			return api.articles.addContributor({ article, contributor });
		},
		onSuccess: onClose,
		...props,
	});

	return (
		<Form.Modal
			form={form}
			size="tiny"
			header={t("addContributor", "Add contributor")}
			onSubmit={onSubmit}
			onClose={onClose}
			error={error}
			submitText={t("add", "Add")}
		>
			<Form.Select name="role" label={t("role", "Role")} options={contributorRolesOptions} />

			<Form.Group widths="equal">
				<Form.Input name="firstName" label={t("firstName", "First name")} />

				<Form.Input name="lastName" label={t("lastName", "Last name")} />
			</Form.Group>
		</Form.Modal>
	);
}
