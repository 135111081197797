import { Divider, Grid, Header, Segment } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";
import useWindowSize from "react-use/lib/useWindowSize";

import { languageName } from "astrid-helpers/src/languages";

import Flex from "../../../../components/Flex/Flex";
import DropdownMenu from "../../../ui/components/DropdownMenu/DropdownMenu";

import featureFlags from "../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../../authorization/hooks/useHasRight";

import ArticleCoverImage from "../../components/ArticleCoverImage/ArticleCoverImage";
import useArticleTypeTranslations from "../../hooks/useArticleTypeTranslations";
import articleTypeIcons from "../../utils/articleTypeIcons";

import ArticleLabel from "./components/ArticleLabel";
import ArticleViewDetail from "./components/ArticleViewDetail";
import ArticleViewDistributionChannels from "./components/ArticleViewDistributionChannels";
import ArticleViewThemaCodes from "./components/ArticleViewThemaCodes";

export default function ArticleView({ article, organization }) {
	const { t } = useTranslation();
	const hasRight = useHasRight();

	const {
		id,
		synopsis,
		author,
		publisher,
		imprint,
		language,
		length,
		isbn,
		narrator,
		originalLanguage,
		originalName,
		serie,
		serieNumber,
		themaCodes,
		translator,
		type,
		channels,
	} = article;
	const articleTypes = useArticleTypeTranslations();
	const { width } = useWindowSize();

	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	const dropdownOptions = [
		{
			text: t("editInOrganization", "Edit in {{organization}}", { organization: publisher?.name }),
			linkTo: `/publisher/${publisher?.id}/admin/articles/${article?.id}`,
			hidden: !hasRight("articles.view") || !organization || !hasFlatteningFeature,
		},
	];

	const desktop = width > 600;

	return (
		<Segment padded>
			<Grid style={{ maxWidth: 1400, margin: "0 auto" }}>
				<Grid.Row>
					{!desktop ? (
						<ArticleCoverImage article={article} style={{ borderRadius: 10, width: "90%" }} />
					) : (
						<Grid.Column width={4}>
							<ArticleCoverImage article={article} style={{ borderRadius: 10 }} />
						</Grid.Column>
					)}

					<Grid.Column width={!desktop ? 15 : 11}>
						<Flex>
							<Header as="h2" style={{ margin: desktop ? 0 : "10px 0 0 0" }}>
								{article?.name}
							</Header>

							<DropdownMenu iconStyle={{ fontSize: 15 }} options={dropdownOptions} />
						</Flex>

						<Flex style={{ marginTop: 5 }} gap={10} justifyContent="flex-start">
							<ArticleViewDetail label={t("author", "Author")} value={author} />

							<ArticleViewDetail label={t("publisher", "Publisher")} value={publisher?.name} />

							<ArticleViewDetail label={t("imprint", "Imprint")} value={imprint?.name} />

							<ArticleViewDetail
								label={t("serie", "Series")}
								value={serie?.name && `${serie?.name} (Nr. ${serieNumber})`}
							/>
						</Flex>

						<Divider />

						<Flex justifyContent="flex-start" gap={4}>
							<ArticleLabel icon="book" value={isbn} />

							<ArticleLabel icon="language" value={languageName(language)} />

							<ArticleLabel icon={articleTypeIcons[type]} value={articleTypes[type]} />

							<ArticleLabel icon="clock outline" value={length} />
						</Flex>

						<Divider />

						{synopsis && (
							<>
								<div>{synopsis}</div>

								<Divider />
							</>
						)}

						<Flex style={{ flexDirection: "column", marginTop: 10 }} alignItems="flex-start" gap={5}>
							<ArticleViewDetail
								label={t("originalLanguage", "Original language")}
								value={languageName(originalLanguage)}
							/>

							<ArticleViewDetail label={t("titleOriginal", "Original title")} value={originalName} />

							<ArticleViewDetail label={t("translator", "Translator")} value={translator} />

							<ArticleViewDetail label={t("reader", "Narrator")} value={narrator} />
						</Flex>

						<ArticleViewThemaCodes themaCodes={themaCodes} />

						<ArticleViewDistributionChannels channels={channels} />
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	);
}
