import { Icon } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import AdminCard from "../../../../admin/components/AdminCard/AdminCard";

export default function OrganizationInfo({ organization }) {
	const { t } = useTranslation();
	const { pathname } = useLocation();

	return (
		<AdminCard header={organization.name}>
			<Link to={`/${organization.type}/${organization.id}/admin`} state={{ pathname }}>
				{t("manage", "Manage")} <Icon name="chevron right" />
			</Link>
		</AdminCard>
	);
}
