import distributionOperations from "astrid-firestore/src/api/distribution/constants/distributionOperations";
import { custom, object } from "astrid-firestore/src/types/_types";

export const DistributeArticlesSchema = object({
	delivery: custom((value) => value.metadata || value.artifact, {
		params: { i18n: "errors.distribution.delivery.empty" },
	}),
	operation: custom((value) => Object.values(distributionOperations).includes(value), {
		params: { i18n: "errors.distribution.operation.empty" },
	}),
	channels: custom((value) => Object.values(value || {}).some((v) => v), {
		params: { i18n: "errors.distribution.channels.empty" },
	}),
});
