import { useTranslation } from "react-i18next";

import distributionOperations from "astrid-firestore/src/api/distribution/constants/distributionOperations";

import Flex from "../../../../../components/Flex/Flex";
import Form from "../../../../forms/components/Form/Form";

import DistributeArticlesFormDistributionDate from "./DistributeArticlesFormDistributionDate";

export default function DistributeArticlesFormDistribution({ articles }) {
	const { t } = useTranslation();

	const { watch } = Form.useFormContext();

	const { delivery, operation } = watch();

	if (!delivery.metadata || !operation) {
		return null;
	}

	const article = articles.length === 1 ? articles[0] : null;

	return (
		<Flex gap={15}>
			{operation === distributionOperations.RELEASE && (
				<DistributeArticlesFormDistributionDate
					label={t("announceAt", "Announce at")}
					name="distribution.announcementDate"
					defaultValue={article?.distribution?.announcementDate || null}
				/>
			)}

			{operation === distributionOperations.RELEASE && (
				<DistributeArticlesFormDistributionDate
					label={t("releaseAt", "Release at")}
					name="distribution.releaseDate"
					defaultValue={article?.distribution?.releaseDate || null}
				/>
			)}

			{[distributionOperations.RELEASE, distributionOperations.TAKEDOWN].includes(operation) && (
				<DistributeArticlesFormDistributionDate
					label={t("takedownAt", "Unpublish")}
					name="distribution.takedownDate"
					defaultValue={article?.distribution?.takedownDate || null}
				/>
			)}
		</Flex>
	);
}
