import { useEffect, useRef, useState } from "react";

const init = { x: -Infinity, y: -Infinity };

export default function useMouse(rect) {
	const frame = useRef(0);
	const [mouse, setMouse] = useState(init);

	useEffect(() => {
		if (rect) {
			const { left = 0, top = 0 } = rect;

			const moveHandler = (event) => {
				cancelAnimationFrame(frame.current);

				const x = event?.pageX || event?.changedTouches?.[0]?.pageX || 0;
				const y = event?.pageY || event?.changedTouches?.[0]?.pageY || 0;

				frame.current = requestAnimationFrame(() =>
					setMouse({
						x: Math.round(x - left + window.pageXOffset),
						y: Math.round(y - top + window.pageYOffset),
					}),
				);
			};

			document.addEventListener("mousemove", moveHandler);
			document.addEventListener("touchmove", moveHandler);

			return () => {
				cancelAnimationFrame(frame.current);

				document.removeEventListener("mousemove", moveHandler);
				document.removeEventListener("touchmove", moveHandler);

				setMouse(init);
			};
		}
	}, [rect]);

	return mouse;
}
