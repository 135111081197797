import { useTranslation } from "react-i18next";

import pipelineStatuses from "astrid-firestore/src/api/distribution/constants/pipelineStatuses";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import DistributionsInstantSearchDataTable from "../../../distribution/components/DistributionsInstantSearchDataTable/DistributionsInstantSearchDataTable";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

export default function AdminDistributionsScheduledView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("scheduledPlural", "Scheduled")}>{searchInput}</AdminHeader>

			<DistributionsInstantSearchDataTable
				organization={organization}
				statuses={[
					pipelineStatuses.PENDING,
					pipelineStatuses.SCHEDULED,
					pipelineStatuses.INITIALIZED,
					pipelineStatuses.RUNNING,
				]}
				searchQuery={searchQuery}
			/>
		</>
	);
}
