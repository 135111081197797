import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import articleStatuses from "astrid-firestore/src/api/articles/constants/articleStatuses";

import api from "../../../../api";
import SecondaryButton from "../../../ui/components/Buttons/SecondaryButton";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";
import themeColors from "../../../ui/constants/themeColors";

import ArticlesCollectionDataTable from "../../../articles/components/ArticlesCollectionDataTable/ArticlesCollectionDataTable";
import Dashboard from "../../../dashboard/components/Dashboard/Dashboard";
import useImprintArticleStatistics from "../../../imprints/hooks/useImprintArticleStatistics";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

import useAdminArticlesDashboardMenuOptions from "./hooks/useAdminArticlesDashboardMenuOptions";

export default function AdminArticlesDashboardView({ organization }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [imprints, loading, error] = api.imprints.useAll({ publisher: organization });

	const [activeImprintId, setActiveImprintId] = useState();

	const menuOptions = useAdminArticlesDashboardMenuOptions(imprints, activeImprintId, setActiveImprintId);

	const delivered = useImprintArticleStatistics({
		imprints,
		activeImprintId,
		deliveryStatus: "delivered",
		label: t("deliveredPlural", "Delivered"),
		color: "green",
		detailColor: themeColors.GREEN,
		link: "articles/delivered",
		organization,
	});

	const deliveryNeeded = useImprintArticleStatistics({
		imprints,
		activeImprintId,
		deliveryStatus: "deliveryNeeded",
		label: t("deliveryNeeded", "Delivery needed"),
		color: "orange",
		detailColor: themeColors.ORANGE,
		link: "articles/deliveryNeeded",
		organization,
	});

	const scheduled = useImprintArticleStatistics({
		imprints,
		activeImprintId,
		deliveryStatus: "scheduled",
		label: t("scheduled", "Scheduled"),
		color: "blue",
		detailColor: themeColors.BLUE,
		link: "articles/scheduled",
		organization,
	});

	const drafts = useImprintArticleStatistics({
		imprints,
		activeImprintId,
		deliveryStatus: articleStatuses.DRAFT,
		label: t("drafts", "Drafts"),
		color: "grey",
		detailColor: themeColors.GREY,
		link: "articles/drafts",
		organization,
	});

	const notDelivered = useImprintArticleStatistics({
		imprints,
		activeImprintId,
		deliveryStatus: "notDelivered",
		label: t("notDeliveredPlural", "Not delivered"),
		link: "articles/notDelivered",
		organization,
	});

	const statistics = [...delivered, ...scheduled, ...deliveryNeeded, ...drafts, ...notDelivered];

	return (
		<>
			<AdminHeader text={t("articles", "Articles")} />

			<LoadingContext data={imprints} loading={loading} error={error}>
				<Dashboard menuOptions={menuOptions} statistics={statistics} />

				<ArticlesCollectionDataTable
					publisher={organization}
					sortable={false}
					filterable={false}
					selectable={false}
					visibleColumns={["name", "status", "type", "isbn", "updated", "created", "menu"]}
					getQuery={(query) => query.orderBy("updated", "desc").limit(10)}
					footer={
						<SecondaryButton onClick={() => navigate("all")}>
							{t("allArticles", "All articles")}
						</SecondaryButton>
					}
				/>
			</LoadingContext>
		</>
	);
}
