// import highpass from "./highpass";

function findMinMax(array) {
	let curr;
	let i = 0;
	let min = Infinity;
	let max = -Infinity;
	let len = array.length;

	for (; i < len; i++) {
		curr = array[i];

		if (min > curr) {
			min = curr;
		}

		if (max < curr) {
			max = curr;
		}
	}

	return [Math.abs(min), Math.abs(max)];
}

export default function minMax(source = [], resolution = 256, start = 0) {
	// source = highpass(source, rate, cutoff);
	resolution = Math.round(resolution);

	const array = [];
	const { length } = source;

	const num = Math.ceil(length / resolution);

	start = start % resolution;

	let i, end, values;

	for (i = 0; i < num; i++) {
		end = (i + 1) * resolution;
		values = source.subarray(start, end);

		if (values.length > 1) {
			if (values.length > 2) {
				values = findMinMax(values);
			}

			array.push([Math.abs(values[0]), Math.abs(values[1])]);
		}

		start = end;
	}

	return array;
}
