import { Grid, Responsive, Segment } from "semantic-ui-react";

import React from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import { db } from "astrid-firebase";
import { useCollectionData, useDocumentData, useLoadingValues } from "astrid-firestore/src/hooks";

import DocTitle from "../../../../components/DocTitle";
import ProductionHeader from "../../../../components/production/parts/ProductionHeader";
import ProductionInformationEdit from "../../../../components/production/parts/ProductionInformationEdit";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import useOffersQuery from "../../../offers/hooks/useOffersQuery";

import useProductionPermissions from "../../hooks/useProductionPermissions";
import updateProduction from "../../utils/updateProduction";

import ProductionDiscussion from "./components/ProductionDiscussion";
import ProductionMenu from "./components/ProductionMenu";
import ProductionSecondaryMenu from "./components/ProductionSecondaryMenu";
import ProductionViewContext from "./context/ProductionViewContext";
import useProductionMetaGuide from "./hooks/useProductionMetaGuide";

export default function ProductionView({ modal, history, production, children, internalPublisher }) {
	const navigate = useNavigate();

	const isDesktop = useMediaQuery({ minWidth: Responsive.onlyTablet.maxWidth });

	const [[producer, publisher, offers], loading, error] = useLoadingValues(
		useDocumentData(production?.producer && db.collection("organizations").doc(production.producer)),
		useDocumentData(production?.publisher && db.collection("organizations").doc(production.publisher)),
		useCollectionData(useOffersQuery({ production })),
	);

	const permissions = useProductionPermissions(production);

	const handleChange = (e, data, options) => updateProduction(e, production, data, options);

	const alerts = useProductionMetaGuide({ history, production });

	return (
		<LoadingContext data={production} loading={loading} error={error}>
			{production && (
				<>
					<DocTitle title={production.title} />
					<ProductionHeader production={production} />

					<Grid>
						<Grid.Column mobile={16} computer={10}>
							<div className="production-tabs-primary">
								<ProductionMenu
									alerts={alerts}
									offers={offers}
									production={production}
									permissions={permissions}
									isDesktop={isDesktop}
								/>

								<Segment attached="bottom" style={{ padding: 0 }}>
									<ProductionViewContext.Provider
										value={{
											alerts,
											offers,
											producer,
											publisher,
											isDesktop,
											permissions,
											handleChange,
											fullProduction: production,
										}}
									>
										{children}
									</ProductionViewContext.Provider>
								</Segment>
							</div>
						</Grid.Column>

						{isDesktop && (
							<Grid.Column width={6}>
								<div className="sticky-secondary">
									<div className="production-tabs-secondary">
										<ProductionSecondaryMenu production={production} isDesktop={isDesktop} />

										<Segment attached="bottom" style={{ padding: 0, borderTop: "none" }}>
											<ProductionDiscussion
												production={production}
												permissions={permissions}
												internalPublisher={internalPublisher}
											/>
										</Segment>
									</div>
								</div>
							</Grid.Column>
						)}
					</Grid>

					{modal === "edit" && (
						<ProductionInformationEdit
							alerts={alerts}
							producer={producer}
							publisher={publisher}
							production={production}
							permissions={permissions}
							handleChange={handleChange}
							onClose={() => navigate(`/production/${production.id}`)}
						/>
					)}
				</>
			)}
		</LoadingContext>
	);
}
