import React from "react";
import { useTranslation } from "react-i18next";

import DataTable from "../../../ui/components/DataTable/DataTable";

import useContributorsTableColumns from "../../../contributors/hooks/useContributorsTableColumns";

export default function ArticleContributorsTable({ article }) {
	const { t } = useTranslation();

	const contributorsTableColumns = useContributorsTableColumns({ article });

	const contributors = article?.contributors;

	return <DataTable columns={contributorsTableColumns} data={contributors} hoverRow={false} />;
}
