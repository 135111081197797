import { useState } from "react";

import PrimaryButton from "../Buttons/PrimaryButton";

export default function OpenButton({ text, children, ...props }) {
	const [open, setOpen] = useState(false);

	return (
		<>
			<PrimaryButton disabled={open} onClick={() => setOpen(true)} {...props}>
				{text}
			</PrimaryButton>

			{open &&
				(typeof children === "function"
					? children({
							open,
							setOpen,
							onClose: () => setOpen(false),
					  })
					: children)}
		</>
	);
}
