import { useNavigate } from "react-router-dom";

import api from "../../../../api";
import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useDistributionsTableColumns from "../../hooks/useDistributionsTableColumns";

export default function DistributionsCollectionDataTable({ article, bundle, limit = 20, ...props }) {
	const navigate = useNavigate();

	const columns = useDistributionsTableColumns();

	const query = api.distributions
		.getQuery({
			article,
			"article.id": bundle && ["in", bundle.articleIds],
		})
		.orderBy("created", "desc")
		.limit(limit);

	const onClick = ({ id, article, publisher }) => {
		navigate(`/publisher/${publisher.id}/admin/articles/${article.id}/distributions/${id}`);
	};

	return <CollectionDataTable query={query} columns={columns} onClick={onClick} {...props} />;
}
