const defaultExcelTemplate =
	"<!DOCTYPE html>\n" +
	"<html>\n" +
	"\n" +
	"<body>\n" +
	"<table>\n" +
	"    <thead>\n" +
	"    <tr>\n" +
	"        <th>Title</th>\n" +
	"        <th>Language</th>\n" +
	"        <th>Original Language</th>\n" +
	"        <th>Author(s)</th>\n" +
	"        <th>Narrator(s)</th>\n" +
	"        <th>Translator(s)</th>\n" +
	"        <th>Original title</th>\n" +
	"        <th>Series Title</th>\n" +
	"        <th>Series Seq No</th>\n" +
	"        <th>Series Episode No</th>\n" +
	"        <th>ISBN Audiobook</th>\n" +
	"        <th>ISBN Ebook</th>\n" +
	"        <th>Publication Date Audiobook</th>\n" +
	"        <th>Publication Date Ebook</th>\n" +
	"        <th>Publisher</th>\n" +
	"        <th>Imprint Audiobook</th>\n" +
	"        <th>Imprint Ebook</th>\n" +
	"        <th>Description</th>\n" +
	"        <th>Takedown Date Audiobook</th>\n" +
	"        <th>Takedown Date Ebook</th>\n" +
	"        <th>Sales Rights Territory Audiobook </th>\n" +
	"        <th>Sales Rights Territory Ebook </th>\n" +
	"        <th>Tag(s)</th>\n" +
	"        <th>Thema Code(s)</th>\n" +
	"    </tr>\n" +
	"    </thead>\n" +
	"    <tbody>\n" +
	"    <tr>\n" +
	"        <td>{{article.title.name}}</td>\n" +
	"        <td>{{article.language}}</td>\n" +
	"        <td>{{article.originalLanguage}}</td>\n" +
	"        <td>\n" +
	"            {{#each article.authors}}\n" +
	"            {{this.name}}{{#unless @last}}, {{/unless}}\n" +
	"            {{/each}}\n" +
	"        </td>\n" +
	"        <td>\n" +
	"            {{#each article.narrators}}\n" +
	"            {{this.name}}{{#unless @last}}, {{/unless}}\n" +
	"            {{/each}}\n" +
	"        </td>\n" +
	"        <td>\n" +
	"            {{#each article.translators}}\n" +
	"            {{this.name}}{{#unless @last}}, {{/unless}}\n" +
	"            {{/each}}\n" +
	"        </td>\n" +
	"        <td>{{article.originalName}}</td>\n" +
	"        <td>{{article.serie.name}}</td>\n" +
	"        <td>TODO serie seq no??</td>\n" +
	"        <td>{{article.serieNumber}}</td>\n" +
	'        <td>{{getIsbn "audiobook" article linkedArticle}}</td>\n' +
	'        <td>{{getIsbn "ebook" article linkedArticle}}</td>\n' +
	'        <td>{{getPublicationDate "audiobook" article linkedArticle}}</td>\n' +
	'        <td>{{getPublicationDate "ebook" article linkedArticle}}</td>\n' +
	"        <td>{{article.publisher.name}}</td>\n" +
	"        <td>{{article.imprint.name}}</td>\n" +
	"        <td>{{linkedArticle.imprint.name}}</td>\n" +
	"        <td>{{article.synopsis}}</td>\n" +
	'        <td>{{getTakedownDate "audiobook" article linkedArticle}}</td>\n' +
	'        <td>{{getTakedownDate "ebook" article linkedArticle}}</td>\n' +
	"        <td>Sales Rights Territory Audiobook</td>\n" +
	"        <td>Sales Rights Territory Ebook</td>\n" +
	"        <td>Tag(s)</td>\n" +
	"        <td>Thema Code(s) TODO - no thema codes in current setup</td>\n" +
	"    </tr>\n" +
	"    </tbody>\n" +
	"</table>\n" +
	"</body>\n" +
	"\n" +
	"</html>";
export default defaultExcelTemplate;
