import React from "react";
import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../authorization/hooks/useFeatureFlag";

import AdminDistributionsAbortedView from "../../views/AdminDistributionsView/AdminDistributionsAbortedView";

export default function AdminDistributionsAborted() {
	const { organization } = useOutletContext();

	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature) {
		return <Unauthorized />;
	}

	return <AdminDistributionsAbortedView organization={organization} />;
}
