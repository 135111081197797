import db from "astrid-firebase/src/db";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import useHasRight from "../../../authorization/hooks/useHasRight";

import AdminActions from "../../components/AdminActions/AdminActions";

import PriceInfo from "./components/PriceInfo";
import PriceTargets from "./components/PriceTargets";

export default function AdminPriceView({ id }) {
	const hasRight = useHasRight();

	const [price, loading, error] = useDocumentData(id && db.collection("prices").doc(id));

	return (
		<LoadingContext data={price} loading={loading} error={error}>
			{price && (
				<>
					<PriceInfo price={price} />

					{hasRight("prices.apply") && <PriceTargets price={price} />}

					<AdminActions data={price}>
						<AdminActions.ApprovalAction right="prices.approve" />
						<AdminActions.DeletionAction right="prices.delete" />
					</AdminActions>
				</>
			)}
		</LoadingContext>
	);
}
