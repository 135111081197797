import { useState } from "react";

import defaultExcelTemplate from "../constants/defaultExcelTemplate";
import defaultOnixTemplate from "../constants/defaultOnixTemplate";
import defaultRoyalLibraryTemplate from "../constants/defaultRoyalLibraryTemplate";
import metadataTemplateTypes from "../constants/metadataTemplateTypes";

function getDefaultTemplate(templateType) {
	switch (templateType) {
		case metadataTemplateTypes.ONIX:
			return defaultOnixTemplate;
		case metadataTemplateTypes.EXCEL:
			return defaultExcelTemplate;
		case metadataTemplateTypes.CUSTOM_XML:
			return defaultRoyalLibraryTemplate;
	}
}

export default function useTemplate(channel, templateType) {
	const [template, setTemplate] = useState(channel?.[templateType] || getDefaultTemplate(templateType));
	return [template, setTemplate];
}
