import React from "react";
import { useTranslation } from "react-i18next";

import List from "astrid-components/lib/components/Data/List";
import Flex from "astrid-components/lib/components/Layout/Flex";
import Text from "astrid-components/lib/components/Text/Text";
import msToTime from "astrid-helpers/src/msToTime";

export default function ArtifactAudioPlayerChapter({ marker, active, first, last, onClick }) {
	const { t } = useTranslation();

	return (
		<List.Item active={active} first={first} last={last} onClick={onClick}>
			<Flex cursor="pointer" minHeight={60} padding={10}>
				<div style={{ marginLeft: 5 }}>
					<Text fontSize={12} lineHeight={1.2} color="positive">
						{t("chapter", "Chapter")}
					</Text>

					<Text fontSize={14} fontWeight={600}>
						{marker.text}
					</Text>
				</div>

				<Flex gap="6px">
					<Text color="positive" fontSize={12}>
						{msToTime(marker.position, false)}
					</Text>
				</Flex>
			</Flex>
		</List.Item>
	);
}
