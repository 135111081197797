import { useCallback } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";

export default function useArtifactAudioPlayerTimeline() {
	const position = Timeline.usePosition();
	const duration = Timeline.useDuration();

	const setPosition = useCallback((position) => {
		Timeline.setPosition(position);
	}, []);

	const setDuration = useCallback((duration) => {
		Timeline.setDuration(duration);
	}, []);

	const moveToPosition = useCallback((position) => {
		const size = Timeline.getSize();

		Timeline.setPosition(position);
		Timeline.setOffset(Math.max(0, position - size * 0.2));
	}, []);

	const resetTimeline = useCallback(() => {
		Timeline.resetDuration();
		Timeline.setPosition(0);
	}, []);

	return {
		position,
		setPosition,
		moveToPosition,

		duration,
		setDuration,

		resetTimeline,
	};
}
