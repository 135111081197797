import "moment";
import "moment/locale/sv";

import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import router from "./features/routing/router";
import SentryErrorBoundary from "./features/sentry/components/SentryErrorBoundary";
import "./features/sentry/sentry";
import { StoreProvider } from "./helpers/context";

const root = createRoot(document.getElementById("root"));

const consoleWarn = console.error;
const SUPPRESSED_WARNINGS = ["Warning: Failed %s type: %s%s"];

console.error = (msg, ...args) => {
	if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes?.(entry))) {
		consoleWarn(msg, ...args);
	}
};

root.render(
	<SentryErrorBoundary>
		<StoreProvider>
			<RouterProvider router={router} />
		</StoreProvider>
	</SentryErrorBoundary>,
);
