import mapDocument from "../utils/mapDocument";

import OptimisticDocument from "./OptimisticDocument";

export default class FirestoreTransaction {
	constructor(firebase, { transaction, debug = false } = {}) {
		this.debug = debug;
		this.firebase = firebase;
		this.firestoreTransaction = transaction?.firestoreTransaction || null;
		this.readOperations = transaction?.readOperations || new Map();
		this.writeOperations = [];
		this.optimisticUpdates = new Map();
	}

	clear() {
		this.writeOperations = [];
	}

	// Operations

	get(refOrRefs) {
		const refs = Array.isArray(refOrRefs) ? refOrRefs : [refOrRefs];

		for (const ref of refs) {
			if (!this.readOperations.has(ref.path)) {
				this.readOperations.set(ref.path, null);
			}
		}
	}

	push({ ref, data, method }) {
		this.writeOperations.push({ ref, data, method });
	}

	set(ref, data) {
		this.push({ ref, data, method: "set" });
	}

	update(ref, data) {
		this.push({ ref, data, method: "update" });
	}

	create(ref, data) {
		this.push({ ref, data, method: "create" });
	}

	delete(ref) {
		this.push({ ref, method: "delete" });
	}

	softDelete(ref) {
		this.push({ ref, method: "softDelete" });
	}

	getDocument(ref) {
		return this.readOperations.get(ref.path);
	}

	getDocuments() {
		return Array.from(this.readOperations.values());
	}

	readDocument(ref) {
		if (!this.readOperations.get(ref.path)) {
			if (this.debug) {
				console.log(`get: ${ref.path}`);
			}

			const promise = this.firestoreTransaction.get(ref).then(mapDocument);

			this.readOperations.set(ref.path, promise);
		}

		return this.readOperations.get(ref.path);
	}

	// Optimistic updates

	getOptimisticUpdate(ref) {
		return this.optimisticUpdates.get(ref.path);
	}

	applyOptimisticUpdate({ ref, method, data, document }) {
		const optimisticUpdate = this.optimisticUpdates.get(ref.path);

		switch (method) {
			case "set":
			case "update":
				optimisticUpdate.update(data);
				break;
			case "create":
				if (document.exists) {
					throw new Error("Document already exists");
				}

				optimisticUpdate.create(data);
				break;
			case "delete":
				optimisticUpdate.delete();
				break;
		}
	}

	applyOptimisticUpdates(writeOperations) {
		for (const { ref, document } of writeOperations) {
			if (!this.optimisticUpdates.has(ref.path)) {
				this.optimisticUpdates.set(ref.path, new OptimisticDocument(ref, document));
			}
		}

		for (const { ref, method, data, document } of writeOperations) {
			this.applyOptimisticUpdate({ ref, method, data, document });
		}
	}

	// Data

	parseData(data, document) {
		// TODO: Kanske inte document här
		data = typeof data === "function" ? data(document) : data;

		return Object.entries(data || {}).reduce((acc, [key, data]) => {
			const value = typeof data === "function" ? data(document) : data;

			if (typeof value === "undefined") {
				return acc;
			}

			return { ...acc, [key]: value };
		}, {});
	}

	// Writes

	hasPendingWrites() {
		return this.writeOperations.length > 0;
	}

	getWriteOperations(read = false) {
		const promise = Promise.all(
			this.writeOperations.map(async ({ ref, method, data }) => {
				const document = read ? await this.readDocument(ref, read) : null;

				return { ref, method, document, data: this.parseData(data, document) };
			}),
		);

		this.clear();

		return promise;
	}

	runWriteOperation({ ref, method, data }) {
		if (!ref) {
			throw new Error("No ref provided");
		}

		if (method !== "delete" && Object.keys(data).length === 0) {
			console.warn(`No data provided for ${method}: ${ref.path}`);
			return;
		}

		if (this.debug) {
			console.groupCollapsed(`${method}: ${ref.path}`);
			console.log(data);
			console.groupEnd();
		}

		switch (method) {
			case "set":
				this.firestoreTransaction.set(ref, data, { merge: true });
				break;
			case "create":
				this.firestoreTransaction.set(ref, data);
				break;
			case "update":
				this.firestoreTransaction.update(ref, data);
				break;
			case "delete":
				this.firestoreTransaction.delete(ref);
				break;
			default:
				console.warn(`Unknown method: ${method}`);
		}
	}

	runWriteOperations(writeOperations) {
		for (const { ref, method, data } of writeOperations) {
			this.runWriteOperation({ ref, method, data });
		}
	}

	async runTransaction(callback, { read = true, optimistic = true } = {}) {
		const writeOperations = await this.getWriteOperations(read);

		if (optimistic) {
			this.applyOptimisticUpdates(writeOperations);
		}

		const result = await callback(this, writeOperations);

		this.runWriteOperations(writeOperations);

		if (this.hasPendingWrites()) {
			const writeOperations = await this.getWriteOperations(false);

			this.runWriteOperations(writeOperations);
		}

		return result;
	}

	run(callback, options) {
		if (this.firestoreTransaction) {
			return this.runTransaction(callback, options);
		}

		return this.firebase.firestore().runTransaction(async (firestoreTransaction) => {
			if (this.debug) {
				console.groupCollapsed("Transaction");
				console.time("time");
			}

			this.firestoreTransaction = firestoreTransaction;

			const result = await this.runTransaction(callback, options);

			if (this.debug) {
				console.groupCollapsed("Result");
				console.log(result);
				console.groupEnd();
				console.timeEnd("time");
				console.groupEnd();
			}

			// throw new Error("Method not implemented");

			return result;
		});
	}
}
