import { useTranslation } from "react-i18next";

import AdminCard from "../../../admin/components/AdminCard/AdminCard";
import CreateDistributionPipelineButton from "../../../distribution/components/CreateDistributionPipelineForm/CreateDistributionPipelineButton";
import DistributionChannelPipelinesDataTable from "../../../distribution/components/DistributionChannelPipelinesDataTable/DistributionChannelPipelinesDataTable";

export default function SuperAdminDistributionChannelPipelinesView({ channel }) {
	const { t } = useTranslation();

	return (
		<AdminCard header={t("pipelines", "Pipelines")} extra={<CreateDistributionPipelineButton channel={channel} />}>
			<DistributionChannelPipelinesDataTable channel={channel} />
		</AdminCard>
	);
}
