import articleDeliveryStatuses from "astrid-firestore/src/api/articles/constants/articleDeliveryStatuses";

import useArticleDeliveryStatuses from "./useArticleDeliveryStatuses";

export default function useArticleDeliveryStatus(key, { article, channel }) {
	const statuses = useArticleDeliveryStatuses();

	const { delivery } = article.channels?.[channel?.id] || article;

	const { status = articleDeliveryStatuses.DELIVERY_NOT_STARTED, percent = 0 } = delivery?.[key] || {};

	return { percent, ...statuses[status] };
}
