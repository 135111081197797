import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import usePublisherManagers from "./usePublisherManagers";

export const NOT_ASSIGNED = "notAssigned";

export default function usePublisherManagerOptions(publisherId) {
	const { t } = useTranslation();

	const [publisherManagers, loading, error] = usePublisherManagers(publisherId);

	const options = useMemo(
		() =>
			[
				{
					key: NOT_ASSIGNED,
					value: NOT_ASSIGNED,
					text: t(NOT_ASSIGNED),
					data: { id: NOT_ASSIGNED },
				},
			].concat(
				publisherManagers?.map((user) => ({
					key: user.id,
					value: user.id,
					text: `${user.firstName} ${user.lastName}`,
					data: user,
				})) || [],
			),
		[publisherManagers, t],
	);

	return [options, loading, error];
}
