import { useTranslation } from "react-i18next";

import Flex from "../../../../../components/Flex/Flex";

import ArticleCoverImage from "../../ArticleCoverImage/ArticleCoverImage";

function DistributionDate({ before, after }) {
	return (
		<>
			<s style={{ opacity: 0.4 }}>{before}</s>
			<p>{after}</p>
		</>
	);
}

export default function useDistributeArticlesTableColumns({ distribution }) {
	const { t } = useTranslation();

	return [
		{
			id: "name",
			Header: t("article", "Article"),
			Cell: ({ value, row }) => (
				<Flex justifyContent="flex-start">
					<ArticleCoverImage
						thumb
						rounded
						article={row.original}
						style={{ marginRight: "1em", height: 45, width: 45 }}
					/>

					<span>{value}</span>
				</Flex>
			),
		},
		distribution?.announcementDate && {
			id: "distribution.announcementDate",
			Header: t("announceAt", "Announce at"),
			collapsing: true,
			Cell: ({ value }) => {
				return <DistributionDate before={value} after={distribution?.announcementDate} />;
			},
		},
		distribution?.releaseDate && {
			id: "distribution.releaseDate",
			Header: t("releaseAt", "Release at"),
			collapsing: true,
			Cell: ({ value }) => {
				return <DistributionDate before={value} after={distribution?.releaseDate} />;
			},
		},
		distribution?.takedownDate && {
			id: "distribution.takedownDate",
			Header: t("takedownAt", "Unpublish"),
			collapsing: true,
			Cell: ({ value }) => {
				return <DistributionDate before={value} after={distribution?.takedownDate} />;
			},
		},
	];
}
