import React from "react";
import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";

export default function PipelineStepNeedsSelect({ id, name, fields }) {
	const { t } = useTranslation();

	const options = [
		{ key: null, value: null, text: "NONE" },
		...fields
			.filter((field) => field.id !== id)
			.map((field) => ({
				key: field.id,
				text: field.name,
				value: field.key,
			})),
	];

	return (
		<Form.Select
			label={t("needs", "Needs")}
			name={`${name}.needs`}
			options={options}
			placeholder="NONE"
			shouldUnregister={false}
		/>
	);
}
