const defaultRoyalLibraryTemplate =
	'<?xml version="1.0" encoding="UTF-8" standalone="yes"?>\n' +
	'<mets:mets OBJID="ISBN:{{article.isbn}}" TYPE="SIP" PROFILE="http://www.kb.se/namespace/mets/fgs/eARD_Paket_FGS-PUBL.xml" xmlns:mods="http://www.loc.gov/mods/v3" xmlns:mets="http://www.loc.gov/METS/" xmlns:xlink="http://www.w3.org/1999/xlink">\n' +
	'    <mets:metsHdr CREATEDATE="{{getCreatedDate}}" RECORDSTATUS="VERSION">\n' +
	'        <mets:agent ROLE="ARCHIVIST" TYPE="ORGANIZATION">\n' +
	"            <mets:name><![CDATA[{{article.publisher.name}}]]></mets:name>\n" +
	"            <mets:note><![CDATA[URI:http://mimer.kb.se/organisations/SE{{getPublisherOrganizationNumber publisher}}]]></mets:note>\n" +
	"        </mets:agent>\n" +
	'        <mets:agent ROLE="CREATOR" TYPE="ORGANIZATION">\n' +
	"            <mets:name><![CDATA[Storyside AB]]></mets:name>\n" +
	"            <mets:note><![CDATA[URI:http://mimer.kb.se/organisations/SE{{getPublisherOrganizationNumber publisher}}]]></mets:note>\n" +
	"        </mets:agent>\n" +
	'        <mets:agent ROLE="ARCHIVIST" TYPE="OTHER" OTHERTYPE="SOFTWARE">\n' +
	"            <mets:name><![CDATA[Astrid – Storysides pliktleverans-system]]></mets:name>\n" +
	"        </mets:agent>\n" +
	'        <mets:altRecordID TYPE="DELIVERYTYPE">DEPOSIT</mets:altRecordID>\n' +
	'        <mets:altRecordID TYPE="DELIVERYSPECIFICATION">http://www.kb.se/namespace/digark/deliveryspecification/deposit/fgs-publ/mods/MODS_enligt_FGS-PUBL.pdf</mets:altRecordID>\n' +
	'        <mets:altRecordID TYPE="SUBMISSIONAGREEMENT">http://www.kb.se/namespace/digark/submissionagreement/ftp/fgs-mods/</mets:altRecordID>\n' +
	"    </mets:metsHdr>\n" +
	'    <mets:dmdSec ID="dmdSec0001">\n' +
	'        <mets:mdWrap MDTYPE="MODS">\n' +
	"            <mets:xmlData>\n" +
	"                <mods:mods>\n" +
	'                    <mods:identifier type="isbn">{{article.isbn}}</mods:identifier>\n' +
	"                    <mods:titleInfo>\n" +
	"                        <mods:title><![CDATA[{{article.title.name}}]]></mods:title>\n" +
	"                    </mods:titleInfo>\n" +
	"                    <mods:abstract><![CDATA[{{article.synopsis}}]]></mods:abstract>\n" +
	"                    {{#if (isTranslated article)}}\n" +
	'                    <mods:language objectPart="translation">\n' +
	'                        <mods:languageTerm type="code" authority="iso639-2b">{{article.originalLanguage}}</mods:languageTerm>\n' +
	"                    </mods:language>\n" +
	"                    {{/if}}\n" +
	"                    <mods:language>\n" +
	'                        <mods:languageTerm type="code" authority="iso639-2b">{{article.language}}</mods:languageTerm>\n' +
	"                    </mods:language>\n" +
	"                    <mods:originInfo>\n" +
	"                        <mods:publisher>{{article.publisher.name}}</mods:publisher>\n" +
	'                        <mods:dateIssued encoding="w3cdtf">{{article.distributionDates.releaseDate}}</mods:dateIssued>\n' +
	"                    </mods:originInfo>\n" +
	"                    <mods:physicalDescription>\n" +
	"                        <mods:extent>TODO!!!!!!</mods:extent>\n" +
	"                    </mods:physicalDescription>\n" +
	"                    {{#if article.serie}}\n" +
	'                    <mods:relatedItem type="series">\n' +
	"                        <mods:titleInfo>\n" +
	"                            <mods:title><![CDATA[{{article.serie.name}}]]></mods:title>\n" +
	"                        </mods:titleInfo>\n" +
	"                    </mods:relatedItem>\n" +
	'                    <mods:relatedItem type="host">\n' +
	"                        <mods:part>\n" +
	'                            <mods:detail type="volume">\n' +
	"                                <mods:number>{{article.serieNumber}}</mods:number>\n" +
	"                            </mods:detail>\n" +
	"                        </mods:part>\n" +
	"                    </mods:relatedItem>\n" +
	"                    {{/if}}\n" +
	"                     {{#each article.authors}}\n" +
	'                    <mods:name type="personal">\n' +
	"                        <mods:role>\n" +
	'                            <mods:roleTerm type="code" authority="marcrelator">aut</mods:roleTerm>\n' +
	"                        </mods:role>\n" +
	'                        <mods:namePart type="family"><![CDATA[{{this.name}}TODO!!!]]></mods:namePart>\n' +
	'                        <mods:namePart type="given"><![CDATA[{{this.name}}TODO!!!]]></mods:namePart>\n' +
	"                    </mods:name>\n" +
	"                    {{/each}}\n" +
	"                    {{#each article.narrators}}\n" +
	'                    <mods:name type="personal">\n' +
	"                        <mods:role>\n" +
	'                            <mods:roleTerm type="code" authority="marcrelator">nrt</mods:roleTerm>\n' +
	"                        </mods:role>\n" +
	'                        <mods:namePart type="family"><![CDATA[{{this.name}}TODO!!!]]></mods:namePart>\n' +
	'                        <mods:namePart type="given"><![CDATA[{{this.name}}TODO!!!]]></mods:namePart>\n' +
	"                    </mods:name>\n" +
	"                    {{/each}}\n" +
	"                    {{#each article.themaCodes}}\n" +
	'                    <mods:genre type="code" authority="thema">TODO!!!!</mods:genre>\n' +
	"                    {{/each}}\n" +
	"                    <mods:typeOfResource>sound recording-nonmusical</mods:typeOfResource>\n" +
	"                    <mods:accessCondition>restricted</mods:accessCondition>\n" +
	"                </mods:mods>\n" +
	"            </mets:xmlData>\n" +
	"        </mets:mdWrap>\n" +
	"    </mets:dmdSec>\n" +
	"    <mets:fileSec>\n" +
	"        <mets:fileGrp>\n" +
	"            {{#each artifact.files}}\n" +
	'            <mets:file ID="FILE{{getFileId @index}}" USE="{{getRoyalLibraryMimeType this}}" MIMETYPE="{{getMimeType this}}" SIZE="{{this.size}}" CREATED="{{getFileCreationISODate this}}" CHECKSUMTYPE="MD5" CHECKSUM="{{getMD5checksum this}}">\n' +
	'                <mets:FLocat xlink:type="simple" xlink:href="file:{{this.name}}" LOCTYPE="URL"/>\n' +
	"            </mets:file>\n" +
	"            {{/each}}\n" +
	"          {{#if article.cover}}\n" +
	'            <mets:file ID="FILE{{getCoverId article artifact.files}}" USE="JPEG File Interchange Format" MIMETYPE="image/jpeg" SIZE="{{article.cover.size}}" CREATED="{{getFileCreationISODate article.cover}}" CHECKSUMTYPE="MD5" CHECKSUM="{{getMD5checksum article.cover}}">\n' +
	'                <mets:FLocat xlink:type="simple" xlink:href="file:{{article.cover.name}}" LOCTYPE="URL"/>\n' +
	"            </mets:file>\n" +
	"            {{/if}}\n" +
	"        </mets:fileGrp>\n" +
	"    </mets:fileSec>\n" +
	'    <mets:structMap TYPE="physical">\n' +
	'        <mets:div TYPE="files">\n' +
	'            <mets:div DMDID="dmdSec0001" TYPE="maincontent">\n' +
	"                {{#each artifact.files}}\n" +
	'                <mets:fptr FILEID="FILE{{getFileId @index}}"/>\n' +
	"                {{/each}}\n" +
	"            </mets:div>\n" +
	"            {{#if article.cover}}\n" +
	'            <mets:div DMDID="dmdSec0001" TYPE="coverpicture">\n' +
	'                <mets:fptr FILEID="FILE{{getCoverId article artifact.files}}"/>\n' +
	"            </mets:div>\n" +
	"            {{/if}}\n" +
	"        </mets:div>\n" +
	"    </mets:structMap>\n" +
	"</mets:mets>";

export default defaultRoyalLibraryTemplate;
