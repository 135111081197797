import { Icon } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";

import DistributeArticlesFormDivider from "./DistributeArticlesFormDivider";

export default function DistributeArticlesFormDeliver() {
	const { t } = useTranslation();

	return (
		<>
			<DistributeArticlesFormDivider style={{ margin: "30px auto 20px" }}>
				{t("deliver", "Deliver")}
			</DistributeArticlesFormDivider>

			<Form.Group widths={3} style={{ justifyContent: "center" }}>
				<Form.CheckboxButton fluid basic name="delivery.artifact">
					<Icon name="file audio outline" />
					{t("artifact", "Artifact")}
				</Form.CheckboxButton>

				<Form.CheckboxButton fluid basic name="delivery.metadata">
					<Icon name="file alternate outline" />
					{t("metaData", "Meta data")}
				</Form.CheckboxButton>
			</Form.Group>

			<Form.ValidationMessage root name="delivery" />
		</>
	);
}
