import { Button, Modal } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function TitleOccupiedModal({ existingProductionId, onClose, onCreate }) {
	const { t } = useTranslation();

	return (
		<Modal open>
			<Modal.Header>{t("titleAlreadyExists", "This title already exists")}</Modal.Header>
			<Modal.Content>
				<p>{t("stillCreateProduction", "Do you still want to create this production?")}</p>
				<Link to={`/production/${existingProductionId}`} rel="noopener noreferrer" target="_blank">
					{t("toExistingProduction", "To existing production")}
				</Link>
			</Modal.Content>
			<Modal.Actions>
				<Button content={t("cancel")} onClick={onClose} />
				<Button primary content={t("create")} onClick={onCreate} />
			</Modal.Actions>
		</Modal>
	);
}
