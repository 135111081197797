import { Artifact } from "astrid-firestore/src/api/artifacts/types/Artifact";
import useBucket from "astrid-hooks/src/useBucket";

import api from "../../../api";

import useSimpleForm from "../../forms/hooks/useSimpleForm";

export default function useArtifactForm({ article, publisher, production, defaultName = "", onClose, ...props }) {
	const bucket = useBucket("earselect-artifacts");

	return useSimpleForm({
		schema: Artifact,
		defaultValues: {
			name: defaultName,
			article: article || null,
			publisher: publisher || null,
			production: production || null,
			isDefault: !!article,
			targetFiles: [],
		},
		onSubmit: async ({ targetFiles, ...data }) => {
			const { id } = api.artifacts.createRef();

			const files = await bucket.putFiles(id, targetFiles);

			try {
				return await api.artifacts.create({ id, files, ...data });
			} catch (error) {
				await bucket.deleteFiles(files);

				throw error;
			}
		},
		onSuccess: onClose,
		...props,
	});
}
