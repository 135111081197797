import { useTranslation } from "react-i18next";

export default function useArticleAbridgedOptions() {
	const { t } = useTranslation();

	return [
		{ key: "yes", value: true, text: t("yes", "Yes") },
		{ key: "no", value: false, text: t("no", "No") },
	];
}
