import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { MetaData } from "../../../types/MetaData";
import {
	array,
	boolean,
	duration,
	enumValues,
	isbn,
	literal,
	nullable,
	string,
	timestamp,
	union,
} from "../../../types/_types";

import { ArtifactRef } from "../../artifacts/types/ArtifactRef";
import { OrganizationRef } from "../../organizations/types/OrganizationRef";
import { ProductionRef } from "../../productions/types/Production";
import { TitleRef } from "../../titles/types/Title";

import articleTypes from "../constants/articleTypes";

import { ArticleBundleRef } from "./ArticleBundleRef";
import { ArticleChannels } from "./ArticleChannel";
import { ArticleContributor } from "./ArticleContributor";
import { ArticleDelivery } from "./ArticleDelivery";
import { ArticleDistribution } from "./ArticleDistribution";
import { ArticlePages } from "./ArticlePages";
import { ArticleRef } from "./ArticleRef";

export const articleType = enumValues(articleTypes);

export const ArticleMetaData = MetaData.extend({
	duration: nullable(duration()),
	isbn: nullable(isbn()),
	linkedArticle: nullable(ArticleRef),
	narrator: nullable(string()),
	pages: nullable(ArticlePages),
	episodeName: nullable(string(3)),
	abridged: boolean().default(false),
	tags: nullable(array(string())),
	contributors: array(ArticleContributor),
});

export const Article = FirestoreDocument.extend({
	type: articleType,
	status: string(3),
	artifact: nullable(ArtifactRef),
	bundle: nullable(ArticleBundleRef),
	channels: ArticleChannels,
	channelIds: nullable(array(string())),
	delivery: ArticleDelivery,
	deliveryDate: nullable(timestamp()),
	distribution: ArticleDistribution,
	producer: nullable(OrganizationRef),
	production: nullable(ProductionRef),
	publisher: OrganizationRef,
	title: union([literal(false), TitleRef]).default(false),
	titleSync: boolean(),
})
	.merge(FirestoreLifecycle)
	.merge(ArticleMetaData);
