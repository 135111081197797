import { parsePhoneNumber } from "awesome-phonenumber";

import { Card } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import { getCountryName } from "astrid-helpers/src/countries";

import AdminCard from "../../../../admin/components/AdminCard/AdminCard";

export default function UserVendors({ user }) {
	const { t } = useTranslation();

	if (!user.vendors) {
		return false;
	}

	return (
		<AdminCard header={t("billing", "Billing")}>
			{Object.entries(user.vendors || {}).map(([key, vendor]) => (
				<React.Fragment key={key}>
					<Card.Description>
						{vendor.name} {vendor.organizationNumber && `(${vendor.organizationNumber})`}
					</Card.Description>
					{vendor.street && <Card.Meta>{vendor.street}</Card.Meta>}
					{vendor.secondaryStreet && <Card.Meta>{vendor.secondaryStreet}</Card.Meta>}
					{vendor.zip && (
						<Card.Meta>
							{vendor.zip} {vendor.city}
						</Card.Meta>
					)}
					{vendor.state && <Card.Meta>{vendor.state}</Card.Meta>}
					{vendor.country && <Card.Meta>{getCountryName(vendor.country)}</Card.Meta>}
					{vendor.phone && <Card.Meta>{parsePhoneNumber(vendor.phone).getNumber("international")}</Card.Meta>}
				</React.Fragment>
			))}
		</AdminCard>
	);
}
