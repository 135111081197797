import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import ArticlesInstantSearchDataTable from "../../../articles/components/ArticlesInstantSearchDataTable/ArticlesInstantSearchDataTable";
import CreateArticleButton from "../../../articles/components/CreateArticleForm/CreateArticleButton";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

export default function AdminArticlesView({ organization }) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("allArticles", "All articles")}>
				{searchInput}

				<CreateArticleButton
					publisher={organization}
					onSuccess={({ id }) => navigate(`/${organization.type}/${organization.id}/admin/articles/${id}`)}
				/>
			</AdminHeader>

			<ArticlesInstantSearchDataTable
				selectable
				publisher={organization}
				searchQuery={searchQuery}
				showDistributeButton
				showArticleBundleButton
			/>
		</>
	);
}
