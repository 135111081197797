import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import useHasRight from "../../../authorization/hooks/useHasRight";

import ProductionOfferForm from "./ProductionOfferForm";

export default function ProductionOfferFormButton({ children, ...props }) {
	const { t } = useTranslation();

	const hasRight = useHasRight();

	if (!hasRight("production.accept")) {
		return false;
	}

	return (
		<OpenButton text={children || t("acceptAndPlan")} {...props}>
			{({ setOpen }) => {
				return <ProductionOfferForm {...props} onClose={() => setOpen(false)} />;
			}}
		</OpenButton>
	);
}
