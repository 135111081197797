import { useState } from "react";

import DataTable from "../../../ui/components/DataTable/DataTable";

import useDistributionArgsTableColumns from "../../hooks/useDistributionArgsTableColumns";
import useDistributionChannelOptions from "../../hooks/useDistributionChannelOptions";

import CreateDistributionArgsForm from "../CreateDistributionCredentials/CreateDistributionArgsForm";

export default function DistributionArgsDataTable({ publisher, ...props }) {
	const [openEdit, setOpenEdit] = useState(false);
	const [distributionChannels] = useDistributionChannelOptions();
	const data =
		Object.entries(publisher?.distribution?.channels || {}).flatMap(([channelId, value]) => {
			const channel = distributionChannels?.find((channel) => channel.key === channelId);

			return Object.entries(value?.actions || {}).map(([actionName, action]) => {
				return {
					channelId: channel?.key,
					channel: channel?.text,
					action: actionName,
					args: action.args,
				};
			});
		}) || [];

	const columns = useDistributionArgsTableColumns({ publisher, setOpenEdit });

	return (
		<>
			{openEdit && (
				<CreateDistributionArgsForm
					publisher={publisher}
					editData={openEdit}
					onClose={() => setOpenEdit(false)}
				/>
			)}
			<DataTable data={data} columns={columns} {...props} />
		</>
	);
}
