import React from "react";

import FormDocumentSelect from "../FormSelect/FormDocumentSelect";

export default function FormCollectionSelect({ collection, query, mapOption, onCreateData, ...props }) {
	const [options, loading, error] = collection.useOptions(query, { mapOption });

	const onAddDocument = (text) => {
		if (onCreateData) {
			return collection.createData(onCreateData(text, { collection, query }));
		}
	};

	return (
		<FormDocumentSelect
			search
			options={options}
			loading={loading}
			error={error}
			onAddDocument={onAddDocument}
			{...props}
		/>
	);
}
