import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { StorageFile } from "../../../types/StorageFile";
import { intMinMax, nullable, object, ref, string } from "../../../types/_types";

import { OrganizationRef } from "../../organizations/types/OrganizationRef";

export const scriptPages = object({
	start: nullable(intMinMax(1)),
	end: nullable(intMinMax(1)),
}).refine(
	({ start, end }) => {
		return start <= end;
	},
	{
		message: "Must be higher than starting page",
		path: ["end"],
	},
);

export const Script = FirestoreDocument.extend({
	name: string(3),
	file: nullable(StorageFile),
	pages: nullable(scriptPages),
	publisher: OrganizationRef,
}).merge(FirestoreLifecycle);

export const ScriptRef = ref(Script, {
	name: true,
	file: true,
	pages: true,
});
