import { Icon } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

export default function usePublisherDashboardStatistics({ organization }) {
	const { t } = useTranslation();

	if (!organization.statistics) {
		return [];
	}

	const { articles, distributions, imprints, users } = organization.statistics;

	return [
		{
			total: articles.total,
			details: [
				{ text: t("audiobook", "Audiobook"), icon: "headphones", amount: articles.audiobook },
				{ text: t("ebook", "E-book"), icon: "book", amount: articles.ebook },
				{ text: t("podcast", "Podcast"), icon: "podcast", amount: articles.podcast },
			],
			label: t("articles", "Articles"),
			link: "articles",
			organization: organization,
		},
		{
			total: distributions.scheduled,
			details: [
				{ text: t("completedPlural", "Completed"), icon: "badge check", amount: distributions.completed },
				{
					text: t("scheduledPlural", "Scheduled"),
					icon: "calendar alternate",
					amount: distributions.scheduled,
				},
				{ text: t("abortedPlural", "Aborted"), icon: "x", amount: distributions.aborted },
			],
			label: t("distributions", "Distributions"),
			link: "distributions",
			organization: organization,
		},
		{
			total: users.total,
			details: [
				{
					text: t("admin", "Admin"),
					icon: (
						<Icon.Group style={{ marginRight: 5, fontSize: 14.5 }}>
							<Icon name="user" />
							<Icon corner name="cog" />
						</Icon.Group>
					),
					amount: users.publisherAdmin,
				},
				{ text: t("other", "Other"), icon: "user", amount: users.publisherStaff },
			],
			label: t("users", "Users"),
			link: "users",
			organization: organization,
		},
		{
			total: imprints.total,
			label: t("imprints", "Imprints"),
			link: "imprints",
			organization: organization,
		},
	];
}
