import { useTranslation } from "react-i18next";

import themeColors from "../../ui/constants/themeColors";

export default function useChannelDistributionStatistics({ organization, channels, activeChannelId }) {
	const { t } = useTranslation();

	const channel = channels?.find(({ id }) => id === activeChannelId);

	const allChannelStatistics = channels
		?.map(({ publishers }) => publishers?.[organization.id]?.statistics?.distributions)
		.filter(Boolean);

	const allStatistics = allChannelStatistics?.reduce(
		(acc, distributions) =>
			Object.entries(distributions || {}).reduce(
				(acc, [key, value]) => ({ ...acc, [key]: (acc[key] || 0) + value }),
				acc,
			),
		{},
	);

	const statistics = channel ? channel.publishers[organization.id].statistics.distributions : allStatistics;

	if (!statistics) {
		return [];
	}

	return [
		{
			total: statistics.completed,
			color: "green",
			detailColor: themeColors.GREEN,
			label: t("completedPlural", "Completed"),
			link: "productions/completed",
			organization: organization,
		},
		{
			total: statistics.scheduled,
			color: "blue",
			detailColor: themeColors.BLUE,
			label: t("scheduledPlural", "Scheduled"),
			link: "distributions/scheduled",
			organization: organization,
		},
		{
			total: statistics.aborted,
			color: "black",
			detailColor: themeColors.BLACK,
			label: t("abortedPlural", "Aborted"),
			link: "distributions/aborted",
			organization: organization,
		},
	];
}
