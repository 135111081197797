import { useTranslation } from "react-i18next";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import ArticlesInstantSearchDataTable from "../../../articles/components/ArticlesInstantSearchDataTable/ArticlesInstantSearchDataTable";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

export default function AdminArticlesDraftsView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("drafts", "Drafts")}>{searchInput}</AdminHeader>

			<ArticlesInstantSearchDataTable
				selectable
				publisher={organization}
				searchQuery={searchQuery}
				drafts
				showArticleBundleButton
			/>
		</>
	);
}
