import { useTranslation } from "react-i18next";

export default function useImprintArticleStatistics({
	imprints,
	activeImprintId,
	deliveryStatus,
	label,
	color,
	detailColor,
	link,
	organization,
}) {
	const { t } = useTranslation();

	const imprint = imprints?.find(({ id }) => id === activeImprintId);

	const total = imprint
		? imprint.statistics.articles[deliveryStatus].total
		: imprints?.reduce((acc, { statistics }) => acc + statistics.articles[deliveryStatus].total, 0);

	const audiobook = imprint
		? imprint.statistics.articles[deliveryStatus].audiobook
		: imprints?.reduce((acc, { statistics }) => acc + statistics.articles[deliveryStatus].audiobook, 0);

	const ebook = imprint
		? imprint.statistics.articles[deliveryStatus].ebook
		: imprints?.reduce((acc, { statistics }) => acc + statistics.articles[deliveryStatus].ebook, 0);

	const podcast = imprint
		? imprint.statistics.articles[deliveryStatus].podcast
		: imprints?.reduce((acc, { statistics }) => acc + statistics.articles[deliveryStatus].podcast, 0);

	return [
		{
			total: total && total,
			details: [
				{
					text: t("audiobook", "Audiobook"),
					icon: "headphones",
					amount: audiobook,
				},
				{
					text: t("ebook", "E-book"),
					icon: "book",
					amount: ebook,
				},
				{
					text: t("podcast", "Podcast"),
					icon: "podcast",
					amount: podcast,
				},
			],
			color,
			detailColor,
			label,
			link,
			organization,
		},
	];
}
