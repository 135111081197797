import React from "react";

import BookmarkIcon from "astrid-components/lib/components/Assets/Icons/Bookmark";
import Button from "astrid-components/lib/components/Inputs/Button";

export default function ArtifactAudioPlayerTool({ tool, activeTool, onChange }) {
	const onMouseDown = ({ button }) => {
		if (button === 0) {
			onChange?.(tool);
		}
	};

	return (
		<Button size="small" active={tool === activeTool} onMouseDown={onMouseDown} onTouchStart={onMouseDown}>
			<BookmarkIcon />
		</Button>
	);
}
