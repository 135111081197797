import { intMinMax, nullable, object } from "../../../types/_types";

export const ArticlePages = object({
	start: nullable(intMinMax(1)),
	end: nullable(intMinMax(1)),
}).refine(
	({ start, end }) => {
		return start <= end;
	},
	{
		message: "Must be higher than starting page",
		path: ["end"],
	},
);
