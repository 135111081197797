import React, { useEffect, useRef } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import artifactMarkerTypes from "astrid-firestore/src/api/artifacts/constants/artifactMarkerTypes";

export default function ArtifactAudioPlayerChapterTool({ x, y, addMarker }) {
	const state = { x, y, active: x && y > 0 && y < 1 };

	const ref = useRef(state);

	ref.current = state;

	useEffect(() => {
		return () => {
			const { x, active } = ref.current;

			if (active) {
				addMarker({
					type: artifactMarkerTypes.CHAPTER,
					position: x,
				});
			}
		};
	}, [addMarker]);

	if (!state.active) return false;

	return (
		<Timeline.Marker active color="positive" position={x}>
			Chapter
		</Timeline.Marker>
	);
}
