import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useAdminArticlesDashboardMenuOptions(imprints, activeImprintId, setActiveImprintId) {
	const { t } = useTranslation();

	return useMemo(() => {
		const allImprintsDeliveryNeededCount = imprints?.reduce(
			(acc, { statistics }) => acc + statistics.articles?.deliveryNeeded.total,
			0,
		);

		return [
			{
				text: t("allImprints", "All imprints"),
				label: allImprintsDeliveryNeededCount,
				labelColor: "orange",
				active: !activeImprintId,
				onClick: () => setActiveImprintId(),
			},
			...(imprints
				?.map(({ id, name, statistics }) => {
					return {
						key: id,
						text: name,
						label: statistics.articles?.deliveryNeeded.total,
						labelColor: "orange",
						active: id === activeImprintId,
						onClick: () => setActiveImprintId(id),
					};
				})
				.sort((a, b) => b.label - a.label) || []),
		];
	}, [activeImprintId, imprints, setActiveImprintId, t]);
}
