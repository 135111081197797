import React from "react";
import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import Accordion from "../../../../ui/components/Accordion/Accordion";

import PipelineStepActionArgs from "./PipelineStepActionArgs";
import PipelineStepActionSelect from "./PipelineStepActionSelect";
import PipelineStepNeedsSelect from "./PipelineStepNeedsSelect";
import PipelineSteps from "./PipelineSteps";

export default function PipelineStepContent({ id, name, fields, error }) {
	const { t } = useTranslation();

	const { watch } = Form.useFormContext();

	const [action] = watch([`${name}.action`, `${name}.needs`]);

	return (
		<Accordion.Content active>
			<div style={{ padding: 10, marginBottom: 10 }}>
				<Form.Input name={`${name}.condition`} label={t("condition", "Condition")} shouldUnregister={false} />
				<PipelineStepNeedsSelect id={id} name={name} fields={fields} />

				<PipelineStepActionSelect name={name} />

				<PipelineStepActionArgs action={action} name={name} />

				{!action && <PipelineSteps name={`${name}.steps`} label={t("steps", "Steps")} error={error} />}
			</div>
		</Accordion.Content>
	);
}
