import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import AdminCard from "../../../../admin/components/AdminCard/AdminCard";
import useBiblioImprints from "../../../../biblio/hooks/useBiblioImprints";
import Form from "../../../../forms/components/Form/Form";
import useFirestoreForm from "../../../../forms/hooks/useFirestoreForm";
import FlexTable from "../../../../ui/components/FlexTable/FlexTable";
import FlexTableExpander from "../../../../ui/components/FlexTable/components/FlexTableExpander";
import LoadingContext from "../../../../ui/components/LoadingContext/LoadingContext";

import useBiblioImprintsColumns from "../hooks/useBiblioImprintsColumns";

export default function BiblioImprintsForm({ settings }) {
	const { t } = useTranslation();

	const { ref, imprints } = settings;

	const [showActiveImprints, setShowActiveImprints] = useState(false);

	const { form, onSubmit } = useFirestoreForm({
		ref,
		defaultValues: {
			imprints: imprints || {},
		},
	});

	const [data = [], loading, error] = useBiblioImprints({ limit: 1000 });

	const columns = useBiblioImprintsColumns(form);

	const activeImprints = useMemo(() => data?.filter(({ id }) => imprints[id]?.publisherId), [data, imprints]);
	const inactiveImprints = useMemo(() => data?.filter(({ id }) => !imprints[id]?.publisherId), [data, imprints]);

	return (
		<AdminCard header={t("imprints", "Imprints")}>
			<Form form={form}>
				<Form.AutoSave onSubmit={onSubmit} />

				<LoadingContext loading={loading} error={error} data={data}>
					<FlexTable
						data={showActiveImprints ? [...inactiveImprints, ...activeImprints] : inactiveImprints}
						columns={columns}
						loading={loading}
						error={error}
					>
						{activeImprints?.length > 0 && (
							<FlexTableExpander
								onClick={() => setShowActiveImprints((showActiveImprints) => !showActiveImprints)}
								expanded={showActiveImprints}
								collapsedText={t("showAll") + ` (${activeImprints.length})`}
								expandedText={t("showLess")}
							/>
						)}
					</FlexTable>
				</LoadingContext>
			</Form>
		</AdminCard>
	);
}
