import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import PrimaryButton from "../../../ui/components/Buttons/PrimaryButton";

import ProductionsCollectionDataTable from "../../../productions/components/ProductionsCollectionDataTable/ProductionsCollectionDataTable";

import AdminCard from "../../components/AdminCard/AdminCard";

export default function AdminArticleProductionsView({ article, organization }) {
	const { t } = useTranslation();

	return (
		<AdminCard
			header={t("productions", "Productions")}
			extra={
				<PrimaryButton
					as={Link}
					to={`/${organization.type}/${organization.id}/create-production/${article.id}`}
				>
					{t("createProduction", "Create production")}
				</PrimaryButton>
			}
		>
			<ProductionsCollectionDataTable
				getQuery={(query) => query.where("articleIds", "array-contains", article.id)}
			/>
		</AdminCard>
	);
}
