import renderHandlebarsTemplate from "../handlebars/renderHandlebarsTemplate";
import fetchMetadata from "../utils/fetchMetadata";

export default async function generateCustomXMLFromTemplate({ document }) {
	const { customXmlTemplate } = document.channel;

	const metadataDocument = await fetchMetadata({ document });

	return renderHandlebarsTemplate({ template: customXmlTemplate, data: metadataDocument });
}
