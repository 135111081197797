import { Button } from "semantic-ui-react";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import ArticleContributorsTable from "../../../../articles/components/ArticleContributorsTable/ArticleContributorsTable";
import AddContributorModal from "../../../../contributors/components/AddContributorModal";

import AdminCard from "../../../components/AdminCard/AdminCard";

export default function AdminArticleContributorsForm({ article }) {
	const { t } = useTranslation();

	const [openModal, setOpenModal] = useState(false);

	return (
		<>
			<AdminCard
				header={t("contributors", "Contributors")}
				extra={
					<Button primary onClick={() => setOpenModal(true)}>
						{t("add", "Add")}
					</Button>
				}
			>
				<ArticleContributorsTable article={article} />
			</AdminCard>

			{openModal && <AddContributorModal article={article} onClose={() => setOpenModal(false)} />}
		</>
	);
}
