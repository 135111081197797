import FirestoreCollection from "../../firestore/FirestoreCollection";
import { MetaData } from "../../types/MetaData";

import { Article } from "./types/Article";

export default class ArticlesCollection extends FirestoreCollection {
	static collectionName = "articles";
	static Schema = Article;

	// uniqueKeys: ["isbn"],

	preProcess(data) {
		// if (!partial && !data.title) {
		// 	data.title = api.titles.create({ name: data.name, publisher: data.publisher }, { transaction });
		// }

		// if (!partial && data.title) {
		// 	data.titleSync = true;
		// }

		// If title is provided, fetch the title data
		if (data.title && data.titleSync) {
			data = { ...data, ...MetaData.parse(data.title) };
		}

		return data;
	}

	async sideEffects({ update, transaction }) {
		const { before, after } = update;

		if (after.exists) {
			const title = update.get("title");

			if (title.isUpdated()) {
				if (title.after) {
					transaction.update(title.after.ref, {
						articleIds: this.utils.arrayUnion(after.id),
					});
				}

				if (title.before) {
					transaction.update(title.before.ref, {
						articleIds: this.utils.arrayUnion(after.id, false),
					});
				}
			}
		}

		// Check if metadata has changed sinse last distribution
		if (before.exists && after.exists) {
			const serie = update.get("serie");
			const imprint = update.get("imprint");

			if (serie.isCreated()) {
				await this.api.series.create({ ...serie.after, publisher: after.publisher }, { transaction });
			}

			if (imprint.isCreated()) {
				await this.api.imprints.create({ ...imprint.after, publisher: after.publisher }, { transaction });
			}
		}
	}

	async updateTitleData({ ref, titleSync = false, ...data }) {
		const title = await this.api.titles.getByRef(data.title.ref);

		if (title) {
			return this.api.articles.update({ ref, titleSync, title });
		}
	}

	addContributor({ article, contributor }) {
		return this.api.articles.update({
			ref: article.ref,
			contributors: this.utils.arrayUnion(contributor),
		});
	}

	removeContributor({ article, contributor }) {
		return this.api.articles.update({
			ref: article.ref,
			contributors: this.utils.arrayRemove(contributor),
		});
	}
}
