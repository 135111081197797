import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import CreateExportForm from "./CreateExportForm";

export default function CreateExportButton({ article }) {
	const { t } = useTranslation();

	return (
		<OpenButton text={t("createExport", "Create export")}>
			{({ setOpen }) => <CreateExportForm article={article} onClose={() => setOpen(false)} />}
		</OpenButton>
	);
}
