import { useTranslation } from "react-i18next";

export default function useActionTitle(type) {
	const { t } = useTranslation();

	const types = {
		producer: t("createProducer", "Create producer"),
		publisher: t("createPublisher", "Create publisher"),
	};

	return types[type];
}
