import { useMemo } from "react";

import { filterDistributionChannelsByArticles } from "astrid-firestore/src/api/distribution/utils/filterDistributionChannels";

import api from "../../../api";

export default function useArticlesDistributionChannels({ publisher, articles }) {
	const [channels, loading, error] = api.distributionChannels.useAll();

	const filteredData = useMemo(
		() => filterDistributionChannelsByArticles({ publisher, articles, channels }),
		[articles, channels, publisher],
	);

	const filteredChannelIds = useMemo(
		() => [...new Set(filteredData.flatMap(({ filteredChannels }) => filteredChannels).map(({ id }) => id))],
		[filteredData],
	);

	const filteredChannels = useMemo(
		() => channels?.filter(({ id }) => filteredChannelIds.includes(id)) || [],
		[channels, filteredChannelIds],
	);

	return [filteredChannels, loading, error];
}
