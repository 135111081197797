import { Route } from "react-router-dom";

import AdminArtifact from "../../pages/AdminArtifact/AdminArtifact";
import AdminArtifactArticles from "../../pages/AdminArtifact/AdminArtifactArticles";
import AdminArtifactFiles from "../../pages/AdminArtifact/AdminArtifactFiles";

const adminArtifactRoute = (
	<Route path=":artifact" element={<AdminArtifact />} handle={{ crumb: ({ artifact }) => artifact?.name }}>
		<Route index element={<AdminArtifactFiles />} handle={{ crumb: ({ t }) => t("files", "Files") }} />

		<Route
			path="articles"
			element={<AdminArtifactArticles />}
			handle={{ crumb: ({ t }) => t("articles", "Articles") }}
		/>
	</Route>
);

export default adminArtifactRoute;
