import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import db from "astrid-firebase/src/db";
import agreementStatuses from "astrid-firestore/src/api/agreements/constants/agreementStatuses";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useAgreementColumns from "../../../agreements/hooks/useAgreementColumns";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

const expiringAgreementsStatuses = [agreementStatuses.SIGNED, agreementStatuses.ACTIVE, agreementStatuses.EXPIRED];

export default function ExpiringAgreementsView({ firstPartyId }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const tenDaysFromNow = new Date();
	tenDaysFromNow.setDate(tenDaysFromNow.getDate() + 10);
	tenDaysFromNow.setHours(23, 59, 59, 999);

	const query = db
		.collection("agreements")
		.where("deleted", "==", false)
		.where("firstParty.id", "==", firstPartyId)
		.where("expires", "<=", tenDaysFromNow)
		.where("status", "in", expiringAgreementsStatuses)
		.orderBy("expires", "desc")
		.limit(200);

	const columns = useAgreementColumns();

	return (
		<>
			<AdminHeader>{t("expiringAgreements", "Expiring agreements")}</AdminHeader>

			<CollectionDataTable
				persist
				query={query}
				columns={columns}
				onClick={({ id }) => navigate(`../${id}`)}
				sortBy={{
					id: "time",
					desc: false,
				}}
			/>
		</>
	);
}
