import { nullable, object, record, string } from "../../../types/_types";

export const DistributionChannelSettings = object({
	actions: record(
		string(),
		object({
			args: nullable(object().passthrough()),
		}),
	),
});
