import { Card, Item } from "semantic-ui-react";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { languageName } from "astrid-helpers/src/languages";

import { useOrganizationId } from "../../../organizations/state/organizationId";
import { useOrganizationType } from "../../../organizations/state/organizationType";

import useArticleTypeTranslations from "../../hooks/useArticleTypeTranslations";
import articleTypeIcons from "../../utils/articleTypeIcons";
import ArticleLabel from "../../views/ArticleView/components/ArticleLabel";

import ArticleCoverImage from "../ArticleCoverImage/ArticleCoverImage";

export default function ArticleCardFull({ article }) {
	const { t } = useTranslation();
	const orgId = useOrganizationId();
	const orgType = useOrganizationType();

	const articleTypeTranslations = useArticleTypeTranslations();

	const [extendText, setExtendText] = useState(article?.synopsis?.length <= 300);

	return (
		<Card
			fluid
			key={article.id}
			as={Link}
			to={`/${orgType}/${orgId}/articles/${article.id}`}
			style={{ color: "inherit" }}
		>
			<Card.Content>
				<Item.Group>
					<Item>
						<Item.Image>
							<ArticleCoverImage thumb article={article} style={{ borderRadius: 3 }} />
						</Item.Image>
						<Item.Content>
							<Item.Header style={{ marginTop: 2 }}>{article.name}</Item.Header>

							<Item.Meta>{article.author}</Item.Meta>

							<Item.Description>
								<p>
									{extendText ? article.synopsis : article.synopsis.substring(0, 300) + "... "}
									{!extendText && article.synopsis.length > 300 && (
										<span
											onClick={(e) => {
												e.preventDefault();
												setExtendText(!extendText);
											}}
											style={{ fontWeight: "bold", cursor: "pointer" }}
										>
											{t("showMore", "Show more")}
										</span>
									)}
								</p>
							</Item.Description>

							<Item.Extra>
								<ArticleLabel icon="book" value={article.isbn} />

								<ArticleLabel icon="language" value={languageName(article.language)} />

								<ArticleLabel
									icon={articleTypeIcons[article.type]}
									value={articleTypeTranslations[article.type]}
								/>
							</Item.Extra>
						</Item.Content>
					</Item>
				</Item.Group>
			</Card.Content>
		</Card>
	);
}
