import { Button } from "semantic-ui-react";

import { useState } from "react";

export default function Collapsible({ children, title, visible = false, disabled = false, style = {} }) {
	const [isVisible, setIsVisible] = useState(visible);

	const toggleVisibility = () => {
		setIsVisible((prevState) => !prevState);
	};

	return (
		<>
			<Button
				basic
				secondary
				disabled={disabled}
				style={style}
				size="tiny"
				icon={isVisible ? "chevron up" : "chevron down"}
				content={title}
				onClick={toggleVisibility}
			/>
			{isVisible && children}
		</>
	);
}
