import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import api from "../../../../api";
import Button from "../../../ui/components/Buttons/Button";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import Dashboard from "../../../dashboard/components/Dashboard/Dashboard";
import DistributionsInstantSearchDataTable from "../../../distribution/components/DistributionsInstantSearchDataTable/DistributionsInstantSearchDataTable";
import useChannelDistributionStatistics from "../../../organizations/hooks/useChannelDistributionStatistics";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

import useAdminDistributionsDashboardMenuOptions from "./hooks/useAdminDistributionsDashboardMenuOptions";

export default function AdminDistributionsDashboardView({ organization }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [channels, loading, error] = api.distributionChannels.useAll();

	const [activeChannelId, setActiveChannelId] = useState();

	const menuOptions = useAdminDistributionsDashboardMenuOptions({
		channels,
		organization,
		activeChannelId,
		setActiveChannelId,
	});

	const statistics = useChannelDistributionStatistics({ organization, channels, activeChannelId });

	return (
		<>
			<AdminHeader text={t("distributions", "Distributions")} />

			<LoadingContext data={channels} loading={loading} error={error}>
				<Dashboard menuOptions={menuOptions} statistics={statistics}>
					<DistributionsInstantSearchDataTable
						organization={organization}
						configure={{
							hitsPerPage: 10,
						}}
						visibleColumns={["status", "channel.name", "article.name", "pipeline.name", "created", "menu"]}
						footer={
							<Button onClick={() => navigate("all")}>
								{t("allDistributions", "All distributions")}
							</Button>
						}
					/>
				</Dashboard>
			</LoadingContext>
		</>
	);
}
