import AdminArticleContributorsForm from "./components/AdminArticleContributorsForm";
import AdminArticleDurationForm from "./components/AdminArticleDurationForm";
import AdminArticleGeneralForm from "./components/AdminArticleGeneralForm";
import AdminArticleLanguageForm from "./components/AdminArticleLanguageForm";
import AdminArticleSeriesForm from "./components/AdminArticleSeriesForm";

export default function AdminArticleSettingsView(props) {
	return (
		<>
			<AdminArticleGeneralForm {...props} />

			<AdminArticleSeriesForm {...props} />

			<AdminArticleLanguageForm {...props} />

			<AdminArticleDurationForm {...props} />

			<AdminArticleContributorsForm {...props} />
		</>
	);
}
