import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useListProductionsColumnTranslations() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			title: t("title", "Title"),
			series: t("seriesPart", "Series (part)"),
			author: t("author", "Author"),
			status: t("status", "Status"),
			progressBar: t("progress", "Progress"),
			noOfCorrections: t("noOfCorrections", "No of corrections"),
			publisher: t("publisher", "publisher"),
			publisherManagerNames: t("publisherManager", "Publisher manager"),
			producer: t("prodComp", "Producer"),
			productionDate: t("prodDate", "Production date"),
			productionType: t("productionTypeNoSemi", "Production type"),
			productionTags: t("productionTags", "Production tags"),
			language: t("language", "Language"),
			readerNames: t("narrator", "Narrator"),
			voiceNames: t("voices", "Voices"),
			editorNames: t("editor", "Editor"),
			prooferNames: t("proofer", "Proofer"),
			managerNames: t("prodManager", "Production manager"),
			subcontractorName: t("subcontractor", "Subcontractor"),
			deliveryDate: t("deliveryDate", "DeliveryDate"),
			duration: t("duration", "Duration"),
			doneDate: t("doneDate", "Done date"),
			scriptDate: t("script", "Script date"),
			created: t("created", "Created"),
			updated: t("updated", "Updated"),
			isbn: t("isbn", "ISBN"),
			ebookISBN: t("ebookISBN", "Ebook ISBN"),
		}),
		[t],
	);
}
