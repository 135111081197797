import { Image } from "semantic-ui-react";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import featureFlags from "../../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../../../authorization/hooks/useHasRight";
import { useOrganizationId } from "../../../../organizations/state/organizationId";
import { useOrganizationType } from "../../../../organizations/state/organizationType";

export default function useMainMenuOptions() {
	const { t } = useTranslation();

	const hasRight = useHasRight();
	const location = useLocation();
	const orgId = useOrganizationId();
	const orgType = useOrganizationType();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	const { pathname } = location;

	return useMemo(
		() => [
			{
				to: "/",
				active: pathname === "/",
				content: (
					<>
						<Image
							src="/img/astrid-icon-bitmap.png"
							size="small"
							style={{ height: 18, width: "auto", margin: "-2px 8px -2px -2px" }}
						/>

						{t("home", "Home")}
					</>
				),
			},
			{
				to: `/${orgType}/${orgId}/create-production`,
				text: t("createProduction", "Create production"),
				hidden: !hasRight("createProduction") || orgType === organizationTypes.NARRATOR,
				active: pathname.includes("create-production"),
			},
			{
				to: `/${orgType}/${orgId}/my-productions/current`,
				text: t("myProductions"),
				hidden: !orgId || !(hasRight("listProducerProductions") || hasRight("listPublisherProductions")),
				active: pathname.includes(`/${orgType}/${orgId}/my-productions`),
			},
			{
				to: `/${orgType}/${orgId}/list-productions`,
				text: t("productions"),
				hidden: !orgId || !hasRight("list-productions.beta") || orgType === organizationTypes.NARRATOR,
				active: pathname.includes(`${orgId}/list-productions`),
			},
			{
				to: `/${orgType}/${orgId}/calendar`,
				text: t("calendar"), //"Kalender",
				hidden: !hasRight("viewCalendar") || orgType === organizationTypes.PUBLISHER,
				active: pathname.includes("/calendar"),
			},
			{
				to: `/${orgType}/${orgId}/articles`,
				text: t("articles", "Articles"),
				hidden: !hasRight("articles.view") || !hasFlatteningFeature || orgType !== organizationTypes.PUBLISHER, // TODO: Remove feature flag check
				active: pathname.startsWith("/articles"),
			},
			{
				to: "/reader",
				text: t("narrators", "Narrators"),
				hidden: !hasRight("readerRegistry.view"),
				active: pathname.startsWith("/reader"),
			},
		],
		[hasFlatteningFeature, hasRight, orgId, orgType, pathname, t],
	);
}
