import { useTranslation } from "react-i18next";

import { DistributionChannel } from "astrid-firestore/src/api/distribution/types/DistributionChannel";

import api from "../../../../../api";
import AdminForm from "../../../../admin/components/AdminForm/AdminForm";
import ArticleTypeSelect from "../../../../articles/components/ArticleTypeSelect/ArticleTypeSelect";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

export default function SuperAdminDistributionChannelForm({ channel }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		schema: DistributionChannel,
		defaultValues: {
			ref: channel.ref,
			name: channel.name || "",
			active: channel.active || false,
			acceptedLanguages: channel.acceptedLanguages || [],
			acceptedArticleTypes: channel.acceptedArticleTypes || [],
			distributionPipelineId: channel.distributionPipelineId || null,
		},
		onSubmit: (data) => {
			return api.distributionChannels.update(data);
		},
	});

	return (
		<AdminForm header={t("settings", "Settings")} {...form}>
			<Form.Group widths="equal">
				<Form.Input name="name" label={t("name", "Name")} />

				<Form.Select
					name="distributionPipelineId"
					label={t("distributionPipeline", "Distribution pipeline")}
					options={Object.values(channel.pipelines || {})?.map((pipeline) => ({
						key: pipeline.id,
						value: pipeline.id,
						text: pipeline.name,
					}))}
					allowEmpty
				/>
			</Form.Group>

			<Form.Group widths="equal">
				<Form.LanguageSelect
					name="acceptedLanguages"
					label={t("acceptedLanguages", "Accepted languages")}
					placeholder={t("allLanguages", "All languages")}
					multiple
				/>

				<Form.Field
					name="acceptedArticleTypes"
					label={t("acceptedArticles", "Accepted articles")}
					placeholder={t("allArticles", "All articles")}
					multiple
				>
					<ArticleTypeSelect />
				</Form.Field>
			</Form.Group>
			<Form.Checkbox slider name="active" label={t("active", "Active")} labelFalse={t("inactive", "Inactive")} />
		</AdminForm>
	);
}
