import useEvent from "react-use/lib/useEvent";

const map = {
	down: {
		touch: "touchstart",
		mouse: "mousedown",
	},
	move: {
		touch: "touchmove",
		mouse: "mousemove",
	},
	up: {
		touch: "touchend",
		mouse: "mouseup",
	},
};

export default function useMouseTouch(type = "down", callback, ...rest) {
	useEvent(map[type].touch, callback, ...rest);
	useEvent(map[type].mouse, callback, ...rest);
}
