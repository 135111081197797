import { useTranslation } from "react-i18next";

import { dropdownMenuColumn } from "../../ui/utils/columns";

import useDistributionArgsDropdownOptions from "./useDistributionArgsDropdownOptions";

export default function useDistributionArgsTableColumns({ publisher, setOpenEdit }) {
	const { t } = useTranslation();

	return [
		{
			id: "channel",
			Header: t("channel", "Channel"),
			accessor: ({ channel }) => {
				return channel;
			},
		},
		{
			id: "action",
			Header: t("action", "Action"),
		},
		{
			id: "args",
			Header: t("args", "Args"),
			accessor: ({ args }) => {
				let obfuscatedArgs = { ...args };
				if (args.PASSWORD) {
					obfuscatedArgs.PASSWORD = "********";
				}
				return JSON.stringify(obfuscatedArgs);
			},
		},
		dropdownMenuColumn(useDistributionArgsDropdownOptions({ publisher, setOpenEdit })),
	];
}
