import { Icon } from "semantic-ui-react";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import ProgressBar from "../../../components/progressbar/ProgressBar";

import ToggleSwitchIndex from "../../listproductions/views/ListProductionsView/components/ToggleSwitchIndex";

import { columns } from "../constants";

function shouldHideDeliveryDate({ profile, organization }) {
	switch (organization.type) {
		default:
			return true;
		case "narrator":
			return true;
		case "producer":
			return !profile?.permissions?.producer?.[organization.id]?.includes?.("producerAdmin", "producerStaff");
		case "publisher":
			return false;
	}
}

export default function useMyProductionColumns({ profile, organization, selectedColumns, isAlgolia }) {
	const { t } = useTranslation(["common", "language"]);
	const hideDeliveryDate = shouldHideDeliveryDate({ profile, organization });

	return useMemo(
		() =>
			[
				{
					id: "title",
					Header: t("title"),
					filter: "text",
					Cell: isAlgolia
						? ({ productionId, title }) => {
								return <Link to={`/production/${productionId}`}>{title}</Link>;
						  }
						: ({ value }) => value || "",
				},
				{
					id: "status",
					filter: "select",
					Header: t("status"),
					accessor: ({ status }) => t(`${status}`),
				},
				selectedColumns[columns.series] && {
					id: "series",
					filter: "text",
					Header: t("seriesPart", "Series (part)"),
				},
				selectedColumns[columns.author] && {
					id: "author",
					filter: "text",
					Header: t("author"),
				},
				(organization.type === organizationTypes.PRODUCER ||
					organization.type === organizationTypes.NARRATOR) &&
					selectedColumns["publisher"] && {
						id: "publisher",
						Header: t("publisher"),
					},
				(organization.type === "publisher" || organization.type === "narrator") &&
					selectedColumns["producer"] && {
						id: "producer",
						Header: t("prodComp"),
					},
				selectedColumns[columns.publisherManagerNames] && {
					id: "publisherManagerNames",
					Header: t("publisherManager"),
				},
				selectedColumns[columns.progressBar] && {
					id: "progressBar",
					Header: t("progress"),
					Cell: ({ value: progressBar }) => {
						return <ProgressBar {...progressBar} />;
					},
				},
				selectedColumns[columns.noOfCorrections] && {
					id: "noOfCorrections",
					Header: t("noOfCorrections"),
					accessor: ({ progressText }) => {
						return progressText;
					},
				},
				selectedColumns[columns.productionType] && {
					id: "productionType",
					filter: "select",
					Header: t("productionTypeNoSemi"),
					accessor: ({ productionType }) => {
						return t(productionType);
					},
				},
				selectedColumns[columns.productionTags] && {
					id: "productionTags",
					filter: "select",
					Header: t("productionTags"),
				},
				selectedColumns[columns.isbn] && {
					id: "isbn",
					filter: "text",
					Header: t("isbn"),
				},
				selectedColumns[columns.language] && {
					id: "language",
					filter: "select",
					Header: t("language"),
					accessor: ({ language }) => t(`language:${language}`),
				},
				selectedColumns[columns.readerNames] && {
					id: "readerNames",
					filter: "text",
					Header: t("narrator"),
				},
				selectedColumns[columns.editorNames] && {
					id: "editorNames",
					filter: "text",
					Header: t("editor"),
				},
				selectedColumns[columns.prooferNames] && {
					id: "prooferNames",
					filter: "text",
					Header: t("proofer"),
				},
				selectedColumns[columns.managerNames] && {
					id: "managerNames",
					filter: "text",
					Header: t("productionManager"),
				},
				selectedColumns[columns.subcontractorName] && {
					id: "subcontractorName",
					filter: "text",
					Header: t("subcontractor"),
				},
				selectedColumns[columns.duration] && {
					id: "duration",
					Header: t("duration"),
					accessor: ({ duration }) => {
						return duration?.time && duration?.isEstimated ? `${duration.time} *` : duration?.time;
					},
				},
				selectedColumns[columns.scriptDate] && {
					id: "scriptDate",
					Header: t("script"),
					Cell: ({ value: scriptDate }) => {
						return (
							<p style={{ display: "flex", alignItems: "baseline" }}>
								<span style={{ whiteSpace: "nowrap" }}>{scriptDate?.date}</span>
								{scriptDate?.date && scriptDate?.isUploaded && (
									<Icon style={{ marginLeft: "5px" }} name="check" color="green" />
								)}
							</p>
						);
					},
				},
				selectedColumns[columns.doneDate] && {
					id: "doneDate",
					Header: t("doneDate"),
				},
				selectedColumns[columns.created] && {
					id: "created",
					Header: t("created"),
				},
				selectedColumns[columns.updated] && {
					id: "updated",
					Header: t("updated"),
				},
				!hideDeliveryDate && {
					id: "deliveryDate",
					Header: t("deliveryDate"),
					CustomHeader: isAlgolia && {
						icon: (
							<ToggleSwitchIndex
								items={[
									{
										value: "deliveryDate_asc",
										iconName: "sort up",
										refineTo: "deliveryDate_desc",
									},
									{
										value: "deliveryDate_desc",
										iconName: "sort down",
										refineTo: "deliveryDate_asc",
									},
								]}
								fallback={{
									value: "productions",
									iconName: "sort",
									refineTo: "deliveryDate_asc",
								}}
							/>
						),
					},
				},
				(organization.type === organizationTypes.PRODUCER ||
					organization.type === organizationTypes.NARRATOR) && {
					id: "productionDate",
					Header: t("prodDate"),
					CustomHeader: isAlgolia && {
						icon: (
							<ToggleSwitchIndex
								items={[
									{
										value: "productions",
										iconName: "sort up",
										refineTo: "productionDate_desc",
									},
									{
										value: "productionDate_desc",
										iconName: "sort down",
										refineTo: "productions",
									},
								]}
								fallback={{
									value: "productions",
									iconName: "sort",
									refineTo: "productionDate_desc",
								}}
							/>
						),
					},
				},
			].filter((v) => v),
		[t, selectedColumns, organization.type, hideDeliveryDate, isAlgolia],
	);
}
