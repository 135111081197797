import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import productionStatuses from "astrid-firestore/src/api/productions/constants/productionStatuses";

export default function useProductionStatusTranslations() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			[undefined]: t("undefined", "Undefined"),
			[productionStatuses.DRAFT]: t("draft"),
			[productionStatuses.OFFER]: t("offer"),
			[productionStatuses.PLANNING]: t("planning"),
			[productionStatuses.ACCEPTED]: t("accepted"),
			[productionStatuses.PRODUCTION]: t("production"),
			[productionStatuses.DONE]: t("done"),
		}),
		[t],
	);
}
