import { useTranslation } from "react-i18next";

import ArticlesCollectionDataTable from "../../../articles/components/ArticlesCollectionDataTable/ArticlesCollectionDataTable";
import AddArticlesToTitleButton from "../../../titles/components/AddArticlesToTitleForm/AddArticlesToTitleButton";

import AdminCard from "../../components/AdminCard/AdminCard";

export default function AdminTitleArticlesView({ title }) {
	const { t } = useTranslation();

	return (
		<AdminCard header={t("articles", "Articles")} extra={<AddArticlesToTitleButton title={title} />}>
			<ArticlesCollectionDataTable title={title} showSync enableSync />
		</AdminCard>
	);
}
