import { ErrorMessage as ReactHookFormErrorMessage } from "@hookform/error-message";

import { Message } from "semantic-ui-react";

import { useFormState } from "react-hook-form";

export default function FormValidationMessage({ root, name, ...props }) {
	const { errors } = useFormState({ name });

	return (
		<ReactHookFormErrorMessage
			name={root ? `${name}.root` : root}
			errors={errors}
			render={({ message }) => (
				<Message negative {...props}>
					{message}
				</Message>
			)}
		/>
	);
}
