import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import linkedArticleTypes from "astrid-firestore/src/api/articles/constants/linkedArticleTypes";
import { Article } from "astrid-firestore/src/api/articles/types/Article";

import api from "../../../../../api";
import ArticleTags from "../../../../articles/components/ArticleTags/ArticleTags";
import ArticlesInstantSearchSelect from "../../../../articles/components/ArticlesInstantSearchSelect/ArticlesInstantSearchSelect";
import useArticleAbridgedOptions from "../../../../articles/hooks/useArticleAbridgedOptions";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";
import ThemaModal from "../../../../thema/components/ThemaModal";

import AdminForm from "../../../components/AdminForm/AdminForm";

export default function AdminArticleGeneralForm({ article, locked, ...props }) {
	const { publisher } = article;

	const { t } = useTranslation();

	const abridgedOptions = useArticleAbridgedOptions();
	const [imprintOptions] = api.imprints.useOptions({ publisher });

	const [openModal, setOpenModal] = useState(false);

	const form = useSimpleForm({
		schema: Article,
		values: {
			ref: article.ref,
			isbn: article.isbn || null,
			name: article.name || null,
			originalName: article.originalName || null,
			synopsis: article.synopsis || null,
			linkedArticle: article.linkedArticle || null,
			themaCodes: article.themaCodes || null,
			abridged: article.abridged || false,
			tags: article.tags || [],
		},
		onSubmit: (data) => {
			return api.articles.update(data);
		},
		...props,
	});

	const { watch, setValue } = form;

	const [themaCodes] = watch(["themaCodes"]);

	return (
		<AdminForm header={t("general", "General")} {...form}>
			<Form.Group widths="equal">
				<Form.Input name="isbn" label={t("isbn", "ISBN")} />

				<Form.Input name="name" label={t("name", "Name")} />

				<Form.Input name="originalName" label={t("originalName", "Original name")} />
			</Form.Group>

			<Form.Group widths="equal">
				<Form.Select
					multiple
					name="themaCodes"
					label={t("themaCodes", "Thema codes")}
					options={themaCodes?.map((code) => ({
						key: code,
						text: code,
						value: code,
					}))}
					allowEmpty
					onClick={() => !locked && setOpenModal(true)}
				/>

				<Form.Field name="tags">
					<ArticleTags article={article} />
				</Form.Field>
			</Form.Group>

			<Form.Group widths="equal">
				<Form.DocumentSelect
					name="imprint"
					label={t("imprint", "Imprint")}
					search
					allowEmpty
					allowAdditions
					options={imprintOptions}
					onAddDocument={(name) => {
						return api.imprints.createData({ name, publisher });
					}}
				/>

				<ArticlesInstantSearchSelect
					name="linkedArticle"
					label={t("linkedArticle", "Linked article")}
					fluid
					allowEmpty
					clearable
					publisher={publisher}
					type={linkedArticleTypes[article.type]}
				/>

				<Form.Select name="abridged" label={t("abridged", "Abridged")} options={abridgedOptions} />
			</Form.Group>

			<Form.TextArea name="synopsis" label={t("synopsis", "Description")} />

			{openModal && (
				<ThemaModal
					value={themaCodes}
					onClose={({ themaCodes }) => {
						setValue("themaCodes", themaCodes, { shouldDirty: true });
						setOpenModal(false);
					}}
				/>
			)}
		</AdminForm>
	);
}
