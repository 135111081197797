import { useTranslation } from "react-i18next";

import ProductionPriceForm from "../../../../productions/components/ProductionPriceForm/ProductionPriceForm";
import OpenButton from "../../../../ui/components/OpenButton/OpenButton";

export default function AddExpenseAction({ partyId, production, ...props }) {
	const { t } = useTranslation();

	return (
		<OpenButton text={t("addCost")} {...props}>
			{({ setOpen }) => {
				return (
					<ProductionPriceForm
						type="expense"
						production={production}
						firstPartyId={partyId}
						onClose={() => setOpen(false)}
					/>
				);
			}}
		</OpenButton>
	);
}
