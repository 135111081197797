export default function msToTime(ms, showMs = true, showSecs = true) {
	ms = Number(ms);
	let milliseconds = Math.floor(ms % 1000),
		seconds = Math.floor((ms / 1000) % 60),
		minutes = Math.floor((ms / (1000 * 60)) % 60),
		hours = Math.floor(ms / (1000 * 60 * 60));

	hours = hours < 10 ? "0" + hours : hours;
	minutes = minutes < 10 ? "0" + minutes : minutes;
	seconds = seconds < 10 ? "0" + seconds : seconds;
	milliseconds = milliseconds < 10 ? "00" + milliseconds : milliseconds < 100 ? "0" + milliseconds : milliseconds;

	return `${hours}:${minutes}${showSecs ? `:${seconds}` : ""}${showMs ? `.${milliseconds}` : ""}`;
}
