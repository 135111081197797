import countryList from "astrid-config/src/countries";

export default function displaySelectedCountries(countries) {
	const mapped = countries?.map((countryCode) => {
		if (countryCode === "WORLD") {
			return countryCode;
		}
		return countryList?.[countryCode];
	});
	if (mapped?.length >= 5) {
		return `${mapped.slice(0, 5).join(", ")}...`;
	}
	return mapped?.join(", ");
}
