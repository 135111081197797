import { useTranslation } from "react-i18next";

import CollectionDataTable from "../../../../ui/components/CollectionDataTable/CollectionDataTable";
import useUsersQuery from "../../../../users/hooks/useUsersQuery";

import AdminCard from "../../../components/AdminCard/AdminCard";

import useAdminPublisherUsersColumns from "../hooks/useAdminPublisherUsersColumns";

export default function AdminPublisherUsers({ publisher }) {
	const { t } = useTranslation();

	const usersQuery = useUsersQuery({ organization: publisher });
	const usersColumns = useAdminPublisherUsersColumns(publisher);

	return (
		<AdminCard header={t("publisherUsers", "Publisher users")}>
			<CollectionDataTable
				query={usersQuery}
				columns={usersColumns}
				sortBy={{
					id: "name",
					desc: false,
				}}
			/>
		</AdminCard>
	);
}
