import { useNavigate } from "react-router-dom";

import productionStatuses from "astrid-firestore/src/api/productions/constants/productionStatuses";

import InstantSearchDataTable from "../../../ui/components/InstantSearch/InstantSearchDataTable";

import useProductionsInstantSearchTableColumns from "../../hooks/useProductionsInstantSearchTableColumns";

export default function ProductionsInstantSearchDataTable({ producer, unfinalized, statuses, ...props }) {
	const navigate = useNavigate();

	const columns = useProductionsInstantSearchTableColumns();

	const filters = [
		producer && `producer:${producer.id}`,
		statuses && `(${statuses.map((status) => `status:${status}`).join(" OR ")})`,
		unfinalized && `openCostsSummary.total > 0 AND status:${productionStatuses.DONE}`,
	]
		.filter(Boolean)
		.join(" AND ");

	return (
		<InstantSearchDataTable
			indexName="productions"
			columns={columns}
			filters={filters}
			onClick={({ objectID }) => navigate(`/production/${objectID}`)}
			{...props}
		/>
	);
}
