import { useTranslation } from "react-i18next";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import CreateTitleButton from "../../../titles/components/CreateTitleForm/CreateTitleButton";
import TitlesInstantSearchDataTable from "../../../titles/components/TitlesInstantSearchDataTable/TitlesInstantSearchDataTable";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

export default function AdminTitlesView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("titles", "Titles")}>
				{searchInput}
				{organization && <CreateTitleButton organization={organization} />}
			</AdminHeader>

			<TitlesInstantSearchDataTable publisher={organization} searchQuery={searchQuery} />
		</>
	);
}
