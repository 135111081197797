import equal from "fast-deep-equal";

import { Button, Checkbox } from "semantic-ui-react";

import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useAsyncFn from "react-use/lib/useAsyncFn";

import updateDocument from "astrid-firestore/src/helpers/updateDocument";

import AdminCard from "../../../../admin/components/AdminCard/AdminCard";
import useOrganizationsOnce from "../../../../organizations/hooks/useOrganizationsOnce";
import Table from "../../../../ui/components/Table/Table";

import useProducersColumns from "../hooks/useProducersColumns";

export default function UserProducersAdmin({ user }) {
	const { t } = useTranslation();

	const producers = useOrganizationsOnce({ type: "producer" });
	const producersColumns = useProducersColumns();

	const [selectedRowIds, setSelectedRowIds] = useState(
		user.adminProducerIds?.reduce((map, id) => ({ ...map, [id]: true }), {}) || {},
	);
	const [someProducers, setSomeProducers] = useState(!!user.adminProducerIds);

	const adminProducerIds = useMemo(
		() => (someProducers ? Object.keys(selectedRowIds) : null),
		[selectedRowIds, someProducers],
	);

	const [{ loading }, onClickSave] = useAsyncFn(async () => {
		await updateDocument(user.ref, { adminProducerIds });
	}, [adminProducerIds, user.ref]);

	const disabled = useMemo(
		() => equal(user.adminProducerIds, adminProducerIds),
		[adminProducerIds, user.adminProducerIds],
	);

	if (producers.length === 0) {
		return false;
	}

	return (
		<AdminCard
			header={t("producerAdmin", "Producer admin")}
			extra={
				<Button primary loading={loading} disabled={disabled} onClick={onClickSave}>
					{t("save")}
				</Button>
			}
		>
			<Checkbox
				slider
				label={someProducers ? t("someProducers", "Some producers") : t("allProducers", "All producers")}
				checked={someProducers}
				onChange={(e, { checked }) => setSomeProducers(checked)}
			/>

			{someProducers && (
				<Table
					style={{ marginTop: 10 }}
					sortable
					selectable
					filterable
					pagination
					data={producers}
					columns={producersColumns}
					onSelectedRowIds={setSelectedRowIds}
					initialState={{ pageSize: 5, selectedRowIds }}
					sortBy={{
						id: "name",
						desc: false,
					}}
				/>
			)}
		</AdminCard>
	);
}
