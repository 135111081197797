import map from "./map";

export function mapLinearLog(value, low1, high1, low2, high2) {
	return Math.exp(map(value, low1, high1, Math.log(low2), Math.log(high2)));
}

export function mapLogLinear(value, low1, high1, low2, high2) {
	return map(Math.log(value), Math.log(low1), Math.log(high1), low2, high2);
}

// export default function mapLog(position, min, max) {
// 	var maxp = 100;

// 	// The result should be between 100 an 10000000
// 	var minv = Math.log(100);
// 	var maxv = Math.log(10000000);

// 	// calculate adjustment factor
// 	var scale = (maxv - minv) / (maxp);

// 	return Math.exp(minv + scale * (position));
// }
