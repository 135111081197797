import { useEffect } from "react";

import {
	useCollectionCount,
	useCollectionData,
	useCollectionDataOnce,
	useDocumentData,
	useDocumentDataOnce,
} from "../../hooks";
import useCollectionDataByIdsOnce from "../../hooks/useCollectionDataByIdsOnce";
import useCollectionDataOptions from "../../hooks/useCollectionDataOptions";

const firestoreHooksPlugin = {
	useByRef(ref, hook = useDocumentData) {
		return this.getByRef(ref, hook);
	},

	useByRefOnce(ref) {
		return this.useByRef(ref, useDocumentDataOnce);
	},

	useById(id) {
		return this.useByRef(this.getRef(id));
	},

	useByIdOnce(id) {
		return this.useByRefOnce(this.getRef(id));
	},

	useAll(query) {
		return this.getAll(query, useCollectionData);
	},

	useAllOnce(query) {
		return this.getAll(query, useCollectionDataOnce);
	},

	useAllByIds(ids) {
		return this.getAllByIds(ids, useCollectionDataByIdsOnce);
	},

	useOptions(query, options) {
		return this.getAll(query, useCollectionDataOptions, options);
	},

	useCount(query) {
		const [data, loading, error, call] = this.getAll(query, useCollectionCount);

		useEffect(() => {
			this.events.on("set", call);

			return () => this.events.off("set", call);
		}, [call]);

		return [data, loading, error];
	},
};

export default firestoreHooksPlugin;
