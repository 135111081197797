import { useEffect, useRef } from "react";

import useFileInput from "./useFileInput";

export default function useFormFileUpload({ onChange }) {
	const onChangeRef = useRef(onChange);

	const { files, input, onClick } = useFileInput({
		multiple: true,
	});

	useEffect(() => {
		if (files.length > 0) {
			onChangeRef.current?.(files);

			return () => onChangeRef.current?.([]);
		}
	}, [files]);

	return { files, input, onClick };
}
