import uniqid from "uniqid";

import FirestoreCollection from "../../firestore/FirestoreCollection";

import { Artifact } from "./types/Artifact";
import { ArtifactRef } from "./types/ArtifactRef";

export default class ArtifactsCollection extends FirestoreCollection {
	static collectionName = "artifacts";
	static Schema = Artifact;

	preProcess(data) {
		// If we have files, we want to calculate the total size
		if (data.files) {
			data.size = data.files.reduce((sum, file) => sum + (file.size || 0), 0);
		}

		return data;
	}

	sideEffects({ update, transaction }) {
		const { before, after } = update;

		const article = update.get("article");

		if (article.isUpdated()) {
			if (before.isDefault && before.article) {
				transaction.update(before.article.ref, { artifact: null });
			}

			if (after.isDefault && after.article) {
				transaction.update(after.article.ref, { artifact: ArtifactRef.parse(after) });
			}
		}
	}

	addMarker({ artifact, type, position, text }) {
		return this.update(
			{
				ref: artifact.ref,
				markers: this.utils.arrayUnion({
					id: uniqid(),
					type,
					position,
					text,
				}),
			},
			{ validate: false },
		);
	}
}
