import React from "react";
import { useTranslation } from "react-i18next";

import { Article } from "astrid-firestore/src/api/articles/types/Article";

import api from "../../../../../api";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";
import useNumberOptions from "../../../../ui/hooks/useNumberOptions";

import AdminForm from "../../../components/AdminForm/AdminForm";

export default function AdminArticleSeriesForm({ article, publisher, locked, ...props }) {
	const { t } = useTranslation();

	const [seriesOptions] = api.series.useOptions({ publisher });

	const numberOptions = useNumberOptions();

	const form = useSimpleForm({
		schema: Article,
		values: {
			ref: article.ref || null,
			serie: article.serie || null,
			serieNumber: article.serieNumber || null,
			episodeName: article.episodeName || null,
		},
		onSubmit: (data) => {
			return api.articles.update(data);
		},
		locked,
		...props,
	});
	return (
		<AdminForm header={t("serie", "Series")} {...form}>
			<Form.Group widths="equal">
				<Form.DocumentSelect
					name="serie"
					label={t("serie", "Series")}
					search
					allowEmpty
					allowAdditions
					options={seriesOptions}
					onAddDocument={(name) => {
						return api.series.createData({ name });
					}}
				/>

				<Form.Select
					name="serieNumber"
					search
					label={t("seriesPart", "Series (part)")}
					allowEmpty
					options={numberOptions}
				/>

				<Form.Input name="episodeName" label={t("episodeName", "Episode name")} />
			</Form.Group>
		</AdminForm>
	);
}
