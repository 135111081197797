import { useState } from "react";

import Button from "../Buttons/Button";

export default function AsyncButton({ loading, disabled, onClick, ...props }) {
	const [internalLoading, setLoading] = useState(false);

	return (
		<Button
			loading={loading || internalLoading}
			disabled={loading || disabled}
			onClick={async (e) => {
				setLoading(true);
				await onClick(e);
				setLoading(false);
			}}
			{...props}
		/>
	);
}
