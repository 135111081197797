import { useTranslation } from "react-i18next";

import productionStatuses from "astrid-firestore/src/api/productions/constants/productionStatuses";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import ProductionsInstantSearchDataTable from "../../../productions/components/ProductionsInstantSearchDataTable/ProductionsInstantSearchDataTable";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

export default function AdminProductionsCompletedView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("completedPlural", "Completed")}>{searchInput}</AdminHeader>

			<ProductionsInstantSearchDataTable
				producer={organization}
				searchQuery={searchQuery}
				statuses={[productionStatuses.DONE]}
				visibleColumns={[
					"title",
					"productionType",
					"publisherName",
					"productionDate",
					"created",
					"doneDate",
					"menu",
				]}
			/>
		</>
	);
}
