import FirestoreCollection from "../../firestore/FirestoreCollection";

import { Script } from "./types/Script";

export default class ScriptsCollection extends FirestoreCollection {
	static collectionName = "scripts";
	static Schema = Script;

	async sideEffects({ update, transaction }) {
		const { after } = update;

		const production = update.get("production");

		if (production.isUpdated()) {
			await api.productions.addScript(
				{
					script: after,
					production: production.after,
				},
				{ transaction },
			);
		}
	}
}
