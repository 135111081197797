import { Form } from "semantic-ui-react";

import useFormField from "../../hooks/useFormField";

export default function FormCheckboxButton({ color = "blue", ...props }) {
	const { field } = useFormField(props);

	const { value, ...restField } = field;

	return (
		<Form.Button
			{...restField}
			color={value ? color : undefined}
			ref={undefined}
			onClick={() => field.onChange(!value)}
		/>
	);
}
