import { useNavigate } from "react-router-dom";

import articleStatuses from "astrid-firestore/src/api/articles/constants/articleStatuses";

import InstantSearchDataTable from "../../../ui/components/InstantSearch/InstantSearchDataTable";

import useArticlesTableColumns from "../../hooks/useArticlesTableColumns";

import ArticlesTableFooter from "../ArticlesTableFooter/ArticlesTableFooter";

export default function ArticlesInstantSearchDataTable({
	publisher,
	enableSync,
	deliveryStatuses,
	drafts,
	showDistributeButton,
	showArticleBundleButton,
	...props
}) {
	const navigate = useNavigate();

	const columns = useArticlesTableColumns({ enableSync });

	const filters = [
		publisher && `publisher.id:${publisher.id}`,
		deliveryStatuses && `(${deliveryStatuses?.map((status) => `delivery.metadata.status:${status}`).join(" OR ")})`,
		`status:${drafts ? articleStatuses.DRAFT : articleStatuses.READY}`,
	]
		.filter(Boolean)
		.join(" AND ");

	return (
		<InstantSearchDataTable
			indexName="articles"
			columns={columns}
			filters={filters}
			onClick={({ id, publisher }) => navigate(`/publisher/${publisher.id}/admin/articles/${id}`)}
			footer={
				<ArticlesTableFooter
					publisher={publisher}
					showDistributeButton={showDistributeButton}
					showArticleBundleButton={showArticleBundleButton}
				/>
			}
			{...props}
		/>
	);
}
