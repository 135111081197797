import { useTranslation } from "react-i18next";

import { timestampColumn } from "../../ui/utils/columns";

import useProductionStatusTranslations from "./useProductionStatusTranslations";
import useProductionTypeTranslations from "./useProductionTypeTranslations";

export default function useProductionsTableColumns() {
	const { t } = useTranslation();

	const productionTypeTranslations = useProductionTypeTranslations();
	const productionStatusTranslations = useProductionStatusTranslations();

	return [
		{
			id: "title",
			Header: t("Name", "Name"),
		},
		{
			id: "productionType",
			Header: t("type", "Type"),
			accessor: ({ productionType }) => productionTypeTranslations[productionType],
		},
		{
			id: "status",
			Header: t("status", "Status"),
			accessor: ({ status }) => productionStatusTranslations[status],
		},
		timestampColumn({
			id: "productionDate",
			Header: t("productionDate", "Production date"),
			time: false,
		}),

		timestampColumn({
			id: "doneDate",
			Header: t("doneDate", "Completion date"),
			time: false,
		}),
	];
}
