import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import CreateTitleForm from "./CreateTitleForm";

export default function CreateTitleButton({ organization }) {
	const { t } = useTranslation();

	const text = t("createTitle", "Create title");

	return (
		<OpenButton text={text}>
			{({ setOpen }) => (
				<CreateTitleForm title={text} organization={organization} onClose={() => setOpen(false)} />
			)}
		</OpenButton>
	);
}
