import Select from "../../../ui/components/Select/Select";

import useArticleTypeOptions from "../../hooks/useArticleTypeOptions";

export default function ArticleTypeSelect({ channel, ...props }) {
	const options = useArticleTypeOptions();

	const filteredOptions = channel?.acceptedArticleTypes?.length
		? options.filter((option) => channel.acceptedArticleTypes.includes(option.value))
		: options;

	return <Select options={filteredOptions} {...props} />;
}
