import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import CreateArticleBundleForm from "./CreateArticleBundleForm";

export default function CreateArticleBundleButton({ organization }) {
	const { t } = useTranslation();

	const text = t("createArticleBundle", "Create article bundle");

	return (
		<OpenButton text={text}>
			{({ setOpen }) => (
				<CreateArticleBundleForm title={text} organization={organization} onClose={() => setOpen(false)} />
			)}
		</OpenButton>
	);
}
