import minMax from "./minMax";
import downsample from "./downsample";
import samplesToMs from "./samplesToMs";

const minRes = 24;

function svg(width, height, shape) {
	return `<svg version="1.1" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">${shape}</svg>`;
}

function polygon({ height, start, source, volume, fadein, fadeout, resolution }) {
	const half = height / 2;
	const data = minMax(source, resolution, start);

	const over = [];
	const under = [];
	const { length } = data;

	let i, x, h, l, amp;

	for (i = 0; i < length; i += 1) {
		amp = i < fadein ? i / fadein : i > length - fadeout ? (length - i) / fadeout : 1;
		h = (1 - data[i][1] * amp * volume) * half;
		over.push(`${i},${Math.round(h * 100) / 100}`);

		x = length - i - 1;
		amp = x < fadein ? x / fadein : x > length - fadeout ? (length - x) / fadeout : 1;
		l = (1 + data[x][0] * amp * volume) * half;

		under.push(`${x},${Math.round(l * 100) / 100}`);
	}

	return svg(length, height, `<path fill="currentColor" d="M${over.join(" ")} ${under.join(" ")} z" />`);
}

function polyline({ width, height, source, volume, fadein, fadeout, resolution }) {
	const half = height / 2;
	const data = downsample(source, width);
	const { length } = data;

	const dots = [];
	const points = [];
	const mod = Math.max(1, 1 / resolution);

	let x, y, amp;

	for (x = 0; x < length; x += 1) {
		amp = x < fadein ? x / fadein : x > length - fadeout ? (length - x) / fadeout : 1;
		y = Math.round((1 - data[x] * amp * volume) * half * 100) / 100;

		points.push(`${x * mod},${y}`);

		if (mod >= 3) {
			points.push(`${(x + 1) * mod},${y}`);
		}
	}

	if (mod < 3) {
		points.push(`${(x + 1) * mod},${y}`);
	}

	return svg(
		mod > 1 ? width : length,
		height,
		`<path fill="transparent" stroke="currentColor" stroke-width="1" vector-effect="non-scaling-stroke" d="M${points.join(
			" ",
		)}" />${dots.join("")}`,
	);
}

function floorMsToSamples(ms, rate) {
	return Math.floor((ms * rate) / 1000);
}

function ceilMsToSamples(ms, rate) {
	return Math.ceil((ms * rate) / 1000);
}

export default function audioWave({
	data,
	start = 0,
	end = 0,
	width = 0,
	height = 260,
	volume = 1,
	fadein = 0,
	fadeout = 0,
	sampleRate = 44100,
}) {
	if (!data || width < 2 || start === undefined || end === undefined) return "";

	end = ceilMsToSamples(end, sampleRate);
	start = floorMsToSamples(start, sampleRate);

	let resolution;
	let shape;

	return {
		end: samplesToMs(end, sampleRate),
		start: samplesToMs(start, sampleRate),
		shapes: data.map((channel) => {
			const source = channel.subarray(start, end);

			if (!resolution) {
				resolution = source.length / width;
				shape = resolution >= minRes ? polygon : polyline;
				fadein = Math.ceil(floorMsToSamples(fadein, sampleRate) / resolution);
				fadeout = Math.ceil(ceilMsToSamples(fadeout, sampleRate) / resolution);
			}

			return { html: shape({ start, height, source, volume, width, fadein, fadeout, resolution }) };
		}),
	};
}
