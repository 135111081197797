import { useTranslation } from "react-i18next";

import ArticleBundlesCollectionDataTable from "../../../articles/components/ArticleBundlesCollectionDataTable/ArticleBundlesCollectionDataTable";

import AdminCard from "../../components/AdminCard/AdminCard";

export default function AdminArticleArticleBundlesView({ article }) {
	const { t } = useTranslation();

	return (
		<AdminCard header={t("bundles", "Bundles")}>
			<ArticleBundlesCollectionDataTable
				getQuery={(query) => query.where("articleIds", "array-contains", article.id)}
			/>
		</AdminCard>
	);
}
