import { useState } from "react";

import useForm from "./useForm";
import useFormSubmit from "./useFormSubmit";

export default function useSimpleForm({
	transform,
	onSubmit: _onSubmit,
	onSuccess,
	onError,
	onSubmitError,
	clearOnSuccess,
	...props
}) {
	const [validationError, setValidationError] = useState(null);

	const form = useForm({
		...props,
		onValidationError: (validationError) => {
			if (validationError) {
				console.error(validationError);
			}

			setValidationError(validationError);
		},
	});

	const [onSubmit, loading, error] = useFormSubmit(form, _onSubmit, {
		transform,
		onSuccess,
		onError,
		onSubmitError,
		clearOnSuccess,
	});

	return { form, ...form, loading, error, validationError, onSubmit };
}
