export default function downsample(data, threshold) {
	const { length } = data;

	if (threshold >= length || threshold === 0) {
		return data;
	}

	const sampled = [];
	const every = (length - 2) / (threshold - 2);

	let i = 0;
	let a = 0;
	let sampledIndex = 0;

	let area;
	let nextA;
	let maxArea;
	let maxAreaPoint;

	sampled[sampledIndex++] = data[a];

	for (i = 0; i < threshold - 2; i++) {
		let avgX = 0;
		let avgY = 0;
		let avgRangeStart = Math.floor((i + 1) * every) + 1;
		let avgRangeEnd = Math.floor((i + 2) * every) + 1;

		avgRangeEnd = avgRangeEnd < length ? avgRangeEnd : length;

		const avgRangeLength = avgRangeEnd - avgRangeStart;

		for (; avgRangeStart < avgRangeEnd; avgRangeStart++) {
			avgX += avgRangeStart;
			avgY += data[avgRangeStart];
		}

		avgX /= avgRangeLength;
		avgY /= avgRangeLength;

		let rangeOffs = Math.floor((i + 0) * every) + 1;
		const rangeTo = Math.floor((i + 1) * every) + 1;

		const pointAX = a;
		const pointAY = data[a];

		maxArea = area = -1;

		for (; rangeOffs < rangeTo; rangeOffs++) {
			area =
				Math.abs((pointAX - avgX) * (data[rangeOffs] - pointAY) - (pointAX - rangeOffs) * (avgY - pointAY)) *
				0.5;

			if (area > maxArea) {
				maxArea = area;
				maxAreaPoint = data[rangeOffs];
				nextA = rangeOffs;
			}
		}

		sampled[sampledIndex++] = maxAreaPoint;
		a = nextA;
	}

	sampled[sampledIndex++] = data[length - 1];

	return sampled;
}
