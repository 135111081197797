import { Form } from "semantic-ui-react";

import React from "react";
import { PatternFormat } from "react-number-format";

import useFormField from "../../hooks/useFormField";

export default function FormDurationInput(props) {
	const { locked, field } = useFormField(props);

	const value = field.value || "000:00:00";

	return (
		<Form.Input {...field} ref={undefined}>
			<PatternFormat
				value={value}
				onValueChange={({ formattedValue }) => {
					field.onChange(null, {
						value: formattedValue.replaceAll("_", "0"),
					});
				}}
				format={locked ? value : "###:##:##"}
				allowEmptyFormatting
				mask="_"
			/>
		</Form.Input>
	);
}
