import React, { useCallback } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import map from "astrid-helpers/src/map";
import useMouse from "astrid-hooks/src/useMouse";
import useMouseTouch from "astrid-hooks/src/useMouseTouch";

export default function ArtifactAudioPlayerTimelineTool({ activeTool: ActiveTool, setActiveTool, ...props }) {
	const size = Timeline.useSize();
	const rect = Timeline.useRect();
	const offset = Timeline.useOffset();

	const { x, y } = useMouse(rect);

	const onMouseUp = useCallback(() => {
		console.log("onDone");
		setActiveTool(() => null);
	}, [setActiveTool]);

	useMouseTouch("up", onMouseUp, window, true);

	return <ActiveTool y={y / rect.height} x={offset + map(x, 0, rect.width, 0, size)} {...props} />;
}
