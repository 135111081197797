import React from "react";
import { useTranslation } from "react-i18next";

import PipelineStatusLabel from "../../../../distribution/components/DistributionStatus/PipelineStatusLabel";
import { dropdownMenuColumn, timestampColumn } from "../../../../ui/utils/columns";

import useExportDropdownMenuOptions from "./useExportDropdownMenuOptions";

export default function useExportsTableColumns() {
	const { t } = useTranslation();

	return [
		{
			id: "status",
			Header: t("status", "Status"),
			collapsing: true,
			Cell: ({ row }) => {
				return <PipelineStatusLabel distribution={row.original} />;
			},
		},
		{
			id: "id",
			Header: t("id", "ID"),
		},
		timestampColumn({ id: "created", Header: t("created", "Created") }),
		timestampColumn({ id: "expires", Header: t("expires", "Expires") }),
		dropdownMenuColumn(useExportDropdownMenuOptions()),
	];
}
