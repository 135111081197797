import { Route } from "react-router-dom";

import AdminDistributionPipelines from "../../pages/AdminDistribution/AdminDistributionPipelines";
import AdminExport from "../../pages/AdminExport/AdminExport";
import AdminExportPipeline from "../../pages/AdminExport/AdminExportPipeline";

const adminExportRoute = (
	<Route path=":export" element={<AdminExport />} handle={{ crumb: ({ export: theExport }) => theExport?.id }}>
		<Route index element={<AdminExportPipeline />} />

		<Route
			path="pipelines"
			handle={{ crumb: ({ t }) => t("pipelines", "Pipelines") }}
			element={<AdminDistributionPipelines />}
		/>
	</Route>
);

export default adminExportRoute;
