import { Form } from "semantic-ui-react";

export default function FormGroup({ label, children, ...props }) {
	return (
		<div>
			{label && (
				<div className="field" style={{ marginBottom: 0 }}>
					<label>{label}</label>
				</div>
			)}

			<Form.Group {...props}>{children}</Form.Group>
		</div>
	);
}
