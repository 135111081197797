import FirestoreCollection from "../../firestore/FirestoreCollection";
import { addDocument } from "../../helpers";

import { Studio } from "./types/Studio";

export default class StudiosCollection extends FirestoreCollection {
	static collectionName = "studios";
	static Schema = Studio;

	add({ id, gen, organization, name }) {
		switch (gen) {
			default:
				throw new Error("Invalid generation");

			case 1:
				return addDocument(organization.ref.collection("studios"), { name });

			case 2:
				return this.create({ id, gen, name, producerId: organization.id });
		}
	}
}
