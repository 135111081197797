import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import CreateOrganizationForm from "./CreateOrganizationForm";
import useActionTitle from "./hooks/useActionTitle";

export default function CreateOrganizationButton({ type, ...props }) {
	const title = useActionTitle(type);

	return (
		<OpenButton text={title} {...props}>
			{({ setOpen }) => <CreateOrganizationForm type={type} onClose={() => setOpen(false)} />}
		</OpenButton>
	);
}
