import { Table as SemanticTable } from "semantic-ui-react";

import { useEffect } from "react";

import TableBody from "./components/TableBody";
import TableFooter from "./components/TableFooter";
import TableHeader from "./components/TableHeader";
import TablePagination from "./components/TablePagination";
import useMappedColumns from "./hooks/useMappedColumns";
import usePersist from "./hooks/usePersist";
import usePlugins from "./hooks/usePlugins";
import useTable from "./hooks/useTable";

export default function Table({
	data,
	footer,
	sortBy,
	filters,
	columns = [],
	actions,
	header = true,
	persist = false,
	overflow = false,
	sortable = false,
	removable = false,
	filterable = false,
	selectable = false,
	autoResetSelectedRows = false,
	pagination = false,
	hoverRow = false,
	expandable,
	children,
	getSubRows,
	initialState,
	onSelectRows,
	onRemoveRow,
	hiddenColumns,
	pageSize,
	onClickRow,
	onSelectedRowIds,
	defaultSelectedRows = [],
	getRowId = (row, index) => index,
	...props
}) {
	const defaultSelectedRowIds = defaultSelectedRows.reduce((acc, row) => {
		const rowId = getRowId(row);
		if (rowId !== undefined) {
			acc[rowId] = true;
		}
		return acc;
	}, {});

	const table = useTable({
		data,
		sortBy,
		filters,
		initialState,
		hiddenColumns,
		pageSize,
		getSubRows,
		autoResetSelectedRows,
		defaultSelectedRowIds,
		getRowId,
		columns: useMappedColumns(columns),
		plugins: usePlugins({
			sortable,
			removable,
			filterable,
			selectable,
			pagination,
			expandable,
			onRemoveRow,
		}),
	});

	const { pageCount, getTableProps, selectedFlatRows, state } = table;

	usePersist({ table, persist });

	useEffect(() => {
		if (selectable && onSelectRows) {
			onSelectRows(selectedFlatRows.map(({ original }) => original));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFlatRows?.length]);

	useEffect(() => {
		if (selectable && onSelectedRowIds) {
			onSelectedRowIds(state.selectedRowIds);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.selectedRowIds]);

	const showFooter = footer !== false || selectable;

	return (
		<div style={{ overflowX: overflow && "auto" }}>
			<SemanticTable padded selectable={hoverRow || onClickRow} {...getTableProps()} {...props}>
				{header && <TableHeader table={table} />}

				<TableBody table={table} pagination={pagination} onClickRow={onClickRow}>
					{children}
				</TableBody>

				{showFooter && (
					<TableFooter table={table} selectable={selectable}>
						{footer}
					</TableFooter>
				)}

				{pagination && pageCount > 1 && <TablePagination table={table} />}
			</SemanticTable>
		</div>
	);
}

Table.Row = SemanticTable.Row;
Table.Cell = SemanticTable.Cell;
