import { Button, Divider, Dropdown, Icon } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import stopPropagation from "astrid-helpers/src/stopPropagation";

import Form from "../../../../forms/components/Form/Form";

export default function CalendarStatusFiltersDropdown({ onClick, openDropdown, onReset, onClear, options, active }) {
	const { t } = useTranslation();

	return (
		<Dropdown
			icon={false}
			floating
			labeled
			trigger={
				<Button
					basic
					size="tiny"
					onClick={onClick}
					primary={active}
					style={{ paddingLeft: 16, paddingRight: 16 }}
				>
					<Icon name="filter" />
					{t("statusFilters", "Status filters")}
				</Button>
			}
			open={openDropdown}
		>
			<Dropdown.Menu onClick={stopPropagation} style={{ fontWeight: 400, padding: 10 }}>
				<Button.Group basic fluid size="tiny">
					<Button onClick={onReset}>{t("reset", "Reset")}</Button>
					<Button onClick={onClear}>{t("clear", "Clear")}</Button>
				</Button.Group>
				<Divider hidden />

				{options.map(({ name, label }) => (
					<Form.Checkbox key={name} label={label} name={name} shouldUnregister={false} />
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
}
