import React from "react";

import Dashboard from "../../../dashboard/components/Dashboard/Dashboard";

import useSuperAdminDashboardStatistics from "../../hooks/useSuperAdminDashboardStatistics";

export default function SuperAdminDashboardView() {
	const statistics = useSuperAdminDashboardStatistics();

	return <Dashboard statistics={statistics} />;
}
