import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { firebase } from "astrid-firebase";
import createOrganization from "astrid-firestore/src/api/organizations/createOrganization";

import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import { useProfile } from "../../../authentication/state/profile";
import Form from "../../../forms/components/Form/Form";

import useOrganizationForm from "./hooks/useOrganizationForm";

export default function CreateOrganizationForm({ type, onClose }) {
	const { t } = useTranslation();

	const user = useProfile();
	const navigate = useNavigate();
	const form = useOrganizationForm();

	const { formState, handleSubmit } = form;

	const headers = {
		producer: t("createProducer", "Create producer"),
		publisher: t("createPublisher", "Create publisher"),
	};

	const onSubmit = async ({ name }) => {
		try {
			const { id } = await createOrganization(firebase, { type, name, user });

			navigate(`../${id}`);
		} catch (error) {
			if (error?.message === "name unavailable") {
				throw new Error(t("nameUnavailable", "Name unavailable"));
			}

			throw error;
		}
	};

	return (
		<ModalPrompt
			size="tiny"
			header={headers[type]}
			disabled={!formState.isValid}
			onSave={handleSubmit(onSubmit)}
			onClose={onClose}
		>
			<Form form={form}>
				<Form.Input name="name" label={t("name")} />
			</Form>
		</ModalPrompt>
	);
}
