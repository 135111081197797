import { useOutletContext } from "react-router-dom";

import AdminDistributionPipelineView from "../../views/AdminDistributionView/AdminDistributionPipelineView";

export default function AdminDistributionPipeline() {
	const { distribution } = useOutletContext();

	if (!distribution.pipeline) {
		return null;
	}

	return <AdminDistributionPipelineView distribution={distribution} />;
}
