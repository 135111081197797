const audioBitrates = {
	"320K": 320,
	"256K": 256,
	"192K": 192,
	"160K": 160,
	"128K": 128,
	"96K": 96,
};

export default audioBitrates;
