import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import countryList from "astrid-config/src/countries";

import DataTable from "../../../ui/components/DataTable/DataTable";

export const WORLD_OPTION = "WORLD";

const countryOptions = [
	{ code: WORLD_OPTION, name: WORLD_OPTION },
	...Object.entries(countryList).map(([code, name]) => ({ code, name })),
];

function mapCountries(countries) {
	return countries?.map((code) => {
		if (code === WORLD_OPTION) {
			return { code: WORLD_OPTION, name: WORLD_OPTION };
		}
		return { name: countryList?.[code], code };
	});
}

export default function CountriesIncludedForm({ name }) {
	const { t } = useTranslation();

	const { setValue, watch } = useFormContext();

	const countries = watch(name);

	return (
		<div style={{ maxHeight: "500px", overflowY: "scroll" }}>
			<DataTable
				filterable
				selectable
				sortable={false}
				searchableKeys={["name"]}
				data={countryOptions}
				onSelectedRowIds={(selectedRowIds) => {
					setValue(name, Object.keys(selectedRowIds), { shouldDirty: true });
				}}
				pagination={false}
				defaultSelectedRows={mapCountries(countries)}
				getRowId={(row) => row.code}
				columns={[
					{
						id: "name",
						Header: t("country", "Country"),
						filter: "text",
					},
				]}
			/>
		</div>
	);
}
