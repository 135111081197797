import mapDocument from "../utils/mapDocument";

export default function runTransaction(db, refs = [], callback) {
	return db.runTransaction(async (transaction) => {
		const documents = await Promise.all(refs.map((ref) => ref && transaction.get(ref?.ref || ref)));

		for (const doc of documents) {
			if (doc && !doc.exists) {
				throw new Error(`Document ${doc.ref.path} not found`);
			}
		}

		return await callback(
			transaction,
			documents.map((doc) => doc && mapDocument(doc)),
		);
	});
}
