import { useOutletContext, useParams } from "react-router-dom";

import db from "astrid-firebase/src/db";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";
import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import AdminPageView from "../components/AdminPage/AdminPageView";
import AdminUserView from "../views/AdminUserView/AdminUserView";

export default function AdminUser() {
	const { id } = useParams();
	const { organization } = useOutletContext();

	const [user, loading, error] = useDocumentData(id && db.collection("users").doc(id));

	const hasRight = useHasRight();

	if (!hasRight("users.view")) {
		return <Unauthorized />;
	}

	return (
		<LoadingContext data={user} loading={loading} error={error}>
			{user && (
				<AdminPageView user={user} organization={organization}>
					<AdminUserView />
				</AdminPageView>
			)}
		</LoadingContext>
	);
}
