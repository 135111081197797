import { useNavigate } from "react-router-dom";

import api from "../../../../api";
import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useArtifactsTableColumns from "../../hooks/useArtifactsTableColumns";

export default function ArtifactsCollectionDataTable({ article, production, ...props }) {
	const navigate = useNavigate();

	const columns = useArtifactsTableColumns({ article });

	return (
		<CollectionDataTable
			query={api.artifacts.getQuery({ article, production })}
			columns={columns}
			onClick={({ id, article, publisher }) =>
				navigate(`/publisher/${publisher.id}/admin/articles/${article?.id}/artifacts/${id}`)
			}
			sortBy={{
				id: "created",
				desc: true,
			}}
			{...props}
		/>
	);
}
