import { useTranslation } from "react-i18next";

import { dropdownMenuColumn, timestampColumn } from "../../ui/utils/columns";

import useScriptDropdownMenuOptions from "./useScriptDropdownMenuOptions";

export default function useScriptsTableColumns({ production } = {}) {
	const { t } = useTranslation();

	return [
		{
			id: "name",
			Header: t("Name", "Name"),
		},
		{
			id: "pages",
			Header: t("pages", "Pages"),
			collapsing: true,
			accessor: ({ pages }) => (pages ? pages.end - pages.start + 1 : null),
		},
		{
			id: "file",
			Header: t("contentType", "Content-Type"),
			collapsing: true,
			accessor: ({ file }) => file?.contentType || null,
		},
		timestampColumn({ id: "created", Header: t("created", "Created") }),
		dropdownMenuColumn(useScriptDropdownMenuOptions({ production })),
	];
}
