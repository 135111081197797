import { Card, Segment } from "semantic-ui-react";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import useWindowSize from "react-use/lib/useWindowSize";

import InstantSearchData from "../../../ui/components/InstantSearch/InstantSearchData";
import Pagination from "../../../ui/components/Pagination/Pagination";

import ArticleCard from "../../components/ArticleCard/ArticleCard";
import ArticlesSearchForm from "../../components/ArticlesSearchForm/ArticlesSearchForm";

function Articles({ hits, nbHits = 0, page = 0, nbPages = 0, setPage }) {
	const { t } = useTranslation();

	const { width } = useWindowSize();

	const desktop = width > 900;
	const tablet = width > 600;

	return (
		<Segment.Group>
			<Segment style={{ paddingLeft: "1.5em" }}>
				{t("foundAmountArticles", "Found {{amount}} articles", { amount: nbHits })}
			</Segment>

			{hits.length > 0 && (
				<Segment padded>
					<Card.Group itemsPerRow={desktop ? 7 : tablet ? 4 : 2}>
						{hits?.map((article) => (
							<ArticleCard key={article.id} article={article} />
						))}
					</Card.Group>
				</Segment>
			)}

			{nbHits > 0 && (
				<Segment>
					<Pagination
						activePage={page + 1}
						totalPages={nbPages}
						onPageChange={(e, { activePage }) => setPage(activePage - 1)}
					/>
				</Segment>
			)}
		</Segment.Group>
	);
}

export default function ArticlesView({ organization }) {
	const [{ searchQuery, titleId, type, imprintId, serieId, language }, setFilters] = useState({
		searchQuery: "",
	});

	const filters = [
		`publisher.id:${organization.id}`,
		titleId ? `title.id:${titleId}` : "",
		type ? `type:${type}` : "",
		imprintId ? `imprint.id:${imprintId}` : "",
		serieId ? `serie.id:${serieId}` : "",
		language ? `language:${language}` : "",
	]
		.filter(Boolean)
		.join(" AND ");

	return (
		<>
			<Segment padded>
				<ArticlesSearchForm organization={organization} onChange={setFilters} />
			</Segment>

			<InstantSearchData
				indexName="articles"
				searchQuery={searchQuery}
				configure={{ hitsPerPage: 7 * 4, filters }}
			>
				<Articles />
			</InstantSearchData>
		</>
	);
}
