import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import agreementStatuses from "astrid-firestore/src/api/agreements/constants/agreementStatuses";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

import useAgreementsQuery from "../../../../agreements/hooks/useAgreementsQuery";
import FlexTable from "../../../../ui/components/FlexTable/FlexTable";
import FlexTableExpander from "../../../../ui/components/FlexTable/components/FlexTableExpander";
import LoadingContext from "../../../../ui/components/LoadingContext/LoadingContext";
import useSortBy from "../../../../ui/hooks/useSortBy";

import AdminCard from "../../../components/AdminCard/AdminCard";

import useAdminUserAgreementsColumns from "../hooks/useAdminUserAgreementsColumns";

const inactiveAgreementStatuses = [agreementStatuses.EXPIRED, agreementStatuses.TERMINATED];

export default function AdminUserAgreements({ user, organization }) {
	const { t } = useTranslation();

	const [showInactiveAgreements, setShowInactiveAgreements] = useState(false);

	const query = useAgreementsQuery({ firstPartyId: organization.id, secondPartyId: user.id });

	const [agreements, loading, error] = useCollectionData(query);
	const columns = useAdminUserAgreementsColumns();

	const sortedAgreements = useSortBy(agreements, "created.seconds");

	const activeAgreements = useMemo(
		() => sortedAgreements?.filter((agreement) => !inactiveAgreementStatuses.includes(agreement.status)),
		[sortedAgreements],
	);

	const inactiveAgreements = useMemo(
		() => sortedAgreements?.filter((agreement) => inactiveAgreementStatuses.includes(agreement.status)),
		[sortedAgreements],
	);

	const allAgreements = useMemo(
		() => [...activeAgreements, ...inactiveAgreements],
		[activeAgreements, inactiveAgreements],
	);

	return (
		<AdminCard header={t("agreements", "Agreements")}>
			<LoadingContext loading={loading} error={error} data={agreements}>
				<FlexTable data={showInactiveAgreements ? allAgreements : activeAgreements} columns={columns}>
					{inactiveAgreements.length > 0 && (
						<FlexTableExpander
							onClick={() =>
								setShowInactiveAgreements((showInactiveAgreements) => !showInactiveAgreements)
							}
							expanded={showInactiveAgreements}
							collapsedText={t("showInactiveAgreements", "Show inactive agreements ({{amount}})", {
								amount: inactiveAgreements.length,
							})}
							expandedText={t("hideInactiveAgreements", "Hide inactive agreements")}
						/>
					)}
				</FlexTable>
			</LoadingContext>
		</AdminCard>
	);
}
