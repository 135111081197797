import { Card } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import AdminCard from "../../../../admin/components/AdminCard/AdminCard";
import useOrganizationRoleTranslations from "../../../../organizations/hooks/useOrganizationRoleTranslations";

function Organization({ id, user, type, name }) {
	const { t } = useTranslation();

	const organizationRoleTranslations = useOrganizationRoleTranslations();

	const userRoles = (user.permissions?.[type]?.[id] || [])
		.filter((role) => role !== "any")
		.map((role) => organizationRoleTranslations[role])
		.join(", ");

	return (
		<Card.Content key={id}>
			<Link to={`/${type}/${id}/admin/users/${user.id}`}>{name}</Link>{" "}
			{userRoles ? `(${userRoles})` : <em>{t("noRoles", "No roles")}</em>}
		</Card.Content>
	);
}

export default function UserOrganizations({ user }) {
	const { t } = useTranslation();

	const organizations = Object.entries(user.organizations || {});

	if (organizations.length === 0) {
		return false;
	}

	return (
		<AdminCard header={t("organizations", "Organizations")}>
			{organizations.map(([id, organization]) => (
				<Organization key={id} id={id} user={user} {...organization} />
			))}
		</AdminCard>
	);
}
