import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import metadataTemplateTypes from "../constants/metadataTemplateTypes";

export default function useTemplateHeaderTranslations() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			[metadataTemplateTypes.ONIX]: t("onixTemplate", "Onix template"),
			[metadataTemplateTypes.EXCEL]: t("excelTemplate", "Excel template"),
			[metadataTemplateTypes.CUSTOM_XML]: t("customXmlTemplate", "Custom XML template"),
		}),
		[t],
	);
}
