import { useEffect, useMemo } from "react";

import Select from "../../../ui/components/Select/Select";

import useFormField from "../../hooks/useFormField";

export default function FormSelect({ name, options, defaultValue, ...props }) {
	const {
		field: { onChange, ...field },
	} = useFormField({ name, ...props });

	const defaultOption = useMemo(() => {
		if (props.multiple && defaultValue) {
			return options.filter((option) => defaultValue.includes(option.value) || !!option.defaultOption);
		}

		return options.find((option) => option.value === defaultValue || !!option.defaultOption);
	}, [defaultValue, options, props.multiple]);

	useEffect(() => {
		if (defaultOption) {
			onChange(null, {
				name,
				value: props.multiple ? defaultOption.map?.((option) => option.value) : defaultOption.value,
			});
		}
	}, [defaultOption, name, onChange, props.multiple]);

	return <Select options={options} onChange={onChange} {...field} ref={undefined} />;
}
