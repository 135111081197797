import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";

import Label from "../../../ui/components/Label/Label";

import offerStatusColors from "../../utils/offerStatusColors";

import OfferStatus from "./OfferStatus";

export default function OfferStatusLabel({ offer, ...props }) {
	if (offer.status === offerStatuses.INITIAL) {
		return null;
	}

	return (
		<Label style={{ whiteSpace: "nowrap" }} color={offerStatusColors[offer.status]} {...props}>
			<OfferStatus offer={offer} />
		</Label>
	);
}
