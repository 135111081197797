import renderHandlebarsTemplate from "../handlebars/renderHandlebarsTemplate";
import fetchMetadata from "../utils/fetchMetadata";

export default async function generateOnixFromTemplate({ document }) {
	const { onixTemplate } = document.channel;

	const metadataDocument = await fetchMetadata({ document });

	return renderHandlebarsTemplate({ template: onixTemplate, data: metadataDocument });
}
