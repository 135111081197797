import actionNames from "astrid-distribution/src/actions/actionNames";

import audioSplit from "./constants/audioSplit";

export default function getExportPipeline({ data }) {
	return {
		id: "export",
		name: "Create export",
		steps: [
			{
				name: "Create audio",
				key: "createAudio",
				action: null,
				steps: [
					{
						key: "getArtifact",
						name: "Get artifacts",
						action: actionNames.GET_ARTIFACT_FILES,
						disabled: false,
						condition: null,
						args: null,
						steps: null,
					},
					{
						key: "splitOrConcatAudio",
						name: "Split or concat audio",
						action:
							data.splitAudio === audioSplit.SPLIT ? actionNames.SPLIT_AUDIO : actionNames.CONCAT_AUDIO,
						needs: "getArtifact",
						disabled: data.splitAudio === audioSplit.AS_IS,
						condition: null,
						args: null,
						steps: null,
					},
					{
						key: "recodeAudio",
						name: "recode audio",
						action: actionNames.RECODE_AUDIO,
						args: {
							AUDIO_FORMAT: data.audioFormat,
							AUDIO_BITRATE: data.bitrate,
						},
						needs: data.splitAudio !== audioSplit.AS_IS ? "splitOrConcatAudio" : "getArtifact",
						disabled: false,
						condition: null,
						steps: null,
					},
					{
						key: "renameAudio",
						name: "Rename audio",
						action: actionNames.RENAME_FILE,
						args: {
							FILE_PATTERN: data.rename,
						},
						needs: "recodeAudio",
						disabled: false,
						condition: null,
						steps: null,
					},
					{
						key: "zipAudio",
						name: "Zip audio",
						action: actionNames.ZIP_FILES,
						needs: "renameAudio",
						disabled: false,
						condition: `${data.zip}`,
						args: null,
						steps: null,
					},
					{
						key: "renameZip",
						name: "Rename zip",
						action: actionNames.RENAME_FILE,
						args: {
							FILE_PATTERN: "ISBN.SUFFIX",
						},
						disabled: false,
						condition: `${data.zip}`,
						needs: "zipAudio",
						steps: null,
					},
					{
						key: "saveToBucket",
						name: "Save to bucket",
						action: actionNames.SAVE_TO_BUCKET,
						disabled: false,
						condition: null,
						args: {
							BUCKET: "stage-earselect-exports",
							PATH: "{{document.id}}",
						},
						needs: data.zip ? "renameZip" : "renameAudio",
						steps: null,
					},
				],
			},
			{
				name: "Create cover",
				key: "createCover",
				condition: `${data.includeCover}`,
				action: null,
				steps: [
					{
						key: "getCover",
						name: "Get cover",
						action: actionNames.GET_COVER,
						args: null,
						disabled: false,
						condition: null,
						steps: null,
					},
					{
						key: "resizeImage",
						name: "Resize and convert image to jpeg",
						action: actionNames.RESIZE_IMAGE,
						needs: "getCover",
						args: null,
						disabled: false,
						condition: null,
						steps: null,
					},
					{
						key: "renameCover",
						name: "Rename cover",
						action: actionNames.RENAME_FILE,
						args: {
							FILE_PATTERN: "ISBN.SUFFIX",
						},
						needs: "resizeImage",
						disabled: false,
						condition: null,
						steps: null,
					},
					{
						key: "saveCoverToBucket",
						name: "Save cover to bucket",
						needs: "renameCover",
						action: actionNames.SAVE_TO_BUCKET,
						args: {
							BUCKET: "stage-earselect-exports",
							PATH: "{{document.id}}",
						},
						disabled: false,
						condition: null,
						steps: null,
					},
				],
			},
		],
	};
}
