import { useMemo } from "react";

import { filterDistributionChannelsByArticle } from "astrid-firestore/src/api/distribution/utils/filterDistributionChannels";

import api from "../../../api";

export default function useArticleDistributionChannels({ publisher, article }) {
	const [channels, loading, error] = api.distributionChannels.useAll();

	const presetChannels = useMemo(
		() => filterDistributionChannelsByArticle({ publisher, article, channels }),
		[article, channels, publisher],
	);

	const articleChannels = presetChannels.filter(
		(channel) => !article.channelIds || article.channelIds.includes(channel.id),
	);

	return [presetChannels, articleChannels, loading, error];
}
