import { useNavigate } from "react-router-dom";

import InstantSearchDataTable from "../../../ui/components/InstantSearch/InstantSearchDataTable";

import useDistributionsTableColumns from "../../hooks/useDistributionsTableColumns";

export default function DistributionsInstantSearchDataTable({ article, publisher, statuses, ...props }) {
	const navigate = useNavigate();

	const columns = useDistributionsTableColumns();

	const filters = [
		publisher && `publisher.id:${publisher.id}`,
		article && `article.id:${article.id}`,
		statuses && `(${statuses.map((status) => `status:${status}`).join(" OR ")})`,
	]
		.filter(Boolean)
		.join(" AND ");

	const onClick = ({ id, article, publisher }) => {
		navigate(`/publisher/${publisher.id}/admin/articles/${article.id}/distributions/${id}`);
	};

	return (
		<InstantSearchDataTable
			indexName="distributions"
			columns={columns}
			filters={filters}
			onClick={onClick}
			{...props}
		/>
	);
}
