import React from "react";

import Dashboard from "../../../dashboard/components/Dashboard/Dashboard";
import useProducerDashboardStatistics from "../../../organizations/hooks/useProducerDashboardStatistics";

export default function AdminProducerDashboardView({ organization }) {
	const statistics = useProducerDashboardStatistics({ organization });

	return <Dashboard statistics={statistics} />;
}
