import React from "react";

import Form from "../../../../forms/components/Form/Form";

export default function PipelineStepActionArg({ arg, name, shouldUnregister = false }) {
	const props = {
		label: arg.id,
		name,
		shouldUnregister,
		rules: arg.required ? { required: true } : { required: false },
	};

	const options = [
		...(arg.required ? [] : [{ key: null, value: null, text: "NONE" }]),
		...(arg.options?.map((value) => ({
			key: value,
			value,
			text: value,
		})) || []),
	];

	const type = arg.options ? "select" : arg.type;

	switch (type) {
		case "string":
			return <Form.Input {...props} />;
		case "number":
			return <Form.NumberInput {...props} />;
		case "password":
			return <Form.PasswordInput {...props} />;
		case "boolean":
			return <Form.Checkbox {...props} />;
		case "select":
			return <Form.Select {...props} options={options} />;
		default:
			return null;
	}
}
