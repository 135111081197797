import { Menu } from "semantic-ui-react";

import React from "react";
import { Link } from "react-router-dom";

import MenuItemContent from "./MenuItemContent";

export default function MenuItems({ options }) {
	return options.map(
		({ to, icon, text, content, children, loading, label, labelColor, onClick, ...option }, index) => (
			<Menu.Item
				key={index}
				as={to !== undefined ? Link : undefined}
				to={to}
				className="menu-item"
				onClick={onClick}
				style={{ whiteSpace: "nowrap", flexShrink: 0 }}
				{...option}
			>
				<MenuItemContent
					icon={icon}
					text={text}
					content={content}
					loading={loading}
					label={label}
					labelColor={labelColor}
				>
					{children}
				</MenuItemContent>
			</Menu.Item>
		),
	);
}
