import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import auth from "astrid-firebase/src/auth";
import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import { setActAsUser, useActAsUser } from "../../../../authentication/state/actAsUser";
import { useProfile } from "../../../../authentication/state/profile";
import useHasRight from "../../../../authorization/hooks/useHasRight";
import useHasRole from "../../../../authorization/hooks/useHasRole";
import useOrganizationsOnce from "../../../../organizations/hooks/useOrganizationsOnce";
import { useOrganizationId } from "../../../../organizations/state/organizationId";
import { useOrganizationType } from "../../../../organizations/state/organizationType";
import useGroupBy from "../../../../ui/hooks/useGroupBy";

export default function useSecondaryMenuOptions() {
	const { t } = useTranslation();

	const hasRole = useHasRole();
	const profile = useProfile();
	const navigate = useNavigate();
	const hasRight = useHasRight();
	const actAsUser = useActAsUser();
	const orgId = useOrganizationId();
	const orgType = useOrganizationType();

	const publisherIds = useMemo(() => Object.keys(profile?.permissions?.publisher || {}), [profile]);
	const publishersById = useGroupBy(useOrganizationsOnce({ ids: publisherIds }), "id");

	return useMemo(
		() => [
			[
				{
					to: `/profile/${profile?.id}`,
					icon: "user outline",
					text: t("profile"),
				},
				{
					to: `/account/${profile?.id}`,
					icon: "setting outline",
					text: t("account", "Account"),
				},
			],

			[
				...Object.keys(profile?.permissions?.producer || {})
					.filter((id) => hasRight("menuOption.ProducerSettings") && id === orgId)
					.map((id) => ({
						to: `/settings/${id}`,
						icon: "settings outline",
						text: t("prodComp"),
					})),

				...Object.keys(profile?.permissions?.publisher || {}).map((id, i) => ({
					to: `/publisher/${id}`,
					icon: "settings outline",
					text: publishersById?.[id]?.[0]?.name || `${t("profilePublisher")} (${i})`,
				})),
			],

			[
				{
					to: `/${orgType}/${orgId}/admin`,
					icon: "settings outline outline",
					text: `${t("admin", "Admin")}`,
					hidden: !(hasRight("admin") && orgId),
				},
				{
					to: "/admin",
					icon: "spy outline",
					text: t("superAdmin"),
					hidden: !hasRole("owner", "admin"),
				},
				{
					to: "/publication",
					icon: "newspaper outline",
					text: t("publication"),
					hidden: !hasRole("owner", "admin"),
				},
			],
			[
				{
					to: "/billing",
					icon: "clipboard list outline",
					text: t("billing"),
					hidden: !(hasRight("producerBilling.createPublisherInvoice") && orgId === "HfvG4FQfXWpWv6dzqM5E"),
				},
				{
					to: "/storytool-archive",
					icon: "archive outline",
					text: `${t("storytoolArchive", "Storytool archive")}`,
					hidden: !profile?.permissions?.storytoolArchive?.enableArchiveAccess && !hasRole("owner", "admin"),
				},
				{
					to: "/distribution",
					icon: "sitemap outline",
					text: t("distribution"),
					hidden: !hasRight("distribution.editDistributors"),
				},
				{
					to: "/upload",
					icon: "upload cloud outline",
					text: t("uploading"),
					hidden: !hasRight("distribution.editDistributors"),
				},
				{
					to: `producer/${orgId}/documents`,
					icon: "folder open outline",
					text: t("documents", "Documents"),
					hidden: !(hasRight("documents.view") && orgId),
				},
				{
					to: `/${orgType}/${orgId}/statistics`,
					icon: "line graph outline",
					text: t("statistics"),
					hidden: !hasRight("viewProductionStatistics") || orgType === organizationTypes.PUBLISHER,
				},
			],
			[
				{
					to: "/production",
					icon: "microphone outline",
					text: `${t("productions")} (old)`,
					hidden: !(hasRight("listProducerProductions") || hasRight("listPublisherProductions")),
				},
				{
					to: "/computejobs",
					icon: "server outline",
					text: t("serverInstances"),
					hidden: !hasRole("owner"),
				},
				{
					to: "/studio-redirect",
					icon: "headphones outline",
					text: t("astridStudio"),
					hidden: !hasRight("studioSession.connect"),
				},
				{
					to: "/apps",
					icon: "download outline",
					text: t("apps"),
					hidden: !hasRight("apps.view"),
				},
			],
			[
				{
					icon: "log out outline",
					text: t("profileLogout"),
					hidden: !!actAsUser,
					onClick: () => {
						auth.signOut();
						navigate("/");
					},
				},
				{
					icon: "log out outline",
					text: t("actAsUserLogout", "Stop acting as user"),
					hidden: !actAsUser,
					onClick: () => {
						setActAsUser();
						navigate(`/admin/users/${actAsUser}`);
					},
				},
			],
		],
		[t, profile, hasRight, orgType, orgId, hasRole, actAsUser, publishersById, navigate],
	);
}
