import { useTranslation } from "react-i18next";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import CreateSerieButton from "../../../series/components/CreateSerieForm/CreateSerieButton";
import SeriesInstantSearchDataTable from "../../../series/components/SeriesInstantSearchDataTable/SeriesInstantSearchDataTable";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

export default function AdminSeriesView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("serie", "Series")}>
				{searchInput}
				{organization && <CreateSerieButton organization={organization} />}
			</AdminHeader>

			<SeriesInstantSearchDataTable publisher={organization} searchQuery={searchQuery} />
		</>
	);
}
