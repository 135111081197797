import { Message } from "semantic-ui-react";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useOutletContext } from "react-router-dom";

import Flex from "../../../components/Flex/Flex";
import DropdownMenu from "../../ui/components/DropdownMenu/DropdownMenu";
import VerticalMenu from "../../ui/components/VerticalMenu/VerticalMenu";

import useHasRight from "../../authorization/hooks/useHasRight";

export default function OrganizationAdminSidebar({ options }) {
	const { t } = useTranslation();
	const { state } = useLocation();
	const { organization } = useOutletContext();

	const [returnPath, setReturnPath] = useState();

	const hasRight = useHasRight();

	useEffect(() => {
		if (state?.pathname) {
			setReturnPath(state.pathname);
		}
	}, [state]);

	return (
		<>
			<Message style={{ backgroundColor: "white", paddingRight: "0.6em", paddingLeft: "1.1em" }}>
				<Flex>
					<Message.Header
						as={Link}
						to={`/${organization.type}/${organization.id}/admin`}
						style={{ color: "black" }}
					>
						{organization?.name}
					</Message.Header>

					{hasRight("superadmin") && (
						<DropdownMenu
							upward
							options={[{ text: t("superAdmin", "Super admin"), linkTo: returnPath || "/admin" }]}
						/>
					)}
				</Flex>
			</Message>

			<VerticalMenu options={options} />
		</>
	);
}
