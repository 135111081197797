import React from "react";
import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../authorization/hooks/useFeatureFlag";

import AdminDistributionsScheduledView from "../../views/AdminDistributionsView/AdminDistributionsScheduledView";

export default function AdminDistributionsScheduled() {
	const { organization } = useOutletContext();

	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature) {
		return <Unauthorized />;
	}

	return <AdminDistributionsScheduledView organization={organization} />;
}
