import { Icon } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import api from "../../../api";
import ConfirmationModalTrigger from "../../ui/components/ConfirmationModal/ConfirmationModalTrigger";

import useContributorRolesTranslations from "./useContributorRolesTranslations";

export default function useContributorsTableColumns({ article }) {
	const { t } = useTranslation();

	const contributorRolesTranslations = useContributorRolesTranslations();

	const removeContributor = (contributor) => {
		return api.articles.removeContributor({ article, contributor });
	};

	return [
		{
			id: "role",
			filter: "select",
			disableSortBy: true,
			Header: t("role", "Role"),
			accessor: ({ role }) => contributorRolesTranslations[role],
		},
		{
			id: "name",
			Header: t("name", "Name"),
		},
		{
			id: "actions",
			collapsing: true,
			disableSortBy: true,
			Cell: ({ row }) => {
				return (
					<ConfirmationModalTrigger
						text={t("confirmRemoveContributor", "Do you want to remove this contributor?")}
						onConfirm={() => removeContributor(row.original)}
						trigger={<Icon link name="trash" />}
					/>
				);
			},
		},
	];
}
