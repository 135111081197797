import React from "react";
import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../authorization/hooks/useFeatureFlag";

import AdminDistributionsCompletedView from "../../views/AdminDistributionsView/AdminDistributionsCompletedView";

export default function AdminDistributionsCompleted() {
	const { organization } = useOutletContext();

	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature) {
		return <Unauthorized />;
	}

	return <AdminDistributionsCompletedView organization={organization} />;
}
