import { Segment, Menu as SemanticMenu } from "semantic-ui-react";

import { useEffect, useState } from "react";

import Flex from "../../../../components/Flex/Flex";
import Menu from "../../../ui/components/Menu/Menu";

import { useProfile } from "../../../authentication/state/profile";
import useHasRight from "../../../authorization/hooks/useHasRight";
import QuickSearch from "../../../productions/components/QuickSearch/QuickSearch";

import OrganizationMenu from "./components/OrganizationsMenu";
import SecondaryMenu from "./components/SecondaryMenu";
import useMainMenuOptions from "./hooks/useMainMenuOptions";
import useSecondaryMenuOptions from "./hooks/useSecondaryMenuOptions";

export default function MainMenu() {
	const profile = useProfile();
	const hasRight = useHasRight();

	const mainMenuOptions = useMainMenuOptions();
	const secondaryMenuOptions = useSecondaryMenuOptions();

	const [showMenu, setShowMenu] = useState(true);

	useEffect(() => {
		let prevScrollY = window.scrollY;

		const onScroll = () => {
			let direction = prevScrollY < window.scrollY ? "down" : "up";

			if (window.scrollY > 66 && direction === "down") {
				setShowMenu(false);
			} else if (direction === "up") {
				setShowMenu(true);
			}

			prevScrollY = window.scrollY;
		};

		window.addEventListener("scroll", onScroll);

		return () => {
			window.removeEventListener("scroll", onScroll);
		};
	}, []);

	const segmentStyle = {
		position: "fixed",
		left: 0,
		right: 0,
		height: 66,
		zIndex: 999,
		padding: 0,
		transform: showMenu ? "translateY(0)" : "translateY(-100%)",
		transition: "transform 0.3s",
	};

	return (
		<Segment attached style={segmentStyle}>
			<div style={{ height: "100%", margin: "0 0.5em" }}>
				<Flex style={{ height: "100%" }}>
					<Menu
						secondary
						options={mainMenuOptions}
						dropdownDirection="right"
						style={{ margin: 0, width: "calc(100% - 320px)" }}
					/>

					<SemanticMenu secondary style={{ margin: 0, justifyContent: "flex-end", alignItems: "center" }}>
						{hasRight("productions.quickSearch") && <QuickSearch />}

						{profile && <OrganizationMenu />}

						<SecondaryMenu hidden={!showMenu} options={secondaryMenuOptions} />
					</SemanticMenu>
				</Flex>
			</div>
		</Segment>
	);
}
