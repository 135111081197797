import React from "react";
import { useTranslation } from "react-i18next";

import actionNames from "astrid-distribution/src/actions/actionNames";

import Form from "../../../../forms/components/Form/Form";

const actionNameOptions = [
	{ key: null, value: null, text: "NONE" },
	...Object.entries(actionNames).map(([key, value]) => ({
		key,
		value,
		text: key,
	})),
];

export default function PipelineStepActionSelect({ name }) {
	const { t } = useTranslation();

	return (
		<Form.Select
			label={t("action", "Action")}
			name={`${name}.action`}
			options={actionNameOptions}
			placeholder="NONE"
			shouldUnregister={false}
		/>
	);
}
