import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Table from "../../../../ui/components/Table/Table";

import AdminCard from "../../../components/AdminCard/AdminCard";

import useAdminPublisherPricesColumns from "../hooks/useAdminPublisherPricesColumns";

import PublisherPricesActions from "./PublisherPricesActions";

export default function AdminPublisherPrices({ publisher, organization }) {
	const { t } = useTranslation();

	const data = useMemo(
		() => Object.values(publisher.prices?.[organization.id] || {}),
		[organization.id, publisher.prices],
	);

	const columns = useAdminPublisherPricesColumns({ publisher, organization });

	return (
		<AdminCard
			header={t("defaultPricing", "Default pricing")}
			extra={<PublisherPricesActions publisher={publisher} organization={organization} />}
		>
			<Table data={data} columns={columns} footer={false} />
		</AdminCard>
	);
}
