import { Header, Segment } from "semantic-ui-react";

import React from "react";

import Flex from "../../../../components/Flex/Flex";

import DropdownMenu from "../DropdownMenu/DropdownMenu";

export default function PageHeader({ header, loading, subheader, children, dropdownMenuOptions }) {
	return (
		<Segment style={{ marginBottom: 20, marginTop: 0, boxShadow: "none" }}>
			<div style={{ height: "100%" }}>
				<Flex style={{ height: "100%" }}>
					<Header as="h1" style={{ margin: 0, fontSize: 20 }}>
						<Flex gap={10} justifyContent="flex-start">
							{header}

							<div style={{ fontSize: 13 }}>{children}</div>
						</Flex>
						<Header.Subheader style={{ marginBottom: 2 }}>{subheader}</Header.Subheader>
					</Header>

					{dropdownMenuOptions && (
						<DropdownMenu
							style={{ marginRight: "-10px" }}
							loading={loading}
							options={dropdownMenuOptions}
						/>
					)}
				</Flex>
			</div>
		</Segment>
	);
}
