import { Route } from "react-router-dom";

import ForwardContext from "../../../../layouts/ForwardContext";

import AdminDistributions from "../../pages/AdminDistributions/AdminDistributions";
import AdminDistributionsAborted from "../../pages/AdminDistributions/AdminDistributionsAborted";
import AdminDistributionsCompleted from "../../pages/AdminDistributions/AdminDistributionsCompleted";
import AdminDistributionsDashBoard from "../../pages/AdminDistributions/AdminDistributionsDashBoard";
import AdminDistributionsScheduled from "../../pages/AdminDistributions/AdminDistributionsScheduled";

import adminDistributionRoute from "./adminDistributionRoute";

const adminDistributionsRoute = (
	<Route
		path="distributions"
		element={<ForwardContext />}
		handle={{ crumb: ({ t }) => t("distributions", "Distributions") }}
	>
		<Route index element={<AdminDistributionsDashBoard />} />

		<Route path="all" element={<AdminDistributions />} />

		<Route path="completed" element={<AdminDistributionsCompleted />} />

		<Route path="scheduled" element={<AdminDistributionsScheduled />} />

		<Route path="aborted" element={<AdminDistributionsAborted />} />

		{adminDistributionRoute}
	</Route>
);

export default adminDistributionsRoute;
