import { Divider, Header } from "semantic-ui-react";

import Flex from "../../../../components/Flex/Flex";

const headerStyle = {
	display: "flex",
	gap: 25,
	alignItems: "center",
	margin: "0.2em 0 0 0",
	fontSize: "1.6em",
};

export default function AdminHeader({ text, children, divider = true, ...props }) {
	return (
		<>
			<Flex gap={10} wrap>
				<div>
					<Header style={headerStyle} {...props}>
						{text || children}
					</Header>
				</div>

				<div style={{ whiteSpace: "nowrap" }}>
					{text && children && (
						<Flex gap={10} justifyContent="flex-start">
							{children}
						</Flex>
					)}
				</div>
			</Flex>

			{divider && <Divider />}
		</>
	);
}
