import DataTable from "../DataTable/DataTable";

import InstantSearchData from "./InstantSearchData";
import InstantSearchPagination from "./InstantSearchPagination";

export default function InstantSearchDataTable(props) {
	return (
		<InstantSearchData {...props}>
			<DataTable sortable={false} filterable={false} footer={<InstantSearchPagination secondary />} />
		</InstantSearchData>
	);
}
