import React from "react";
import { useTranslation } from "react-i18next";

import { dropdownMenuColumn, timestampColumn } from "../../ui/utils/columns";

import PipelineStatusLabel from "../components/DistributionStatus/PipelineStatusLabel";

import useDistributionDropdownMenuOptions from "./useDistributionDropdownMenuOptions";

export default function useDistributionsTableColumns() {
	const { t } = useTranslation();

	return [
		timestampColumn({ id: "created", Header: t("created", "Created") }),
		{
			id: "status",
			Header: t("status", "Status"),
			collapsing: true,
			disableSortBy: true,
			Cell: ({ row }) => {
				return <PipelineStatusLabel distribution={row.original} />;
			},
		},
		{
			id: "channel.name",
			Header: t("channel", "Channel"),
			disableSortBy: true,
		},
		{
			id: "article.name",
			Header: t("article", "Article"),
			disableSortBy: true,
		},
		{
			id: "pipeline.name",
			Header: t("pipeline", "Pipeline"),
			disableSortBy: true,
		},
		dropdownMenuColumn(useDistributionDropdownMenuOptions),
	];
}
