import trim from "lodash/trim";

import FirestoreCollection from "../../firestore/FirestoreCollection";

import { ScriptRef } from "../scripts/types/Script";

import { Production } from "./types/Production";

export default class ProductionsCollection extends FirestoreCollection {
	static collectionName = "productions";
	static Schema = Production;

	preProcess(data) {
		if (data.title) {
			data.title = trim(data.title);
		}

		if (data.publisherId) {
			data.publisher = data.publisherId;
		}

		if (data.producerId) {
			data.producer = data.producerId;
		}

		if (data.publisher && data.title && !data.skipTitleValidation) {
			// const productions = await api.productions.getAll((ref) =>
			// 	ref
			// 		.where("publisher", "==", data.publisher)
			// 		.where("title", "==", data.title)
			// 		.where(firebase.firestore.FieldPath.documentId(), "!=", data.ref.id),
			// );
			//
			// if (productions.length > 0) {
			// 	throw new ProductionTitleAlreadyExistsError(productions[0].id);
			// }
		}

		if (data.publisherManagers) {
			data.publisherManagerIds = data.publisherManagers.map(({ id }) => id);
		}

		return data;
	}

	addArticle({ article, deliveryDate, production }) {
		return this.api.articles.update({ ref: article.ref, deliveryDate, production });
	}

	removeArticle({ article }) {
		return api.articles.update({ ref: article.ref, production: null });
	}

	addScript({ production, script, transaction }) {
		return this.update(
			{
				ref: production.ref,
				[`scripts.${script.id}`]: ScriptRef.parse(script),
			},
			{ transaction, validate: false },
		);
	}

	removeScript({ production, script, transaction }) {
		return this.update(
			{
				ref: production.ref,
				[`scripts.${script.id}`]: this.utils.delete(),
			},
			{ transaction, validate: false },
		);
	}
}
