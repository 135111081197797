import { useTranslation } from "react-i18next";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import ArtifactsInstantSearchDataTable from "../../../artifacts/components/ArtifactsInstantSearchDataTable/ArtifactsInstantSearchDataTable";
import CreateArtifactButton from "../../../artifacts/components/CreateArtifactForm/CreateArtifactButton";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

export default function AdminArtifactsView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("artifacts", "Artifacts")}>
				{searchInput}

				<CreateArtifactButton basic secondary publisher={organization} />
			</AdminHeader>

			<ArtifactsInstantSearchDataTable searchQuery={searchQuery} organization={organization} />
		</>
	);
}
