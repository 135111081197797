import DataTable from "../../../ui/components/DataTable/DataTable";

import ArticleDistributionFooter from "./components/ArticleDistributionFooter";
import useDistributionChannelsTableColumns from "./hooks/useArticleDistributionChannelsTableColumns";

export default function ArticleDistributionChannels({ channels, publisher, article, ...props }) {
	const columns = useDistributionChannelsTableColumns({ article });

	return (
		<DataTable
			selectable
			data={
				article.channelIds ? channels?.filter((channel) => article.channelIds?.includes(channel.id)) : channels
			}
			allowEmpty
			columns={columns}
			sortBy={{
				id: "name",
				desc: false,
			}}
			searchableKeys={["name"]}
			footer={<ArticleDistributionFooter publisher={publisher} article={article} channels={channels} />}
			{...props}
		/>
	);
}
