import { useRef, useState, useEffect, useCallback } from "react";

export default function useTemp(value) {
	const [temp, setTemp] = useState(value);
	const ref = useRef();

	useEffect(() => {
		ref.current = temp;
	}, [temp]);

	useEffect(() => setTemp(value), [value]);

	return [temp, setTemp, useCallback(() => ref.current, [])];
}
