import React from "react";
import { useTranslation } from "react-i18next";

import PrimaryButton from "../../../ui/components/Buttons/PrimaryButton";

import Form from "../../../forms/components/Form/Form";

import AdminCard from "../AdminCard/AdminCard";

export default function AdminForm({ form, children, onSubmit, submitText, ...props }) {
	const { t } = useTranslation();

	const { locked, formState } = form;

	return (
		<AdminCard
			{...props}
			extra={
				!locked && (
					<PrimaryButton
						disabled={formState.isSubmitting || !formState.isDirty}
						loading={formState.isSubmitting}
						onClick={onSubmit}
					>
						{submitText || t("save", "Save")}
					</PrimaryButton>
				)
			}
		>
			<Form form={form}>{children}</Form>
		</AdminCard>
	);
}
