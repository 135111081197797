const pipelineStatuses = {
	PENDING: "pending",
	SCHEDULED: "scheduled",
	INITIALIZED: "initialized",
	RUNNING: "running",
	CANCELLED: "cancelled",
	COMPLETED: "completed",
	FAILED: "failed",
};

export default pipelineStatuses;
