import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import PrimaryButton from "../../../ui/components/Buttons/PrimaryButton";
import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import usePricesQuery from "../../../prices/hooks/usePricesQuery";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

import usePricesColumns from "./hooks/usePricesColumns";

export default function AdminPricesView({ producerId, ...props }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const query = usePricesQuery({ producerId });
	const columns = usePricesColumns();

	return (
		<>
			<AdminHeader text={t("globalPrices", "Global prices")}>
				<PrimaryButton as={Link} to="create">
					{t("addPrices", "Add prices")}
				</PrimaryButton>
			</AdminHeader>

			<CollectionDataTable
				persist
				query={query}
				columns={columns}
				onClick={({ id }) => navigate(id)}
				sortBy={{
					id: "created",
					desc: true,
				}}
				{...props}
			/>
		</>
	);
}
