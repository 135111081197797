import React from "react";
import { useTranslation } from "react-i18next";

import { Article } from "astrid-firestore/src/api/articles/types/Article";

import api from "../../../../../api";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

import AdminForm from "../../../components/AdminForm/AdminForm";

export default function AdminArticleLanguageForm({ article, locked, ...props }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		schema: Article,
		values: {
			ref: article.ref || null,
			additionalLanguages: article.additionalLanguages || null,
			language: article.language || null,
			originalLanguage: article.originalLanguage || null,
		},
		onSubmit: (data) => {
			return api.articles.update(data);
		},
		locked,
		...props,
	});

	const { watch } = form;

	const language = watch("language");

	return (
		<AdminForm header={t("language", "Language")} {...form}>
			<Form.Group widths="equal">
				<Form.LanguageSelect name="language" label={t("language", "Language")} clearable />

				<Form.LanguageSelect
					name="originalLanguage"
					label={t("originalLanguage", "Original language")}
					clearable
				/>

				<Form.LanguageSelect
					name="additionalLanguages"
					label={t("otherLanguages", "Other occuring languages")}
					filterOptions={(option) => option.value !== language}
					multiple
					clearable
				/>
			</Form.Group>
		</AdminForm>
	);
}
