import { useTranslation } from "react-i18next";

import api from "../../../../../api";
import AdminCard from "../../../../admin/components/AdminCard/AdminCard";
import AdminForm from "../../../../admin/components/AdminForm/AdminForm";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

import metadataTemplateTypes from "../constants/metadataTemplateTypes";

import MetadataEditor from "./MetadataEditor";

export default function SuperAdminDistributionChannelMetaDataTemplates({ channel }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		defaultValues: {
			enabledTemplates: channel.enabledTemplates,
		},
		onSubmit: ({ enabledTemplates }) => {
			return api.distributionChannels.update({
				ref: channel.ref,
				enabledTemplates,
			});
		},
	});
	return (
		<AdminCard header={t("metadataTemplates", "Metadata templates")}>
			<AdminForm {...form}>
				<Form.Select
					multiple
					name="enabledTemplates"
					label={t("enabledTemplates", "Enabled templates")}
					options={Object.entries(metadataTemplateTypes).map(([key, value]) => ({
						key,
						value,
						text: value,
					}))}
				/>
			</AdminForm>
			{channel.enabledTemplates?.map((templateType) => (
				<MetadataEditor key={templateType} channel={channel} templateType={templateType} />
			))}
		</AdminCard>
	);
}
