import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../authorization/constants/featureFlags";
import useFeatureFlag from "../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../authorization/hooks/useHasRight";

import AdminDistributionSettingsView from "../views/AdminDistributionSettingsView/AdminDistributionSettingsView";

export default function AdminDistributionSettings() {
	const { organization } = useOutletContext();

	const hasRight = useHasRight();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature || !hasRight("distributions.admin")) {
		return <Unauthorized />;
	}

	return <AdminDistributionSettingsView publisher={organization} />;
}
