import { useOutletContext, useParams } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import AdminPageView from "../components/AdminPage/AdminPageView";
import AdminPublisherView from "../views/AdminPublisherView/AdminPublisherView";

export default function AdminPublisher() {
	const { id } = useParams();
	const { organization } = useOutletContext();

	const hasRight = useHasRight();

	if (!hasRight("publishers.view")) {
		return <Unauthorized />;
	}

	return (
		<>
			<AdminPageView id={id} organization={organization}>
				<AdminPublisherView />
			</AdminPageView>
		</>
	);
}
