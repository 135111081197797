import { useNavigate } from "react-router-dom";

import InstantSearchDataTable from "../../../ui/components/InstantSearch/InstantSearchDataTable";

import useTitlesTableColumns from "../../hooks/useTitlesTableColumns";

export default function TitlesInstantSearchDataTable({ publisher, ...props }) {
	const navigate = useNavigate();

	const columns = useTitlesTableColumns();

	return (
		<InstantSearchDataTable
			indexName="titles"
			columns={columns}
			configure={{
				filters: publisher && `publisher.id:${publisher.id}`,
			}}
			onClick={({ id, publisher }) => navigate(`/publisher/${publisher.id}/admin/titles/${id}`)}
			{...props}
		/>
	);
}
