import { Divider, Statistic } from "semantic-ui-react";

import React from "react";

import Flex from "../../../../components/Flex/Flex";

import DashboardStatisticButton from "./DashboardStatisticButton";
import DashboardStatisticDetail from "./DashboardStatisticDetail";

export default function DashboardStatistic({
	total,
	details,
	label,
	color = "black",
	detailColor = "black",
	organization,
	link,
}) {
	return (
		<Statistic color={color}>
			<Statistic.Label style={{ color: detailColor }}>{label}</Statistic.Label>

			<Statistic.Value>
				<div style={{ fontSize: 40 }}>{total}</div>
			</Statistic.Value>

			{details ? (
				<Flex justifyContent="center" gap={15} style={{ color: detailColor, minHeight: 32 }}>
					{details.map(({ text, type, icon, amount }, index) => {
						return (
							<DashboardStatisticDetail key={index} text={text} type={type} icon={icon} amount={amount} />
						);
					})}
				</Flex>
			) : (
				<Divider style={{ width: 100, margin: "15px auto", backgroundColor: detailColor }} />
			)}

			<div style={{ width: 165, margin: "0 auto" }}>
				<DashboardStatisticButton organization={organization} link={link} color={color} />
			</div>
		</Statistic>
	);
}
