import { useTranslation } from "react-i18next";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import Pipeline from "../../../pipelines/components/Pipeline/Pipeline";

import AdminCard from "../../components/AdminCard/AdminCard";

export default function AdminExportPipelineView({ export: theExport }) {
	const { t } = useTranslation();

	return (
		<AdminCard header={t("pipeline", "Pipeline")}>
			<LoadingContext data={theExport.pipeline?.steps}>
				<Pipeline document={theExport} />
			</LoadingContext>
		</AdminCard>
	);
}
