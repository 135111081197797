import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import SecondaryButton from "../../ui/components/Buttons/SecondaryButton";

export default function OldStartPageLink() {
	const { t } = useTranslation();

	return (
		<SecondaryButton
			size="tiny"
			as={Link}
			to="/home-old"
			content={t("oldStartPage", "To old start page")}
			style={{ marginBottom: 10 }}
		/>
	);
}
