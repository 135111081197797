import { useEffect, useMemo, useState } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import context from "astrid-helpers/src/audioContext";
import createAudioPlayer from "astrid-helpers/src/audioPlayer";

export default function useArtifactAudioPlayer({ audioFiles }) {
	const [state, setState] = useState({
		action: "stop",
		position: 0,
	});

	const player = useMemo(() => createAudioPlayer(context), []);

	const play = () => {
		setState({
			action: "play",
			position: Timeline.getPosition(),
		});
	};

	const pause = () => {
		setState({
			action: "stop",
			position: Timeline.getPosition(),
		});
	};

	useEffect(() => {
		const { action, position } = state;

		if (action === "play") {
			let stopped = false;
			let soundPlayer;

			const disconnect = player.connect();

			(async () => {
				await player.resume();

				const sounds = await Promise.all(
					audioFiles.map((file) => {
						const seek = Math.max(0, position - file.start);
						const start = Math.max(0, seek);
						const length = Math.max(0, file.length - seek);

						return player.createSoundFromUrl(file.url, start, length, {
							position: Math.max(0, file.start - position),
						});
					}),
				);

				if (!stopped) {
					soundPlayer = player.playSounds(sounds);

					soundPlayer.on("time", (time) => {
						Timeline.setPosition(position + time);
					});
				}
			})();

			return () => {
				stopped = true;
				soundPlayer?.stopSounds();
				disconnect();
			};
		}
	}, [audioFiles, player, state]);

	return {
		play,
		pause,
		action: state.action,
	};
}
