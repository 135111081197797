import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Article } from "astrid-firestore/src/api/articles/types/Article";
import { object } from "astrid-firestore/src/types/_types";

import api from "../../../../api";

import ArticlesInstantSearchSelect from "../../../articles/components/ArticlesInstantSearchSelect/ArticlesInstantSearchSelect";
import useArticleTypeOptions from "../../../articles/hooks/useArticleTypeOptions";
import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

export default function AddArticlesToTitleForm({ title, onClose }) {
	const { t } = useTranslation();

	const articleTypeOptions = useArticleTypeOptions();

	const { form, watch, setValue, onSubmit, error } = useSimpleForm({
		schema: object({
			article: Article.partial(),
		}),
		defaultValues: {
			article: null,
			action: "addExisting",
		},
		onSubmit: ({ article }) => {
			return api.titles.addArticle({ title, article });
		},
		onSuccess: onClose,
	});

	const { action } = watch();

	useEffect(() => {
		if (action === "createNew") {
			setValue("article.name", title.name);
			setValue("article.isbn", "");
			setValue("article.type", "");
		}
	}, [action, setValue, title.name]);

	return (
		<Form.Modal
			form={form}
			size="tiny"
			header={t("addArticle", "Add article")}
			onSubmit={onSubmit}
			onClose={onClose}
			error={error}
		>
			<Form.Group widths={3} style={{ justifyContent: "center" }}>
				<Form.RadioButton fluid basic name="action" value="addExisting">
					{t("addExisting", "Add existing")}
				</Form.RadioButton>

				<Form.RadioButton fluid basic name="action" value="createNew">
					{t("createNew", "Create new")}
				</Form.RadioButton>
			</Form.Group>

			{action === "addExisting" && (
				<ArticlesInstantSearchSelect name="article" publisher={title.publisher} noTitle />
			)}

			{action === "createNew" && (
				<>
					<Form.Input name="article.name" label={t("articleName", "Article name")} disabled />

					<Form.Input name="article.isbn" label={t("isbn", "ISBN")} />

					<Form.Select name="article.type" label={t("type", "Type")} options={articleTypeOptions} />
				</>
			)}
		</Form.Modal>
	);
}
