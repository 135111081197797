import { useTranslation } from "react-i18next";

import api from "../../../api";

export default function useArtifactDropdownMenuOptions({ article }) {
	const { t } = useTranslation();

	return (artifact) => [
		{
			as: "a",
			text: t("download", "Download"),
			href: artifact?.url,
			download: true,
		},
		{
			text: t("delete", "Delete"),
			disabled: article?.artifact?.id === artifact.id,
			action: async () => {
				await api.artifacts.delete(artifact);
			},
		},
	];
}
