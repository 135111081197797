import { useTranslation } from "react-i18next";

import Flex from "../../../../components/Flex/Flex";

import useArticleDeliveryStatus from "../../hooks/useArticleDeliveryStatus";

import ArticleDeliveryStatusLabel from "./components/ArticleDeliveryStatusLabel";

export default function ArticleDeliveryStatus({ article, channel }) {
	const { t } = useTranslation();

	const artifactStatus = useArticleDeliveryStatus("artifact", { article, channel });
	const metadataStatus = useArticleDeliveryStatus("metadata", { article, channel });

	return (
		<Flex gap={10} justifyContent="flex-start">
			<ArticleDeliveryStatusLabel text={t("artifact", "Artifact")} {...artifactStatus} />
			<ArticleDeliveryStatusLabel text={t("metaData", "Meta data")} {...metadataStatus} />
		</Flex>
	);
}
