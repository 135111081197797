import { useTranslation } from "react-i18next";

import ProductionDropdownMenu from "../components/ProductionDropdownMenu/ProductionDropdownMenu";

import useProductionStatusTranslations from "./useProductionStatusTranslations";
import useProductionTypeTranslations from "./useProductionTypeTranslations";

export default function useProductionsInstantSearchTableColumns() {
	const { i18n, t } = useTranslation();

	const productionTypeTranslations = useProductionTypeTranslations();
	const productionStatusTranslations = useProductionStatusTranslations();

	return [
		{
			id: "title",
			Header: t("Name", "Name"),
		},
		{
			id: "publisherName",
			Header: t("publisher", "Publisher"),
		},
		{
			id: "productionType",
			Header: t("type", "Type"),
			accessor: ({ productionType }) =>
				productionTypeTranslations[productionType === "internal" ? null : productionType],
		},
		{
			id: "status",
			Header: t("status", "Status"),
			accessor: ({ status }) => productionStatusTranslations[status],
		},
		{
			id: "productionDate",
			Header: t("productionDate", "Production date"),
			accessor: ({ productionDate_timestamp }) =>
				productionDate_timestamp
					? new Date(productionDate_timestamp * 1000).toLocaleDateString(i18n.language)
					: "",
		},
		{
			id: "doneDate",
			Header: t("doneDate", "Completion date"),
			accessor: ({ doneDate_timestamp }) =>
				doneDate_timestamp ? new Date(doneDate_timestamp * 1000).toLocaleDateString(i18n.language) : "",
		},
		{
			id: "created",
			Header: t("created", "Created"),
			collapsing: true,
			accessor: ({ created_timestamp }) =>
				created_timestamp ? new Date(created_timestamp * 1000).toLocaleDateString(i18n.language) : "",
		},
		{
			id: "menu",
			textAlign: "right",
			disableSortBy: true,
			collapsing: true,
			Cell: ({ row }) => <ProductionDropdownMenu production={row.original} />,
		},
	];
}
