import { useEffect } from "react";

import getCollectionDataById from "../utils/getCollectionDataById";

import useLoadingValue from "./useLoadingValue";

export default function useCollectionDataByIdsOnce(firebase, collection, ids = []) {
	const { value, loading, error, reset, setValue, setError } = useLoadingValue(true);

	useEffect(() => {
		getCollectionDataById(firebase, collection, ids).then(setValue).catch(setError);

		return reset;
	}, [...ids]);

	return [value, loading, error];
}
