import { useRef, useState } from "react";

import { getFileDuration } from "astrid-helpers/src/fileReader";

export default function useFileInput({ multiple = false }) {
	const ref = useRef();

	const [files, setFiles] = useState([]);

	const handleFiles = async (selectedFiles) => {
		const updatedFiles = await Promise.all(
			[...selectedFiles].map(async (file) => {
				const duration = await getFileDuration(file);
				return Object.defineProperty(file, "duration", {
					value: duration,
					writable: false,
					enumerable: true,
					configurable: true,
				});
			}),
		);

		setFiles(updatedFiles);
	};

	const input = (
		<input
			style={{ display: "none" }}
			ref={ref}
			type="file"
			multiple={multiple}
			onChange={(e) => handleFiles(e.target.files)}
		/>
	);

	const onClick = () => {
		ref.current.click();
	};

	return { files, input, onClick };
}
