import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import CreateDistributionArgsForm from "./CreateDistributionArgsForm";

export default function CreateDistributionArgsButton({ publisher, ...props }) {
	const { t } = useTranslation();

	return (
		<OpenButton text={t("addArgs", "Add args")} {...props}>
			{({ setOpen }) => <CreateDistributionArgsForm publisher={publisher} onClose={() => setOpen(false)} />}
		</OpenButton>
	);
}
