import React, { useEffect } from "react";

import actionArgs from "astrid-distribution/src/actions/actionArgs";

import Form from "../../../../forms/components/Form/Form";

import PipelineStepActionArg from "./PipelineStepActionArg";

function getDefaultValue(arg) {
	switch (arg.type) {
		case "string":
			return "";
		case "boolean":
			return false;
		default:
			return null;
	}
}

export default function PipelineStepActionArgs({ name, action }) {
	const { getValues, setValue } = Form.useFormContext();

	const args = actionArgs[action];

	useEffect(() => {
		if (args) {
			const values = getValues(`${name}.args`);

			const defaultValues = args.reduce(
				(acc, arg) => ({
					...acc,
					[arg.id]: values?.[arg.id] || arg.defaultValue || getDefaultValue(arg),
				}),
				{},
			);

			setValue(`${name}.args`, defaultValues);
		}
	}, [args, getValues, name, setValue]);

	if (!args) {
		return null;
	}

	return (
		<>
			{args.map((arg) => (
				<PipelineStepActionArg key={arg.id} arg={arg} name={`${name}.args.${arg.id}`} />
			))}
		</>
	);
}
