import { Button } from "semantic-ui-react";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AddUserLanguageModal from "../../../../account/views/AccountSettingsView/components/AddUserLanguageModal";
import AdminCard from "../../../../admin/components/AdminCard/AdminCard";
import UserLanguagesTable from "../../../../users/components/UserLanguagesTable/UserLanguagesTable";

export default function UserLanguageSkills({ user }) {
	const { t } = useTranslation();

	const [openModal, setOpenModal] = useState(false);

	return (
		<>
			<AdminCard
				header={t("languageSkills", "Language skills")}
				extra={
					<Button primary onClick={() => setOpenModal(true)}>
						{t("add", "Add")}
					</Button>
				}
			>
				<UserLanguagesTable user={user} />
			</AdminCard>

			{openModal && <AddUserLanguageModal user={user} onClose={() => setOpenModal(false)} />}
		</>
	);
}
