import moment from "moment/moment";

import { Button, Header, Segment } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import Flex from "../../../components/Flex/Flex";
import ErrorMessage from "../../ui/components/Messages/ErrorMessage";
import Message from "../../ui/components/Messages/Message";

import Form from "../../forms/components/Form/Form";
import useSimpleForm from "../../forms/hooks/useSimpleForm";

import DurationForQuarter from "../components/DurationForQuarter";
import IndexQuestions from "../components/IndexQuestions";
import fetchStatistics from "../helpers/fetchStatistics";
import generateXLXS from "../helpers/generateXLXS";

const dateTypes = {
	STATISTICS_DATE: "statisticsDate",
	DELIVERY_DATE: "production.deliveryDate",
};

export default function ProducerStatisticsView({ organization }) {
	const { t } = useTranslation();

	const { form, formState, error, onSubmit } = useSimpleForm({
		defaultValues: {
			dateType: dateTypes.STATISTICS_DATE,
			fromDate: moment(new Date()),
			toDate: moment(new Date()),
		},
		onSubmit: async ({ dateType, fromDate, toDate }) => {
			if (fromDate > toDate) {
				throw new Error("To date needs to be after from date");
			}

			const statistics = await fetchStatistics({
				producerId: organization.id,
				dateType,
				fromDate,
				toDate,
			});

			await generateXLXS(statistics);
		},
	});

	return (
		<Flex justifyContent="center">
			<Segment style={{ width: "60%" }} padded>
				<Header as="h2" textAlign="center">
					{t("statistics", "Statistics")}
				</Header>

				<Segment.Group>
					<Segment>
						<Header as="h3">{t("dates", "Dates")}</Header>
					</Segment>

					<Segment>
						<Form form={form}>
							<Form.Group>
								<Form.Radio
									name="dateType"
									label={t("doneDate", "Completion date")}
									value={dateTypes.STATISTICS_DATE}
								/>

								<Form.Radio
									name="dateType"
									label={t("deliveryDate", "Delivery date")}
									value={dateTypes.DELIVERY_DATE}
								/>
							</Form.Group>

							<Form.Group widths="equal">
								<Form.DateInput name="fromDate" label={t("fromDate", "From date")} disableBeforeToday />

								<Form.DateInput name="toDate" label={t("toDate", "To date")} disableBeforeToday />
							</Form.Group>
						</Form>

						{error && <ErrorMessage error={error} />}

						{formState.isSubmitSuccessful && !error && (
							<Message
								header={t(
									"successfullyFetchedProductionStatistics",
									"Successfully fetched production statistics",
								)}
							>
								{t("seeDownloadedFiles", "See downloaded files")}
							</Message>
						)}

						<Button
							primary
							fluid
							loading={formState.isSubmitting}
							disabled={formState.isSubmitting}
							onClick={onSubmit}
						>
							{t("downloadStatistics", "Download statistics")}
						</Button>
					</Segment>
				</Segment.Group>

				<DurationForQuarter />

				<IndexQuestions />
			</Segment>
		</Flex>
	);
}
