import { Icon } from "semantic-ui-react";

import React from "react";

export default function UserProfileInformation({ icon, value, children }) {
	if (!value) {
		return null;
	}

	return (
		<div>
			{<Icon name={icon} />}
			{children}
		</div>
	);
}
