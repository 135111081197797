import { useOutletContext, useParams } from "react-router-dom";

import ItemDeletedMessage from "../../../components/ItemDeletedMessage";
import renderChildren from "../../../utils/renderChildren";
import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";

export default function FirestoreDocumentContext({ children, param, api }) {
	const params = useParams();
	const context = useOutletContext();

	const [data, loading, error] = api.useById(params[param || "id"]);

	const nextContext = {
		...context,
		[param || "data"]: data,
	};

	if (data?.deleted) {
		return <ItemDeletedMessage id={data.id} />;
	}

	return (
		<LoadingContext data={data} loading={loading} error={error}>
			{data && renderChildren(children, nextContext)}
		</LoadingContext>
	);
}
