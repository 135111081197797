import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";
import "intl-pluralrules";
import { locizePlugin } from "locize";
import locizeLastUsed from "locize-lastused";
import { z } from "zod";
import { makeZodI18nMap } from "zod-i18n-map";

import { initReactI18next } from "react-i18next";

import { isLocal } from "astrid-helpers/src/env";

i18n.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.use(locizePlugin)
	.use(locizeLastUsed)
	.init({
		defaultNS: "common",
		fallbackLng: "en",
		preload: ["en", "sv"],
		keyseparator: false, //don't use keys in form ....
		interpolation: {
			escapeValue: false, //react already saves from xss ...?
			format: function (value, format, lng) {
				if (value && format === "upperfirst") return value.charAt(0).toUpperCase() + value.slice(1);
				if (value && format === "uppercase") return value.toUpperCase();
				if (value && format === "lowercase") return value.toLowerCase();
				return value;
			},
		},
		backend: {
			referenceLng: "en",
			apiKey: "61e3ff95-1c69-4ee0-aa5f-ff615bcb0286",
			projectId: "c44a5cf2-154e-4cba-ba67-95868adf7258",
			version: window.location.hostname === "astrid.fm" ? "single-namespace-live" : "single-namespace",
		},
		react: {
			useSuspense: false,
			allowedHosts: ["localhost", "astrid.fm", "beta.astrid.fm", "stage.astrid.fm"],
		},
		// saveMissing: isLocal,
		detection: {
			convertDetectedLanguage: (lng) => {
				const language = lng.split("-")[0];

				return language === "sv" ? "sv" : "en";
			},
		},
		locizeLastUsed: {
			debounceSubmit: isLocal ? 1000 * 60 : 1000 * 60 * 5,
		},
	})
	.then((t) => {
		z.setErrorMap(makeZodI18nMap({ t, ns: "common" }));
	});

export default i18n;
