import { Button, Card } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import { firebase } from "astrid-firebase";
import createPrices from "astrid-firestore/src/api/prices/createPrices";

import Form from "../../../forms/components/Form/Form";

import CurrencySelect from "../CurrencySelect/CurrencySelect";

import PricesFormTable from "./components/PricesFormTable";
import usePricesForm from "./hooks/usePricesForm";

export default function PricesForm({ organization, afterSubmit }) {
	const { t } = useTranslation();

	const form = usePricesForm(organization);

	const { handleSubmit, formState } = form;
	const { isSubmitting, isValid } = formState;

	const onSubmit = async ({ prices, currency, languages }) => {
		await createPrices(firebase, { organization, prices, currency, languages });

		afterSubmit?.();
	};

	return (
		<Form.FormProvider form={form}>
			<Card.Group>
				<Card fluid>
					<Card.Content>
						<Card.Header>{t("settings", "Settings")}</Card.Header>
					</Card.Content>
					<Card.Content>
						<Form.Form>
							<Form.Group widths="equal">
								<Form.LanguageSelect
									name="languages"
									label={t("productionLanguage", "Production language")}
									limitedOptions={organization.languageOptions}
									multiple
								/>

								<Form.Field name="currency">
									<CurrencySelect
										label={t("currency", "Currency")}
										limitedOptions={organization?.currencyOptions}
									/>
								</Form.Field>
							</Form.Group>
						</Form.Form>
					</Card.Content>
				</Card>
				<Card fluid>
					<Card.Content>
						<Card.Header>{t("prices", "Prices")}</Card.Header>
					</Card.Content>
					<Card.Content>
						<Form.Form>
							<PricesFormTable />
						</Form.Form>
					</Card.Content>
				</Card>
				<Card fluid>
					<Card.Content>
						<Button
							primary
							loading={isSubmitting}
							disabled={!isValid || isSubmitting}
							onClick={handleSubmit(onSubmit)}
						>
							{t("addPrices", "Add prices")}
						</Button>
					</Card.Content>
				</Card>
			</Card.Group>
		</Form.FormProvider>
	);
}
