import { z } from "zod";

import { boolean } from "./_types";

const FirestoreDocument = z.object({
	id: z.string(),
	ref: z.any(),
	exists: boolean(false),
});

export default FirestoreDocument;
