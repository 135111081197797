import dot from "dot-object";

dot.useArray = false;
dot.keepArray = true;
dot.override = true;

export function dotObject(data) {
	return dot.object(Object.assign({}, data));
}

export function fromDotObject(data) {
	return dotObject(data);
}

export function toDotObject(data) {
	return dot.dot(data, {});
}

export function dotPick(key, data, remove) {
	return dot.pick(key, data, remove);
}

export function dotDelete(key, data) {
	return dot.delete(key, data);
}

export default dot;
