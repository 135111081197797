import { dotDelete, fromDotObject } from "../utils/dotObject";

import OptimisticUpdate from "./OptimisticUpdate";

export default class OptimisticDocument extends OptimisticUpdate {
	constructor(ref = null, before, after) {
		super(before, after);

		this.ref = ref;
	}

	update(data) {
		const keys = Object.keys(data);

		if (keys.length !== 0) {
			const deletedKeys = Object.entries(data)
				.filter(([, value]) => value?._delegate?._methodName === "FieldValue.delete")
				.map(([key]) => key);

			this.after = fromDotObject({ ...this.after, ...data });

			for (const key of deletedKeys) {
				dotDelete(key, this.after);
			}
		}
	}

	create(data) {
		this.before = {
			id: this.ref.id,
			ref: this.ref,
			exists: false,
		};

		this.after = {
			...this.before,
			...data,
			exists: true,
		};
	}

	delete() {
		this.after = {
			id: this.ref.id,
			ref: this.ref,
			exists: false,
		};
	}
}
