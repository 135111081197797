import recorderIsPartOfTeam from "astrid-web/src/features/calendar/helpers/recorderIsPartOfTeam";
import sessionIsAnyRecorderInTeam from "astrid-web/src/features/calendar/helpers/sessionsIsAnyRecorderInTeam";

import sessionOfferTypes from "../../constants/sessionOfferTypes";
import { teamRoles } from "../../constants/teamRoles";
import runTransaction from "../../helpers/runTransaction";
import { getPriceRef, getSessionRef, getUserRef } from "../../utils/referenceMappers";

import createProductionPriceData from "../productions/prices/createProductionPriceData";
import createTeamMemberData from "../productions/team/createTeamMemberData";

import getSessionPriceAndAgreement from "./getSessionPriceAndAgreement";

const SESSION_NOT_AVAILABLE = "Session is no longer available!";

function isNotPartOfTeamAsRecorder({ production, user }) {
	return !Object.values(production.team || {}).find(
		(teamMember) => teamMember.role === teamRoles.RECORDER && teamMember.user?.id === user.id,
	);
}

export function canGrabSession({ session, user, production }) {
	return (
		session.status === "booked" &&
		(session.recorder === sessionOfferTypes.OPEN_POSITION_RECORDER ||
			(sessionIsAnyRecorderInTeam(session.recorder) && recorderIsPartOfTeam({ production, user }))) &&
		session.end.toDate() > new Date() &&
		!!user?.prices?.[production?.producer]?.[`${production?.language}-${teamRoles.RECORDER}`]
	);
}

export default function grabSession(firebase, { session, production, user }) {
	const db = firebase.firestore();
	const sessionRef = db.collection("sessions").doc(session.id);

	return runTransaction(db, [sessionRef], (transaction, [transactionSession]) => {
		if (
			transactionSession.status !== "booked" ||
			(transactionSession.recorder &&
				![sessionOfferTypes.OPEN_POSITION_RECORDER, sessionOfferTypes.ANY_RECORDER_IN_TEAM].includes(
					transactionSession.recorder,
				))
		) {
			throw SESSION_NOT_AVAILABLE;
		}

		const { price, agreement, defaultUserPrice, priceId } = getSessionPriceAndAgreement({
			session,
			production,
			user,
			isGrab: true,
		});

		const productionPrice =
			price &&
			createProductionPriceData(firebase, {
				...price,
				expense: true,
				approved: true,
				producer: session.producer,
				production,
				agreement,
				firstParty: agreement?.firstParty,
				secondParty: agreement?.secondParty,
				referencePrice: price,
				session: getSessionRef({ ...session, ref: sessionRef }),
			});

		const sessionUpdate = {
			recorder: user.id,
			recorderData: getUserRef(user),
			priceId: priceId || null,
			priceRef: price || null, // null = teamrecorder without price
			agreementRef: agreement || null, // null = teamrecorder without price
			productionPriceRef: productionPrice?.ref || null,
		};

		if (
			session.recorder === sessionOfferTypes.OPEN_POSITION_RECORDER &&
			isNotPartOfTeamAsRecorder({ production, user })
		) {
			const member = createTeamMemberData(firebase, {
				user,
				agreement,
				production,
				role: teamRoles.RECORDER,
				firstParty: defaultUserPrice?.firstParty,
			});

			transaction.update(production.ref, {
				[`team.${member.id}`]: { ...member, price: getPriceRef(defaultUserPrice) },
			});

			if (session.priceRef === "default") {
				transaction.update(sessionRef, { ...sessionUpdate, priceId: member.id });
			}
		} else {
			transaction.update(sessionRef, sessionUpdate);
		}
		if (productionPrice) {
			transaction.set(productionPrice.ref, productionPrice);
		}
	});
}
