import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import InstantSearchDataTable from "../../../ui/components/InstantSearch/InstantSearchDataTable";
import useSearchInput from "../../../ui/hooks/useSearchInput";

import AddUserButton from "../../../users/components/AddUser/AddUserButton";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

import useAdminReadersColumns from "./hooks/useAdminReadersColumns";

export default function AdminReadersView({ organization }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const columns = useAdminReadersColumns();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("readers", "Narrators")}>
				{searchInput}
				<AddUserButton organization={organization} reader />
			</AdminHeader>

			<InstantSearchDataTable
				indexName="readers"
				searchQuery={searchQuery}
				columns={columns}
				filters={`organizationIds:${organization.id}`}
				onClick={({ objectID }) => navigate(objectID)}
			/>
		</>
	);
}
