import React from "react";
import { useTranslation } from "react-i18next";

import { Article } from "astrid-firestore/src/api/articles/types/Article";

import api from "../../../../api";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import ArticleDistributionChannels from "../../../articles/components/ArticleDistributionChannels/ArticleDistributionChannels";
import useArticleDistributionChannels from "../../../articles/hooks/useArticleDistributionChannels";
import useArticleStatusOptions from "../../../articles/hooks/useArticleStatusOptions";
import DistributionsCollectionDataTable from "../../../distribution/components/DistributionsCollectionDataTable/DistributionsCollectionDataTable";
import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

import AdminCard from "../../components/AdminCard/AdminCard";
import AdminForm from "../../components/AdminForm/AdminForm";

import AdminArticleDistributionCountries from "./components/AdminArticleDistributionCountries";
import AdminArticleDistributionDatesForm from "./components/AdminArticleDistributionDatesForm";

export default function AdminArticleDistributionView({ article, publisher, ...props }) {
	const { t } = useTranslation();

	const statusOptions = useArticleStatusOptions();
	const [presetChannels, articleChannels, loading, error] = useArticleDistributionChannels({ publisher, article });

	const form = useSimpleForm({
		schema: Article,
		values: {
			ref: article.ref,
			deliveryDate: article.deliveryDate?.toDate() || null,
			status: article.status || null,
		},
		onSubmit: (data) => {
			return api.articles.update(data);
		},
		...props,
	});

	return (
		<>
			<AdminForm header={t("general", "General")} {...form}>
				<Form.Group widths="equal">
					<Form.Select name="status" label={t("status", "Status")} options={statusOptions} />

					<Form.DateInput name="deliveryDate" label={t("deliveryDate", "Delivery date")} disableBeforeToday />
				</Form.Group>
			</AdminForm>

			<LoadingContext data={presetChannels} loading={loading} error={error}>
				<AdminCard header={t("channels", "Channels")}>
					<ArticleDistributionChannels channels={presetChannels} article={article} publisher={publisher} />
				</AdminCard>

				<AdminArticleDistributionDatesForm article={article} channels={articleChannels} />

				<AdminArticleDistributionCountries channels={articleChannels} publisher={publisher} article={article} />
			</LoadingContext>

			<AdminCard header={t("distributions", "Distributions")}>
				<DistributionsCollectionDataTable article={article} />
			</AdminCard>
		</>
	);
}
