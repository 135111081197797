import { Card, Icon } from "semantic-ui-react";

import React from "react";
import { MiddleTruncate } from "react-middle-truncate";
import { Link } from "react-router-dom";

import { languageName } from "astrid-helpers/src/languages";

import { useOrganizationId } from "../../../organizations/state/organizationId";
import { useOrganizationType } from "../../../organizations/state/organizationType";

import useArticleTypeTranslations from "../../hooks/useArticleTypeTranslations";
import articleTypeIcons from "../../utils/articleTypeIcons";

import ArticleCoverImage from "../ArticleCoverImage/ArticleCoverImage";

export default function ArticleCard({ article }) {
	const orgId = useOrganizationId();
	const orgType = useOrganizationType();

	const articleTypeTranslations = useArticleTypeTranslations();

	return (
		<Card
			raised
			key={article.id}
			as={Link}
			to={`/${orgType}/${orgId}/articles/${article.id}`}
			style={{ color: "inherit" }}
		>
			<ArticleCoverImage thumb article={article} />

			<Card.Content style={{ display: "flex", flexDirection: "column" }}>
				<Card.Header>
					<MiddleTruncate text={article.name} />
				</Card.Header>

				<Card.Meta style={{ marginTop: 4 }}>
					<MiddleTruncate text={article.isbn} />
				</Card.Meta>

				<Card.Meta>
					<MiddleTruncate text={languageName(article.language)} />
				</Card.Meta>
			</Card.Content>

			<Card.Content extra>
				<Icon name={articleTypeIcons[article.type]} />
				{articleTypeTranslations[article.type]}
			</Card.Content>
		</Card>
	);
}
