import { useTranslation } from "react-i18next";

import { DistributionPreset } from "astrid-firestore/src/api/distribution/types/DistributionPreset";
import { languageOptions } from "astrid-helpers/src/languages";

import api from "../../../../api";
import Collapsible from "../../../ui/components/Collapsible/Collapsible";
import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";
import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import ArticleTypeSelect from "../../../articles/components/ArticleTypeSelect/ArticleTypeSelect";
import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

import CountriesIncludedForm, { WORLD_OPTION } from "../CountriesIncluded/CountriesIncludedForm";

export default function CreateDistributionPresetForm({ publisher, preset, onClose }) {
	const { t } = useTranslation();

	const { form, onSubmit, error, setValue, watch } = useSimpleForm({
		schema: DistributionPreset,
		values: {
			channel: preset?.channel || null,
			articleType: preset?.articleType || null,
			languages: preset?.languages || [],
			targetData: {
				countries: preset?.targetData?.countries || [WORLD_OPTION],
			},
		},
		onSubmit: ({ channel, articleType, languages, targetData }) => {
			const data = { publisher, channel, articleType, languages, targetData };
			if (preset) {
				return api.organizations.updateDistributionPreset({ id: preset.id, ...data });
			}
			return api.organizations.addDistributionPreset(data);
		},
		onSuccess: onClose,
	});

	const { channel } = watch();

	const onChangeOptions =
		(name) =>
		({ value }) => {
			if (value.length > 1 && value.includes("all")) {
				const last = value[value.length - 1];

				setValue(name, last === "all" ? ["all"] : value.filter((v) => v !== "all"));
			}
		};

	const langOptions = channel?.acceptedLanguages?.length
		? languageOptions.filter((option) => channel.acceptedLanguages.includes(option.value))
		: languageOptions;

	return (
		<ModalPrompt
			header={t("addPreset", "Add preset")}
			onSave={onSubmit}
			onClose={onClose}
			closeOnConfirm={false}
			error={error}
		>
			<Form form={form}>
				<Form.CollectionSelect
					name="channel"
					label={t("channel", "Channel")}
					collection={api.distributionChannels}
					clearable
				/>
				<Form.Field name="articleType">
					<ArticleTypeSelect
						channel={channel}
						label={t("articleType", "Article type")}
						clearable
						disabled={!channel}
					/>
				</Form.Field>

				<Form.LanguageSelect
					name="languages"
					label={t("languages", "Languages")}
					additionalOptions={[{ key: "all", value: "all", text: t("all", "All") }]}
					options={langOptions}
					onChangeOptions={onChangeOptions("languages")}
					multiple
					clearable
					disabled={!channel}
				/>
				<Collapsible title={t("countries", "Countries")} disabled={!channel} style={{ marginBottom: 10 }}>
					<CountriesIncludedForm name="targetData.countries" />
				</Collapsible>
			</Form>

			{error && <ErrorMessage error={error}>{t("errorMessageHeader", "Something went wrong")}</ErrorMessage>}
		</ModalPrompt>
	);
}
