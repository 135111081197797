import { useTranslation } from "react-i18next";

import articleDeliveryStatuses from "astrid-firestore/src/api/articles/constants/articleDeliveryStatuses";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import ArticlesInstantSearchDataTable from "../../../articles/components/ArticlesInstantSearchDataTable/ArticlesInstantSearchDataTable";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

export default function AdminArticlesDeliveredView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("deliveredPlural", "Delivered")}>{searchInput}</AdminHeader>

			<ArticlesInstantSearchDataTable
				selectable
				publisher={organization}
				searchQuery={searchQuery}
				deliveryStatuses={[articleDeliveryStatuses.DELIVERY_COMPLETED]}
				showDistributeButton
				showArticleBundleButton
			/>
		</>
	);
}
