import { getDocumentData } from "astrid-firestore/src/helpers";

function mergeWithoutNull({ defaults, channelSpecific }) {
	const result = { ...defaults };

	for (const key in channelSpecific) {
		if (channelSpecific[key] !== null) {
			result[key] = channelSpecific[key];
		}
	}

	return result;
}

function mergeDistributionDates({ article, channelId }) {
	const defaults = article?.distribution || {};
	const channelSpecifics = article?.channels?.[channelId]?.distribution || {};

	return mergeWithoutNull({ defaults, channelSpecifics });
}

export default async function fetchMetadata({ document }) {
	const { article } = document;

	const linkedArticle = await getDocumentData(article.linkedArticle?.ref);

	return {
		...document,
		article: {
			...article,
			distributionDates: mergeDistributionDates({ article, channelId: document.channel.id }),
		},
		linkedArticle: linkedArticle
			? {
					...linkedArticle,
					distributionDates: mergeDistributionDates({
						article: linkedArticle,
						channelId: document.channel.id,
					}),
			  }
			: null,
	};
}
