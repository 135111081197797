import uniqid from "uniqid";

import FirestoreCollection from "../../firestore/FirestoreCollection";

import { DistributionChannelRef } from "../distribution/types/DistributionChannelRef";

import { Organization } from "./types/Organization";

export default class OrganizationsCollection extends FirestoreCollection {
	static collectionName = "organizations";
	static Schema = Organization;
	static softDeletes = false;

	addDistributionPreset({ publisher, channel, articleType, languages, targetData }) {
		const id = uniqid();
		return this.update(
			{
				ref: publisher.ref,
				[`distribution.presets.${id}`]: {
					id,
					languages,
					channel: DistributionChannelRef.parse(channel),
					articleType,
					targetData,
				},
			},
			{ validate: false },
		);
	}

	updateDistributionPreset({ id, publisher, channel, articleType, languages, targetData }) {
		return this.update(
			{
				ref: publisher.ref,
				[`distribution.presets.${id}`]: {
					id,
					languages,
					channel: DistributionChannelRef.parse(channel),
					articleType,
					targetData,
				},
			},
			{ validate: false },
		);
	}

	removeDistributionPreset({ publisher, preset }) {
		return this.update(
			{
				ref: publisher.ref,
				[`distribution.presets.${preset.id}`]: this.utils.delete(),
			},
			{
				validate: false,
			},
		);
	}

	addDistributionArgs({ publisher, channel, action, args }) {
		return this.update(
			{
				ref: publisher.ref,
				[`distribution.channels.${channel.id}.actions.${action}.args`]: args,
			},
			{
				validate: false,
			},
		);
	}

	removeDistributionArgs({ publisher, channel, action }) {
		return this.update(
			{
				ref: publisher.ref,
				[`distribution.channels.${channel.id}.actions.${action}`]: this.utils.delete(),
			},
			{
				validate: false,
			},
		);
	}
}
