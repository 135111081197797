import { Button, Modal } from "semantic-ui-react";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { updateDocument } from "astrid-firestore/src/helpers";

import Form from "../../../../forms/components/Form/Form";
import useForm from "../../../../forms/hooks/useForm";
import ErrorMessage from "../../../../ui/components/Messages/ErrorMessage";

import useSkillLevelOptions from "../hooks/useSkillLevelOptions";

export default function AddUserLanguageModal({ user, onClose }) {
	const { t } = useTranslation();
	const skillLevelOptions = useSkillLevelOptions();

	const [error, setError] = useState(false);

	const form = useForm({
		defaultValues: {
			language: null,
			skill: null,
			dialect: "",
		},
	});

	const { handleSubmit, formState } = form;

	const { isSubmitting, isValid } = formState;

	const onSubmit = async ({ language, skill, dialect }) => {
		try {
			await updateDocument(user.ref, {
				[`languages.${language}`]: {
					skill,
					dialect,
				},
			});

			onClose();
		} catch (error) {
			console.log(error);
			setError(error);
		}
	};

	return (
		<Modal open closeOnDimmerClick onClose={onClose} size="small">
			<Modal.Header>{t("addLanguage", "Add language")}</Modal.Header>
			<Modal.Content>
				<Form form={form}>
					<Form.Group widths="equal">
						<Form.LanguageSelect
							name="language"
							label={t("language", "Language")}
							filterOptions={({ value }) => !user.languages?.[value]}
						/>

						<Form.Select
							name={"skill"}
							label={t("skillLevel", "Skill level")}
							options={skillLevelOptions}
						/>
					</Form.Group>

					<Form.Input name={"dialect"} label={t("accent", "Accent")} rules={{ required: false }} />
				</Form>

				{error && <ErrorMessage error={error}>{t("errorMessageHeader", "Something went wrong")}</ErrorMessage>}
			</Modal.Content>

			<Modal.Actions>
				<Button onClick={onClose}>{t("cancel", "Cancel")}</Button>

				<Button primary loading={isSubmitting} disabled={!isValid} onClick={handleSubmit(onSubmit)}>
					{t("add", "Add")}
				</Button>
			</Modal.Actions>
		</Modal>
	);
}
