import { useState } from "react";

import List from "astrid-components/lib/components/Data/List";

import useArtifactAudioPlayerTimeline from "../hooks/useArtifactAudioPlayerTimeline";

import ArtifactAudioPlayerChapter from "./ArtifactAudioPlayerChapter";

export default function ArtifactAudioPlayerChapters({ markers }) {
	const { moveToPosition } = useArtifactAudioPlayerTimeline();

	const [activeIndex, setActiveIndex] = useState();

	const item = (index) => {
		const marker = markers[index];

		return (
			<ArtifactAudioPlayerChapter
				first={index === 0}
				last={index === markers.length - 1}
				marker={marker}
				active={activeIndex === index}
				onClick={() => {
					setActiveIndex(index);

					moveToPosition(marker.position);
				}}
			/>
		);
	};

	return <List.Variable itemContent={item} totalCount={markers.length} />;
}
