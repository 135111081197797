import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Select from "../../../ui/components/Select/Select";

import useFormField from "../../hooks/useFormField";

export default function FormDocumentSelect({ onAddDocument, options, multiple, ...props }) {
	const { t } = useTranslation();

	const [allOptions, setAllOptions] = useState(options);

	useEffect(() => {
		setAllOptions(options);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options.length]);

	const { field } = useFormField(props);

	const addDocument = async (value) => {
		const data = await onAddDocument(value);

		if (data?.id) {
			setAllOptions((options) => [
				{
					key: data.id,
					text: value,
					value: data.id,
					data,
				},
				...options,
			]);
		}

		return data;
	};

	const onChange = async (e, { value, foundOptions }) => {
		const values = multiple ? value : [value];

		const additions = values.filter((value) => value && !allOptions.find((option) => option.value === value));

		const optionsData = foundOptions.map(({ data }) => data);

		const data = [...optionsData, ...(await Promise.all(additions.map(addDocument)))];

		field.onChange(e, { value: multiple ? data : data[0] || null });
	};

	return (
		<Select
			{...field}
			value={multiple ? field.value?.map?.(({ id }) => id) : field.value?.id || null}
			options={allOptions}
			onChange={onChange}
			multiple={multiple}
			ref={undefined}
		/>
	);
}
