export function getFormDataValue({ e, data, valueField }) {
	if (data) {
		switch (data?.type) {
			case "range":
			case "number":
				return data[valueField] ? +data[valueField] : null;
			case "file":
				return data.multiple ? e.target.files : e.target.files[0];
			default:
				return data[valueField];
		}
	}

	return e?.target?.[valueField] || e?.target?.value || e;
}

export function getFormFieldValue({ data, value }) {
	if (value instanceof FileList || value instanceof File) {
		return data?.value;
	}

	return value;
}
