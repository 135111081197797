import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";
import useSearchInput from "../../../ui/hooks/useSearchInput";

import CreateStudioButton from "../../../studios/components/CreateStudio/CreateStudioButton";
import useStudiosQuery from "../../../studios/hooks/useStudiosQuery";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

import useAdminStudiosColumns from "./hooks/useAdminStudiosColumns";

export default function AdminStudiosView({ gen, organization }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const query = useStudiosQuery({ gen, organizationId: organization?.id });
	const columns = useAdminStudiosColumns({ gen });

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("studios", "Studios")}>
				{searchInput}

				<CreateStudioButton gen={gen} organization={organization} />
			</AdminHeader>

			<CollectionDataTable
				persist
				filterable={false}
				query={query}
				columns={columns}
				onClick={({ id }) => navigate(`../${id}`)}
				searchQuery={searchQuery}
				searchableKeys={["name"]}
				sortBy={{
					id: "name",
					desc: false,
				}}
			/>
		</>
	);
}
