import React from "react";

import Form from "../../../../forms/components/Form/Form";
import DataTable from "../../../../ui/components/DataTable/DataTable";

import useArtifactFilesTableColumns from "../../../hooks/useArtifactFilesTableColumns";

import ArtifactFilesDataTableFooter from "./ArtifactFilesDataTableFooter";

export default function ArtifactDataTableForm({ artifact, onSubmit }) {
	const { watch } = Form.useFormContext();

	const { files } = watch();

	const columns = useArtifactFilesTableColumns({ artifact });

	return (
		<DataTable
			allowEmpty
			selectable
			autoResetSelectedRows
			sortable={false}
			pagination={false}
			data={[...files]}
			columns={columns}
			footer={<ArtifactFilesDataTableFooter artifact={artifact} onSubmit={onSubmit} />}
		/>
	);
}
