import Accordion from "../../../../ui/components/Accordion/Accordion";

import PipelineStep from "./PipelineStep";

const style = {
	border: "2px solid grey",
	boxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
	marginTop: 0,
};

export default function PipelineSteps({ steps, progress }) {
	return (
		<Accordion styled fluid style={style}>
			{steps.map((step) => (
				<PipelineStep key={step.key} steps={steps} step={step} progress={progress} />
			))}
		</Accordion>
	);
}
