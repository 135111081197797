import { useOutletContext, useParams } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import AdminPageView from "../components/AdminPage/AdminPageView";
import AdminAgreementView from "../views/AdminAgreementView/AdminAgreementView";

export default function AdminAgreement() {
	const { id } = useParams();
	const { organization } = useOutletContext();

	const hasRight = useHasRight();

	if (!hasRight("agreements.view")) {
		return <Unauthorized />;
	}

	return (
		<AdminPageView id={id} organization={organization}>
			<AdminAgreementView />
		</AdminPageView>
	);
}
