import { useOutletContext, useParams } from "react-router-dom";

import CreateProductionLayout from "../layouts/CreateProductionLayout";
import CreateProductionPublisherView from "../views/CreateProductionPublisherView/CreateProductionPublisherView";

export default function CreateProductionPublisher() {
	const { organization } = useOutletContext();
	const { articleId } = useParams();

	return (
		<CreateProductionLayout>
			<CreateProductionPublisherView organization={organization} articleId={articleId} />
		</CreateProductionLayout>
	);
}
