import FirestoreCollection from "../../firestore/FirestoreCollection";

import { Distribution } from "./types/Distribution";
import { filterDistributionChannelsByArticles } from "./utils/filterDistributionChannels";

export default class DistributionsCollection extends FirestoreCollection {
	static collectionName = "distributions";
	static Schema = Distribution;

	preProcess(data) {
		if (data.article?.publisher) {
			data.publisher = data.article.publisher;
		}

		if (data.article?.artifact) {
			data.artifact = data.article.artifact;
		}

		return data;
	}

	distribute({ delivery, publisher, articles, channels }) {
		const filteredData = filterDistributionChannelsByArticles({ publisher, articles, channels });

		const documents = filteredData.flatMap(({ article, filteredChannels }) => {
			return filteredChannels.map((channel) => {
				return { article, channel, delivery };
			});
		});

		return this.createAll(documents);
	}
}
