import { Route } from "react-router-dom";

import AdminArticleArtifacts from "../../pages/AdminArticle/AdminArticleArtifacts";

import adminArtifactRoute from "./adminArtifactRoute";

const adminArtifactsRoute = (
	<Route path="artifacts" handle={{ crumb: ({ t }) => t("artifacts", "Artifacts") }}>
		<Route index element={<AdminArticleArtifacts />} />

		{adminArtifactRoute}
	</Route>
);

export default adminArtifactsRoute;
