import { Card, Header } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import Flex from "../../../../../components/Flex/Flex";
import ArticleCoverImage from "../../../../articles/components/ArticleCoverImage/ArticleCoverImage";
import ArticleDeliveryStatus from "../../../../articles/components/ArticleDeliveryStatus/ArticleDeliveryStatus";
import useArticleTypeTranslations from "../../../../articles/hooks/useArticleTypeTranslations";

import AdminPageDropdownMenu from "../../../components/AdminPage/AdminPageDropdownMenu";
import AdminPageMenu from "../../../components/AdminPage/AdminPageMenu";

import useAdminArticleDropdownMenuOptions from "../hooks/useAdminArticleDropdownMenuOptions";
import useAdminArticleMenuOptions from "../hooks/useAdminArticleMenuOptions";

export default function AdminArticleHeader({ article }) {
	const { t } = useTranslation();
	const articleTypeTranslations = useArticleTypeTranslations();

	const menuOptions = useAdminArticleMenuOptions({ article });

	const { input, loading, options: dropdownMenuOptions } = useAdminArticleDropdownMenuOptions({ article });

	const { title, name, episodeName, type } = article;

	return (
		<Card fluid>
			<Card.Content>
				<Flex gap={10}>
					<Flex justifyContent="flex-start" gap={10}>
						{input}

						<ArticleCoverImage
							thumb
							rounded
							article={article}
							style={{ width: 80, height: 80 }}
							loading={loading}
						/>

						<Flex>
							<Header style={{ margin: 0 }}>
								{title && (
									<Header.Subheader style={{ marginBottom: 2 }}>
										{`${t("title", "Title")}: ${title.name}`}
									</Header.Subheader>
								)}

								<div>
									{name}
									{episodeName && ` - ${episodeName}`}
									<span style={{ opacity: 0.4, fontWeight: 400, marginLeft: 5 }}>
										{`(${articleTypeTranslations[type]})`}
									</span>
								</div>
							</Header>

							<div>
								<ArticleDeliveryStatus article={article} />
							</div>
						</Flex>
					</Flex>

					{dropdownMenuOptions && <AdminPageDropdownMenu options={dropdownMenuOptions} />}
				</Flex>
			</Card.Content>

			{menuOptions && (
				<Card.Content>
					<AdminPageMenu options={menuOptions} />
				</Card.Content>
			)}
		</Card>
	);
}
