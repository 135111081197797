import { dotObject, dotPick } from "../utils/dotObject";

import DataValidator from "./DataValidator";

export default class DataParser {
	constructor(Schema) {
		if (!Schema) {
			throw new Error("Schema is required");
		}

		this.Schema = Schema;
		this.validator = new DataValidator(Schema);
	}

	getParseData(data, { validate = true, partial } = {}) {
		if (!data) {
			throw new Error("Data is required");
		}

		const objectData = dotObject(data);

		if (!validate) {
			return objectData;
		}

		return this.validator.validate(objectData, { partial });
	}

	getDotkeys(data) {
		return Object.keys(data).filter((key) => key.includes("."));
	}

	parse(data, options) {
		const dotKeys = this.getDotkeys(data);
		const parsedData = this.getParseData(data, options);

		return Object.keys(parsedData).reduce((acc, key) => {
			const exists = dotKeys.some((dotKey) => dotKey.startsWith(`${key}.`));

			if (exists) {
				return dotKeys.reduce(
					(acc, dotKey) => ({
						...acc,
						[dotKey]: dotPick(dotKey, parsedData),
					}),
					acc,
				);
			}

			return {
				...acc,
				[key]: parsedData[key],
			};
		}, {});
	}
}
