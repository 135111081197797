import { Route } from "react-router-dom";

import AdminDistribution from "../../pages/AdminDistribution/AdminDistribution";
import AdminDistributionPipeline from "../../pages/AdminDistribution/AdminDistributionPipeline";
import AdminDistributionPipelines from "../../pages/AdminDistribution/AdminDistributionPipelines";

const adminDistributionRoute = (
	<Route
		path=":distribution"
		element={<AdminDistribution />}
		handle={{ crumb: ({ distribution }) => distribution?.id }}
	>
		<Route index element={<AdminDistributionPipeline />} />

		<Route
			path="pipelines"
			handle={{ crumb: ({ t }) => t("pipelines", "Pipelines") }}
			element={<AdminDistributionPipelines />}
		/>
	</Route>
);

export default adminDistributionRoute;
