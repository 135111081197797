import { useTranslation } from "react-i18next";

import TitleVersionsCollectionDataTable from "../../../titles/components/TitleVersionsCollectionDataTable/TitleVersionsCollectionDataTable";

import AdminCard from "../../components/AdminCard/AdminCard";

export default function AdminTitleVersionsView({ title }) {
	const { t } = useTranslation();

	return (
		<AdminCard header={t("versions", "Versions")}>
			<TitleVersionsCollectionDataTable title={title} />
		</AdminCard>
	);
}
