import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import TeamMemberForm from "../TeamMemberForm/TeamMemberForm";

export default function AddMemberAction(props) {
	const { t } = useTranslation();

	return (
		<OpenButton text={t("add", "Add")} {...props}>
			{({ setOpen }) => {
				return <TeamMemberForm {...props} onClose={() => setOpen(false)} />;
			}}
		</OpenButton>
	);
}
