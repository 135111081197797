import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Table from "../../../../ui/components/Table/Table";

import AdminCard from "../../../components/AdminCard/AdminCard";

import useAdminUserPricesColumns from "../hooks/useAdminUserPricesColumns";

import AdminUserPricesActions from "./AdminUserPricesActions";

export default function AdminUserPrices({ user, organization }) {
	const { t } = useTranslation();

	const data = useMemo(() => Object.values(user.prices?.[organization.id] || {}), [organization.id, user.prices]);

	const columns = useAdminUserPricesColumns({ user, organization });

	return (
		<AdminCard
			header={t("defaultPricing", "Default pricing")}
			extra={<AdminUserPricesActions user={user} organization={organization} />}
		>
			<Table data={data} columns={columns} footer={false} />
		</AdminCard>
	);
}
