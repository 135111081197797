import AdminPageView from "../../components/AdminPage/AdminPageView";

import AdminExportHeader from "./components/AdminExportHeader";

export default function AdminExportView(props) {
	return (
		<AdminPageView {...props}>
			<AdminExportHeader />
		</AdminPageView>
	);
}
