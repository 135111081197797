import equal from "fast-deep-equal";

import FirestoreCollection from "../../firestore/FirestoreCollection";
import { MetaData } from "../../types/MetaData";

import { Title } from "./types/Title";

export default class TitlesCollection extends FirestoreCollection {
	static collectionName = "titles";
	static Schema = Title;


	async sideEffects({ update, transaction }) {
		const { before, after } = update;

		const serie = update.get("serie");
		const imprint = update.get("imprint");

		const publisherIds = this.utils.arrayUnion(after.publisher.id);

		if (serie.isCreated()) {
			await this.api.series.create({ ...serie.after, publisher: after.publisher }, { transaction });
		}

		if (imprint.isCreated()) {
			await this.api.imprints.create({ ...imprint.after, publisher: after.publisher }, { transaction });
		}

		if (before.exists && after.exists) {
			const metaDataBefore = MetaData.parse(before);
			const metaDataAfter = MetaData.parse(after);

			const hasChanged = !equal(metaDataBefore, metaDataAfter);

			if (hasChanged) {
				const articles = await this.getSyncedArticles(after);

				await this.api.articles.updateAll(
					articles.map((article) => ({
						ref: article.ref,
						title: after,
						titleSync: true,
					})),
					{ transaction },
				);
			}
		}

		if (before.exists && !after.exists) {
			const articles = await this.getArticles(after);

			await this.api.articles.updateAll(
				articles.map((article) => ({
					ref: article.ref,
					title: false,
					titleSync: false,
				})),
				{ transaction },
			);
		}
	}

	getArticles(title) {
		return this.api.articles.getAll((query) => query.where("title.id", "==", title.id));
	}

	getSyncedArticles(title) {
		return this.api.articles.getAll((query) =>
			query.where("title.id", "==", title.id).where("titleSync", "==", true),
		);
	}

	addArticle({ title, article }) {
		if (!article.exists) {
			return this.api.articles.create({
				...article,
				title,
				titleSync: true,
				publisher: title.publisher,
			});
		}
		return this.api.articles.update({
			ref: article.ref,
			title,
		});
	}

	removeArticle({ article }) {
		return this.api.articles.update({
			ref: article.ref,
			title: false,
			titleSync: false,
		});
	}
}
