export default function Flex({
	width = "100%",
	flex,
	justifyContent = "space-between",
	alignItems = "center",
	flexDirection = "row",
	gap = "normal",
	style,
	children,
	wrap,
	...props
}) {
	return (
		<div
			style={{
				width,
				display: "flex",
				justifyContent,
				alignItems,
				gap,
				flex,
				flexDirection,
				flexWrap: wrap ? "wrap" : "nowrap",
				...style,
			}}
			{...props}
		>
			{children}
		</div>
	);
}
