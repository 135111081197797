import api from "../../../../api";
import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useScriptsTableColumns from "../../hooks/useScriptsTableColumns";

export default function ScriptsCollectionDataTable({ ...props }) {
	const columns = useScriptsTableColumns();

	return (
		<CollectionDataTable
			hoverRow={false}
			query={api.scripts.getQuery()}
			columns={columns}
			sortBy={{
				id: "created",
				desc: true,
			}}
			{...props}
		/>
	);
}
