import { Route } from "react-router-dom";

import ForwardContext from "../../../../layouts/ForwardContext";

import AdminArticle from "../../pages/AdminArticle/AdminArticle";
import AdminArticleArticleBundles from "../../pages/AdminArticle/AdminArticleArticleBundles";
import AdminArticleDistribution from "../../pages/AdminArticle/AdminArticleDistribution";
import AdminArticleExports from "../../pages/AdminArticle/AdminArticleExports";
import AdminArticleProductions from "../../pages/AdminArticle/AdminArticleProductions";
import AdminArticleSettings from "../../pages/AdminArticle/AdminArticleSettings";
import AdminArticleVersion from "../../pages/AdminArticle/AdminArticleVersion";
import AdminArticleVersionChange from "../../pages/AdminArticle/AdminArticleVersionChange";
import AdminArticleVersions from "../../pages/AdminArticle/AdminArticleVersions";

import adminArtifactsRoute from "../artifacts/adminArtifactsRoute";
import adminDistributionRoute from "../distributions/adminDistributionRoute";
import adminExportRoute from "../exports/adminExportRoute";

const adminArticleRoute = (
	<Route path=":article" element={<AdminArticle />} handle={{ crumb: ({ article }) => article?.name }}>
		<Route index element={<AdminArticleSettings />} handle={{ crumb: ({ t }) => t("metaData", "Meta data") }} />

		<Route
			path="bundles"
			element={<AdminArticleArticleBundles />}
			handle={{ crumb: ({ t }) => t("bundles", "Bundles") }}
		/>

		{adminArtifactsRoute}

		<Route
			path="distributions"
			element={<AdminArticleDistribution />}
			handle={{ crumb: ({ t }) => t("distributions", "distributions") }}
		/>

		<Route
			path="distributions"
			element={<ForwardContext />}
			handle={{ crumb: ({ t }) => t("distributions", "Distributions") }}
		>
			{adminDistributionRoute}
		</Route>

		<Route
			path="productions"
			element={<AdminArticleProductions />}
			handle={{ crumb: ({ t }) => t("productions", "Productions") }}
		/>

		<Route path="versions" handle={{ crumb: ({ t }) => t("versions", "Versions") }}>
			<Route index element={<AdminArticleVersions />} />

			<Route path=":version" element={<AdminArticleVersion />} handle={{ crumb: ({ version }) => version?.id }}>
				<Route path="after" element={<AdminArticleVersionChange change="after" />} />

				<Route path="before" element={<AdminArticleVersionChange change="before" />} />

				<Route path="revert" element={<AdminArticleVersionChange change="after" revert />} />
			</Route>
		</Route>

		<Route
			path="exports"
			element={<AdminArticleExports />}
			handle={{ crumb: ({ t }) => t("exports", "Exports") }}
		/>

		<Route path="exports" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("exports", "Exports") }}>
			{adminExportRoute}
		</Route>
	</Route>
);

export default adminArticleRoute;
