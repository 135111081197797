import { Table } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import Flex from "../../../../../components/Flex/Flex";

export default function TableFooter({ table, selectable, children }) {
	const { t } = useTranslation();

	const { footerGroups, selectedFlatRows } = table;

	return (
		<Table.Footer fullWidth>
			{children ? (
				<Table.Row>
					<Table.HeaderCell colSpan={99}>
						<Flex justifyContent="space-between" gap={10} wrap>
							<div>
								{children.type ? <children.type {...children.props} {...table} /> : children(table)}
							</div>

							{selectable && (
								<div>
									{t("amountOfRowsSelected", "{{amount}} row(s) selected", {
										amount: selectedFlatRows?.length,
									})}
								</div>
							)}
						</Flex>
					</Table.HeaderCell>
				</Table.Row>
			) : (
				footerGroups.map((group) => (
					<Table.Row {...group.getFooterGroupProps()}>
						{group.headers.map((column) => (
							<Table.HeaderCell {...column.getFooterProps()} textAlign={column.textAlign || "left"}>
								{column.render("Footer")}
							</Table.HeaderCell>
						))}
					</Table.Row>
				))
			)}
		</Table.Footer>
	);
}
