import moment from "moment";

import React from "react";
import { useTranslation } from "react-i18next";

import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";

import Label from "../../../ui/components/Label/Label";

export default function OfferExpiresLabel({ offer }) {
	const { t } = useTranslation();

	const prefix = offer.status === offerStatuses.EXPIRED ? t("expired", "Expired") : t("expires", "Expires");
	const expires = moment(offer.expiresAt?.toDate()).fromNow();

	return (
		<Label basic>
			{prefix}

			<Label.Detail style={{ marginLeft: 5 }}>{expires}</Label.Detail>
		</Label>
	);
}
