import * as XLSX from "xlsx";

import renderHandlebarsTemplate from "../handlebars/renderHandlebarsTemplate";
import fetchMetadata from "../utils/fetchMetadata";

export default async function generateExcelFromTemplate({
	document,
	domParser = (html) => {
		const { JSDOM } = require("jsdom");
		const dom = new JSDOM(html);
		return dom.window.document;
	},
	type = "binary",
}) {
	const { excelTemplate } = document.channel;
	const metadataDocument = await fetchMetadata({ document });
	const html = renderHandlebarsTemplate({ template: excelTemplate, data: metadataDocument });
	const doc = domParser(html);
	const table = doc.querySelector("table");
	const worksheet = XLSX.utils.table_to_sheet(table);
	const workbook = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(workbook, worksheet, "Blad1");
	return XLSX.write(workbook, { bookType: "xlsx", type });
}
