import { Dropdown, Message } from "semantic-ui-react";

import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useProfile } from "../../../authentication/state/profile";
import Sentry from "../../../sentry/sentry";

import DropdownMenu from "../DropdownMenu/DropdownMenu";

export default function ErrorMessage({ header, children, error, ...props }) {
	const user = useProfile();
	const { t } = useTranslation();

	const [visible, setVisible] = useState(true);

	const eventId = useMemo(() => {
		if (error) {
			console.error(error);
			return Sentry.captureException(error);
		}
	}, [error]);

	if (!visible) {
		return null;
	}

	const message =
		typeof error === "string" ? error : error?.message || t("errorMessageHeader", "Something went wrong");

	return (
		<Message negative {...props}>
			{header && <Message.Header>{header}</Message.Header>}

			{children || message}

			<DropdownMenu style={{ position: "absolute", right: 10, top: 15, fontSize: 12 }}>
				{() => (
					<>
						{eventId && (
							<Dropdown.Item
								onClick={() =>
									Sentry.showReportDialog({
										event_id: eventId,
										user,
									})
								}
							>
								{t("reportIssue", "Report issue")}
							</Dropdown.Item>
						)}
						<Dropdown.Item onClick={() => setVisible(false)}>{t("dismiss", "Dismiss")}</Dropdown.Item>
					</>
				)}
			</DropdownMenu>
		</Message>
	);
}
