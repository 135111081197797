import { Icon, Statistic } from "semantic-ui-react";

import React from "react";

import Popup from "../../../ui/components/Popup/Popup";

export default function DashboardStatisticDetail({ text, type, icon, amount }) {
	if (type === "total") {
		return null;
	}

	return (
		<Popup
			content={text}
			trigger={
				<Statistic.Value>
					{typeof icon === "string" ? <Icon name={icon} /> : icon}
					{amount}
				</Statistic.Value>
			}
			position="right center"
		/>
	);
}
